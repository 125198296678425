import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IOSSwitch from '../../../common/IOSSwitch';

const FirstNameField = (props) => {
  const {
    classes,
    cartLimit,
    handleLimitSwitch,
    hasOrderLimit,
    setNewUserInput,
    t,
  } = props;

  return (
    <div className={classes.imageInput}>
      <IOSSwitch
        checked={hasOrderLimit}
        handleActivate={() => handleLimitSwitch()}
        label=''
        title={
          hasOrderLimit ? t('tooltips:deactivate') : t('tooltips:activate')
        }
      />
      {sessionStorage.getItem('currency')}&nbsp;
      <TextField
        disabled={!hasOrderLimit}
        name='cartLimit'
        value={cartLimit || ''}
        className={classes.textField}
        style={{ marginBottom: '0' }}
        onChange={(e) => {
          const val = {
            value: e.target.value.replace(/([^0-9.,\s])/, ''),
            name: 'cartLimit',
          };

          setNewUserInput(val);
        }}
      />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.hasOrderLimit === nextProps.hasOrderLimit &&
    prevProps.cartLimit === nextProps.cartLimit
  );
}

export default withStyles(styles)(React.memo(FirstNameField, areEqual));
