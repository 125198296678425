import { put } from 'redux-saga/effects';

import { GET_ARTICLES } from '../constants/api';

import { setArticles } from '../actions/info';
import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';

export function* getArticles() {
  try {
    const articles = yield callHttp(get, GET_ARTICLES);
    yield put(setArticles(articles));
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}
