export default theme => ({
  main_content: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column'
  },
  content_row: {
    width: '100%',
    marginTop: '50px'
  },
  content_el_row: {
    width: '100%',
    minWidth: '42px'
  },
  lightButton: {
    backgroundColor: theme.palette.primary.buttonColor,
    marginBottom: '20px',
    width: '50%'
  }
});
