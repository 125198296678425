import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Treeview from '../TreeView';

class TabWithTree extends React.Component {
  render() {
    const { classes, product, type, children } = this.props;

    return (
      <div className={classNames(classes.main, 'spare-parts')}>
        <Treeview className={classes.treeColumn} root={product} type={type} />
        <div
          className={classNames(classes.contentColumn, 'spare-parts-content')}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withNamespaces('productPage')(withStyles(styles)(TabWithTree));