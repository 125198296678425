export default theme => ({
  main_content: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    margin: '20px 0 0 0'
  },
  content_row: {
    width: '100%',
    marginBottom: '30px'
  },
  content_el_row: {
    width: '100%',
    minWidth: '42px'
  },
  lightButton: {
    backgroundColor: theme.palette.primary.buttonColor,
    width: '60%'
  },
  emailHeader: {
    textTransform: 'none',
    fontSize: '14px',
    marginBottom: '5px'
  }
});
