const drawerWidth = 240;

export default theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: '100%',
    backgroundColor: 'black'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  menuItemDrawer: {
    height: 24,
    borderRadius: '1.2em',
    borderColor: 'white',
    alignItems: 'flex-end',
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
    paddingLeft: '1em',
    paddingRight: '1em',
    marginRight: '5px',
    '&:last-child': {
      marginRight: '5px',
    },
    marginTop: 10,
    width: 'fit-content',
    fontSize: '14px',
    color:'white',
    textTransform: 'uppercase',

    background: '#2F3234',
    '&:hover': {
      background: '#7F868A',
    }
  },
  menuDrawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  iconDrawer: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  listItem: {
    width: 'fit-content',
    bottom: -130
  },
  listItemIcon: {
    minWidth: 5,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
});
