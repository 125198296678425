import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';

import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  setShippingAddressInput,
  setShippingTitle,
  setShippingCountry,
  postNewShippingAddress,
  setEditableShippingAddress,
  addCartShippingAddress,
  setSaveAddress,
  setNewAddress,
} from '../../../../actions/cart';
import { openErrorSnack } from '../../../../actions/startpage';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';
import { Checkbox, Button } from '@material-ui/core';

class ShippingForm extends React.Component {
  state = {
    checked: false,
    countries: [],
    country: {},
  };

  componentDidMount() {
    const { countries } = this.props;

    if (countries) {
      this.setState((prevState) => {
        return {
          countries,
        };
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { countries, address } = this.props;

    if (prevProps.countries !== countries) {
      this.setState((prevState) => {
        return {
          countries,
        };
      });
    }
    if (address !== prevProps.address && address.country) {
      this.setState((prevState) => {
        return {
          country: address.country,
        };
      });
    }
    if (
      address.saveAddress !== undefined &&
      this.state.checked !== address.saveAddress
    )
      this.setState((prevState) => {
        return { checked: !this.state.checked };
      });
  }

  validateForm = () => {
    let message = '';

    if (!this.props.address.companyName) {
      message = this.props.t('Validation Messages:Company Name is Required!');
    } else if (!this.props.address.streetName) {
      message = this.props.t('Validation Messages:Street Name is Required!');
    } /*else if (!this.props.address.streetNumber) {
      message = this.props.t('Validation Messages:Street Number is Required!');
    } */ else if (!this.props.address.postalCode) {
      message = this.props.t('Validation Messages:Postal Code is Required!');
    } else if (!this.props.address.city) {
      message = this.props.t('Validation Messages:City is Required!');
    } else if (!this.props.address.country) {
      message = this.props.t('Validation Messages:Country is Required!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.submitForm();
  };

  submitForm = () => {
    this.validateForm() && this.props.handleSubmit();
  };

  handleChecked = (e) => {
    this.props.setSaveAddress(!this.state.checked);
    /* this.props.postNewShippingAddress({ address: address }) */
  };

  handleEnableEdit = () => {
    this.props.setEditableShippingAddress();
    this.props.setNewAddress(true);
  };

  handleConfirm = () => {
    if (this.props.address.saveAddress === undefined) {
      this.props.setSaveAddress(this.state.checked);
      this.props.addCartShippingAddress({ address: this.props.address });
    } else this.props.addCartShippingAddress({ address: this.props.address });

    this.props.setNewAddress(this.state.checked);
  };

  setShippingCountry = (country) => {
    this.props.setShippingCountry(country);
    this.setState((prevState) => {
      return {
        country,
      };
    });
  };

  render() {
    const { classes, t, titles, address, isEditable } = this.props;
    const { countries, country } = this.state;
    const selectedTitle = address.title ? titles.find(item => item.code === address.title) : null

    return (
      <form className={classes.shippingModal} onSubmit={this.handleSubmit}>
        <div className={classes.contentRow}>
          {isEditable? (<RadioGroup
            name='title'
            className={classes.groupRadio}
            value={(address && address.title) || ''}
            onChange={(e) =>
              isEditable && this.props.setShippingTitle(e.target.value)
            }
          >
            {titles &&
                titles.map((v, i) => (
                <FormControlLabel
                  key={v.code}
                  value={v.code}
                  control={<Radio />}
                  label={v.name}
                />
              ))}
          </RadioGroup>):
              (<div className={classes.groupRadio}>{selectedTitle ? selectedTitle.name : ''}</div>)}
        </div>
        <InputWrapper header={t('firstName')} isRequire={false}>
          <TextField
            name='firstName'
            className={classes.textField}
            value={(address && address.firstName) || ''}
            onChange={(e) => this.props.setShippingAddressInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        <InputWrapper header={t('secondName')} isRequire={false}>
          <TextField
            name='lastName'
            className={classes.textField}
            value={(address && address.lastName) || ''}
            onChange={(e) => this.props.setShippingAddressInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        <InputWrapper header={t('company')} isRequire={true}>
          <TextField
            name='companyName'
            className={classes.textField}
            value={(address && address.companyName) || ''}
            onChange={(e) => this.props.setShippingAddressInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        <InputWrapper header={t('additional')}>
          <TextField
            name='additional'
            className={classes.textField}
            value={(address && address.additional) || ''}
            onChange={(e) => this.props.setShippingAddressInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        <div className='multi-field-row'>
          <InputWrapper
            header={t('street')}
            isRequire={true}
            style={{ flex: 5 }}
          >
            <TextField
              name='streetName'
              value={this.props.address.streetName || ''}
              className={classes.textField}
              onChange={(e) => this.props.setShippingAddressInput(e.target)}
              disabled={!isEditable}
            />
          </InputWrapper>

          <InputWrapper header={t('no')} isRequire={false} style={{ flex: 2 }}>
            <TextField
              name='streetNumber'
              value={this.props.address.streetNumber || ''}
              className={classes.textField}
              onChange={(e) => this.props.setShippingAddressInput(e.target)}
              disabled={!isEditable}
            />
          </InputWrapper>
        </div>
        <div className='multi-field-row'>
          <InputWrapper
            header={t('postalCode')}
            isRequire={true}
            style={{ flex: 2 }}
          >
            <TextField
              name='postalCode'
              value={this.props.address.postalCode || ''}
              onChange={(e) => this.props.setShippingAddressInput(e.target)}
              className={classNames(classes.textField, 'disable-arrows')}
              disabled={!isEditable}
              inputProps={{ maxLength: '16' }}
            />
          </InputWrapper>
          <InputWrapper header={t('city')} isRequire={true} style={{ flex: 5 }}>
            <TextField
              name='city'
              value={this.props.address.city || ''}
              onChange={(e) => this.props.setShippingAddressInput(e.target)}
              className={classes.textField}
              disabled={!isEditable}
            />
          </InputWrapper>
        </div>
        <InputWrapper isRequire={true} header={t('country')}>
          <Autocomplete
            className={classes.textField}
            disabled={!isEditable}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option._id === value._id}
            id='combo-box-demo'
            onChange={(e, value) =>
              value ? this.setShippingCountry(value) : null
            }
            options={countries}
            placeholder={t('country')}
            renderInput={(params) => (
              <TextField {...params} label='' variant='outlined' />
            )}
            style={{ width: '100%' }}
            value={countries.find((v) => v.code === country) || {}}
          />
        </InputWrapper>
        <InputWrapper header={t('phone')}>
          <TextField
            name='phone'
            className={classes.textField}
            value={(address && address.phone) || ''}
            onChange={(e) => this.props.setShippingAddressInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        <div className={classes.footer}>
          <div>
            {this.props.isNewAddress ? (
              <div className={classes.checkboxContainer}>
                <Checkbox
                  disabled={
                    address.saveAddress === undefined ||
                    (address.saveAddress !== undefined && isEditable)
                      ? false
                      : true
                  }
                  checked={
                    address.saveAddress === undefined
                      ? this.state.checked
                      : address.saveAddress
                  }
                  value={this.state.checked}
                  onChange={() => this.handleChecked()}
                />
                <div className={classes.label}>{t('saveAsTemplate')}</div>
              </div>
            ) : null}
          </div>

          {address.saveAddress === undefined ||
          (address.saveAddress !== undefined && isEditable) ? (
            <Button
              className={classes.button}
              onClick={() => this.validateForm() && this.handleConfirm()}
            >
              {t('confirm')}
            </Button>
          ) : (
            <img
              alt='edit'
              className='clickable'
              onClick={() => this.handleEnableEdit()}
              title={t('tooltips:edit')}
              src='/images/icons/MyAccount/icon-edit.png'
            />
          )}
        </div>
      </form>
    );
  }
}

export default connectTo(
  (state) => ({
    address: state.cart.shippingAddress,
    isEditable: state.cart.isEditable,
    countries: state.metadata.countries,
    titles: state.metadata.titles,
    isNewAddress: state.cart.isNewAddress,
    saveAddress: state.cart.saveAddress,
  }),
  {
    setShippingAddressInput,
    setShippingTitle,
    setShippingCountry,
    openErrorSnack,
    postNewShippingAddress,
    setEditableShippingAddress,
    addCartShippingAddress,
    setSaveAddress,
    setNewAddress,
  },
  withNamespaces([
    'accountPage',
    'messages',
    'Validation Messages',
    'tooltips',
  ])(withStyles(styles)(ShippingForm))
);
