import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { formatMoney } from '../../../../utils/localization';
import { getLocaleDateString } from '../../../../utils/localization';

class OrderList extends React.Component {
  render() {
    const { classes, t, orders, handleOrderSelect, selectedId } = this.props;

    return (
      <TableBody>
        {orders.map((order, i) => (
          <TableRow
            className={classNames(
              classes.row,
              selectedId === order.orderId ? classes.selected : ''
            )}
            key={i}
          >
            <TableCell
              className={classNames(
                classes.cellCentred,
                'orderList_tableBodyCells'
              )}
            >
              {getLocaleDateString(order.createdAt)}
            </TableCell>
            <TableCell
              title={t('Show Details')}
              className={classNames(
                classes.cellCentred,
                'orderList_tableBodyCells',
                'orderList_tableBodyCells_sm'
              )}
            >
              {order.orderId}
            </TableCell>
            <TableCell
              className={classNames(
                classes.nameCell,
                classes.cellCentred,
                'orderList_tableBodyCells',
                'orderList_tableBodyCells_md'
              )}
            >
              {order.status.name}
            </TableCell>
            <TableCell className={classes.cellCentred}>
              {formatMoney(order.totalWithTaxAndShipping, order.currency)}
            </TableCell>
            <TableCell
              className={classNames(
                classes.cellCentred,
                'orderList_tableBodyCells',
                'orderList_tableBodyCells_md_l'
              )}
            >
              {order &&
              order.user &&
              order.user.firstName &&
              order.user.lastName ? (
                <span>
                  {order.user.firstName} {order.user.lastName}
                </span>
              ) : null}
            </TableCell>
            <TableCell className={classes.cellCentred}>
              <Tooltip
                title={t('Order Details')}
                placement={'top'}
                enterDelay={300}
              >
                <ExpandMoreIcon
                  className={
                    selectedId === order.orderId
                      ? classes.iconUnfold
                      : classes.iconFold
                  }
                  onClick={() => {
                    handleOrderSelect(order);
                  }}
                  style={{ paddingTop: '5px' }}
                ></ExpandMoreIcon>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
}

export default withNamespaces('myOrders')(withStyles(styles)(OrderList));
