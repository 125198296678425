import { createAction } from 'redux-act';

export const setCart = createAction('setCart');
export const getCart = createAction('getCart');
export const setCheckoutCart = createAction('setCheckoutCart');
export const getCheckoutCart = createAction('getCheckoutCart');
export const addProduct = createAction('addProduct');
export const addProductsFromOrder = createAction('addProductsFromOrder');
export const clearCart = createAction('clearCart');
export const deleteProduct = createAction('deleteProduct');
export const updateProduct = createAction('updateProduct');
export const updateEmptyProduct = createAction('updateEmptyProduct');

export const setShippingAddressInput = createAction(
  'setCartShippingAddressInput'
);
export const setShippingTitle = createAction('setCartShippingAddressTitle');
export const setShippingCountry = createAction('setCartShippingAddressCountry');

export const getDefaultShippingAddress = createAction(
  'getDefaultShippingAddress'
);
export const setDefaultShippingAddress = createAction(
  'setDefaultShippingAddress'
);
export const setShippingAddress = createAction('setCartShippingAddress');
export const getAsDefaultBillingAddress = createAction(
  'getAsDefaultBillingAddress'
);
export const setAsDefaultBillingAddress = createAction(
  'setAsDefaultBillingAddress'
);

export const postNewShippingAddress = createAction('postNewShippingAddress');
export const setNewShippingAddress = createAction('setNewShippingAddress');

export const setEditableShippingAddress = createAction(
  'setEditableShippingAddress'
);
export const setInternalOrderNumber = createAction('setInternalOrderNumber');
// export const setEditableInfo = createAction('setEditableInfo');

export const setShippingOptions = createAction('setShippingOptions');
export const getShippingOptions = createAction('getShippingOption');
export const addShippingOption = createAction('addShippingOption');
export const setShippingOption = createAction('setShippingOption');

export const setPaymentMethods = createAction('setPaymentMethods');
export const getPaymentMethods = createAction('getPaymentMethods');
export const addPaymentMethod = createAction('addPaymentMethod');
export const setPaymentMethod = createAction('setPaymentMethod');
export const addCartShippingAddress = createAction('addCartShippingAddress');
export const setCartShippingAddress = createAction('setCartShippingAddress');
export const setSaveAddress = createAction('setSaveAddress');
export const setNewAddress = createAction('setNewAddress');
export const setDisableCheckbox = createAction('setDisableCheckbox');

export const getTerms = createAction('getTerms');
export const setTerms = createAction('setTerms');
export const checkTerm = createAction('checkTerm');

export const order = createAction('order');
export const setOrderResult = createAction('setOrderResult');
export const addCartDiscount = createAction('addCartDiscount');
export const removeCartDiscount = createAction('removeCartDiscount');

export const setComment = createAction('setComment');
