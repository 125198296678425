export default (theme) => ({
  paper: {
    display: 'block',
    overflow: 'auto',
    outline: 'none',
    borderTop: '3px solid',
    borderColor: theme.palette.primary.buttonColor,
  },
  closeIcon: {
    position: 'absolute !important',
    top: '10px',
    right: '10px'
  },
  imageSection: {
    height: '200px',
    background:
      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/backgrounds/work-3383831_1280.jpg) no-repeat;',
    backgroundSize: '146%',
    backgroundPositionX: '36%',
    backgroundPositionY: '89%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  otherSection: {
    minHeight: '240px',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5em',
    textAlign: 'center'
  },
  inputRow: {
    width: '85%',
    marginBottom: '10px',
  },
  textInput: {
    width: '100%',
  },
  button: {
    background: theme.palette.primary.buttonColor,
  },
  buttonRow: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
});
