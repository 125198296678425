export default theme => ({
  panel: {
    display: 'flex',
    cursor: 'pointer'
  },
  navigation: {
	overflowX: 'auto',
	width: '100%'
  },
  content: {
    flex: 3
  },
  toggleItem: {
    display: 'flex',
    alignItems: 'center'
  },
});
