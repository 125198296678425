export default (theme) => ({
  content: {
    width: '100%',
  },
  smallHeader: {
    color: 'black',
    fontSize: '0.95em',
  },
  bigHeader: {
    color: 'black',
    fontSize: '1.5em',
    textTransform: 'uppercase',
  },
  infoBar: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '300px',
    height: 'auto',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    /* marginRight: '5px', */
  },
  carouselRoot: { width: '60%' },
  statusImage: {
    marginRight: '5px',
  },
  tabs: {
    marginTop: '30px',
  },
  contentColumn: {
    /*  marginLeft: '20px', */
    flex: 1,
  },
  statusRow: {
    display: 'flex',
    margin: '15px 0',
    alignItems: 'center',
  },
  amountInput: {
    width: '65px',
  },
  matNumber: {
    fontSize: '0.8em',
    color: 'black',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0 10px',
  },
  goodStatus: {
    color: theme.palette.primary.green,
    paddingTop: '2px',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  badStatus: {
    color: theme.palette.primary.buttonColor,
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  limitedStatus:{
    color: theme.palette.primary.orange,
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  divider: {
    margin: '25px 0',
  },
  buyInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectTitle: {
    fontSize: '0.75em',
    color: 'black',
    marginBottom: '5px',
  },
  priceColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  price: {
    fontSize: '1.5em',
    color: theme.palette.primary.blue,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  originalPrice: {
    fontSize: '1.5em',
    color: theme.palette.primary.blue,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  discountPrice: {
    color: theme.palette.primary.blackPrice,
    marginRight: '10px',
    fontSize: '1.2em',
    textDecoration: 'line-through',
  },
  address: {
    fontSize: '0.75em',
    background: theme.palette.primary.grey,
    borderRadius: '15px',
    padding: '0 15px',
    minHeight: '1.5em',
    marginLeft: '20px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    height: 58
  },
  buyButton: {
    background: 'white',
    border: '3px solid ',
    color: theme.palette.primary.buttonColor,
    borderColor: theme.palette.primary.buttonColor,
    letterSpacing: '1px',
  },
  buyButtonDisabled: {
    color: '#666',
    borderColor: '#666',
    '&:hover': {
      color: '#666 !important',
    },
  },
  buttonRoot: {
    padding: '5px',
    '&:hover': {
      color: theme.palette.primary.buttonColor,
    },
  },
  cartIcon: {
    width: 20,
    height: 20,
    marginRight: 9,
    display: 'block',
  },
  matNum: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
});
