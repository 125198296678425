import React from 'react';

import styles from './style';
import {withNamespaces} from 'react-i18next';
import {connectTo} from '../../../utils/generic';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import TechnicalData from '../../common/technicalData';
/* import ThreeDModel from '../ThreeDModel'; */
import {formatMoney} from '../../../utils/localization';
import ReactSVG from 'react-svg';
import NumberInput from '../../common/NumberInput';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';

import {addProduct} from '../../../actions/cart';
import {openLoginForm} from "../../../actions/auth";
import {getShowDiscount} from "../../../utils/discounts";
import showDeliveryTime from "../../../utils/showDeliveryTime";
import {PRICE_TYPE_INDIVIDUAL} from "../../../constants/common";
import QuizIcon from "@mui/icons-material/Quiz";
import {openInquiry, setInquiryProduct} from "../../../actions/products";
import InquiryIconButton from '../../common/Inquiry/Button'

const ProductImage = (props) => {
  const { classes, detail, imageToStart } = props;
  let images = null;

  if (imageToStart === null) {
    images = [{ url: '/images/product_example_image.png' }];
  } else {
    images = detail.image;
  }

  return (
    <div className={classNames(classes.imageContainer, 'image-container')}>
      {detail ? (
        <Carousel className={classNames(classes.carouselRoot, 'carousel-root')}>
          {images.map((image, i) => (
            <div key={(Math.random() + 1).toString(36).substring(7)}>
              <img key={i} src={image.url} />
            </div>
          ))}
        </Carousel>
      ) : null}
    </div>
  );
};

const StatusRow = (props) => {
  const { classes, detail, token, t } = props;
  return (
    <div className={classNames(classes.statusRow, 'mat-num-medium')}>
      <div className={classes.matNum}>
        <div className={classes.smallHeader}>{t('materialNumber')}:</div>
        <div className={classes.matNumber}>{detail ? detail.code : ''}</div>
      </div>
      {token && <div
        className={classNames(
          detail && detail.isOrderable
              ? (detail.inStock
                 ? classes.goodStatus
                 : classes.limitedStatus)
              : classes.badStatus,
          // detail && detail.inStock ? classes.goodStatus : classes.badStatus,
          'center-all',
          'bold'
        )}
      >
        <img
          alt='status'
          className={classes.statusImage}
          src={
          detail && detail.isOrderable
            ? (detail.inStock
              ? '/images/icons/icon-available.svg'
              : '/images/icons/icon-available-limited.svg')
            : '/images/icons/icon-not-available.svg'
          }
        />
          {detail && detail.isOrderable
              ? (detail.discountPrice > 0 || detail.price > 0) && showDeliveryTime(detail.inStock, detail.deliveryTime)
            : t('notAvailable') }
      </div>}
    </div>
  );
};

const DetailName = (props) => {
  const { classes, detail } = props;
  return (
    <div
      className={classNames(
        classes.bigHeader,
        'bold',
        'large-font',
        'big-header-medium'
      )}
    >
      {detail ? (
        <Link to={`/product/${detail.alias}/`}> {detail.name} </Link>
      ) : null}
    </div>
  );
};

class BuyMachine extends React.Component {
  state = {
    count: 1,
  };
  handleInquiry (product) {
    this.props.setInquiryProduct({product:{productName:  product.productName, productCode:  product.productCode, quantity: product.quantity}})
    this.props.openInquiry()
  }
  render() {
    const { classes, t, detail, settings, showPricesForGuest } = this.props;
      const showDiscount = getShowDiscount(settings)

      return (
      <div className={classes.content}>
        <div className={classNames(classes.infoBar, 'info-bar')}>
          <ProductImage
            classes={classes}
            detail={detail}
            imageToStart={
              detail && detail.image && detail.image[0]
                ? detail.image[0].url
                : null
            }
          />
          <div
            className={classNames(
              classes.contentColumn,
              'details-content-column'
            )}
          >
            <DetailName classes={classes} detail={detail} />

            <StatusRow classes={classes} detail={detail} t={t} />

            <div
              style={{ fontWeight: '300' }}
              className={classNames(classes.infoRow, 'info-row')}
              dangerouslySetInnerHTML={{
                __html:
                  detail && detail.details
                    ? detail.details.shortDescription
                    : '',
              }}
            />
            {/* <Divider className={classes.divider} /> */}
            {(detail?.price > 0 || detail.priceType === PRICE_TYPE_INDIVIDUAL) ? (
              <div>
                <div className={classes.buyInfoRow}>
                  <div>
                    <div className={classes.selectTitle}>{t('amount')}</div>
                    <NumberInput
                      value={this.state.count}
                      className={classes.amountInput}
                      onChange={(val) =>
                        this.setState({
                          count: val,
                        })
                      }
                    />
                  </div>
                  <div className={classes.priceColumn}>
                    {detail.discountPrice > 0 ? (
                        <div className='pdp-price-column'>
                          {(showDiscount || detail.priceType === PRICE_TYPE_INDIVIDUAL) &&
                          <div
                              className={classNames(
                                  classes.price,
                                  classes.discountPrice,
                                  'bold'
                              )}
                          >
                            {detail.price > 0 && formatMoney(this.state.count * detail.price)}
                          </div>}

                        <div
                          className={classNames(
                            classes.price,
                            'bold',
                            'price-medium'
                          )}
                          style = {{color: (!showDiscount  && detail.priceType !== PRICE_TYPE_INDIVIDUAL) &&'black'}}
                        >
                          {formatMoney(this.state.count * detail.discountPrice)}
                          {detail.priceType === PRICE_TYPE_INDIVIDUAL && '*'}
                        </div>
                       </div>
                    ) : (
                      <div
                        className={classNames(
                          classes.originalPrice,
                          'bold',
                          'price-medium'
                        )}
                      >
                        {formatMoney(this.state.count * detail.price)}
                      </div>
                    )}

                    <div className={classes.address}>{t('cart:taxInfo')}</div>
                  </div>
                </div>

                <div className={classes.buttonRow}>
                  <Button
                    className={classNames(
                      classes.buyButton,
                      detail && detail.isOrderable
                        ? ''
                        : classes.buyButtonDisabled
                    )}
                    classes={{
                      root: classes.buttonRoot,
                    }}
                    onClick={() =>
                      /*this.props.token ?*/ this.props.addProduct({
                        code:
                          detail && detail.product
                            ? detail.product.code
                            : detail.code,
                        quantity: this.state.count,
                      }) /*:
                          this.props.openLoginForm({centered:true})*/
                    }
                  >
                          <ReactSVG
                              alt='cart'
                                  svgClassName={
                                      detail && detail.isOrderable
                                          ?
                                          classNames(classes.cartIcon)
                                          :
                                          classNames(classes.cartIcon, 'colored-gray')
                                  }
                              src='/updated_images/svg/cart filled 15px.svg'
                          />
                    {t('cart:intoCart')}
                  </Button>
                </div>
              </div>
            ) : showPricesForGuest ? (
              <div className={classNames(classes.price, 'bold')}>
                {t('requestPrice')}
              </div>
            ): (
              <div>
                <div className={classes.buyInfoRow}>
                  <div>
                    <div className={classes.selectTitle}>{t('amount')}</div>
                    <NumberInput
                        value={this.state.count}
                        className={classes.amountInput}
                        onChange={(val) =>
                            this.setState({
                              count: val,
                            })
                        }
                    />
                  </div>
                  <div
                      className={classNames(
                          classes.originalPrice,
                          'bold',
                          'price-medium'
                      )}
                  >
                    {t('productPage:guestPrice')}
                  </div>
                </div>
                <div className={classes.buttonRow}>

                  <InquiryIconButton
                  onClick={ ()=> {
                    this.handleInquiry({productCode: detail.code, productName: detail.name, quantity: this.state.count})
                  }}
                  /> 

                </div>
                </div>

              )}
          </div>
        </div>
        <div className={classes.tabs}>
          {/* <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  'title-exapnsion-panel',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('3D Model')}
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ justifyContent: 'center' }}>
              <ThreeDModel />
            </AccordionDetails>
          </Accordion> */}
          {detail && detail.details && detail.details.longDescription ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  className={classNames(
                    'title-exapnsion-panel',
                    'accordion-summary-typography-responsive'
                  )}
                >
                  {t('details')}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className={'long-description'}
                  style={{ fontWeight: '300', width: '100%' }}
                  dangerouslySetInnerHTML={{
                    __html: detail.details.longDescription,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}
          {detail ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  className={classNames(
                    'title-exapnsion-panel',
                    'accordion-summary-typography-responsive'
                  )}
                >
                  {t('technicalData')}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TechnicalData detail={detail} />
              </AccordionDetails>
            </Accordion>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    product: state.productHome.product,
    settings: state.staticContent.settings,
    token: state.auth.token,
    showPricesForGuest: state.staticContent.showPricesForGuest,

  }),
  {
    addProduct,
    openLoginForm,
    openInquiry,
    setInquiryProduct
  },
  withNamespaces(['productPage', 'cart', 'checkoutPage'])(withStyles(styles)(BuyMachine))
);
