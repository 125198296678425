import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connectTo } from '../../../utils/generic';

const PrivateRoute = ({allowGuestAccess:allowGuestAccess, ...rest }) => {
    return (

        allowGuestAccess ? <Route {...rest} /> :
            (rest.token !== null && !rest.redirectToLogin ?
                <Route {...rest} />
                :
                allowGuestAccess === "" && <Redirect
                    to={{
                        pathname: '/',
                        state: { from: rest.location },
                    }}
                />)
    );
};

export default connectTo(
  (state) => ({
    token: state.auth.token,
    redirectToLogin: state.auth.redirectToLogin,
  }),
  {},
  React.memo(PrivateRoute)
);
