import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import TextField from '@material-ui/core/TextField';

import {
  setComment,
  setInternalOrderNumber,
  // setEditableInfo,
} from '../../../../actions/cart';
import { openErrorSnack } from '../../../../actions/startpage';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';
import { toastr } from 'react-redux-toastr';

class AdditionalInformation extends React.Component {
  state = { width: 0 };

  updateDimensions = () => {
    this.setState((prevState) => {
      return { width: window.innerWidth };
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    const comment = localStorage.getItem('comment');
    const internalOrderNumber = localStorage.getItem('internalOrderNumber');

    if (comment) this.props.setComment(comment);
    if (internalOrderNumber)
      this.props.setInternalOrderNumber(internalOrderNumber);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // handleSubmit = e => {
  //   e.preventDefault();
  //   const { comment, internalOrderNumber } = this.props;
  //   localStorage.setItem('comment', comment);
  //   localStorage.setItem('internalOrderNumber', internalOrderNumber);
  //   this.props.setEditableInfo();
  // };

  // handleEnableEdit = () => {
  //   this.props.setEditableInfo();
  // };

  setComment = (comment) => {
    this.props.setComment(comment);
    localStorage.setItem('comment', comment);
  };

  setInternalOrderNumber = (value) => {
    localStorage.setItem('internalOrderNumber', value);
    this.props.setInternalOrderNumber(value);
  };

  render() {
    const {
      classes,
      t,
      // infoIsEditable,
      internalOrderNumber,
      comment,
    } = this.props;

    const { width } = this.state;

    return (
      <div
        className={classes.group}
        style={{ display: 'flex', flexDirection: 'row', flex: '1' }}
      >
        <div
          className={width < 486 ? classes.formResponsive : classes.form}
          onSubmit={this.handleSubmit}
        >
          <InputWrapper
            header={t('checkoutPage:internalOrderNumber')}
            style={{ width: '100%' }}
            isRequire={this.props.settings && this.props.settings.length > 0 && this.props.settings.find((x) => x.name === 'is_internal_order_number_mandatory' && x.value !== null).value === '1'}
          >
            <TextField
              name='number'
              className={classes.textField}
              multiline={false}
              value={internalOrderNumber || ''}
              onChange={(e) =>
                e.target.value.length <= 30
                  ? this.setInternalOrderNumber(e.target.value)
                  : toastr.info(t('toastMessage:info'), t('messages:30 restriction'))
              }
              // disabled={!infoIsEditable}
              style={{ width: '100%' }}
            />
          </InputWrapper>
          <InputWrapper
            header={t('accountPage:comment')}
            style={{ width: '100%' }}
          >
            <TextField
              name='comment'
              className={classes.textField}
              multiline
              rowsMax='255'
              value={comment || ''}
              onChange={(e) =>
                e.target.value.length < 256
                  ? this.setComment(e.target.value)
                  : toastr.info(t('toastMessage:info'), t('messages:255 restriction'))
              }
              // disabled={!infoIsEditable}
              style={{ width: '100%' }}
            />
          </InputWrapper>

{/*          <div className={classes.footer}>
            {(internalOrderNumber && internalOrderNumber.length > 0) ||
            (comment && comment.length > 0) ? (
              infoIsEditable ? (
                <Button
                  className={classes.button}
                  onClick={() => this.handleSubmit()}
                >
                  {t('accountPage:confirm')}
                </Button>
              ) : (
                <img
                  alt='edit'
                  className='clickable'
                  onClick={() => this.handleEnableEdit()}
                  title={t('tooltips:edit')}
                  src='/images/icons/MyAccount/icon-edit.png'
                />
              )
            ) : null}
          </div>*/}
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    comment: state.cart.comment,
    // infoIsEditable: state.cart.infoIsEditable,
    internalOrderNumber: state.cart.internalOrderNumber,
    settings: state.staticContent.settings,
  }),
  {
    openErrorSnack,
    // setEditableInfo,
    setInternalOrderNumber,
    setComment,
  },
  withNamespaces([
    'checkoutPage',
    'accountPage',
    'messages',
    'Validation Messages',
    'tooltips',
    'toastMessage'
  ])(withStyles(styles)(AdditionalInformation))
);
