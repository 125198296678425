export default (theme) => ({
  content: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    fontSize: '1.2em',
    color: 'black',
    margin: '0 0 10px 10px',
  },
  sortBar: {
    display: 'flex',
    alignItems: 'center',
  },
  sortLabel: {
    marginRight: '10px',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  selectedPage: {
    color: theme.palette.primary.buttonColor + ' !important',
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  itemsTotal: { margin: '0 10px 15px' },
  noResultsInfo: {
    margin: '20px 0 20px 10px',
    backgroundColor: '#ebf0f4',
    display: 'flex',
    flexDirection: 'row',
    color: 'rgb(0, 112, 178)',
    alignItems: 'center',
    padding: '20px',
  },
  muiListPadding: {
    padding: 0,
  },
});
