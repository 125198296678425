export default (theme) => ({
  title: {
    textAlign: 'left',
    width: '100%',
  },
  content: {
    width: 'calc(100% - 20px)',
  },
  mainColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  bigHeader: {
    marginBottom: '10px',
    textTransform: 'none',
    fontSize: '1.7em',
    marginTop: '20px',
  },
  smallHeader: {
    marginBottom: '10px',
    marginTop: '0',
  },
  tab: {
    fontSize: '0.9em',
    flexGrow: '1',
    maxWidth: 'none',
    flexShrink: '1',
  },
});
