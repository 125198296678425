import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connectTo} from "../../../utils/generic";
import {closeLoginForm} from "../../../actions/auth";
import {withNamespaces} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import styles from "../../start/cards/style";
import IconButton from "@material-ui/core/IconButton";
import LoginCard from "../../start/cards/LoginCard";
import classNames from "classnames";
import CloseIcon from '@mui/icons-material/Close';

const LoginDialog = (props) => {

    const {
        t,
        classes,
        isLoginDialogOpened,
        centered
    } = props

    const handleClose = () => {
        props.closeLoginForm()
    }

    return (
            <Dialog
                open={isLoginDialogOpened}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: centered?classes.dialogPaperCentered:classes.dialogPaper
                }}
            >
                <DialogTitle className={classNames(classes.title)}>
                    <div className={classes.dialogHeader}>
                        <div className={classNames('blue-header', 'medium')}>
                            {t('loginTitle')}
                        </div>
                        <IconButton
                            className={classes.iconButton}
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>

                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <LoginCard/>
                </DialogContent>
            </Dialog>
    );
}
export default connectTo(
    (state) => ({
        isLoginDialogOpened: state.auth.isLoginDialogOpened,
        centered: state.auth.centered,
    }),
    {
        closeLoginForm
    },
    withNamespaces(['startPage'])(
        withStyles(styles)(LoginDialog)
    )
);
