import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import {
  getIndividualProduct,
  getProductTypes,
  closeTypeModal,
} from '../../actions/products';
import { getCategories } from '../../actions/listingPage';
import { connectTo } from '../../utils/generic';
import styles from './style';

import Page from '../page';
import ProductsList from '../../components/home/ProductsList';
import Delimiter from '../../components/common/Delimiter';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import { services } from '../../constants/mock';
import TypeModal from '../../components/home/TypeModal';
import classNames from 'classnames';

class HomePage extends React.Component {
  componentDidMount() {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
    this.props.getCategories();
    this.props.token && this.props.getIndividualProduct();
    this.props.getProductTypes();
  }

  componentDidUpdate() {
      !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
  }

  render() {
    const { classes, t } = this.props;

    return (
      <Page>
        <TypeModal
          open={this.props.open?this.props.open:false}
          handleClose={() => this.props.closeTypeModal()}
        />
        <div
          className='layout-section home-section'

        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              { name: t('startPage:machinery'), link: '/machines' },
            ]}
          />
        </div>
        {/*<SearchBox />*/}
          <div className='main-content' style={{ flexWrap: 'wrap' }}>
          <div className={classNames(classes.titleContent, 'title-content')}>
            <div
              className={classNames(classes.mainTitle, 'light', 'main-title')}
            >
              {t('welcomeToHome')}
            </div>
            <div className={classNames(classes.subTitle, 'light', 'sub-title')}>
              {t('homeDesc')}
            </div>
            <div className={classNames(classes.subTitle, 'light', 'sub-title')}>
              {t('anotherHomeDesc')}
            </div>
          </div>

              {this.props.token && <Delimiter title={t('yourMachines')} />}

              {this.props.token && <ProductsList
            id='my'
            products={this.props.userProducts}
            category={'yourMachines'}
            type='my'
            color='red'
          />}

          <Delimiter title={t('standardMachines')} />
          <ProductsList
            id='standard'
            products={this.props.standardProducts}
            category={t('standardMachines')}
            type='standard'
            color='orange'
          />

          <Delimiter title={t('service')} />

          <ProductsList
            products={services(this.props.categories)}
            category='service'
            color='blue'
            type='service'
          />
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    open: state.home.isOpenTypeModal,
    token: state.auth.token,
    categories: state.listingPage.categories,
    userProducts: state.home.userProducts,
    standardProducts: state.home.standardProducts,
    backgroundUrl: state.staticContent.backgroundUrl,
    allowGuestUser: state.staticContent.allowGuestUser,
  }),
  {
    getCategories,
    getIndividualProduct,
    getProductTypes,
    closeTypeModal,
  },
  withNamespaces(['homePage', 'startPage'])(withStyles(styles)(HomePage))
);
