import { createAction } from 'redux-act';

export const closeForgotPass = createAction('closeForgotPass');
export const openForgotPass = createAction('openForgotPass');
export const receiveForgotPass = createAction('receiveForgotPass');

export const setRegistrationType = createAction('setRegistrationType');
export const closeRegistration = createAction('closeRegistration');
export const openRegistration = createAction('openRegistration');
export const receiveRegisterData = createAction('receiveRegisterData');

export const setRegistrationEmail = createAction('setRegistrationEmail');

export const openErrorSnack = createAction('openErrorSnack');
export const openSimpleErrorSnack = createAction('openSimpleErrorSnack');
export const closeErrorSnack = createAction('closeErrorSnack');
export const closeSnack = createAction('closeSnack');

export const openSuccessSnack = createAction('openSuccessSnack');
