export default (theme) => ({
  topHeader: {
    width: '100%',
    height: '40px',
    paddingTop: '6px',
    backgroundColor: 'black',
  },
  topContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
});
