import { createAction } from 'redux-act'

export const patchBillingAddress = createAction('patchBillingAddress')
export const getBillingAddress = createAction('getBilligAddress')
export const setBillingAddress = createAction('setBilligAddress')
export const setBillingAddressInput = createAction('setBillingAddressInput')
export const setBillingCustomerInput = createAction('setBillingCustomerInput')

export const setBillingAddressCountry = createAction('setBillingAddressCountry')
export const useAsShippingAddress = createAction('useAsShippingAddress')
export const successChangeBillingAddress = createAction('successChangeBillingAddress')
export const editBillingAddress = createAction('editBillingAddress')


export const getShippingAddresses = createAction('getShippingAddresses')
export const postShippingAddress = createAction('postShippingAddress')
export const patchShippingAddress = createAction('patchShippingAddress')
export const deleteShippingAddress = createAction('deleteShippingAddress')

export const addShippingAddress = createAction('addShippingAddress')
export const editShippingAddress = createAction('editShippingAddress')

export const setShippingAddressInput = createAction('setShippingAddressInput')
export const setShippingTitle = createAction('setShippingTitle')
export const setShippingCountry = createAction('setShippingCountry')

export const setShippingAddresses = createAction('setShippingAddresses')
// export const setShippingAddress = createAction('setShippingAddress')

export const closeShipping = createAction('closeShipping')
export const successChangeShippingAddress = createAction('successChangeShippingAddress')


export const getContactData = createAction('getContactData')
export const patchContactData = createAction('patchContactData')
export const setContactData = createAction('setContactData')
export const setContactDataInput = createAction('setContactDataInput')
export const successChangeContactData = createAction('successChangeContactData')
export const setContactDataTitle = createAction('setContactDataTitle')
export const editContactData = createAction('editContactData')


// export const openErrorSnack = createAction('openErrorSnack')
// export const closeErrorSnack = createAction('closeErrorSnack')
