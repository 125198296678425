export default (theme) => ({
  mainContainer: {
    display: 'flex',
    marginBottom: '20px'
  },
  infoContainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  resultHeader: {
    fontSize: '1.8em',
    marginTop: '10px',
    color: 'black',
  },
  resultText: {
    color: 'black',
    fontSize: '1em',
  },
  orderId: {
    fontSize: '1.2em',
    textTransform: 'uppercase',
  },
  backButton: {
    backgroundColor: '#fff',
    border: `1.5px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
  },
});
