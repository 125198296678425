import React from 'react';

import { primaryColor } from '../../../../theme';
import ReactSVG from 'react-svg';
class Node extends React.Component {
  state = {
    isShowChilden: false,
    parents: [],
  };
  componentDidMount() {
    const { parents } = this.props;

    this.setState((prevState) => {
      return { parents };
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.parents !== this.props.parents) {
      this.setState((prevState) => {
        return { parents: this.props.parents };
      });
    }

    if (prevState.parents !== this.state.parents) {
      this.setState((prevState) => {
        return {
          isShowChildren: this.isParent(),
        };
      });
    }
  }

  handleClick = () => {
    const { product } = this.props;
    let { parents } = this.state;
    if (parents && parents.includes(product.alias)) {
      let index = parents.indexOf(product.alias);
      if (index > -1) {
        parents.splice(index, 1);
        this.setState({ isShowChilden: false, parents });
      }
    } else this.setState({ isShowChilden: !this.state.isShowChilden });
  };

  isHaveChildren = () => {
    const { product } = this.props;
    if (product && product.children && product.children.length) {
      return true;
    }
    return false;
  };

  isParent = () => {
    const { product } = this.props;
    const { parents } = this.state;

    if (parents && parents.length > 0 && parents.includes(product.alias)) {
      return true;
    }
    return false;
  };

  selectProduct = (alias) => {
    this.props.selectProduct(alias);
    this.setState((prevState) => {
      return { isShowChilden: true };
    });
  };

  render() {
    const {
      product,
      level,
      selectedProduct,
      selectProduct,
      parents,
    } = this.props;
    const { isShowChilden } = this.state;
    const newLevel = level + 1;

    return (
      <div>
        <div className='node' style={{ marginLeft: 10 * level }}>
          {(this.isHaveChildren() || this.isParent()) &&
          product &&
          product.children &&
          product.children.length ? (
            <ReactSVG
              svgClassName='icon-more colored'
              alt=''
              onClick={() => this.handleClick()}
              src={
                (isShowChilden || this.isParent()) &&
                product &&
                product.children &&
                product.children.length
                  ? '/images/icons/tree-icon-min.svg'
                  : '/images/icons/tree-icon-plus.svg'
              }
            />
          ) : (
            <div className='empty' />
          )}
          <div
            className={`node-label ${this.isHaveChildren() ? 'bold' : 'light'}`}
            onClick={() => this.selectProduct(product.alias)}
            style={{
              color:
                product && selectedProduct === product.alias
                  ? primaryColor.buttonColor
                  : 'white',
            }}
          >
            {product && product.name}
          </div>
        </div>

        {product && product.children && (isShowChilden || this.isParent())
          ? product.children.map((k, i) => (
              <Node
                parents={parents}
                key={i}
                product={k}
                level={newLevel}
                selectProduct={selectProduct}
                selectedProduct={selectedProduct}
              />
            ))
          : null}
      </div>
    );
  }
}

export default Node;
