import React from 'react';

import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import InputWrapper from '../../../common/InputTitleWrapper';
import classNames from "classnames";

const EditableContacts = (props) => {
  const {
    classes,
    t,
    titles,
    contact,
    setContactDataTitle,
    setContactDataInput,
  } = props;

  return (
    <div>
      <RadioGroup
        name='title'
        className={classes.group}
        value={contact.title ? contact.title : ''}
        onChange={(e) => {
          setContactDataTitle(e.target.value);
        }}
      >
        {titles &&
            titles.map((v, i) => (
            <FormControlLabel
              key={v.code}
              value={v.code}
              control={<Radio />}
              label={
                <InputWrapper header={v.name} isRequire={true}></InputWrapper>
              }
            />
          ))}
      </RadioGroup>

      <InputWrapper
        header={t('firstName')}
        headerClass={classes.smallTitle}
        isRequire={true}
      >
        <TextField
          name='firstName'
          value={contact.firstName || ''}
          className={classes.textField}
          onChange={(e) => setContactDataInput(e.target)}
        />
      </InputWrapper>

      <InputWrapper
        header={t('secondName')}
        headerClass={classes.smallTitle}
        isRequire={true}
      >
        <TextField
          name='lastName'
          value={contact.lastName || ''}
          className={classes.textField}
          onChange={(e) => setContactDataInput(e.target)}
        />
      </InputWrapper>

      <div className={classNames(classes.multiField, 'multi-field-row')}>
        <InputWrapper header={t('phone')} headerClass={classes.smallTitle}>
          <TextField
            name='phone'
            value={contact.phone || ''}
            className={classes.textField}
            onChange={(e) => setContactDataInput(e.target)}
          />
        </InputWrapper>
        <InputWrapper header={t('mobile')} headerClass={classes.smallTitle}>
          <TextField
            name='mobile'
            value={contact.mobile || ''}
            className={classes.textField}
            onChange={(e) => setContactDataInput(e.target)}
          />
        </InputWrapper>
      </div>
    </div>
  );
};

export default withNamespaces('accountPage')(
  withStyles(styles)(EditableContacts)
);
