import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../../../utils/generic';

import ShippingCard from './shippingCard';
import Arrow from '../../../../../common/Arrow';

import { setShippingAddress } from '../../../../../../actions/cart';
import { getShippingAddresses } from '../../../../../../actions/address';
import Pagination from '../../../../../common/carousel/Pagination';
import classNames from 'classnames';

class ShippingCards extends React.Component {
  state = {
    pos: 0,
    arrowsVisibleLeft: false,
    arrowsVisibleRight: true,
    pageSize: 3
  };

  componentDidMount() {
    this.props.getShippingAddresses();
  }

  nextPage = () => {
    this.setState({ arrowsVisibleLeft: true });
    if (
      this.state.pos ===
      this.props.addresses.length - 1 - this.state.pageSize
    ) {
      this.setState({ arrowsVisibleRight: false });
    }
    if (this.state.pos < this.props.addresses.length - 1) {
      this.setState({ pos: this.state.pos + 1 });
    }
  };

  prevPage = () => {
    this.setState({ arrowsVisibleRight: true });
    if (this.state.pos === 1) {
      this.setState({ arrowsVisibleLeft: false });
    }
    if (this.state.pos > 0) {
      this.setState({ pos: this.state.pos - 1 });
    }
  };

  handleChangePage = page => {
    this.checkPosition(page);
    this.setState({ pos: page });
  };

  checkPosition = pos => {
    this.setState({
      arrowsVisibleLeft: pos > 0,
      arrowsVisibleRight:
        pos < this.props.addresses.length - this.state.pageSize
    });
  };

  render() {
    const { classes, addresses } = this.props;
    const { arrowsVisibleLeft, arrowsVisibleRight, pageSize, pos } = this.state;

    return (
      <div>
        <div className={classes.carousel}>
          <Arrow
            src="/images/icons/arrow-up-gray.svg"
            arrowVisible={
              addresses.length > pageSize ? arrowsVisibleLeft : false
            }
            handleClick={() => this.prevPage()}
            className={classes.arrowUp}
          />

          <div className={classNames(classes.list, 'checkout-modal-slider')}>
            <div
              className={classes.slider}
              style={{
                transform: `translateX(-${this.state.pos * 100}%)`,
                left: 0
              }}
            >
              {addresses &&
                addresses.map((v, i) => (
                  <ShippingCard
                    key={i}
                    style={{ left: `${i * 100}%` }}
                    card={v}
                    onClick={() => {
                      this.props.setShippingAddress(v);
                      this.props.handleClose();
                    }}
                  />
                ))}
            </div>
          </div>
          <Arrow
            src="/images/icons/arrow-down-gray.svg"
            arrowVisible={
              addresses.length > pageSize ? arrowsVisibleRight : false
            }
            handleClick={() => this.nextPage()}
            className={classes.arrowDown}
          />
        </div>
        <Pagination
          curPage={pos}
          totalPages={addresses.length - this.state.pageSize + 1}
          handleClick={this.handleChangePage}
          style={{
            width: '10px',
            height: '10px',
            borderWidth: '1px'
          }}
          wrapperStyle={{
            marginTop: '10px'
          }}
        />
      </div>
    );
  }
}

export default connectTo(
  state => ({
    addresses: state.address.shippingAddresses
  }),
  {
    getShippingAddresses,
    setShippingAddress
  },
  withStyles(styles)(ShippingCards)
);
