import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import styles from './style';

import { withNamespaces } from 'react-i18next';

import { logout } from '../../../../actions/auth';
import { connectTo } from '../../../../utils/generic';
import * as settings from "../../../../settings";
import MenuItemDrawer from "./MenuItemDrawer";
import CheckoutIcon from "../TopHeader/CheckoutIcon";

class DplainDrawer extends React.Component {
  render() {
    const { classes, theme, drawerOpen, handleDrawerClose, t, token } = this.props;
    return (
      <Drawer
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <CloseIcon style={{color: 'white'}} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <div className={classes.menuDrawer}>
{/*            <MenuItemDrawer
                label={t('homePage:offers')}
                link='/content/aktion'
            />
            <MenuItemDrawer
                label={t('homePage:standard')}
                link='/standard-machines-content'
            />*/}

            <MenuItemDrawer
                label={t('productPage:Spare Parts')}
                link='/machines'
            />

            <MenuItemDrawer
                label={t('equipment')}
                link='/content/accessory'
            />

            {settings && settings.show_fanshop  ?
                <MenuItemDrawer
                label={t('fanshop')}
                link='/content/fanshop'
            /> : null}

            <MenuItemDrawer
                label={t('quickOrder')}
                link='/quickorder'
            />
          </div>
        </List>
        <Divider />
        <List className={classes.iconDrawer}>
          {/*token &&*/ <ListItem button onClick={() => this.props.history.push('/cart')}
                              className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <CheckoutIcon/>
            </ListItemIcon>
          </ListItem>}
          <ListItem
              className={classes.listItem}
              button onClick={() => this.props.history.push('/account')}>
            <ListItemIcon className={classes.listItemIcon}>
             {token && <img
                  className="menu-icon"
                  src="/updated_images/svg/user-circle 15px.svg"
                  alt={t('myAccount')}
              />}
            </ListItemIcon>
          </ListItem>
          {token &&   <ListItem
              className={classes.listItem}
              button onClick={this.props.logout}>
            <ListItemIcon className={classes.listItemIcon}>
              <img
                  className="menu-icon"
                  src='/updated_images/svg/logout 15px.svg'
                  alt={t('logout')}
              />
            </ListItemIcon>
          </ListItem> }
        </List>
      </Drawer>
    );
  }
}

export default connectTo(
  (state) => ({ token: state.auth.token }),
  { logout },
  withNamespaces(['startPage', 'homePage', 'productPage'])(
    withStyles(styles, { withTheme: true })(withRouter(DplainDrawer))
  )
);
