export default (theme) => ({
  pageNotFoundMain: {
    marginTop: '15%',
  },
  st0: {
    fill: '#fff',
  },
  st2: {
    fill: '#0070b2',
  },
  st3: {
    fill: '#3b3b3b',
  },
  st4: {
    fill: '#fff',
    stroke: '#74c6f2',
    strokeMiterlimit: '10',
  },
  st6: {
    stroke: '#0070b2',
    strokeWidth: '2',
  },
  st7: {
    stroke: '#3b3b3b',
    strokeWidth: '5',
    strokeLinecap: 'round',
    fill: 'none',
    strokeMiterlimit: '10',
  },
  st8: {
    fill: '#fff',
    stroke: '#0070b2',
    strokeMiterlimit: '10',
  },
  st9: {
    fill: 'none',
  },
});
