export default theme => ({
  content: {
    display: 'flex',
    padding: '10px 0 0 0',
    flex: 1
  },
  logoColumn: {
    width: '30%'
  },
  img: {
    display: 'block',
    margin: '3% 25% 25% 20%',
    width: '55%',
    height: 'auto'
  },
  button: {
    backgroundColor: theme.palette.primary.buttonColor,
    display: 'flex',
    width: '100%',
    marginBottom: '10px'
  },
  mainColumn: {
    width: '75%',
    marginRight: '5%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    width: '100%',
    margin: '10px 0'
  },
  chooseBtnRoot: {
    '&:hover': {
      color: 'white',
    },
  },
});
