import { createAction } from 'redux-act'

export const setCategory = createAction('setCategory')
export const getCategory = createAction('getCategory')

export const setCategories = createAction('setCategories')
export const getCategories = createAction('getCategories')

export const findProducts = createAction('findProducts')
export const setSearchResult = createAction('setSearchResult')
