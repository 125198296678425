import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as settings from '../../settings'
import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import Page from '../page';

import Header from '../../components/machine/Header';
import ImageBar from '../../components/machine/ImageBar';
import Documents from '../../components/machine/Documents';
import SpareParts from '../../components/machine/spareParts';
import BuyMachine from '../../components/machine/BuyMachine';
import VisualizeSensorData from '../../components/machine/visualizeSensorData';

import BreadCrumbs from '../../components/common/Breadcrumbs';
import { setProductType } from '../../actions/products';

import {
  getMyProduct,
  getMachine,
  closeTypeModal,
  getProductTypes,
  getIndividualProduct,
} from '../../actions/products';
import TypeModal from '../../components/home/TypeModal';
import TabWithTree from '../../components/machine/TabWithTree';
import {PRICE_TYPE_INDIVIDUAL} from "../../constants/common";

class ProductPage extends React.Component {
  state = {
    value: 1,
    loadParts: false,
  };

  updateValueBasedOnProduct = (product) => {
    const isProductValid = !(product && product.product && product.product.isOrderable);
    this.setState({ value: isProductValid ? 0 : 1 });
  };

  hasProductData(product) {
    return product && product.product;
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
    const alias = this.props.match.params.id;

    this.props.category === 'my'
      ? this.props.getMyProduct({ alias: alias })
      : this.props.getMachine({ alias: alias });
    this.props.getProductTypes();
    this.props.token && this.props.getIndividualProduct();
  }

  componentDidUpdate(prevProps) {
      !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
      if (
      this.state.loadParts === false &&
      this.props.product &&
      prevProps.product &&
      prevProps.product !== this.props.product
    ) {
      this.setState({ loadParts: true });
    }
    if (prevProps.product !== this.props.product) {
      this.updateValueBasedOnProduct(this.props.product);
    }
  }

  generateValue = (product, a, b) => {

    const hasProductData = this.hasProductData(product);
    return hasProductData && product.product.isOrderable ? a : b;

  };

  adjustCarouselProducts = (products, type) => {
      return products ? products.map(product => {
            let newProduct = {
                name: product.name,
                image: type === "product" ? (product.image.length && product.image[0] ? product.image[0].url : '') : product.image,
                handleClick: type === "product" ? () => this.props.history.push(`/my/machine/${product.alias}`) : (() => this.props.setProductType({ product: product }))
            };
          if(!newProduct.image){
              newProduct.image = '/images/product_example_image.png';
          }

          return newProduct;
      }) : [];
  }

  render() {
    const { classes, t, product, category } = this.props;
    const hasProductData = this.hasProductData(product);
    const AKTION_URI = 'content/aktion';
    const STANDARD_MACHINES_URI = 'content/standard-machines';
    const CONTENT_URI = '/content/';
    var from = this.props.history.location.from;
    const { value, loadParts } = this.state;
    const carouselProducts = this.adjustCarouselProducts(this.props.products, "productType").concat(this.adjustCarouselProducts(this.props.userProducts, "product"));
    const directProduct = product && product.product ? product.product : {};
    var fromUri = ""
        if(from && from.includes(CONTENT_URI) )
        {
          if(from.includes(AKTION_URI))
            fromUri = t("homePage:offers")
          else if(from.includes(STANDARD_MACHINES_URI))
            fromUri = t("homePage:standardMachines")

        }
    return (
      <Page>
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              {
                name:
                    fromUri ? fromUri : category !== 'my' ? t('standardMachines') : t('yourMachines'),
                link: fromUri ? from.substring(from.indexOf('/content/')) : category !== 'my' ? '/machines#standard' : '/machines#my',
              },
              {
                name:
                product && product.product && product.product.code
                    ? product.product.code
                    : '',
                link: '#',
              },
            ]}
          />
        </div>
         {/*<SearchBox />*/}

        <div style={{ position: 'relative' }}>
          <div
            className={classNames(
              'main-content',
              classes.content
              /* 'product-page' */
            )}
          >
            <Header product={directProduct} category={category} />
            <TypeModal
              open={this.props.open?this.props.open:false}
              handleClose={() => this.props.closeTypeModal()}
            />

            <ImageBar products={carouselProducts} product={directProduct} />

            <div className={classNames(/* "main-content",  */ classes.details)}>
              <Tabs
                className={classes.tabBar}
                value={value}
                onChange={this.handleChange}
              >
                {hasProductData && product.product.isOrderable ? (
                  <Tab label={t('BuyMachine')} />
                ) : null}
                <Tab label={t('Spare Parts')} className={'medium-font'} />
                <Tab label={t('document')} className={'medium-font'} />
                {settings && settings.show_sensor_data  ? <Tab label={t('Sensor Data')} className={'medium-font'} /> : null}
              </Tabs>
              {value === 0 &&
             hasProductData &&
                product.product.isOrderable && (
                  <BuyMachine detail={this.props.product.product} />
                )}

              {value === this.generateValue(product, 1, 0) && loadParts && (
                 <TabWithTree product={product} type={this.props.category}>
                  <SpareParts
                    category={category}
                    className={classes.tabPage}
                    product={product}
                    type={this.props.category}
                  />
                </TabWithTree>
              )}
              {value === this.generateValue(product, 2, 1) &&  (
                <TabWithTree product={product} type={this.props.category}>
                  <Documents
                    className={classes.tabPage}
                    type={this.props.category}
                  />
                </TabWithTree>
              )}

              {settings && settings.show_sensor_data && value === this.generateValue(product, 3, 2) &&  (
                <TabWithTree product={product} type={this.props.category}>
                  <VisualizeSensorData width={360} height={300} />
                </TabWithTree>
              )}
                {this.props.product?.product?.priceType === PRICE_TYPE_INDIVIDUAL && value === 0 &&
                <div className= 'footNote'>* {t('checkoutPage:No Further Discount Applied')}</div>
                }
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    open: state.home.isOpenTypeModal,
    products: state.home.standardProducts,
    userProducts: state.home.userProducts,
    token: state.auth.token,
    image: state.productHome.image,
    product: state.productHome.product,
    backgroundUrl: state.staticContent.backgroundUrl,
    allowGuestUser: state.staticContent.allowGuestUser,
  }),
  {
    getMyProduct,
    getMachine,
    getProductTypes,
    getIndividualProduct,
    closeTypeModal,
    setProductType
  },
  withNamespaces(['productPage', 'homePage'])(withStyles(styles)(ProductPage))
);
