import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Product from './product';
import styles from './style';

const ProductList = (props) => {
  const { classes, products, category } = props;

  return (
    <div className={classNames(classes.content, 'search-productlist')}>
      {products
        ? products.map((p, i) => (
            <Product
              key={i}
              product={p}
              link={
                p.isMachine
                  ? `/machine/${p.alias}`
                  : `/category/${category}/${p.alias}`
              }
            />
          ))
        : ''}
    </div>
  );
};

export default withStyles(styles)(React.memo(ProductList));
