import React from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { withNamespaces } from 'react-i18next';

import { connectTo } from '../../../../utils/generic';

class OrderOption extends React.Component {
  state = {
    selectValue: '',
  };

  handleSelect = (val) => {
    this.setState((prevState) => {
      return { selectValue: val };
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <RadioGroup
          value={this.state.selectValue}
          onChange={(e) => this.handleSelect(e.target.value)}
        >
          {/* <FormControlLabel
						value='Licence'
						control={<Radio />}
						label={t('Licence')}
					/> */}
          <FormControlLabel
            value='Product'
            control={<Radio />}
            label={t('Product')}
          />
        </RadioGroup>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({}),
  {},
  withNamespaces('checkoutPage')(OrderOption)
);
