import news from './news';
import auth from './auth';
import cart from './cart';
import home from './home';
import start from './start';
import tasks from './tasks';
import search from './search';
import toastr from './toastr';
import footer from './footer';
import account from './account';
import address from './address';
import generic from './generic';
import metadata from './metadata';
import navigation from './navigation';
import quickorder from './quickorder';
import productHome from './productHome';
import listingPage from './listingPage';
import staticContent from './static';

export default {
  news,
  auth,
  cart,
  home,
  start,
  tasks,
  search,
  toastr,
  footer,
  account,
  address,
  generic,
  metadata,
  navigation,
  quickorder,
  productHome,
  listingPage,
  staticContent
};
