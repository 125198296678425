import { put } from 'redux-saga/effects';

import { CATEGORY, CATEGORIES, FILTER_CATEGORY } from '../constants/api';

import {
  setCategory,
  setSearchResult,
  setCategories,
} from '../actions/listingPage';
import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';

export function* getCategory({ payload }) {
  try {
    const { alias } = payload;
    const category = yield callHttp(get, CATEGORY(alias));
    yield put(setCategory(category));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getCategories() {
  try {
    const categories = yield callHttp(get, CATEGORIES);
    yield put(setCategories(categories));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* findProducts({ payload }) {
  try {
    payload.perPage = 24;
    const searchResult = yield callHttp(get, FILTER_CATEGORY(payload));
    yield put(setSearchResult(searchResult));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
