import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { isEmailValid } from '../../../../utils/validation';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';
import { getTitles } from '../../../../actions/metadata';
import { openErrorSnack } from '../../../../actions/startpage';

import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setInquiryProduct, sendInquiryProduct} from "../../../../actions/products";

class InquiryForm extends React.Component {
  componentDidMount() {
    this.props.getTitles();

  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.reCaptchaSiteKey && this.state.canSubmitForm !== prevState.canSubmitForm && this.state.canSubmitForm) {
      this.inquiry();
      this.setState({canSubmitForm: false});
    }
    if( this.props.reCaptchaSiteKey ){
      if(this.state.captchaToken && this.state.captchaToken !== prevState.captchaToken) {
        this.inquiry();
      }
    }
  }

  state = {
    title: 'Mr',
    quantity: 0,
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    customer: '',
    comment: ''
  };

  validateForm() {

    let message = '';
    if (!this.state.firstName) {
      message = this.props.t('Validation Messages:First Name is Required!');
    } else if (!this.state.lastName) {
      message = this.props.t('Validation Messages:Last Name is Required!');
    } else if (!this.state.companyName) {
      message = this.props.t('Validation Messages:Company Name is Required!');
    } else if (!this.state.email) {
      message = this.props.t('Validation Messages:Email is Required!');
    } else if (!isEmailValid(this.state.email)) {
      message = this.props.t('Validation Messages:Wrong email format!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  }

  handleVerifyRecaptcha = async () => {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    let iterations = 0;
    let waitCaptcha = setInterval(async () => {
      iterations++;
      const token = await executeRecaptcha();
      this.setState({
        captchaToken: token
      });

      if(token){
        clearInterval(waitCaptcha);
      } else {
        if(iterations > 10) {
          clearInterval(waitCaptcha);
          alert('Can not access reCaptcha source site');
        }
      }
    }, 1000);
  };

  inquiry() {
    const data = {
        product: this.props.inquiryProduct.productCode,
        quantity: this.props.inquiryProduct.quantity,
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        companyName: this.state.companyName,
        customer: this.state.customer,
        comment: this.state.comment
    };

    this.props.sendInquiryProduct(data);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.validateForm()){
      if(this.props.reCaptchaSiteKey){
        this.handleVerifyRecaptcha();
      } else {
        this.setState({canSubmitForm: true});
      }
    }
  };

  render() {
    const { classes, titles, t, inquiryProduct, fullScreen} = this.props;

    return (
        <div className={classes.content}>
          <div
              className={classes.mainColumn}
              style={{  width: '100%'  }}
          >
            <div>
              {inquiryProduct && inquiryProduct.productName && inquiryProduct.quantity && <div className={classes.afterTitle}>
                {inquiryProduct.productName} x {inquiryProduct.quantity}
              </div>}
            </div>
            <form onSubmit={this.handleSubmit} className={classes.container}>
              <div className={classes.contentRow}>
                <FormControl
                    component='fieldset'
                    className={classNames(classes.formControl, classes.genderRow)}
                >
                  <RadioGroup
                      aria-label='Gender'
                      name='gender'
                      className={classes.group}
                      value={this.state.title}
                      onChange={
                        this.handleChange('title')
                        //this.setState({ title: event.target.value });
                      }
                  >
                    {titles &&
                        titles.map((v, i) => (
                            <FormControlLabel
                                key={v.code}
                                value={v.code}
                                control={<Radio
                                    className={classes.radioButton}
                                />}
                                label={v.name}
                            />
                        ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={classes.contentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={t('firstName')}
                    isRequire={true}
                >
                  <TextField
                      name='firstName'
                      className={classes.textInput}
                      value={this.state.firstName}
                      onChange={this.handleChange('firstName')}
                  />
                </InputWrapper>
              </div>
              <div className={classes.contentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={t('lastName')}
                    isRequire={true}
                >
                  <TextField
                      name='lastName'
                      className={classes.textInput}
                      value={this.state.lastName}
                      onChange={this.handleChange('lastName')}
                  />
                </InputWrapper>
              </div>
              <div className={classes.contentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={t('company')}
                    isRequire={true}
                >
                  <TextField
                      name='companyName'
                      className={classes.textInput}
                      value={this.state.companyName}
                      onChange={(e) =>
                          this.setState({
                            companyName: e.target.value,
                          })
                      }
                  />
                </InputWrapper>
              </div>

              <div className={classes.contentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={'Email'}
                    isRequire={true}
                >
                  <TextField
                      name='email'
                      className={classes.textInput}
                      value={this.state.email}
                      onChange={this.handleChange('email')}
                  />
                </InputWrapper>
              </div>


              <div className={classes.contentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={t('customerId')}
                    isRequire={false}
                >
                  <TextField
                      name='customer'
                      className={classes.textInput}
                      value={this.state.customer}
                      onChange={this.handleChange('customer')}
                  />
                </InputWrapper>
              </div>

              <div className={classes.lastContentRow}>
                <InputWrapper
                    className={classes.textField}
                    headerClass={classes.header}
                    header={t('comment')}
                    isRequire={false}
                >
                  <TextField
                      multiline
                      rows={4}
                      InputProps={{
                        className: classes.input,
                      }}
                      name='comment'
                      className={classes.textInput}
                      value={this.state.comment}
                      onChange={this.handleChange('comment')}
                  />
                </InputWrapper>
              </div>
              <div>
                <Button className={classes.button} classes={{ root: classes.inquiryBtnRoot }} type='submit'>
                  {t('sendInquiry')}
                </Button>
              </div>
            </form>
          </div>
        </div>
    );
  }
}

export default connectTo(
    (state) => ({
      titles: state.metadata.titles,
      reCaptchaSiteKey: state.staticContent.reCaptchaSiteKey,
      inquiryProduct: state.productHome.inquiryProduct
    }),
    {
      getTitles,
      setInquiryProduct,
      sendInquiryProduct,
      openErrorSnack
    },
    withNamespaces(['startPage', 'Validation Messages'])(
        withStyles(styles)(withGoogleReCaptcha(InquiryForm))
    )
);
