import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../utils/generic';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import {openLoginForm} from "../../actions/auth";

import Page from '../page';
import SearchBar from '../../components/order/SearchBar';
import OrderList from '../../components/order/OrderList';
import Dropzone from '../../components/order/Dropzone';
import {
  deleteProductInOrder,
  editProductInOrder,
  getBatchProducts,
} from '../../actions/quickorder';
import { addProductsFromOrder } from '../../actions/cart';
import { Button } from '@material-ui/core';
import ReactSVG from 'react-svg';

class OrderPage extends React.Component {
  getProducts = () =>
    localStorage.getItem('products') &&
    localStorage.getItem('products') !== 'undefined'
      ? JSON.parse(localStorage.getItem('products'))
      : [];

   state = {products: this.getProducts(), isCSVuploaded:false} // isCSVuploaded = false => no csv file is uploaded. Only refreshing the page

    componentDidMount() {
      this.props.getBatchProducts(this.state);
      !(this.props.token || this.props.showPricesForGuest) && this.props.history.replace('/');

    // !this.props.token && this.props.history.replace('/');
  }

  componentDidUpdate() {
    // !this.props.token && this.props.history.replace('/');
  }

  render() {
    const { classes, t } = this.props;
    const currentLanguage = localStorage.getItem('i18nextLng');
    const csvFileName = '/images/example.' + currentLanguage + '.csv';

    const products = this.getProducts();
    return (
      <Page>
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              { name: t('quickOrder'), link: '#' },
            ]}
          />
        </div>
         {/*<SearchBox />*/}
        <div
          className={classNames(
            'order-content',
            'main-content',
            classes.content
          )}
        >
          <h1 className={classes.header}>{t('quickOrder')}</h1>
          <div className={classes.rightbar}>
            <div title={t('tipsforTemplate')}>
              <a href={csvFileName} download>
                <img
                  className={classes.iconExel}
                  src='/images/icons/icon-excel.svg'
                  alt='xls'
                />
              </a>
            </div>
            <Dropzone buttonClassName={classes.uploadButton} />
          </div>
        </div>

        <SearchBar />
        <div
          className={classNames(
            'order-content',
            'main-content',
            classes.content
          )}
        >
          <OrderList
            deleteProduct={(args) => this.props.deleteProductInOrder(args)}
            updateProduct={(args) => this.props.editProductInOrder(args)}
            items={products}
          />
          {products && products.length ? (
            <Button
              className={classes.button}
              onClick={() => /*this.props.token ?*/ this.props.addProductsFromOrder(products)/*:this.props.openLoginForm({centered:true})*/}
              sx = {{
                  '& .MuiButton-label': {
                      width: 160,
                      height: 48
              }
              }}
            >
              <ReactSVG
                svgClassName= {classes.icon}
                src='/updated_images/svg/cart filled 15px.svg'
                alt='cart'
              />
              {t('cart:intoCart')}
            </Button>
          ) : null}
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    products: state.quickorder.products,
    backgroundUrl: state.staticContent.backgroundUrl,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {
    addProductsFromOrder,
    editProductInOrder,
    deleteProductInOrder,
    getBatchProducts,
    openLoginForm
  },
  withNamespaces(['orderPage', 'cart'])(
    withRouter(withStyles(styles)(OrderPage))
  )
);
