import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

class ShippingCard extends React.Component {
  render() {
    const { classes, card, onClick } = this.props;

    return (
      <div className={classes.item} style={this.props.style} onClick={onClick}>
        {card && (
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div className={classes.cardContainer}>
                <span
                  className={classNames(classes.cardRow, classes.cardTitle)}
                >
                  {card.firstName} {card.lastName}
                </span>
                <span
                  className={classes.cardRow}
                  style={{ textTransform: 'uppercase' }}
                >
                  {card.companyName}
                </span>
                <span className={classes.cardRow}>{card.additional}</span>
                <span className={classes.cardRow}>
                  {card.streetName} {card.streetNumber}
                </span>
                <span className={classes.cardRow}>
                  {card.postalCode}, {card.city}
                </span>
                <span className={classes.cardRow}>{card.country.name}</span>
                <span className={classes.cardRow}>{card.phone}</span>
              </div>

              <div
                className={classNames(
                  classes.rightBottomCorne,
                  card.isDefault && classes.defaultRightBottomCorner
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ShippingCard);
