import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../utils/localization';

const ShoppingCartSummary = ({ classes, t, cart }) => (
  <div className={classes.priceContainer}>
    <div className={classes.price}>
      <div className={classes.priceRow}>
        <span className={classes.priceText}>{t('productCost')}:</span>
        <div className={classNames(classes.priceValue, 'bold')}>{formatMoney(cart.total)}</div>
      </div>

      <div className={classes.priceRow}>
        <span className={classes.priceText}>
          {t('taxValue')} ({cart.tax}%):
        </span>
        <div className={classNames(classes.priceValue, 'bold')}>{formatMoney(cart.totalTax)}</div>
      </div>

      <hr />

      <div className={classes.priceRow}>
        <span className={classes.priceText}>{t('totalCost')}:</span>
        <div className={classNames(classes.priceValue, 'bold')}>
          {formatMoney(cart.totalWithTax)}
        </div>
      </div>

      <div className={classNames(classes.priceRow, classes.taxText)}>
        {t('cart:taxDeliveryCost')}
      </div>
    </div>
  </div>
);

export default withNamespaces(['checkoutPage', 'cart'])(
  withStyles(styles)(ShoppingCartSummary)
);
