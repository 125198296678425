export default (theme) => ({
  main: {
    width: '100%',
    height: '12px',
    borderBottom: '1px solid black',
    margin: '20px 0',
    textAlign: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '0 20px',
    backgroundColor: 'white',
  },
});
