import { black } from "material-ui/styles/colors";

export default (theme) => ({
  content: {
    background: "black",
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '5px 0',
  },
  title: {
    color: 'white',
  },
  breakLabel: {
    fontSize: '0.8em',
    color: theme.palette.primary.buttonColor,
    cursor: 'pointer',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    marginBottom: '5px',
  },
  rootCheckBox: {
    padding: 0,
  },
  root: {
    padding: 0,
    color: "white",
    background: 'black',
    borderRadius: 0,
    height: '18px',
    width: '18px',
    marginRight: '10px',
  },
  number: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75em',
    background: theme.palette.primary.buttonColor,
    color: 'white',
    textAlign: 'center',
    minWidth: '13px',
    borderRadius: '15px',
    padding: '0 7px',
    margin: '0 10px',
    height: '1.5em',
  },
  width: {
    width: '100%',
  },
  formBlock: {
    display: 'block',
    padding: '5px 0',
  },
});
