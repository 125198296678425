import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import {withNamespaces} from 'react-i18next';
import qs from 'qs';

import {connectTo} from '../../utils/generic';
import styles from './style';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import TypeModal from '../../components/home/TypeModal';
import {findProducts} from '../../actions/listingPage';

import {GridItems} from '../../utils/showCategoryTiles'
import * as settings from "../../settings";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CategoryTileHomePage from "../../components/home/CategoryTileHomePage";
import {Link} from "react-router-dom";

class NewHomePage extends React.Component {
  componentDidMount() {
      !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
  }

  componentDidUpdate(prevProps) {

    if (prevProps.match.params.alias !== this.props.match.params.alias) {
      const category = this.props.match.params.alias;
      if (category) {
        this.props.getCategory({ alias: category });
        this.props.findProducts(this.getParams());
      }
    }
  }

  getParams = () => {
    const requestParams = qs.parse(this.props.location.search.replace('?', ''));
    const category = this.props.match.params.alias;
    const params = {
      ...requestParams,
      category: requestParams.category || category,
    };
    return params;
  };

  setGridItems = () =>{
    const { classes, t } = this.props

      let itemsContent = GridItems()
      itemsContent =  itemsContent.filter(item => item.linkTo !== '/standard-machines-content' && item.linkTo !== '/content/aktion')

      if (!(this.props.token || this.props.showPricesForGuest)) {
        itemsContent =  itemsContent.filter(item => item.linkTo !== '/quickorder')
      }

      if (settings && settings.show_fanshop) {
          itemsContent.push(
              {
                  linkTo: '/content/fanshop',
                  src: '/images/homePage/fanshop.jpg',
                  alt: 'startPage:fanshop',
                  title: 'startPage:fanshop'
              }
          )
      }




 return (
   <Box mt={3} mb={3}  justifyItems="center" display ='inline-grid' style={{marginTop: '0px'}}>
      <Grid item={true} container direction="row" justify="center" spacing={5} xs={12}>
         {itemsContent.map((item, index) => (
            <Grid item={true} key={index} sm={4} md={4} style={{ minWidth: '365px'}} >
                <Link to={item.linkTo} className={classes.link} flexwrap="wrap">
                    <CategoryTileHomePage
                    src={item.src}
                    alt={t(item.alt)}
                    tileTitle={t(item.title)}
                    tileSubtitle={''}
                    />
                  </Link>
              </Grid>
             ))}
        </Grid>
      </Box>
  )}

  render() {
    const { t } = this.props;

    return (
      <Page>
        <TypeModal
          open={this.props.open?this.props.open:false}
          handleClose={() => this.props.closeTypeModal()}
        />
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs links={[{ name: t('home'), link: '/home' }]} />
        </div>
        <div className='main-content' style={{ flexWrap: 'wrap', height: '100%', verticalAlign: 'middle' }}>

            {this.setGridItems()}
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    open: state.home.isOpenTypeModal,
    token: state.auth.token,
    backgroundUrl: state.staticContent.backgroundUrl,
    allowGuestUser: state.staticContent.allowGuestUser,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {
    findProducts,
  },
  withNamespaces([
    'accountPage',
    'homePage',
    'orderPage',
    'startPage',
    'productPage',
  ])(withStyles(styles)(NewHomePage))
);
