import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const ModalHeader = props => {
  const { classes, handleClose, title } = props;

  return (
    <div style={{ width: '100%' }}>
      <div className={classNames(classes.modalHeader, 'bold')}>
        <div className={classes.headerContent}>{title}</div>
        <div className={classes.closeIcon} onClick={handleClose}>
          <img
            height="20"
            width="20"
            alt="close"
            src="/images/icons/X-black.png"
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(React.memo(ModalHeader));
