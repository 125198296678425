import React from 'react';

import TextField from '@material-ui/core/TextField';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import InputWrapper from '../../../common/InputTitleWrapper';
import { getCountries } from '../../../../actions/metadata';
import {connectTo} from "../../../../utils/generic";


class FormBillingAddress extends React.Component {

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { classes, t, address, countries } = this.props;
    const selectedCountry = countries.find((v) => v.code === address.country);

    return (
      <form onSubmit={this.handleSubmit}>
        <InputWrapper header={t('company')}>
          <TextField
            name="companyName"
            value={(address.customer && address.customer.companyName) || ''}
            className={classes.textField}
            disabled
          />
        </InputWrapper>
        {address.customer && address.customer.additional && (
          <InputWrapper header={t('additional')}>
            <TextField
              name="additional"
              value={(address.customer && address.customer.additional) || ''}
              placeholder={t('additional')}
              className={classes.textField}
              disabled
            />
          </InputWrapper>
        )}

        <InputWrapper header={t('street')} style={{ flex: 5 }}>
          <TextField
            name="streetName"
            value={
              (address.streetName || '') + ' ' + (address.streetNumber || '')
            }
            placeholder="Musterstraßes"
            className={classes.textField}
            disabled
          />
        </InputWrapper>

        <div className="multi-field-row">
          <InputWrapper header={t('postalCode')} style={{ flex: 2 }}>
            <TextField
              name="postalCode"
              placeholder="12345"
              value={address.postalCode || ''}
              className={classNames(classes.textField, 'disable-arrows')}
              disabled
              inputProps={{maxLength:"16"}}
            />
          </InputWrapper>
          <InputWrapper header={t('city')} style={{ flex: 5 }}>
            <TextField
              name="city"
              placeholder="Musterstadt"
              value={address.city || ''}
              className={classes.textField}
              disabled
            />
          </InputWrapper>
        </div>

        <InputWrapper header={t('country')}>
          <TextField
            name="country"
            placeholder="Deutschland"
            value={selectedCountry ? selectedCountry.name : ''}
            className={classes.textField}
            disabled
          />
        </InputWrapper>
      </form>
    );
  }
}

export default connectTo(
    (state) => ({
        countries: state.metadata.countries
    }), {
        getCountries
    },
    withNamespaces('accountPage')(withStyles(styles)(FormBillingAddress))
);

