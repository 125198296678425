export default (theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    marginLeft: 40,
    width: '80%'
  },
  leftCorner: {
    position: 'relative',
    left: '0',
    alignSelf: 'center',
    display: 'flex'
  },
  link: {
    // color: theme.palette.primary.breadCrumbsColor,
    textDecoration: 'none',
    marginRight: '5px',
    textTransform: 'uppercase'
  },
  linkBold: {
    textDecoration: 'none',
    marginRight: '5px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  crumb: {
    color: theme.palette.primary.breadCrumbsColor,
    display: 'flex',
    marginRight: '5px',
    fontSize: '0.9rem',
  },
});
