export default (theme) => ({
  formContent: {
    backgroundColor: 'black',
    outline: 'none',
    minHeight: 'auto',
    maxHeight: '900px',
    color: 'white',
    padding: '20px 20px 20px 40px',
    /* padding: '5px 10px', */
    borderTop: '5px solid red',
    borderTopColor: theme.palette.primary.buttonColor,
    overflowY: 'auto',
    width: '550px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: '100%',
      padding: '20px 0px 20px 10%;'
    }
  },
  overlayContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    // width: '650px',
    minHeight: '210px',
    color: 'black',
    outline: 'none',
    borderTop: '5px solid red',
    borderTopColor: theme.palette.primary.buttonColor,
    padding: '10px 10px 20px 10px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '30px'
    }
  },
  content: {
    display: 'flex',
  },
  paperFullScreen: {
    overflowY: 'scroll',
  },
});
