export default theme => ({
  content: {
    maxWidth: '860px'
  },
  line: {
    height: '35px',
    width: '2px',
    backgroundColor: theme.palette.primary.buttonColor
  },
  point: {
    height: '10px',
    width: '10px',
    margin: '-1px 0 0 -4px',
    backgroundColor: theme.palette.primary.buttonColor,
    borderRadius: '50%'
  }
});
