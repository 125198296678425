import React from 'react';

import { TextField } from '@material-ui/core';

const Input = ({
  placeholder,
  value,
  handleChange,
  onEnterPress,
  className,
}) => (
  <TextField
    value={value}
    className={className}
    onChange={(e) => handleChange(e.target.value)}
    onKeyPress={(e) => e.which === 13 && onEnterPress()}
    placeholder={placeholder}
    style={{ paddingBottom: '5px' }}
  />
);

const areEqual = (prevProps, nextProps) => prevProps.value === nextProps.value;

export default React.memo(Input, areEqual);
