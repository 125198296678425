export default (theme) => ({
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    textTransform: 'uppercase',
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: '300',
  },
  button: {
    background: 'white',
    border: `3px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
    padding: '2px 15px',
    margin: '10px 0',
    alignSelf: 'flex-end',
    width: 196,
    height: 58
  },
  icon :{
    display: 'block',
    height: 20,
    marginRight: 9,
    width: 20
  },

  uploadButton: {
    background: theme.palette.primary.buttonColor,
  },
  rightbar: {
    display: 'flex',
  },
  iconExel: {
    marginRight: '5px',
  },
});
