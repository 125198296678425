import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import {openLoginForm} from "../../../actions/auth";

import { Link } from 'react-router-dom';
import { Divider, Button } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import TechnicalData from '../technicalData';
import { addProduct } from '../../../actions/cart';
import { formatMoney } from '../../../utils/localization';
import ReactSVG from 'react-svg';
import NumberInput from '../../common/NumberInput';
import {getShowDiscount} from "../../../utils/discounts";
import showDeliveryTime from "../../../utils/showDeliveryTime";
import {getDocument} from "../../../actions/products";
import {PRICE_TYPE_INDIVIDUAL} from "../../../constants/common";
import InquiryIconButton from "../Inquiry/Button";
import {openInquiry, setInquiryProduct} from "../../../actions/products";


const ProductImage = (props) => {
  const { classes, detail } = props;
  return (
    <div className={classNames(classes.imageContainer, 'image-container')}>
      <Link to={`/product/${detail.alias}/`}>
        <img
          className={classNames(classes.image, 'pdp-image')}
          alt='product'
          src={
            detail.image && detail.image.length
              ? detail.image[0].url
              : '/images/product_example_image.png'
          }
        />
      </Link>
    </div>
  );
};

const StatusRow = (props) => {
  const { classes, detail,t } = props;
  return (
    <div className={classNames(classes.statusRow, 'mat-num-medium')}>
      <div className={classes.matNum}>
        <div className={classes.smallHeader}>{t('materialNumber')}:</div>
        <div className={classes.matNumber}>{detail.code}</div>
      </div>
        {props.token && <div
        className={classNames(
           detail.isOrderable
              ? (detail.inStock
                ? classes.goodStatus
                : classes.limitedStatus)
              : classes.badStatus,
          'center-all',
          'bold'
        )}
      >
        <img
          alt='status'
          className={classes.statusImage}
          src={
            detail.isOrderable
             ? (detail.inStock
               ? '/images/icons/icon-available.svg'
               : '/images/icons/icon-available-limited.svg')
             : '/images/icons/icon-not-available.svg'
          }
        />
        {props.token && (detail.isOrderable
            ? (detail.discountPrice > 0 || detail.price > 0) && showDeliveryTime(detail.inStock, detail.deliveryTime)
          : t('notAvailable')) }
      </div>}
    </div>
  );
};

const DetailName = (props) => {
  const { classes, detail } = props;
  return (
    <div
      className={classNames(
        classes.bigHeader,
        'bold',
        'large-font',
        'big-header-medium'
      )}
    >
      <Link to={`/product/${detail.alias}/`}> {detail.name} </Link>
    </div>
  );
};

class ProductDetails extends React.Component {
  state = {
    count: 1,
  };
  handleInquiry(product) {
    this.props.setInquiryProduct({product:{productName:  product.productName, productCode: product.productCode, quantity: product.quantity}})
    this.props.openInquiry()
  }


  render() {
    const { classes, t, detail, settings } = this.props;
    const showDiscount = getShowDiscount(settings)
    if (!detail.code) return <div></div>;

    return (
      <div className={classes.content}>
        <div className={classNames(classes.infoBar, 'info-bar')}>
          <ProductImage classes={classes} detail={detail} />
          <div
            className={classNames(
              classes.contentColumn,
              'details-content-column'
            )}
          >
            <DetailName classes={classes} detail={detail} />

            <StatusRow classes={classes} detail={detail} token={this.props.token} t={t} />

            <div
              className={classes.infoRow}
              dangerouslySetInnerHTML={{
                __html: detail.details ? detail.details.shortDescription : '',
              }}
            />
            <Divider className={classes.divider} />
            { (this.props.token || this.props.showPricesForGuest) ? (
                (detail.price > 0 || detail.priceType === PRICE_TYPE_INDIVIDUAL) ? (
              <div>
                <div className={classes.buyInfoRow}>
                  <div>
                    <div className={classes.selectTitle}>{t('amount')}</div>
                    <NumberInput
                      value={this.state.count}
                      className={classes.amountInput}
                      onChange={(val) =>
                        this.setState({
                          count: val,
                        })
                      }
                    />
                  </div>
                  <div className={classes.priceColumn}>
                    {
                        detail.discountPrice > 0 ? (

                        <div className='pdp-price-column'>
                            {(showDiscount || detail.priceType === PRICE_TYPE_INDIVIDUAL) &&
                            <div
                                className={classNames(
                                    classes.price,
                                    classes.discountPrice,
                                    'bold'
                                )}
                            >
                                {detail.price > 0 && formatMoney(this.state.count * detail.price)}
                            </div>}

                        <div
                          className={classNames(
                            classes.price,
                            'bold',
                            'price-medium'
                          )}
                          style = {{color: (!showDiscount  && detail.priceType !== PRICE_TYPE_INDIVIDUAL) &&'black'}}
                        >
                          {formatMoney(this.state.count * detail.discountPrice)}
                            {detail.priceType === PRICE_TYPE_INDIVIDUAL && '*'}

                        </div>
                      </div>
                    ) : (
                      <div
                          className={classNames(
                          classes.originalPrice,
                          'bold',
                          'price-medium'
                        )}
                      >
                        {formatMoney(this.state.count * detail.price)}
                      </div>
                    )}

                    <div className={classes.address}>{t('cart:taxInfo')}</div>
                  </div>
                </div>

                <div className={classes.buttonRow}>
                  <Button
                    className={classNames(
                      classes.buyButton,
                      detail.isOrderable ? '' : classes.buyButtonDisabled
                    )}
                    classes={{
                      root: classes.buttonRoot,
                    }}
                    onClick={() =>
                        /*this.props.token ?*/ this.props.addProduct({
                        code: detail.product
                          ? detail.product.code
                          : detail.code,
                        quantity: this.state.count,
                      }) /*:
                            this.props.openLoginForm({centered:true})*/
                    }
                  >
                      <ReactSVG
                          alt='cart'
                          svgClassName={
                              detail.isOrderable
                                  ?
                                  classNames(classes.cartIcon)
                                  :
                                  classNames(classes.cartIcon, 'colored-gray')
                          }
                          src='/updated_images/svg/cart filled 15px.svg'
                      />
                    {t('cart:intoCart')}
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classNames(classes.price, 'bold')}>
                {t('requestPrice')}
              </div>
            )):
            <div className={classes.guestButtonRow}>
              
            <div className={classNames(classes.price, classes.guestPrice, 'bold')}>
              {t('guestPrice')}
            </div>

             <InquiryIconButton 
             onClick={ ()=> {
              this.handleInquiry({productCode: detail.code, productName: detail.name, quantity: this.state.count})
            }}/>
            </div>
            }
          </div>
        </div>
        <div className={classes.tabs}>
          {detail.details && detail.details.longDescription ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  className={classNames(
                    'title-exapnsion-panel',
                    'accordion-summary-typography-responsive'
                  )}
                >
                  {t('details')}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.details.longDescription,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  'title-exapnsion-panel',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('technicalData')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <TechnicalData detail={detail} />
            </AccordionDetails>
          </Accordion>
          {detail.document && detail.document.length ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  className={classNames(
                    'title-exapnsion-panel',
                    'accordion-summary-typography-responsive'
                  )}
                >
                  {t('document')}
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.document}>
                {detail.document.map((d, i) => (
                  <div key={i}>
                    <div
                      className='clickable'
                      onClick={() =>
                        this.props.getDocument({
                          url: d.url,
                          name: d.title,
                        })
                      }
                    >
                      {d.title}
                    </div>
                    <div>{d.description}</div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </div>
          {detail.priceType === PRICE_TYPE_INDIVIDUAL &&
          <div className='footNote'>* {t('checkoutPage:No Further Discount Applied')}</div>
          }
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    product: state.productHome.product,

      settings: state.staticContent.settings,
      token: state.auth.token,
      showPricesForGuest: state.staticContent.showPricesForGuest,
    }),
  {
    addProduct, getDocument, openLoginForm, openInquiry, setInquiryProduct
  },
  withNamespaces(['productPage', 'cart', 'checkoutPage'])(
    withStyles(styles)(ProductDetails)
  )
);
