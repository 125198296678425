import {t} from "i18next";
import React from "react";

export default function showDeliveryTime ( inStock, deliveryTime) {
        if (inStock)
            return (<>{t('productPage:available')}</>)
        else if( deliveryTime > 0)

            return (<>{t('productPage:Delivery in {{deliveryTime}} days', {deliveryTime: deliveryTime})}</>)
        else
            return (<>{t('productPage:limited')}</>)
}
