import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../utils/generic';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = props => {
  const { classes, tasks } = props;

  return tasks ? (
    <div className={classes.overlay}>
      <CircularProgress className={classes.text} />
    </div>
  ) : null;
};

export default connectTo(
  state => ({
    tasks: state.tasks.tasks
  }),
  {},
  withStyles(styles)(Spinner)
);
