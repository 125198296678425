import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import TableLarge from './TableLarge';
import TableSmall from './TableSmall';

import { addProduct } from '../../../actions/cart';
import { selectProduct, showDetails } from '../../../actions/products';

class ProductsList extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  };

  /* componentWillMount() {
    this.updateDimensions();
  } */

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  hadleAddProduct = (product) => {
    this.props.addProduct({
      code: product.product ? product.product.code : product.code,
      quantity: 1,
    });
  };

  render() {
    const { rows, classes, showDetails, selectProduct, schemaRef, tableRef, t } = this.props;

    const shouldShowLarge = this.state.width > 750;

    return (
      <div ref={tableRef} className={classNames(classes.table, 'machine-table')}>
        {rows.length ? (
          shouldShowLarge ? (
            <TableLarge
              schemaRef={schemaRef}
              category={this.props.category}
              selectProduct={selectProduct}
              showDetails={showDetails}
              rows={rows}
            />
          ) : (
            <TableSmall
              category={this.props.category}
              selectProduct={selectProduct}
              showDetails={showDetails}
              rows={rows}
            />
          )
        ) : (
          <h3>{t('noData')} </h3>
        )}
      </div>
    );
  }
}

export default connectTo(
  (state) => state,
  {
    selectProduct,
    showDetails,
    addProduct,
  },
  withNamespaces('productPage')(withStyles(styles)(ProductsList))
);
