import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { checkTerm } from '../../../../actions/cart';

import { connectTo } from '../../../../utils/generic';

const Term = props => {
  const { classes, type, term, index } = props;

  return (
    <div className={classes.checkboxContainer}>
      {type === 'checkbox' && (
      <Checkbox
        checked={term.checked}
        onChange={() => props.checkTerm(index)}
      />
      )}
      {term.labelLink ? (
        <div className='required-field'>
          &nbsp;{term.start}&nbsp;
          <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={term.useFile ? term.description : `/term/${term.alias}`}
            >
              {term.labelLink}
          </a>
          &nbsp;{term.end}
        </div>
      ) : (
          <div>&nbsp;{term.label}</div>
        )}
    </div>
  );
};

export default connectTo(
  state => ({}),
  {
    checkTerm
  },
  withNamespaces('startPage')(withStyles(styles)(Term))
);
