import React from 'react';

import Button from '@material-ui/core/Button';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

const RegistrationOverlay = props => {
  const { classes, handleClose, t, fullScreen } = props;

  return (
    <div className={classes.content}>
      {!fullScreen ?
        <div className={classes.logoColumn}>
          <img
            alt="registraion"
            className={classes.img}
            height="100"
            width="100"
            src="/images/glyphs/svg/glyph-registration-green.svg#reg"
          />
        </div> : null
      }
      <div className={classes.mainColumn} style={{ width: !fullScreen ? '75%' : '100%' }}>
        <div className="title-header success">{t('wellDone')}</div>
        <div className={classes.text}>
          {t('overlayTitle')}
          <br />
          {t('overlayText')}
        </div>

        <Button className={classes.button} onClick={() => handleClose()}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default connectTo(
  state => ({
    countries: state.metadata.countries,
    titles: state.metadata.titles,
    registrationEmail: state.start.registrationEmail,
    error: state.start.error
  }),
  {},
  withNamespaces('startPage')(withStyles(styles)(RegistrationOverlay))
);
