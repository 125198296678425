import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';

import { closeErrorSnack, closeSnack } from '../../../../actions/startpage';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import { toastr } from 'react-redux-toastr';
import {withNamespaces} from "react-i18next";

class ErrorSnackBar extends React.Component {
	componentDidUpdate(prevState) {
    const { t } = this.props;
		prevState.errors !== this.props.errors &&
			this.props.errors &&
			this.props.errors.map(e => {
				if (e.message) toastr.error(t('toastMessage:error'), e.message);
				return e;
			});

		const success = this.props.successMessage;
		prevState.successMessage !== success &&
			success !== '' &&
    toastr.success(t('toastMessage:success'), success, {
				onShowComplete: () => this.props.closeSnack()
			});
	}

	render() {
		return (
			<div>
				<ReduxToastr
					timeOut={5000}
					newestOnTop={false}
					preventDuplicates
					position='bottom-left'
					transitionIn='bounceIn'
					transitionOut='bounceOut'
					closeOnToastrClick
				/>
			</div>
		);
	}
}

export default connectTo(
    state => ({
      errors: state.start.error,
      successMessage: state.start.successMessage
    }),
    {
      closeErrorSnack,
      closeSnack
    },
    withNamespaces('toastMessage')(withStyles(styles)(ErrorSnackBar))
);
