import React from 'react';

import keycode from 'keycode';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { addProductInOrder } from '../../../actions/quickorder';
function renderInput(inputProps) {
  const { InputProps, classes, ref, handleClick, t, ...other } = inputProps;

  return (
    <TextField
      placeholder=""
      InputProps={{
        classes: {
          root: classNames(classes.inputRoot, 'order-search-input'),
          input: classNames(classes.inputInput, 'search-bar')
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  itemStyle
}) {
  const isHighlighted = highlightedIndex === index;
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.code}
      selected={isHighlighted}
      component="div"
      className={itemStyle}
    >
      {suggestion.code} {suggestion.name}
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : suggestions; // .slice(0, suggestions.length < 13 ? suggestions.length : 12);
}

class DownshiftMultiple extends React.Component {
  componentDidUpdate(prevProps) {
    prevProps.code !== null &&
      this.props.code === null &&
      this.setState({ inputValue: '' });
  }

  state = {
    inputValue: '',
    openFound: false
  };
  addProducts = () => {
      this.state.inputValue &&
      this.props.addProductInOrder({
        quantity: 1,
        inputValue: this.state.inputValue
      });
  };

  findProducts = pattern => {
    this.props.setSearchString(pattern);
  };

  handleKeyDown = (event, open, selectedIndex) => {
    this.setState({ openFound: true });

    if (keycode(event) !== 'enter') return;
    if (!open || (open && selectedIndex === null)) {
      this.findProducts(this.state.inputValue);
      this.addProducts();
    }

    this.setState({ openFound: false });
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value }, () =>
      this.props.setSearchString(this.state.inputValue)
    );
  };

  onSelect = (item) => {
    if (item) {
      this.props.selectProduct(item.alias);
      this.setState({
        inputValue: item.code
      });
    }
  };

  itemToString = item => (item ? item.code : '')

  render() {
    const { classes, t, suggestions } = this.props;
    return (
    <>
      <Downshift
        inputValue={this.state.inputValue}
        isOpen={this.state.openFound}
        onSelect={this.onSelect}
        itemToString={this.itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              handleClick: () => {
                this.setState({ openFound: false });
                this.findProducts(this.state.inputValue);
              },
              InputProps: getInputProps({
                onChange: this.handleInputChange,
                onKeyDown: e => this.handleKeyDown(e, isOpen, highlightedIndex),
                placeholder: t('search')
              })
            })}
            {isOpen && (
              <ClickAwayListener onClickAway={() => this.setState({ openFound: false })}>
                <Paper
                  className="search-bar-suggest-qiuck"
                  onClick={() => this.setState({ openFound: false })}
                  square
                >
                  {getSuggestions(inputValue2, suggestions).map(
                    (suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: { code: suggestion.code, alias: suggestion.alias } }),
                        highlightedIndex,
                        itemStyle: classes.menuItem
                      })
                  )}
                </Paper>
              </ClickAwayListener>
            )}
          </div>
        )}
      </Downshift>
      <img
      src="/images/icons/icon-go.svg"
      className={classes.goIcon}
      alt="add"
      onClick={this.addProducts}
    />
    </>
    );
  }
}

export default connectTo(
  state => ({
    code: state.quickorder.code
  }),
  { 
    addProductInOrder,
  },
  withNamespaces('homePage')(withStyles(styles)(withRouter(DownshiftMultiple)))
);
