import React from 'react';

import Page from '../page';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import InputWrapper from '../../components/common/InputTitleWrapper';

import { openErrorSnack } from '../../actions/startpage';
import { resetPassword, disableResetPassword } from '../../actions/auth';

class ResetPassPage extends React.Component {
  state = {
    password: '',
    repeatPassword: '',
    visible: true,
    visibleRepeat: true,
    values: {},
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  queryToJSON = (query) => {
    if (query === '') return {};
    return query
      .slice(1)
      .split('&')
      .reduce((obj, item, i) => {
        if (item) {
          item = item.split('=');
          obj[item[0]] = item[1];
          return obj;
        }
        return {};
      }, {});
  };

  componentDidMount() {
    this.props.disableResetPassword();
    try {
      const values = this.queryToJSON(this.props.location.search);
      const info = JSON.parse(atob(decodeURIComponent(decodeURI(values.info))));

      this.setState((state) => ({
        ...state,
        values: {
          ...info,
          token: values.token,
        },
      }));
    } catch (err) {
      this.props.history.replace('/');
    }
  }

  componentDidUpdate() {
    this.props.isSuccessResetPassword && this.props.history.replace('/');
  }

  validate() {
    let message = '';
    if (!this.state.password) {
      message = this.props.t('passwordEmpty');
    } else if (!this.state.repeatPassword) {
      message = this.props.t('repeatPasswordEmpty');
    } else if (this.state.repeatPassword !== this.state.password) {
      message = this.props.t('passwordsNotEqual');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { values, password } = this.state;
    this.validate() &&
      this.props.resetPassword({
        email: values.email,
        token: values.token,
        password: password,
      });
  };

  render() {
    const { classes, t, type } = this.props;
    return (
      <Page>
        <div
          className={classNames(
            'layout-section',
            'reset-section',
            classes.background
          )}
          style={{
            background: /*linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ `url('${this.props.backgroundUrl}') no-repeat 0% 0% / 100%`,
          }}
        >
          <div className='big-header'>
            {type === 'new' ? t('newPassword') : t('passwordReset')}
          </div>
        </div>
        <div className='main-content'>
          <form className={classes.resetContainer} onSubmit={this.handleSubmit}>
            <div className={classes.logoColumn}>
              <img
                className={classes.img}
                src='/images/glyphs/png/glyph-reset-password.png'
                alt='logo'
              />
            </div>
            <div className={classes.mainColumn}>
              <div>{t('description')}</div>
              <div>
                <InputWrapper header={t('newPassword')}>
                  <div className='card-column'>
                    <TextField
                      name='password'
                      type={this.state.visible ? 'password' : ''}
                      onChange={this.handleChange}
                      className='panel-element'
                      value={this.state.password}
                    />
                    <div
                      className={classes.eye}
                      onClick={() =>
                        this.setState({ visible: !this.state.visible })
                      }
                    >
                      {this.state.visible ? (
                        <img
                          alt='eye'
                          className={classes.eyeImg}
                          src='/images/icons/icon-eye-white.svg'
                        />
                      ) : (
                        <img
                          alt='eye'
                          className={classes.eyeImg}
                          src='/images/icons/icon-eye-red.svg'
                        />
                      )}
                    </div>
                  </div>
                </InputWrapper>
                <InputWrapper header={t('repeatPassword')}>
                  <div className='card-column'>
                    <TextField
                      name='repeatPassword'
                      type={this.state.visibleRepeat ? 'password' : ''}
                      onChange={this.handleChange}
                      className='panel-element'
                      value={this.state.repeatPassword}
                    />
                    <div
                      className={classes.eye}
                      onClick={() =>
                        this.setState({
                          visibleRepeat: !this.state.visibleRepeat,
                        })
                      }
                    >
                      {this.state.visibleRepeat ? (
                        <img
                          alt='eye'
                          className={classes.eyeImg}
                          src='/images/icons/icon-eye-white.svg'
                        />
                      ) : (
                        <img
                          alt='eye'
                          className={classes.eyeImg}
                          src='/images/icons/icon-eye-red.svg'
                        />
                      )}
                    </div>
                  </div>
                </InputWrapper>
              </div>

              <div className={classes.buttonBar}>
                <Button
                  className={classNames(classes.button, classes.secondButton)}
                  onClick={() => {
                    this.setState({
                      password: '',
                      repeatPassword: '',
                    });
                  }}
                >
                  {t('conclude')}
                </Button>
                <Button className={classes.button} type='submit'>
                  {t('finished')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    isSuccessResetPassword: state.auth.isSuccessResetPassword,
    backgroundUrl: state.staticContent.backgroundUrl,
  }),
  {
    openErrorSnack,
    resetPassword,
    disableResetPassword,
  },
  withNamespaces('resetPassPage')(withStyles(styles)(ResetPassPage))
);
