import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import {
  deleteShippingAddress,
  closeShipping,
  editShippingAddress,
  patchShippingAddress
} from '../../../../actions/address';
import { Checkbox } from '@material-ui/core';
//import ReactSVG from 'react-svg';

class ShippingCard extends React.Component {
  render() {
    const { classes, children, card, t, onClick } = this.props;

    return (
      <div className="shippingCard" onClick={onClick}>
        {card ? (
          <div className={classes.wrapper}>
            <div
              className={classNames(
                classes.content,
                card.isDefault && classes.defaultContent
              )}
            >
              <div className={classes.cardHeader}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.deleteShippingAddress({ address: card });
                  }}
                >
                  <img
                    alt="close"
                    height="20"
                    width="20"
                    src="/images/icons/X-black.png"
                  />
                </div>
              </div>
              <div className={classes.cardContainer}>
                <span
                  className={classNames(classes.cardRow, classes.cardTitle)}
                >
                  {card.firstName} {card.lastName}
                </span>
                <span
                  className={classes.cardRow}
                  style={{ textTransform: 'uppercase' }}
                >
                  {card.companyName}
                </span>
                <span className={classes.cardRow}>{card.additional}</span>
                <span className={classes.cardRow}>
                  {card.streetName} {card.streetNumber}
                </span>
                <span className={classes.cardRow}>
                  {card.postalCode}, {card.city}
                </span>
                <span className={classes.cardRow}>{card.country.name}</span>
                <span className={classes.cardRow}>{card.phone}</span>
              </div>
              <div className={classes.footer}>
                <div className={classes.checkbox}>
                  <Checkbox
                    checked={card.isDefault}
                    onChange={() =>
                      this.props.patchShippingAddress({
                        address: { ...card, isDefault: !card.isDefault }
                      })
                    }
                    className={classes.customChekbox}
                  />
                  <div className={classes.checkboxLabel}>
                    {t('asStandardAddress')}
                  </div>
                </div>
                <img
                  alt="edit"
                  onClick={() => this.props.editShippingAddress(card)}
                  onMouseEnter={e =>
                    (e.currentTarget.src =
                      '/images/icons/icon-card-settings-hover.svg')
                  }
                  onMouseLeave={e =>
                    (e.currentTarget.src =
                      '/images/icons/icon-card-settings-default.svg')
                  }
                  className={classNames(classes.image, classes.edit)}
                  src="/images/icons/icon-card-settings-default.svg"
                />
              </div>
              <div
                className={classNames(
                  classes.rightBottomCorne,
                  card.isDefault && classes.defaultRightBottomCorner
                )}
              />
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default connectTo(
  state => ({
    address: state.address.shippingAddress
  }),
  {
    closeShipping,
    editShippingAddress,
    deleteShippingAddress,
    patchShippingAddress
  },
  withNamespaces('accountPage')(withStyles(styles)(ShippingCard))
);
