export default theme => ({
  arrowUp: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    height: 'max-content',
    width: 'max-content'
  },
  arrowDown: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    width: 'max-content',
    height: 'max-content'
  },
  carousel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  hotSpot: {
    backgroundColor: theme.palette.primary.buttonColor + '45',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute'
  },
  item: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',

  },
  hideLeft: {
    width: 0,
    left: 0
  },
  hideRight: {
    width: 0,
    left: 500
  },
  slider: {
    display: 'flex',
    height: '100%',
    transition: 'all 0.5s ease'
  },
  arrow: {
    width: '35px'
  },
  fullItem: {
    flexShrink: 0,
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  width: {
    width: '100%'
  }
});
