import { createReducer } from 'redux-act';

import * as a from '../actions/news';

const DEFAULT_STATE = {
  articles: [],
  article: {}
};

export default createReducer(
  {
    [a.setArticles]: (state, articles) => ({
      ...state,
      articles: articles
    }),
    [a.setArticle]: (state, article) => ({
      ...state,
      article: article
    })
  },
  DEFAULT_STATE
);
