import React from 'react';

import SmallTableRow from '../../../common/SmallTableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {openLoginForm} from "../../../../actions/auth";

import styles from './style';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import ReactSVG from 'react-svg';

import { addProduct } from '../../../../actions/cart';
import { formatMoney } from '../../../../utils/localization';
import {
  selectProduct,
  showDetails,
  getSelectedProduct, setInquiryProduct, openInquiry,
  //getMySelectedProduct
} from '../../../../actions/products';
import { primaryColor } from '../../../../theme';
import QuizIcon from "@mui/icons-material/Quiz";
import InquiryIconButton from '../../../common/Inquiry/Button' ;

class TableSmall extends React.Component {
  hadleAddProduct = (product) => {
    this.props.addProduct({
      code: product.product ? product.product.code : product.code,
      quantity: 1,
    });
  };

  handleInquiry (product) {
    this.props.setInquiryProduct({product:{productName:  product.productName, productCode:  product.productCode, quantity: product.quantity}})
    this.props.openInquiry()
  }

  render() {
    const {
      rows,
      classes,
      t,
      showDetails,
      selectedLabel /*,
      category*/,
    } = this.props;

    const getSelectedProduct =
      /*category === 'my'
        ? this.props.getMySelectedProduct
        : this.props.getSelectedProduct;*/
      this.props.selectProduct;

    return (
      <div className={classes.table}>
        {rows.map((product, i) => (
          <Accordion
            key={i}
            style={{
              backgroundColor:
                product.label && selectedLabel === product.label
                  ? primaryColor.buttonColor + '45'
                  : '#fff',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={'accordion-summary-responsive'}
            >
              <Typography
                className={classNames(
                  classes.summaryTitleFont,
                  'accordion-summary-typography-responsive'
                )}
              >
                {product.label}&nbsp;&nbsp;&nbsp;{product.product.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.rows}>
              <Table className={classes.Table}>
                <TableBody>
                  <SmallTableRow title={t('position')} item={product.label} />

                  <SmallTableRow
                    title={t('materialNumber')}
                    item={
                      <div
                        title={product.product.code}
                        className={classes.nameCell}
                        style={{ color: '#0070b2' }}
                        onClick={() =>
                          (product.product.children &&
                            product.product.children.length) ||
                          product.isGroup
                            ? getSelectedProduct({
                                product,
                                /* alias: product.product.alias */
                              })
                            : showDetails({ product: product.product })
                        }
                      >
                        {product.product.code}
                      </div>
                    }
                  />
                  <SmallTableRow
                    title={t('quantity')}
                    item={product.quantity}
                  />
                  <SmallTableRow
                    title={t('unit')}
                    item={product.product.unit && product.product.unit.name}
                  />
                  <SmallTableRow
                    title={t('price')}
                    item={
                      product.product.discountPrice > 0
                        ? formatMoney(product.product.discountPrice)
                         : product.product.price > 0 ? formatMoney(product.product.price)
                        : t('requestPrice')
                    }
                  />
                  <SmallTableRow
                    title={t('availibility')}
                    item={
                      <img
                        alt='status'
                        src={product.product.isOrderable
                             ? (product.product.inStock
                               ? '/images/icons/icon-available.svg'
                               : '/images/icons/icon-available-limited.svg')
                             : '/images/icons/icon-not-available.svg'
                        }
                      />
                    }
                  />
                  <SmallTableRow
                    title={t('cart')}
                    item={
                      (this.props.token || this.props.showPricesForGuest) ?
                      <img
                        alt='Cart'
                        className={
                          product.product.isOrderable && (product.product.price || product.product.discountPrice)
                            ? 'cart-plus-icon available'
                            : 'cart-plus-icon'
                        }
                        onClick={() => /*this.props.token?*/this.hadleAddProduct(product)/*:this.props.openLoginForm({centered:true})*/}
                        src={
                          product.product.isOrderable && (product.product.price || product.product.discountPrice)
                            ? '/updated_images/svg/cart-plus 15px.svg'
                            : '/updated_images/svg/cart-plus 15px grey.svg'
                        }
                        style={{ cursor: 'pointer' }}
                      /> :
                      <InquiryIconButton
                      asImage
                      onClick={ ()=> {
                        this.handleInquiry({productName: product.product.code, quantity: 1 })
                      }}
                      /> 
                    }
                  />
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    selectedLabel: state.productHome.highlightedProductLabel,
    showPricesForGuest: state.staticContent.showPricesForGuest,
    token: state.auth.token,
  }),
  {
    selectProduct,
    getSelectedProduct,
    //    getMySelectedProduct,
    showDetails,
    addProduct,
    openInquiry,
    setInquiryProduct
  },
  withNamespaces('productPage')(withStyles(styles)(TableSmall))
);
