import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {getLocaleDateString} from "../../../../utils/localization";

class BillingDetails extends React.Component {

  render() {
    const { order, classes, t } = this.props;
    return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellCentred}>
                    {t('invoice date')}
                  </TableCell>
                  <TableCell className={classes.cellCentred}>
                    {t('invoice number')}
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.billings.map((billing, i) => {
                return (
                    <TableRow  className={classes.row} key={i}>
                      <TableCell className={classes.cellCentred}>
                        {getLocaleDateString(billing.generatedAt)}
                      </TableCell>
                      <TableCell className={classes.cellCentred}>
                        {billing.billingID}
                      </TableCell>
                    </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
    );
  }
}

export default connectTo(
  (state) => ({}),
  {},
  withNamespaces('billingDetails')(withStyles(styles)(BillingDetails))
);
