import React from 'react';
import {
  AUDIO_EXTENSIONS,
  VIDEO_EXTENSIONS,
  IMAGE_EXTENSIONS,
  WORD_EXTENSIONS,
  EXCEL_EXTENSIONS,
  PDF_EXTENSIONS,
  TXT_EXTENSIONS,
} from '../constants/extensions';

export const extractFileExtension = (url) => {
  const pattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
  return url.match(pattern)[0];
};

export const renderExtensionIcon = (url) => {
  let extension = extractFileExtension(url);

  if (WORD_EXTENSIONS.includes(extension))
    return <img src='/images/icons/Documents/word-24.png' alt='Word file' />;
  else if (VIDEO_EXTENSIONS.includes(extension))
    return (
      <img src='/images/icons/Documents/video-file-24.png' alt='Video file' />
    );
  else if (AUDIO_EXTENSIONS.includes(extension))
    return (
      <img src='/images/icons/Documents/audio-file-24.png' alt='Audio file' />
    );
  else if (EXCEL_EXTENSIONS.includes(extension))
    return <img src='/images/icons/Documents/excel-24.png' alt='Excel file' />;
  else if (IMAGE_EXTENSIONS.includes(extension))
    return <img src='/images/icons/Documents/photo-24.png' alt='Image file' />;
  else if (PDF_EXTENSIONS.includes(extension))
    return <img src='/images/icons/Documents/pdf-24.png' alt='Pdf file' />;
  else if (TXT_EXTENSIONS.includes(extension))
    return (
      <img src='/images/icons/Documents/text-file-24.png' alt='Text file' />
    );
  else
    return <img src='/images/icons/Documents/blank-file-24.png' alt='File' />;
};
