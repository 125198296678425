import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';

const CategoryTile = (props) => {

  const { classes, src, alt, tileTitle, tileSubtitle } = props;

  return (
    <div className={classes.tileContainer}>
      <img className={classes.tileImage} src={src && Array.isArray(src)? src.length > 0 ? src[0] : "/images/product_example_image.png"  : src ? src :"/images/product_example_image.png"} alt={alt} />
      <div className={classes.titleContainer}>
        <span className={classNames(classes.titleSpanContainer, 'tile-title')}>
          {tileTitle && tileTitle.length > 45 ? tileTitle.substring(0, 45) + "..." : tileTitle }
        </span>
      </div>
      <div className={classes.subtitleContainer}>
        <span className={classNames(classes.subtitleSpanContainer)}>
          {tileSubtitle}
        </span>
      </div>
    </div>
  );
};
export default withStyles(styles)(React.memo(CategoryTile));
