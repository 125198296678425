export default (theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.darkGrey}`,
    borderRadius: '5px',
    marginTop: '20px',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.darkGrey}`,
    background: '#f8f8f8',
    '-moz-box-shadow': 'inset 0 0 50px #FFFFFF',
    '-webkit-box-shadow': 'inset 0 0 50px #FFFFFF',
    boxShadow: 'inset 0 0 50px #FFFFFF',
    padding: '20px',
    textTransform: 'uppercase',
    fontSize: '1.2em',
    borderRadius: '5px 5px 0 0',
  },
  headerRed: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: 'white',
    boxShadow: 'none',
    display: 'flex',
    flexWrap: 'wrap',
  },
  headerTitle: {
    marginRight: '30px',
  },
  orderDetails: {
    fontSize: '0.6em',
    display: 'flex',
    alignItems: 'flex-end',
  },
  table: {
    marginTop: '10px',
    display: 'flex',
    padding: '0 10px',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  selected: {
    backgroundColor: theme.palette.primary.selected,
  },
  row: {
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  inStockCell: {
    width: '100px',
    color: theme.palette.primary.green,
  },
  cartCell: {
    width: '50px',
    cursor: 'pointer',
  },
  cellCentred: {
    textAlign: 'left',
    padding: '0 5px',
  },
  detailsButton: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
    padding: '0 10px',
  },
  document: {
    height: '30px',
  },
  orderButton: {
    minHeight: 0,
    margin: '7px 0',
  },
  subtotal: {
    marginTop: '7px',
  },
  productName: {
    fontSize: '0.9em',
    fontWeight: '500',
  },
  tableContainer: {
    width: '100%',
  },
  discountCol: {
    color: theme.palette.primary.blue,
  },
  notDiscountPrice: {
    textAlign: 'right',
    textDecoration: 'line-through',
  },
  discountPrice: {
    marginBottom: '10px',
  },
  selectedPage: {
    color: theme.palette.primary.buttonColor + ' !important',
  },
  iconFold: {
    cursor: 'pointer',
  },
  iconUnfold: {
    transform: 'translateY(0%) rotate(180deg);',
    color: '#0070B2',
    cursor: 'pointer',
    top: '50%',
    transition: 'opacity 2s ease-out',
  },
});
