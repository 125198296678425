import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

import Toolbar from '@material-ui/core/Toolbar';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import NewUserModal from '../newUser';
import { connectTo } from '../../../../utils/generic';
import {
  openNewUserForm,
  closeNewUserForm,
  openEditUserForm,
  addUser,
  patchUser,
} from '../../../../actions/account';
import ReactSVG from 'react-svg';
//import { primaryColor } from '../../../../theme';

class EnhancedTableToolbar extends React.Component {
  state = {
    selectedAction: '',
  };

  handleSelect = (value) => {
    value === '1' && this.props.handleActivateMany();
    value === '2' && this.props.handleDeactivateMany();
    value === '3' && this.props.handleDeleteMany();
    this.setState({ selectedAction: value });
  };

  render() {
    const {
      numSelected,
      allUsers,
      activeUsers,
      handleFilterChange,
      classes,
      t,
    } = this.props;

    return (
      <Toolbar
        className={classes.root}
        classes={{
          root: 'enhanced-table-toolbar',
          gutters: 'enhanced-table-toolbar',
        }}
      >
        <NewUserModal
          open={this.props.isNewUserOpen}
          handleClose={() => this.props.closeNewUserForm()}
          title={this.props.isModeEdit ? t('editUser') : t('addUser')}
          handleSubmit={
            this.props.isModeEdit
              ? (user) => this.props.patchUser({ user: user })
              : (user) => this.props.addUser({ user: user })
          }
          t={t}
        />
        <div className={classes.toolbarContainer}>
          <div className={classNames(classes.toolbarTopRow, 'toolbar-top-row')}>
            <div className='center-all'>
              <span className={classNames(classes.tableHeader, 'table-header')}>
                {t('users')}
              </span>
            </div>
            <FormControl
              className={classes.margin}
              classes={{ root: 'toolbar-search-input' }}
            >
              <Input
                className={classNames(
                  classes.searchInput,
                  'toolbar-search-input'
                )}
                id='input-with-icon-adornment'
                startAdornment={
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                }
                onChange={handleFilterChange}
              />
            </FormControl>
          </div>
          <div
            className={classNames(classes.toolbarBottomRow, 'bottom-row', {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <div
              className={classNames(classes.title, 'bottom-row-title')}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {numSelected > 0 ? (
                <span>{numSelected} {t('selected')}</span>
              ) : (
                <div
                  className={classNames(
                    classes.statsRow,
                    'stats-row',
                    'stats-row-medium'
                  )}
                >
                  <div
                    className={classNames(
                      classes.statsRow,
                      'center-all',
                      'no-margin'
                    )}
                  >
                    <div
                      className={classNames(
                        classes.users,
                        classes.allUsers,
                        'center-all',
                        'bold',
                        'users-badge'
                      )}
                    >
                      {allUsers}
                    </div>
                    <span className={classes.bottomUserText}>
                      {t('allUsers')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      classes.statsRow,
                      'center-all',
                      'no-margin'
                    )}
                  >
                    <div
                      className={classNames(
                        classes.users,
                        classes.activeUsers,
                        'center-all',
                        'bold',
                        'users-badge'
                      )}
                    >
                      {activeUsers}
                    </div>
                    <span className={classes.bottomUserText}>
                      {t('activeUsers')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={classNames(classes.spacer, 'spacer')} />
            <div
              className={classNames(
                classes.actions,
                'center-all',
                'stats-row-medium'
              )}
              style={{ backgroundColor: 'white' }}
            >
              <div
                className={classNames(classes.addUserCol, 'center-all')}
                onClick={() => this.props.openNewUserForm()}
                style={{ cursor: 'pointer' }}
              >
                <ReactSVG
                  alt='add'
                  className={classes.newIcon}
                  svgClassName={classNames(classes.newIcon, 'colored')}
                  src='/images/icons/MyAccount/icon-add.svg'
                />
                <span className={classNames(classes.addUser, 'bold')}>
                  {' '}
                  {t('addUser')}{' '}
                </span>
              </div>
              <FormControl style={{ marginTop: '5px' }}>
                <Select
                  className={classes.multipleActionContainer}
                  value={this.state.selectedAction}
                  displayEmpty
                  onChange={(e) => this.handleSelect(e.target.value)}
                >
                  <MenuItem value='' disabled>
                    <div
                      className={classes.selectAction}
                      style={{ opacity: '0.5' }}
                    >
                      {t('selectOption')}
                    </div>
                  </MenuItem>
                  <MenuItem key='1' value='1'>
                    <div className={classes.selectAction}>{t('activate')}</div>
                  </MenuItem>
                  <MenuItem key='2' value='2'>
                    <div className={classes.selectAction}>
                      {t('deactivate')}
                    </div>
                  </MenuItem>
                  <MenuItem key='3' value='3'>
                    <div className={classes.selectAction}>{t('delete')}</div>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

export default connectTo(
  (state) => ({
    isNewUserOpen: state.account.isUserFormOpen,
    isModeEdit: state.account.isModeEdit,
  }),
  {
    openNewUserForm,
    closeNewUserForm,
    openEditUserForm,
    addUser,
    patchUser,
  },
  withStyles(styles)(EnhancedTableToolbar)
);
