import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import classNames from 'classnames';
import styles from './style';
import {withNamespaces} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';

import {formatMoney} from '../../../../../../utils/localization';
import {connectTo} from "../../../../../../utils/generic";
import {getSettings} from "../../../../../../actions/static";
import {getCartItemDiscountDetails, getShowDiscount} from "../../../../../../utils/discounts";

class OrderDetailsTable extends React.Component {

    render() {
        const {classes, t, order, settings} = this.props;
        const showDiscount = getShowDiscount(settings)

        return (
            <Table style={{padding: '0 10px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Position')}</TableCell>
                        <TableCell>{t('Details')}</TableCell>
                        {/*{(showDiscount || this.props.order.items.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL)) &&*/}<TableCell/>{/*}*/}
                        <TableCell>{t('Price')}</TableCell>
                        <TableCell>{t('Quantity')}</TableCell>
                        <TableCell>{t('Subtotal')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {order.items.map((item, i) => {
                        if (item.product) {
                            const {
                                rowPrice,
                                discountValue,
                                discountPriceCellClass,
                                haveDiscounts
                            } = getCartItemDiscountDetails(item, showDiscount);

                            return (
                                <TableRow className={classes.row} key={i}>
                                    <TableCell className={classes.cellCentred}>{i + 1}</TableCell>
                                    <TableCell
                                        className={classNames(classes.nameCell, classes.cellCentred)}>
                                        <div className={classes.productName}>
                                            {item.product.name}
                                        </div>
                                        <div>
                                            {t('Material Number')}: {item.product.code}
                                        </div>
                                    </TableCell>
                                    {/*{(showDiscount || this.props.order.items.find(e => e.product.priceType === PRICE_TYPE_INDIVIDUAL)) &&*/}
                                    <TableCell>
                                        {
                                            showDiscount && haveDiscounts ?
                                                <div className={classes.notDiscountPrice}>
                                                    {item.price > 0 /*&& (showDiscount || item.priceType === PRICE_TYPE_INDIVIDUAL)*/ && formatMoney(item.price)}
                                                </div> : null
                                        }
                                    </TableCell>
                                    {/*}*/}

                                    <TableCell>
                                        {
                                            showDiscount && haveDiscounts ?
                                                <div>
                                                    <div className={discountPriceCellClass}>
                                                        {rowPrice ? formatMoney(rowPrice) : t('requestPrice')}
                                                    </div>
                                                    {(showDiscount && discountValue) &&
                                                        <div className={classes.discount}>
                                                            {t('cart:discount')} {discountValue}%
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                (rowPrice > 0 ? formatMoney(rowPrice) : t('requestPrice'))
                                        }
                                    </TableCell>

                                    <TableCell className={classes.cellCentred}>
                                        {item.quantity}
                                    </TableCell>
                                    <TableCell className={classes.cellCentred}>
                                        {formatMoney(rowPrice * item.quantity)}
                                    </TableCell>
                                </TableRow>
                            )
                        } else return null;
                    })}
                </TableBody>
            </Table>
        );
    }
}


export default connectTo(
    (state) => ({
        settings: state.staticContent.settings
    }),
    {
        getSettings
    },
    withNamespaces('myOrders')(withStyles(styles)(OrderDetailsTable))
);