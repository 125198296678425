import React from 'react';

import { Select, MenuItem } from '@material-ui/core';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import ProductList from './productList';
import Pagination from '../../account/userManagement/pagination';
import classNames from 'classnames';

class SearchResult extends React.Component {
  state = {
    sort: 'asc',
    rowsPerPage: 20,
    visiblePages: 5,
  };

  handleSortingChange = (e) => {
    const val = e.target.value;
    this.setState({ sort: val });
    this.props.changeSorting(val);
  };

  render() {
    const {
      t,
      classes,
      searchResult,
      handleChangePage,
      page,
      category,
    } = this.props;

    if (!searchResult) return <div> {t('Nothing was found')} </div>;

    const products = searchResult.items;

    return (
      <div className={classNames(classes.content, 'search-result')}>
        <div className={classes.header}>
          <div />
          <div className={classes.sortBar}>
            <div className={classes.sortLabel}>{t('sortBy')}</div>
            <Select value={this.state.sort} onChange={this.handleSortingChange}>
              <MenuItem value='asc'>A-Z</MenuItem>
              <MenuItem value='desc'>Z-A</MenuItem>
            </Select>
          </div>
        </div>
        <div>
          <ProductList category={category} products={products} />
        </div>
        {searchResult.pagination && searchResult.pagination.length > 1 && (
          <div className='center-all'>
            <Pagination
              total={searchResult.pagination && searchResult.pagination.length}
              current={page}
              display={this.state.visiblePages}
              onChange={handleChangePage}
              selectedClass={classes.selectedPage}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    searchResult: state.listingPage.searchResult,
  }),
  {},
  withNamespaces('searchPage')(withStyles(styles)(SearchResult))
);
