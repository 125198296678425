import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const Arrow = props => {
  const {
    classes,
    src,
    handleClick,
    arrowVisible,
    className,
    arrowClass
  } = props;

  return (
    <div
      className={classNames('center-all', classes.arrowUp, className)}
      style={{ visibility: arrowVisible ? 'visible' : 'collapse' }}
    >
      <img
        alt="arrow"
        className={arrowClass}
        src={src}
        onClick={() => handleClick()}
      />
    </div>
  );
};

export default withStyles(styles)(React.memo(Arrow));
