import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

const LastNameField = (props) => {
  const { classes, lastName, setNewUserInput, t } = props;

  return (
    <div className={classNames(classes.content_row, classes.controlMd)}>
      <div className={classes.textField}>
        <div className={classNames(classes.label, 'required-field')}>
          {t('secondName')}
        </div>
        <TextField
          name='lastName'
          value={lastName || ''}
          placeholder='Musterman'
          className={classes.textField}
          onChange={(e) => setNewUserInput(e.target)}
        />
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.lastName === nextProps.lastName;
}

export default withStyles(styles)(React.memo(LastNameField, areEqual));
