import React from 'react';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';

import { connectTo } from '../../utils/generic';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import { Link } from 'react-router-dom';

import { getLocaleDateStringLong } from '../../utils/localization';

import { getArticles, getArticle } from '../../actions/news';
import classNames from 'classnames';

const Article = (props) => {
  const { classes, article } = props;
  return (
    <div className={classes.mainContent}>
      <div className={classNames(classes.title, 'bold')}>
        {article && article.title ? article.title : ''}
      </div>
      <div className={classes.date}>
        {article && article.createdAt
          ? getLocaleDateStringLong(article.createdAt)
          : ''}
      </div>
      {article ? (
        <img
          alt={article.title}
          className={classes.image}
          src={article.image}
        />
      ) : null}

      <div
        dangerouslySetInnerHTML={{ __html: article ? article.description : '' }}
      />
    </div>
  );
};

class ArticlePage extends React.Component {
  state = {
    page: 0,
  };

  componentDidMount() {
    this.props.getArticles();

    const alias = this.props.location.state
      ? this.props.location.state.alias
      : this.props.location.pathname.replace('/article/', '');

    this.props.getArticle({ alias });
  }

  render() {
    const { classes, t, article, articles } = this.props;

    return (
      <Page>
        <div
          className='layout-section home-section'

        >
          <BreadCrumbs
            links={[
              { name: t('Home'), link: '/' },
              { name: t('Blog'), link: '/' },
              {
                name:
                  article &&
                  article.title &&
                  (article.title.length <= 20
                    ? article.title
                    : article.title.slice(0, 20) + '...'),
                link: '#',
              },
            ]}
          />
        </div>
        <div className='main-content'>
          <div className={classNames(classes.content, 'article-content')}>
            <Article article={article} classes={classes} articles={articles} />
            <div
              className={classNames(
                classes.sidebar,
                'light',
                'article-content--info'
              )}
            >
              <div className={classes.sidebarHeader}>
                {t('Recent Blog Posts')}
              </div>
              {articles
                ? articles.map((a) => (
                    <div className={classes.sidebarArticle} key={a.alias}>
                      <img
                        alt={a.title}
                        className={classes.image}
                        src={a.image}
                      />
                      <div className={classNames(classes.articleTitle, 'bold')}>
                        {a.title}
                      </div>
                      <div className={classNames(classes.articleDate, 'bold')}>
                        {getLocaleDateStringLong(a.createdAt)}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: a.description }}
                      />
                      <Link
                        className={classNames(classes.link, 'bold')}
                        to={{
                          pathname: `/article/${a.alias}`,
                          state: { alias: a.alias },
                        }}
                      >
                        {t('Read')}
                      </Link>
                      <hr />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    articles: state.news.articles,
    article: state.news.article,
    backgroundUrl: state.staticContent.backgroundUrl,
  }),
  {
    getArticles,
    getArticle,
  },
  withNamespaces('Article Page')(withStyles(styles)(withRouter(ArticlePage)))
);
