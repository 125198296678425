export default (theme) => ({
  // mainBox: {
  //   height: '2.5em',
  //   width: '250px',
  //   backgroundColor: theme.palette.primary.slightlyGrey,
  //   display: 'flex',
  //   alignItems: 'center',
  //   right: '30px'
  // },
  // content: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  searchIcon: {
    position: 'absolute',
    right: '5px',
    zIndex: 1,
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    paddingTop: 1,
    paddingRight: '5px'
},
  magnifier: {
    width: '40px',
    height: '40px',
    margin: '14px 18px 18px 17px',
  },
  items: {
    // borderRadius: '1em',
    // position: 'relative',
    // paddingBottom: '0',
    // width: '200px',
    // marginBottom: '10px',
    // // background: 'rgb(' + '30, 30, 30' +')',
    // backgroundColor: 'transparent'

  },
  inputRoot: {
    "@media (max-width: 750px)": {
      width: 40,
      zIndex: 2,
      opacity: 0.2,
    },
    color:'white',
    borderRadius: 15,
    position: 'relative',
    paddingBottom: '0',
    width: 240,
    height: 32,
    marginBottom: '11px',
    bottom: 6,
    background: 'rgb(' + '30, 30, 30' + ')',
    // backgroundColor: 'transparent',
    "&.Mui-focused": {
      "@media (max-width: 750px)": {
        width: 200,
        borderColor: 'white',
        border: 'solid 1px',
        zIndex: 0,
        opacity: 1,
        "& ::placeholder": { /*Chrome, Firefox, Opera, Safari 10.1*/
          color: 'black',
          opacity: 1
        },
      },
      borderRadius: '1em',
      position: 'relative',
      right: 0,
      paddingBottom: '0',
      bottom: 6,
      width: '400px',
      height: 32,
      marginBottom: '11px',
      background: 'rgb(' + '30, 30, 30' + ')',
    },
    // "& ::-webkit-input-placeholder": { /*chrome, edge*/
    //   color: 'white',
    //   opacity: 1
    // },
    "& ::placeholder": { /*Chrome, Firefox, Opera, Safari 10.1*/
      color: 'white',
      opacity: 1
    },

    "& :-ms-input-placeholder": { /* Internet Explorer 10-11 */
      color: 'white'
    },
    "& ::-ms-input-placeholder": { /* Edge */
      color: 'white'
    },
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    position: 'relative',
    margin: 'auto',
    background: 'transparent',
    top:12,
  },
  chip: {
    margin: `${theme.spacing(1/2)}px ${theme.spacing(1/4)}px`,
  },
   inputInput: {
     border: 'unset',
     padding: '8px 8px 8px 0',
     backgroundColor: 'transparent',
     color: 'white',
   },

  divider: {
    height: theme.spacing(2),
  },
  displayNoClearIcon: {
    display: 'none',
  },
  clearIcon: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#80808059',
    margin: '0 0 0 10px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  positionStart: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '-20px'
    }
  }
});
