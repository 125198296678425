import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

class ShoppingCartSummary extends React.Component {
  render() {
    const { classes, title, price, showAsterisk } = this.props;

    return (
      <div className={classes.priceRow}>
        <span className={classNames(classes.priceText, 'price-text')}>
          {title}:
        </span>

        {showAsterisk ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <div className={classNames(classes.priceValue, 'bold')}>
              {price}
            </div>
            <span style={{ color: '#c03d13' }}>&nbsp;*</span>
          </div>
        ) : (
          <div className={classNames(classes.priceValue, 'bold')}>{price}</div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ShoppingCartSummary);
