export default (theme) => ({
  main: {
    // cursor: 'default',
    position: 'absolute',
    width: '430px',
    // marginTop: '-20px',
    left: 'calc(-310px + 50%)',
    zIndex: '100',
  },
  rectangleContent: {
    padding: '18px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    cursor: 'default',
  },
  centred: {
    textAlign: 'center',
  },
  nameCell: {
    maxWidth: '140px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  buttons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '140px',
    fontSize: '0.7em',
  },
  emptyButton: {
    backgroundColor: '#fff',
    border: `1.5px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
  },
  filledButton: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
  },
  count: {
    fontSize: '0.75em',
    background: theme.palette.primary.buttonColor,
    color: 'white',
    borderRadius: '15px',
    padding: '0 7px',
    marginRight: '5px',
    height: '1.5em',
  },
  header: {
    display: 'flex',
  },
  head: {
    display: 'inline-block',
    width: '100%',
  },
  body: {
    display: 'inline-block',
    overflow: 'auto',
    height: ' 144px',
    width: '100%',
  },
  table: {
    width: '100%',
    display: 'block',
  },
  priceContainer: {
    textAlign: 'right',
    marginTop: '10px',
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.1em',
  },
  price: {
    marginLeft: '5px',
  },
  info: {
    fontSize: '0.75em',
    margin: '7px 0 20px',
  },
  triangleContainer: {
    display: 'flex',
    height: '10px',
  },
  empty: {
    flex: 1,
  },
  emptyLeft: {
    flex: '0 0 300px'
  },
  triangle: {
    // width: '10px',
    marginTop: '-10px',
    borderRightColor: 'transparent',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    border: '10px solid',
    borderColor: 'white',
  },
  img: {
    maxWidth: '60px',
    maxHeight: '60px',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: 'grey',
  },
  emptyCart: {
    margin: '30px 0',
  },
});
