import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import { withNamespaces } from 'react-i18next';

import SmallTableRow from '../../common/SmallTableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import { formatMoney } from '../../../utils/localization';

const TechnicalData = (props) => {
  const { classes, t, detail } = props;

  return (
    <div className={classes.detailsContainer}>
      <Table className={classes.Table}>
        <TableBody>
          <SmallTableRow
            title={t('price')}
            item={detail.price ? formatMoney(detail.price) : t('requestPrice')}
          />
          <SmallTableRow
            title={t('unit')}
            item={(detail.unit && detail.unit.name) || '-'}
          />
          {detail.details && detail.details.technicalDetails
            ? detail.details.technicalDetails.map((techDetails, i) => {
                return (
                  <SmallTableRow
                    key={i}
                    title={
                      techDetails.attribute && techDetails.attribute.name
                        ? techDetails.attribute.name
                        : '-'
                    }
                    item={
                      techDetails && techDetails.content ? (
                        techDetails.isHtml ? (
                          <div
                            className='inner-html-container'
                            dangerouslySetInnerHTML={{
                              __html: techDetails.content,
                            }}
                          />
                        ) : (
                          techDetails.content
                        )
                      ) : (
                        '-'
                      )
                    }
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
    </div>
  );
};

export default withNamespaces('productPage')(withStyles(styles)(TechnicalData));
