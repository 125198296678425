import React from 'react';

import Footer from '../components/common/Footer';
import Header from '../components/common/header';
import LanguageHeader from '../components/common/header/TopHeader/LanguageHeader';
import ServiceHeader from '../components/common/header/ServiceHeader';
import StickySideBar from '../components/common/stickySideBar';

import { getBackground } from '../actions/static';
import { connectTo } from '../utils/generic';
import { withRouter } from 'react-router-dom';
import { setAnalytics, getSettings } from '../actions/static';
import { setMyRole } from '../actions/auth';

import { withNamespaces } from 'react-i18next';
import {
  closeInquiry
} from '../actions/products';
import ModalInquiry from '../components/common/Inquiry'
// import CookieConsent, { Cookies } from 'react-cookie-consent';
import LoginDialog from "../components/common/header/loginDialog";
import DialogContent from "@material-ui/core/DialogContent";
import {withStyles} from "@material-ui/core/styles";
import styles from "../components/account/myData/modals/style";
import withMobileDialog from "@material-ui/core/withMobileDialog";


class Page extends React.Component {
  componentDidMount() {
    if (!this.props.backgroundUrl) this.props.getBackground();
    this.props.getSettings();
    if (localStorage.getItem('service'))
      this.props.setMyRole({ code: 'ROLE_USER' });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.role !== this.props.role) {
      // if (this.props.token && this.props.role === 'ROLE_SERVICE') this.props.history.push('service');
       this.props.history.push(this.props.location.pathname)
    }
    const { analyticsActivated, settings, role, customers, fullScreen } = this.props;

    if (
      prevProps.customers !== customers &&
      customers &&
      customers.length > 0 &&
      role === null &&
      !localStorage.getItem('service')
    )
      this.props.setMyRole({ code: 'ROLE_SERVICE' });

    if ( !analyticsActivated ) {
      if (
        prevProps.settings !== settings ||
        prevProps.analyticsActivated !== analyticsActivated
      ) {
        let googleCode = this.googleCodeExistsCheck();
        if (googleCode && googleCode.value) {
          this.generateAnalyticsCode(googleCode.value);
        } else {
          googleCode = null;
        }

        if (googleCode) {
          this.props.setAnalytics(true);
        }
      }
    }
  }

  googleCodeExistsCheck = () => {
    const { settings } = this.props;

    if (settings) {
      const set = Array.from(settings);
      if (set.length !== 0) {
        let googleCode = set.find((item) => item.name === 'google_analytics');
        if (googleCode) return googleCode;
      }
    }
    return false;
  };

  generateAnalyticsCode = (codeString) => {
    if (codeString) {
      const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gim;
      let match;
      while ((match = re.exec(codeString))) {
        if (match[0].match(/<script.*?src=["|'](.*?)["|']/gim)) {
          this.generateFromSource(match[0]);
        } else {
          this.generateFromContent(match[0]);
        }
      }
    }
  };

  generateFromContent = (codeString) => {
    let contentString = codeString.replace(/<script\b[^>]*>|<\/script>/gi, '');
    setTimeout(contentString, 0);
  };

  generateFromSource = (codeString) => {
    let script = document.createElement('script');
    let template = document.createElement('div');
    template.innerHTML = codeString;

    const element = template.getElementsByTagName('script')[0];
    for (let attribute of element.attributes) {
      if (attribute) {
        script[attribute.name] = attribute.value ? attribute.value : true;
      }
    }
    document.head.appendChild(script);
  };

  render() {
    const { children, role, pageStyles, pageHeader, showStickyBar, additionalClass, fullScreen } = this.props;

    let googleCode = this.googleCodeExistsCheck();
    let service = localStorage.getItem('service');
    let technician = localStorage.getItem('technician');

    return (
   <div className={`HolyGrail ${additionalClass || ""}`} style = {pageStyles || null}>
        {!this.props.showPricesForGuest &&
            <ModalInquiry
             open={this.props.isInquiryOpen}
             handleClose={() => this.props.closeInquiry()}
         />
           }
        {service ? <ServiceHeader /> : null}

        {role === 'ROLE_SERVICE' || technician ? (
          <LanguageHeader />
        ) : (
              // pageHeader ? (pageHeader) : <Header />
            pageHeader ? <Header/> : <Header pageLocation={this.props.location}/>
        )}
        {showStickyBar === false ? null  : <StickySideBar />}
        <div className='HolyGrail-body'>
          <LoginDialog/>
          <div className='HolyGrail-content'>{children}</div>
        </div>

        {/*
        {Cookies.get('CookieConsent') === undefined && googleCode ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
             <CookieConsent
              location='bottom'
              cookieName='CookieConsent'
              expires={365}
              enableDeclineButton
              cookieValue={true}
              declineCookieValue={false}
              acceptOnScroll={false}
              onAccept={({ acceptedByScrolling }) => {
                if (!acceptedByScrolling) {
                  this.props.setAnalytics(false);
                  Cookies.set('CookieConsent', 'true');
                }
              }}
              onDecline={() => {
                Cookies.set('CookieConsent', 'false');
              }}
              buttonText={t('Accept all Cookies')}
              declineButtonText={t('Accept only neccessary Cookies')}
            >
              <p style={{ fontSize: '20px', color: 'white' }}>
                {t('useCookiesText')}
              </p>
              {t('cookieBannerText')}{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={t('cookieBannerLink')}
                style={{
                  color: '#0070b2',
                  textDecoration: 'underline',
                }}
              >
                {t('learnMore')}
              </a>
            </CookieConsent>
          </div>
        ) : null}
            */}
        <Footer />
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    backgroundUrl: state.staticContent.backgroundUrl,
    analyticsActivated: state.staticContent.analyticsActivated,
    settings: state.staticContent.settings,
    role: state.auth.role,
    customers: state.staticContent.serviceCustomers,
    isInquiryOpen: state.productHome.isInquiryOpen,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  { getBackground, getSettings, setAnalytics, setMyRole, closeInquiry },
    withRouter(withNamespaces('startPage')(Page))
);
