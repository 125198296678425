import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import { Link } from 'react-router-dom';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../utils/localization';
import NumberInput from '../../common/NumberInput';
import {getShowDiscount} from "../../../utils/discounts";
import {connectTo} from "../../../utils/generic";
import {PRICE_TYPE_DISCOUNTED, PRICE_TYPE_INDIVIDUAL} from "../../../constants/common";

class ShoppingCart extends React.Component {
  render() {
    const { classes, t, items, settings } = this.props;
    const showDiscount = getShowDiscount(settings)

    return (
      <div className={classes.machineTable}>
        {items && items.length ? (
          <div>
            <div className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('position')}</TableCell>
                    <TableCell />
                    <TableCell>{t('data')}</TableCell>
                      {(showDiscount || this.props.items.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL)) &&  <TableCell />}
                    <TableCell>{t('productPrice')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell>{t('subtotal')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, i) => {
                    const price = row.discountPrice || row.price;
                    return (
                      <TableRow className={classes.row} key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          <div
                            className={classNames(
                              classes.circle,
                              'image-cropper'
                            )}
                          >
                            <img
                              alt={row ? row.name : ''}
                              src={
                                row.image && row.image.length
                                  ? row.image[0].url
                                  : '/images/product_example_image.png'
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.nameCell}>
                          <Link
                            to={`/product/${row.alias}`}
                            className={classes.description}
                          >
                            <div
                              className={classNames(
                                classes.productName,
                                classes.descriptionRow
                              )}
                              title={row.name}
                            >
                              {row.name}
                            </div>
                            <div
                              className={classNames(
                                classes.descriptionRow,
                                classes.sku
                              )}
                            >
                              {`${t('materialNumber')}: ${row.code}`}
                            </div>
                            {/* <div className={classes.detailsRow}>
                              {row.details &&
                                row.details.shortDescription}
                            </div> */}
                          </Link>
                        </TableCell>

                          {(showDiscount || row.priceType === PRICE_TYPE_INDIVIDUAL) &&
                          <TableCell>
                              {row.discountPrice ? (
                                  <div className={classes.notDiscountPrice}>
                                      {row.price > 0 && formatMoney(row.price)}
                                  </div>

                              ) : null}
                          </TableCell>}

                        <TableCell>
                          {row.discountPrice ? (
                            <div>
                              <div
                                  className={classNames(row.priceType === PRICE_TYPE_INDIVIDUAL && 'individualPrice' ,
                                      (showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED) ? 'discountPriceWithOriginalPrice' :'discountPrice'
                                  )}
                              >
                                {price > 0 ? formatMoney(price) : t('productPage:requestPrice')}
                              </div>
                                {(showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED) &&
                                <div className={classes.discountValue}>
                                    {t('cart:discount')} {row.discountValue}%
                                </div>
                                }
                            </div>
                          ) : price > 0 ? (
                            formatMoney(price)
                          ) : (
                            t('productPage:requestPrice')
                          )}
                        </TableCell>

                        <TableCell>
                          <NumberInput
                            value={row.quantity}
                            className={classes.quantity}
                            onChange={(val) =>
                              this.props.updateProduct({
                                code: row.code,
                                quantity: val,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {row.price
                            ? formatMoney(price * row.quantity)
                            : t('productPage:requestPrice')}
                        </TableCell>
                        <TableCell>
                          <img
                            alt='cart'
                            className='icon-default clickable  cart-icon deleteIcon '
                            onClick={() =>
                              this.props.deleteProduct({ code: row.code })
                            }
                            title={t('tooltips:delete')}
                            src='/updated_images/svg/bin 15px.svg'
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connectTo(
    (state) => ({
      settings: state.staticContent.settings,
    }),
    { },withNamespaces(['checkoutPage', 'tooltips','productPage'])(
  withStyles(styles)(ShoppingCart))
);
