export default theme => ({
  content: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    fontSize: '1.2em',
    color: 'black'
  },
  sortBar: {
    display: 'flex',
    alignItems: 'center',
  },
  sortLabel: {
    marginRight: '10px',
    fontSize: '0.75em',
    textTransform: 'uppercase'
  },
  selectedPage: {
    color: theme.palette.primary.buttonColor + ' !important'
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
});