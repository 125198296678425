import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { isEmailValid } from '../../../../utils/validation';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';
import { /*registerUserWithCode, registerUserWithVatId*/registerUser } from '../../../../actions/auth';
import { setRegistrationEmail } from '../../../../actions/startpage';
import { getCountries, getTitles } from '../../../../actions/metadata';
import { openErrorSnack } from '../../../../actions/startpage';
import Terms from '../../../../components/terms/Terms/index';

import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

class RegistrationForm extends React.Component {
  componentDidMount() {
    this.props.getCountries();
    this.props.getTitles();
  }

  componentDidUpdate(prevProps, prevState){
    if(!this.props.reCaptchaSiteKey && this.state.canSubmitForm !== prevState.canSubmitForm && this.state.canSubmitForm) {
      this.register();
      this.setState({canSubmitForm: false});
    }

    if( this.props.reCaptchaSiteKey ){
      if(this.state.captchaToken && this.state.captchaToken !== prevState.captchaToken) {
        this.register();
      }
    }
  }

  state = {
    title: 'Mr',
    firstName: '',
    lastName: '',
    address: {
      companyName: '',
      streetName: '',
      streetNumber: '',
      additional: '',
      postalCode: '',
      city: '',
      country: '',
    },
    email: '',
    code: '',
    vatId: '',
    captchaToken: '',
    canSubmitForm: false
  };

  validateForm() {
    const { registrationType } = this.props;

    let message = '';
    if (!this.state.firstName) {
      message = this.props.t('Validation Messages:First Name is Required!');
    } else if (!this.state.lastName) {
      message = this.props.t('Validation Messages:Last Name is Required!');
    } else if (!this.state.address.companyName) {
      message = this.props.t('Validation Messages:Company Name is Required!');
    } else if (!this.state.address.streetName) {
      message = this.props.t('Validation Messages:Street Name is Required!');
    } /*else if (!this.state.address.streetNumber) {
      message = this.props.t('Validation Messages:Street Number is Required!');
    }*/ else if (!this.state.address.postalCode) {
      message = this.props.t('Validation Messages:Postal Code is Required!');
    } else if (!this.state.address.city) {
      message = this.props.t('Validation Messages:City is Required!');
    } else if (this.state.address.country === 'default') {
      message = this.props.t('Validation Messages:Country is Required!');
    } else if (!this.state.email) {
      message = this.props.t('Validation Messages:Email is Required!');
    } else if (!isEmailValid(this.state.email)) {
      message = this.props.t('Validation Messages:Wrong email format!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  }

  handleVerifyRecaptcha = async () => {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    let iterations = 0;
    let waitCaptcha = setInterval(async () => {
      iterations++;
      const token = await executeRecaptcha();
      this.setState({
        captchaToken: token
      });

      if(token){
        clearInterval(waitCaptcha);
      } else {
        if(iterations > 10) {
          clearInterval(waitCaptcha);
          alert('Can not access reCaptcha source site');
        }
      }
    }, 1000);
  };

  register() {
    //const { registrationType } = this.props;

    const data = {
      user: {
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email
      },
      billingAddress: {
        streetName: this.state.address.streetName,
        streetNumber: this.state.address.streetNumber,
        postalCode: this.state.address.postalCode,
        city: this.state.address.city,
        country: this.props.countries[this.state.address.country].code,
      },
      companyName: this.state.address.companyName,
      code: this.state.code,
      vatId: this.state.vatId,
      additional: this.state.additional,
      captchaToken: this.state.captchaToken
    };

    //registrationType && registrationType=='new' && this.props.registerUserWithVatId({ data: data });
    //registrationType && registrationType=='existing' && this.props.registerUserWithCode({ data: data });
    this.props.registerUser({ data: data });

  }

  handleCountryChange = (event) => {
    const value = event.target.value;
    this.setState((state) => ({
      ...state,
      address: {
        ...state.address,
        country: value,
      },
    }));
  };

  handleAddressChange = (name) => (event) => {
    const value = event.target.value;
    this.setState((state) => ({
      ...state,
      address: {
        ...state.address,
        [name]: value,
      },
    }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.validateForm()){
      if(this.props.reCaptchaSiteKey){
        this.handleVerifyRecaptcha();
      } else {
        this.setState({canSubmitForm: true});
      }
    }
  };

  render() {
    const { classes, countries, titles, t, fullScreen, registrationType } = this.props;

    return (
      <div className={classes.content}>
        {!fullScreen ? (
          <div className={classes.logoColumn}>
            <img
              className={classes.img}
              src='/images/glyphs/png/icon-registration.png'
              alt='logo'
            />
          </div>
        ) : null}
        <div
          className={classes.mainColumn}
          style={{ width: !fullScreen ? '75%' : '100%' }}
        >
          <div>
            <div className={classes.afterTitle}>
              {t('registrationBottomTitle')}
            </div>
          </div>
          <form onSubmit={this.handleSubmit} className={classes.container}>
            <div className={classes.contentRow}>
              <FormControl
                component='fieldset'
                className={classNames(classes.formControl, classes.genderRow)}
              >
                <RadioGroup
                  aria-label='Gender'
                  name='gender'
                  className={classes.group}
                  value={this.state.title}
                  onChange={(event) => {
                    this.setState({ title: event.target.value });
                  }}
                >
                  {titles &&
                      titles.map((v, i) => (
                      <FormControlLabel
                        key={v.code}
                        value={v.code}
                        control={<Radio
                            className={classes.radioButton}
                        />}
                        label={v.name}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={t('firstName')}
                isRequire={true}
              >
                <TextField
                  name='firstName'
                  className={classes.textInput}
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={t('lastName')}
                isRequire={true}
              >
                <TextField
                  name='lastName'
                  className={classes.textInput}
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={t('company')}
                isRequire={true}
              >
                <TextField
                  name='companyName'
                  className={classes.textInput}
                  value={this.state.address.companyName}
                  onChange={this.handleAddressChange('companyName')}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={t('additional')}
              >
                <TextField
                  name='additional'
                  className={classes.textInput}
                  value={this.state.address.additional}
                  onChange={this.handleAddressChange('additional')}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classNames(classes.textField, classes.multiField)}
                headerClass={classes.header}
                style={{ flex: 6 }}
                header={t('street')}
                isRequire={true}
              >
                <TextField
                  name='streetName'
                  className={classes.textInput}
                  value={this.state.address.streetName}
                  onChange={this.handleAddressChange('streetName')}
                />
              </InputWrapper>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                style={{ flex: 2 }}
                header={t('no')}
                isRequire={false}
              >
                <TextField
                  name='streetNumber'
                  className={classes.textInput}
                  value={this.state.address.streetNumber}
                  onChange={this.handleAddressChange('streetNumber')}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classNames(classes.textField, classes.multiField)}
                headerClass={classes.header}
                style={{ flex: 2 }}
                header={t('postalCode')}
                isRequire={true}
              >
                <TextField
                  name='postalCode'
                  className={classes.textInput}
                  value={this.state.address.postalCode}
                  onChange={this.handleAddressChange('postalCode')}
                  inputProps={{ maxLength: '16' }}
                />
              </InputWrapper>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                style={{ flex: 4, color: 'white' }}
                header={t('city')}
                isRequire={true}
              >
                <TextField
                  name='city'
                  id='city'
                  className={classes.textField}
                  value={this.state.address.city}
                  onChange={this.handleAddressChange('city')}
                  style={{ flex: 3 }}
                />
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                style={{ flex: 5 }}
                header={t('country')}
                isRequire={true}
              >
                <Select
                  onKeyPress={(e) => e.which === 13 && this.handleSubmit(e)}
                  value={this.state.address.country}
                  onChange={this.handleCountryChange}
                  className={classes.textInput}
                  name='country'
                >
                  {countries &&
                    countries.map((v, i) => (
                      <MenuItem key={i} value={i}>
                        {v.name}
                      </MenuItem>
                    ))}
                </Select>
              </InputWrapper>
            </div>
            <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={'Email'}
                isRequire={true}
              >
                <TextField
                  name='email'
                  className={classes.textInput}
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                />
              </InputWrapper>
            </div>

            {registrationType && registrationType==='new' && <div className={classes.contentRow}>
              <InputWrapper
                className={classes.textField}
                headerClass={classes.header}
                header={t('customerVatId')}
                isRequire={false}
              >
                <TextField
                  name='code'
                  className={classes.textInput}
                  value={this.state.vatId}
                  onChange={(e) =>
                    this.setState({
                      vatId: e.target.value,
                    })
                  }
                />
              </InputWrapper>
            </div>}

            { registrationType && registrationType==='existing' && <div className={classes.contentRow}>
              <InputWrapper
                  className={classes.textField}
                  headerClass={classes.header}
                  header={t('customerNumber')}
                  isRequire={false}
              >
                <TextField
                    name='code'
                    className={classes.textInput}
                    value={this.state.code}
                    onChange={(e) =>
                        this.setState({
                          code: e.target.value,
                        })
                    }
                />
              </InputWrapper>
            </div>}

            <Terms category='registration' />

            <div>
              <Button className={classes.button} classes={{ root: classes.registerBtnRoot }} type='submit'>
                {t('registerButton')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    countries: state.metadata.countries,
    titles: state.metadata.titles,
    reCaptchaSiteKey: state.staticContent.reCaptchaSiteKey,
    registrationType: state.start.registrationType,
  }),
  {
    //registerUserWithVatId,
    //registerUserWithCode,
    registerUser,
    getCountries,
    getTitles,
    setRegistrationEmail,
    openErrorSnack
  },
  withNamespaces(['startPage', 'Validation Messages'])(
    withStyles(styles)(withGoogleReCaptcha(RegistrationForm))
  )
);
