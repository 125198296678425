import { createReducer } from 'redux-act'
import * as a from '../actions/generic'

const DEFAULT_STATE = {
  pageWidth: window.innerWidth,
  pageHeight: window.innerHeight
}

export default createReducer(
  {
    [a.changePageSize]: (state, { width, height }) => ({
      ...state,
      pageWidth: width,
      pageHeight: height
    })
  },
  DEFAULT_STATE
)
