import React from 'react';

import Button from '@material-ui/core/Button';
import CartSummary from './CartSummary';
import { Link } from 'react-router-dom';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import CartTableDataDetailsLarge from './CartTableDataDetails/CartTableDataDetailsLarge';
import CartTableDataDetailsSmall from './CartTableDataDetails/CartTableDataDetailsSmall';

import {openLoginForm} from "../../../actions/auth";
import {PRICE_TYPE_INDIVIDUAL} from "../../../constants/common";

class ShoppingCart extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  };

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render() {
    const shouldShowLarge = this.state.width > 800;
    const { classes, t, cart, token, openLoginForm } = this.props;
    return (
      <div className={classes.shoppingCart}>
        {cart.items && cart.items.length ? (
          <div>
            {shouldShowLarge ? (
              <CartTableDataDetailsLarge cart={cart} />
            ) : (
              <CartTableDataDetailsSmall cart={cart} />
            )}
            {cart.total && <CartSummary cart={cart} />}{' '}
            <div className={classes.buttons}>
              <Link to='/home' style={{ marginRight: '5px' }}>
                <Button
                  className={classNames(classes.button, classes.emptyButton)}
                >
                  {t('back')}
                </Button>
              </Link>

              <Button
                className={classNames(classes.button, classes.filledButton)}
                onClick={() => token ? this.props.next() : openLoginForm({centered:true})}
                style={{ marginLeft: '5px' }}
              >
                {t('confirmation')}
              </Button>
            </div>
          </div>
        ) : (
          <h3> {t('Cart Is Empty')} </h3>
        )}
        {this.props.cart?.items?.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL) &&
        <div className='footNote'>* {t('No Further Discount Applied')}</div>
        }
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    cart: state.cart.cart,
    token: state.auth.token,
  }),
  {
    openLoginForm
  },
  withNamespaces(['checkoutPage', 'tooltips', 'cart'])(
    withStyles(styles)(ShoppingCart)
  )
);
