export default theme => ({
  error: {
    backgroundColor: '#d32f2f',
    marginTop: '10px'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  root: {
    width: 'inherit',
    height: 'inherit',
    margin: '0'
  }
});
