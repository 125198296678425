import React from 'react';
import { connectTo } from '../../utils/generic';

import { unblockAccount } from '../../actions/auth';
import {openErrorSnack} from "../../actions/startpage";
import {withNamespaces} from "react-i18next";

class UnblockUserPage extends React.Component {

  queryToJSON = (query) => {
    if (query === '') return {};
    return query
      .slice(1)
      .split('&')
      .reduce((obj, item, i) => {
        if (item) {
          item = item.split('=');
          obj[item[0]] = item[1];
          return obj;
        }
        return {};
      }, {});
  };

  componentDidMount() {
    try {
      const values = this.queryToJSON(this.props.location.search);
      const info = JSON.parse(atob(decodeURIComponent(decodeURI(values.info))));
      this.props.unblockAccount({
        email: info.email,
        token: values.token,
      });
    } catch (err) {
      this.props.openErrorSnack({message: this.props.t("Invalid URL")})
    } finally {
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <React.Fragment />
    );
  }
}

export default connectTo(
  (state) => ({
  }),
  {
    unblockAccount,
    openErrorSnack
  },
  withNamespaces("unblockAccount")(UnblockUserPage)
);
