export default theme => ({
  textField: {
    width: '100%',
    marginBottom: '9px'
  },
  link: {
    color: theme.palette.primary.buttonColor,
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    cursor: 'pointer'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    fontSize: '0.8125em',
    marginLeft: '3px'
  },
  contentRow: {
    display: 'flex',
    maxWidth: '1100px'
  },
  groupRadio: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0'
  },
  button: {
    background: theme.palette.primary.buttonColor
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})