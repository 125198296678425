import React from 'react';

import TextField from '@material-ui/core/TextField';
//import MenuItem from '@material-ui/core/MenuItem';
//import Input from '@material-ui/core/Input';
//import Select from '@material-ui/core/Select';
import { Button } from '@material-ui/core';

import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';
import { connectTo } from '../../../../utils/generic';

import Autocomplete from '@material-ui/lab/Autocomplete';

import InputWrapper from '../../../common/InputTitleWrapper';

import { openErrorSnack } from '../../../../actions/startpage';

import {
  getBillingAddress,
  setBillingAddressInput,
  setBillingAddressCountry,
  patchBillingAddress,
  successChangeBillingAddress,
  editBillingAddress,
  setBillingCustomerInput,
} from '../../../../actions/address';
import { getCountries } from '../../../../actions/metadata';

class FormBillingAddress extends React.Component {
  state = {
    countries: [],
    country: {},
  };

  componentDidMount() {
    this.props.getCountries();
    this.props.getBillingAddress();
  }

  componentDidUpdate(prevProps) {
    const { countries, address } = this.props;

    if (prevProps.countries !== countries) {
      this.setState((prevState) => {
        return {
          countries,
        };
      });
    }
    if (address !== prevProps.address && address.country) {
      this.setState((prevState) => {
        return {
          country: address.country,
        };
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateForm() &&
      this.props.patchBillingAddress({
        address: this.props.address,
      });
  };

  handleCancelClick = () => {
    this.props.successChangeBillingAddress();
    this.props.getBillingAddress();
  };

  validateForm = () => {
    let message = '';

    if (!this.props.address && !this.props.address.customer) return false;
    if (!this.props.address.customer.companyName) {
      message = this.props.t('Validation Messages:Company Name is Required!');
    } else if (!this.props.address.streetName) {
      message = this.props.t('Validation Messages:Street Name is Required!');
    } /*else if (!this.props.address.streetNumber) {
      message = this.props.t('Validation Messages:Street Number is Required!');
    }*/ else if (!this.props.address.postalCode) {
      message = this.props.t('Validation Messages:Postal Code is Required!');
    } else if (!this.props.address.city) {
      message = this.props.t('Validation Messages:City is Required!');
    } else if (!this.props.address.country) {
      message = this.props.t('Validation Messages:Country is Required!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  };

  setBillingAddressCountry = (country) => {
    this.props.setBillingAddressCountry(country.code);
    this.setState((prevState) => {
      return {
        country,
      };
    });
  };

  render() {
    const { classes, t, address } = this.props;
    const { countries, country } = this.state;
    const isEditable =
      this.props.isEditable && this.props.me && this.props.me.isAdmin;

    return (
      <form onSubmit={this.handleSubmit} className={classes.form}>
        <InputWrapper
          header={t('company')}
          headerClass={classes.smallTitle}
          isRequire={true}
        >
          <TextField
            name='companyName'
            value={(address.customer && address.customer.companyName) || ''}
            className={classes.textField}
            onChange={(e) => this.props.setBillingCustomerInput(e.target)}
            disabled={!isEditable}
          />
        </InputWrapper>
        {isEditable || (address.customer && address.customer.additional) ? (
          <InputWrapper
            header={t('additional')}
            headerClass={classes.smallTitle}
          >
            <TextField
              name='additional'
              value={(address.customer && address.customer.additional) || ''}
              className={classes.textField}
              onChange={(e) => this.props.setBillingCustomerInput(e.target)}
              disabled={!isEditable}
            />
          </InputWrapper>
        ) : null}
        {isEditable ? (
          <div className='multi-field-row'>
            <InputWrapper
              header={t('street')}
              style={{ flex: 5 }}
              headerClass={classes.smallTitle}
              isRequire={true}
            >
              <TextField
                name='streetName'
                value={this.props.address.streetName || ''}
                className={classes.textField}
                onChange={(e) => this.props.setBillingAddressInput(e.target)}
              />
            </InputWrapper>
            <InputWrapper
              header={t('no')}
              style={{ flex: 2 }}
              headerClass={classes.smallTitle}
              isRequire={true}
            >
              <TextField
                name='streetNumber'
                value={this.props.address.streetNumber || ''}
                className={classes.textField}
                onChange={(e) => this.props.setBillingAddressInput(e.target)}
              />
            </InputWrapper>
          </div>
        ) : (
          <InputWrapper
            header={t('street')}
            style={{ flex: 5 }}
            headerClass={classes.smallTitle}
            isRequire={true}
          >
            <TextField
              name='streetName'
              value={
                this.props.address.streetName +
                  ' ' +
                  this.props.address.streetNumber || ''
              }
              className={classes.textField}
              disabled
            />
          </InputWrapper>
        )}

        <div className='multi-field-row'>
          <InputWrapper
            header={t('postalCode')}
            style={{ flex: 2 }}
            headerClass={classes.smallTitle}
            isRequire={true}
          >
            <TextField
              name='postalCode'
              value={this.props.address.postalCode || ''}
              onChange={(e) => this.props.setBillingAddressInput(e.target)}
              className={classNames(classes.textField, 'disable-arrows')}
              disabled={!isEditable}
              inputProps={{ maxLength: '16' }}
            />
          </InputWrapper>
          <InputWrapper
            header={t('city')}
            style={{ flex: 5 }}
            headerClass={classes.smallTitle}
            isRequire={true}
          >
            <TextField
              name='city'
              value={this.props.address.city || ''}
              onChange={(e) => this.props.setBillingAddressInput(e.target)}
              className={classes.textField}
              disabled={!isEditable}
            />
          </InputWrapper>
        </div>

        <InputWrapper isRequire={true} header={t('country')}>
          <Autocomplete
            className={classes.textField}
            disabled={!isEditable}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option._id === value._id}
            id='combo-box-demo'
            onChange={(e, value) =>
              value ? this.setBillingAddressCountry(value) : null
            }
            options={countries}
            placeholder={t('country')}
            renderInput={(params) => (
              <TextField {...params} label='' variant='outlined' />
            )}
            style={{ width: '100%' }}
            value={countries.find((v) => v.code === country) || {}}
          />
        </InputWrapper>

        <div className={classNames(classes.right, classes.contentRow)}>
          {!this.props.isEditable && this.props.me && this.props.me.isAdmin && (
            <div
              onClick={() => this.props.editBillingAddress()}
              className='clickable'
            >
              <img
                className={classNames(classes.editIcon, 'cart-icon')}
                title={t('tooltips:edit')}
                src='/images/icons/icon-edit.svg'
                alt='edit'
              />
            </div>
          )}
        </div>
        {isEditable && (
          <div>
            <Button
              onClick={this.handleCancelClick}
              className={classes.cancelButton}
            >
              {t('cancel')}
            </Button>
            <Button className={classes.saveButton} type='submit'>
              {t('save')}
            </Button>
          </div>
        )}
      </form>
    );
  }
}

export default connectTo(
  (state) => ({
    countries: state.metadata.countries,
    address: state.address.billingAddress,
    isEditable: state.address.isEditableBillingAddress,
    me: state.account.me,
  }),
  {
    patchBillingAddress,
    getBillingAddress,
    setBillingAddressInput,
    setBillingCustomerInput,
    setBillingAddressCountry,
    getCountries,
    successChangeBillingAddress,
    editBillingAddress,
    openErrorSnack,
  },
  withNamespaces(['accountPage', 'Validation Messages', 'tooltips'])(
    withStyles(styles)(FormBillingAddress)
  )
);
