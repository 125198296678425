import React from "react";

//import { Checkbox } from "@material-ui/core";

import styles from "./style";
import { withNamespaces } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import { connectTo } from "../../../../utils/generic";

import {
  getDefaultShippingAddress,
  getAsDefaultBillingAddress
} from "../../../../actions/cart";

import { getTitles } from "../../../../actions/metadata";

import ShippingForm from "./form";
import ShippingModal from "./ShippingModal";
import classNames from "classnames";
//import { Button } from "@material-ui/core";

class ShippingAddress extends React.Component {
  state = {
    isBilling: false,
    isOpenModal: false
  };

  componentDidMount() {
    this.props.getDefaultShippingAddress();
    this.props.getTitles();
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.group}>
        <ShippingModal
          open={this.state.isOpenModal}
          handleClose={() => this.setState({ isOpenModal: false })}
        />

        <div className={classes.checkboxContainer}>
          {/* <Checkbox
            checked={this.state.isBilling}
            onChange={() => {
              this.state.isBilling
                ? this.props.getDefaultShippingAddress()
                : this.props.getAsDefaultBillingAddress();
              this.setState({ isBilling: !this.state.isBilling });
            }}
          /> */}

          <div className={classNames(classes.label, "medium")}>
            {/* {t("useBilling")} */}
            {t("Use")}{" "}
            <span
              className={classes.link}
              onClick={() => {
                this.props.getAsDefaultBillingAddress();
              }}>
              {t("billingAddress")}
            </span>{" "}
            {t("asShippingAddressOr")}{" "}
            <span
              className={classes.link}
              onClick={() => this.setState({ isOpenModal: true })}>
              {t("useShipping")}
            </span>
          </div>
        </div>
        <ShippingForm />
      </div>
    );
  }
}

export default connectTo(
  state => ({}),
  {
    getDefaultShippingAddress,
    getAsDefaultBillingAddress,
    getTitles
  },
  withNamespaces("checkoutPage")(withStyles(styles)(ShippingAddress))
);
