import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../utils/generic';
import PropTypes from 'prop-types';

import SocialIcon from './SocialIcon';

import { getMenu, getSocialLinks } from '../../../actions/static';

class Footer extends React.Component {
  componentDidMount() {
    this.props.getMenu();
    this.props.getSocialLinks();

  }

  render() {
    const { classes, footer, t, social } = this.props;
    return (
      <footer className={classes.footerMain}>
          <div className={classes.sloganArea}>
              <span className={classes.slogan}>{t('slogan')}</span>
          </div>

        <div className={classes.footerContainer}>
            <div className={classNames('copyright-content', classes.copyright)}>
                <p className={classes.companyName}>{t('copyright')}</p>
                <div className={classes.footer}>
                    {footer &&
                    footer.items &&
                    footer.items.length &&
                    footer.items.map((v) => (
                        <a
                            href={v.link}
                            className={classNames('footer-list-item', classes.footerListItem)}
                            key={v.title}
                            target={v.isBlank ? '_blank' : ''}
                        >
                            {v.title}
                        </a>
                    ))}
                </div>
            </div>
            <div className={classes.mediaArea}>
                <div><img alt='logo' src='/images/logo/maulwurf.png' height='32' /></div>
                <div className={classes.socialIcons}>
                    {(social && social.length) ?
                    social.map((element) =>
                        (<SocialIcon
                            key={element.code}
                            imageSource={element.image}
                            link={element.url}
                            isBlank={true}
                            name={element.name}
                        />)
                    ): null
                    }
                </div>
            </div>

          </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  footer: PropTypes.object,
  getMenu: PropTypes.func,
};

export default connectTo(
  (state) => ({
    footer: state.staticContent.footer,
    social: state.staticContent.social,

  }),
  {
    getMenu,
    getSocialLinks
  },
  withNamespaces('footer')(withStyles(styles)(Footer))
);
