
export default theme => ({
  dialog: {
    borderTop: `3px solid ${theme.palette.primary.buttonColor}`
  },
  headerContent: {
    fontSize: '1.4em',
    color: '#000',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px 0 20px',
    fontSize: '14px'
  },
})