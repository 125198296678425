import React from 'react';

import { NavLink } from 'react-router-dom';
import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const MenuItemDrawer = props => {
  const { classes, label, link } = props;

  return (
    <NavLink to={link} exact={true} activeClassName='is-active' activeStyle={{
        color: "black",
        backgroundColor: 'rgb(' + '0, 155, 255' +')'
    }} className={classNames(classes.menuItemDrawer, 'menu-item-row')}
    >
        {label}
    </NavLink>
  );
};

export default withStyles(styles)(MenuItemDrawer);
