export default theme => ({
  overlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    background: 'white',
    zIndex: 2000,
    pointerEvents: 'none' 
  },
  text: {
    position: 'absolute',
    left: '50%',
    right: '50%',
    top: '350px',
    color: 'red',
    [theme.breakpoints.down('sm')]: {
      left: '45%',
    }
  }
});
