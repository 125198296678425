export default theme => ({
  content: {
    justifyContent: 'center',
    flexDirection: 'column'
  },
  header: {
    fontSize: '2em',
    margin: '5px',
    color: '#000'
  },
  progressBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    margin: '1vw',
    position: 'relative'
  },
  tableContainer: {
    width: '100%',
    margin: '20px 0 0 0'
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center'
  },
  line: {
    position: 'absolute',
    width: '67%',
    border: '1.2px solid #d5e7eb',
    top: '40%',
    left: '15%',
    zIndex: 0
  },
  
});
