import React from 'react';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { hideDetails, getImages, setImages } from '../../../actions/products';

import ProductTable from '../ProductTable';
import ProductDetails from '../../common/pdp';
import Schema from './Schema';
import ReactSVG from 'react-svg';

class SpareParts extends React.Component {
  componentDidMount() {
    this.props.hideDetails();
    if (
      this.props.selectedProduct.product &&
      this.props.selectedProduct.product.schema &&
      this.props.selectedProduct.product.schema.length
    )
      this.props.getImages({
        schemas: this.props.selectedProduct.product.schema,
      });
    else
      this.props.setImages({
        images: [],
      });
  }

  constructor(props) {
    super(props);
    this.schemaRef = React.createRef();
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { selectedProduct } = this.props;

    if (
      prevProps.selectedProduct !== selectedProduct &&
      selectedProduct &&
      selectedProduct.product &&
      selectedProduct.product.schema
    )
      if (this.props.selectedProduct.product.schema.length !== 0) {
        this.props.getImages({
          schemas: this.props.selectedProduct.product.schema,
        });
      } else
        this.props.setImages({
          images: [],
        });
  }

  render() {
    const {
      classes,
      t,
      product,
      hideDetails,
      selectedProduct,
      schemas,
    } = this.props;

    return !this.props.isShowingDetails ? (
      <div ref={this.schemaRef}>
        {schemas && schemas.length ? (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={'accordion-summary-typography-responsive'}>
                {t('DRAWING')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Schema images={schemas} product={product} tableRef={this.tableRef}/>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={'accordion-summary-typography-responsive'}>
              {t('PRODUCTS')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.detailsRoot }}>
            <ProductTable
              category={this.props.category}
              rows={
                selectedProduct && selectedProduct.children
                  ? selectedProduct.children
                  : []
              }
              code={product && product.code}
              schemaRef={this.schemaRef}
              tableRef={this.tableRef}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <div>
        <img
            alt={t('productPage:back')}
            onClick={() => hideDetails()}
            className={'back-icon'}
            src={'/images/icons/back-icon-32.svg'}
        />
        <ProductDetails detail={this.props.selectedDetail} />
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    isShowingDetails: state.productHome.isShowingDetails,
    product: state.productHome.product,
    selectedProduct: state.productHome.selectedProduct,
    selectedDetail: state.productHome.selectedDetail,
    schemas: state.productHome.schemas,
  }),
  {
    hideDetails,
    getImages,
    setImages,
  },
  withNamespaces('productPage')(withStyles(styles)(SpareParts))
);
