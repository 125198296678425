export default (theme) => ({
  content: {
    background: /* theme.palette.primary.grey */ 'black',
    fontSize: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    color: 'white',
  },
  breakLabel: {
    fontSize: '16px',
    color: theme.palette.primary.buttonColor,
    cursor: 'pointer',
    display: 'flex',
    paddingBottom: '15px',
    /* justifyContent: 'flex-end', */
  },
  rootCheckBox: {
    padding: 0,
  },
  root: {
    padding: 0,
    background: 'white',
    borderRadius: 0,
    height: '18px',
    width: '18px',
    marginRight: '10px',
  },
  tree: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  filterButton: {
    marginTop: '10px',
    width: '100px',
    backgroundColor: theme.palette.primary.red,
  },
  collapsedFilterGroup: {
    overflowY: 'hidden',
    height: '300px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterGroup: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabelRoot: {
    width: '100%',
  },
  filterSpan: {
    cursor: 'pointer',
    color: '#0070B2',
    padding: '10px 3px 10px',
  },
  number: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75em',
    background: theme.palette.primary.buttonColor,
    color: 'white',
    textAlign: 'center',
    minWidth: '13px',
    borderRadius: '15px',
    padding: '0 7px',
    margin: '0 10px',
    height: '1.5em',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    marginBottom: '5px',
  },
  checkboxRowContainer: {
    width: ' 100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  summaryText: {
    color: 'white',
    marginLeft: '5px',
  },
  panel: {
    background: 'black',
  },
  panelRoot: {
    '&:before': {
      backgroundColor: 'red',
    },
  },
  checkboxRoot: {
    color: 'white',
  },
});
