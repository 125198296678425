export default (theme) => ({
    textCard: {
        margin: '30px',
        textAlign: 'center',
        width: '60%',
    },
    textHeader: {
        marginBottom: '20px',
    },
    error: {
        backgroundColor: '#d32f2f',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    loginCard: {
        display: 'flex',
        margin: '50px 3px',
        minWidth: '280px',
        background: 'black',
        padding: 10,
        marginTop: 0,
        marginBottom: 10,
        paddingBottom: 0,
        paddingTop: 0,
        color: 'white',
        justifyContent: 'center',
        borderRadius: '10px',
        opacity: 0.7,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
            padding: 0,
            margin: 0
        }
    },
    /* logoColumn: {
      width: '26%',
    }, */
    mainColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 220,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 200
        }
    },
    customerHeader: {
        color: 'white',
        margin: '15px 0',
    },
    img: {
        display: 'block',
        width: '55%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    title: {
        '&.MuiDialogTitle-root': {
            paddingBottom: 0
        }
    },
    buttonsCard: {
        marginTop: '40px',
    },
    button: {
        backgroundColor: theme.palette.primary.buttonColor,
        /*margin: '12px 0',*/
    },
    buttonRegister: {
        backgroundColor: theme.palette.primary.linkColor,
        /*margin: '12px 0',*/
    },
    buttonDisabled: {
        backgroundColor: theme.palette.secondary.disabled,
        // margin: '12px 0',
    },
    text: {
        marginBottom: '10px',
    },
    forgotPass: {
        // color: theme.palette.primary.blue,
        color: '#7F868A',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    forgotPassDiv: {
        justifyContent: 'center',
    },
    registerLink: {
        // color: theme.palette.primary.blue,
        color: '#7F868A',

        textDecoration: 'underline',
        cursor: 'pointer',
    },
    aboutSection: {
        width: '100%',
        minHeight: '370px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.slightlyGrey,
    },
    aboutContent: {
        margin: '5px 20px',
        height: 'calc(55% - 10px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    aboutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    aboutCard: {
        width: '300px',
        height: '350px',
        color: theme.palette.primary.black,
        backgroundColor: theme.palette.primary.white,
    },
    aboutCardImg: {
        width: '100%',
        height: '45%',
        display: 'flex',
        justifyContent: 'center',
    },
    aboutImg: {
        width: '100%',
    },
    aboutCardLearnMore: {
        textTransform: 'uppercase',
        fontSize: '12.8px',
        color: theme.palette.primary.blue,
    },
    cardRow: {
        margin: '15px',
    },
    aboutCardText: {
        fontSize: '14.4px',
        fontWeight: '300',
    },
    hidden: {
        // IE 11 best browser!
        width: 0,
        height: 0,
        padding: 0,
        borderWidth: 0,
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
    },
    proceedButton: {
        margin: '15px 0 5px',
    },
    signinBtnRoot: {
        '&:hover': {
            color: 'white',
        },
    },
    autocompleteRoot: {
        color: 'white',
    },
    inputStyles: {

        "& :focus, & :hover": {
            borderBottomColor: '#00BEFF',
        },

        "& input": {
            backgroundColor: "#383E42",
            opacity: 0.8,
            color: 'white',
            borderTopStyle: 'hidden',
            borderLeftStyle: 'hidden',
            borderRightStyle: 'hidden',
            "&:-webkit-autofill": {
                "-webkit-text-fill-color": 'white',
                "-webkit-background-color": '#FF0',
                "-webkit-box-shadow": "0 0 0 50px #383E42 inset",
            },
            "&:-webkit-autofill:focus, &:-webkit-autofill:hover": {
                borderBottomStyle: '#00BEFF !important',
            },
        },
        // "& textarea": {},
    },
    manageAccount: {
        margin: '12px 0 2.5px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconButton: {
        color: 'white'
    },
    dialogPaper: {
        borderTop: `7px ${theme.palette.primary.buttonColor} solid`,
        border: `2px ${theme.palette.primary.slightlyGrey} solid`,
        backgroundColor: 'black',
        [theme.breakpoints.up('sm')]:{
            position: 'absolute',
            right: 60,
            top: 20
        },
        borderRadius: 0,
        [theme.breakpoints.down('sm')]:{
            minWidth: 220
        },
    },
    dialogPaperCentered: {
        borderTop: `7px ${theme.palette.primary.buttonColor} solid`,
        border: `2px ${theme.palette.primary.slightlyGrey} solid`,
        backgroundColor: 'black',
        [theme.breakpoints.up('sm')]:{
            position: 'absolute',
        },
        borderRadius: 0,
        [theme.breakpoints.down('sm')]:{
            minWidth: 220
        },
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,

        }
    }
});
