import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import ModalRegistration from '../registration';
import ModalForgotPassword from '../ForgotPassword';

import {closeLoginForm, login} from '../../../actions/auth';
import {
  closeForgotPass,
  openForgotPass,
  closeRegistration,
  openRegistration,
} from '../../../actions/startpage';

import { isEmailValid } from '../../../utils/validation';
import { openErrorSnack } from '../../../actions/startpage';

class LoginCard extends React.Component {
  state = {
    email: '',
    password: '',
    remember: true,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validate() {
    let message = '';
    if (!this.state.email) {
      message = this.props.t('Validation Messages:Email is Required!');
    } else if (!isEmailValid(this.state.email)) {
      message = this.props.t('Validation Messages:Wrong email format!');
    } else if (!this.state.password) {
      message = this.props.t('Validation Messages:Password is Required!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.validate()) {
      this.props.login({
        email: this.state.email,
        password: this.state.password,
        remember: this.state.remember,
      })
      this.props.closeLoginForm()
    }

  };

  render() {
    const {
      t,
      classes,
      IsForgotPassOpen,
      openForgotPass,
      closeForgotPass,
      IsRegistrationOpen,
      closeRegistration,
      openRegistration,
    } = this.props;

    return (
      <>
        <ModalForgotPassword
          open={IsForgotPassOpen}
          handleClose={() => closeForgotPass()}
        />
        <ModalRegistration
          open={IsRegistrationOpen}
          handleClose={() => closeRegistration()}
        />

        <div className={classes.loginCard}>
          <div className={classes.logoColumn}>
            <img
              className={classes.img}
              src="/images/glyphs/svg/glyph-registration-green.svg#reg"
              alt='logo'
            />
          </div>
          <div className={classes.mainColumn}>
            {/*<div className={classNames(classes.title, 'blue-header', 'medium')}>*/}
            {/*  {t('loginTitle')}*/}
            {/*</div>*/}
            {/* <div className='block-with-text'>{t('loginText')}</div> */}

            <form onSubmit={this.handleSubmit}>
              <input
                type='submit'
                id='ie11compability'
                className={classes.hidden}
              />
              <div>
                <div
                  className={classNames('input-header', 'input-header-login')}
                >
                  {t('username')}
                </div>
                <div className='card-column'>
                  <TextField
                    name='email'
                    onChange={this.handleChange}
                    className={classNames('panel-element', classes.inputStyles)}

                  />
                </div>
              </div>
              <div>
                <div
                  className={classNames('input-header', 'input-header-login')}
                >
                  {t('password')}
                </div>
                <div className='card-column'>
                  <TextField
                    name='password'
                    // className='panel-element'
                    className={classNames('panel-element', classes.inputStyles)}
                    type='password'
                    autoComplete='current-password'
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className={classes.buttonsCard}>
 {/*               <div className='checkbox'>
                  <Checkbox
                    checked={this.state.remember}
                    onChange={() =>
                      this.setState({ remember: !this.state.remember })
                    }
                  />
                  <div className='label'>{t('staySigned')}</div>
                </div>*/}

                <div className='card-column'>
                  <Button
                    type='sumbit'
                    onClick={this.handleSubmit}
                    className={classNames(classes.button, 'panel-element')}
                    classes={{ root: classes.signinBtnRoot }}
                  >
                    {t('loginButton')}
                  </Button>
                </div>
                <div className='card-column'>
                  <Button
                      onClick={() => openRegistration()}
                      className={classNames(classes.buttonRegister, "panel-element")}
                      classes={{ root: classes.signinBtnRoot }}
                  >
                    {t("registerButton")}
                  </Button>
                </div>

              </div>
            <div className={classNames(classes.manageAccount)} >
              <div className={classNames(classes.forgotPassDiv, 'card-column')}>
                <div
                  onClick={() => openForgotPass()}
                  className={classes.forgotPass}
                >
                  {t('forgotPassword')}
                </div>
              </div>


              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default connectTo(
  (state) => ({
    IsRegistrationOpen: state.start.IsRegistrationOpen,
    IsForgotPassOpen: state.start.IsForgotPassOpen,
    error: state.start.error,
  }),
  {
    login,
    closeForgotPass,
    openForgotPass,
    closeRegistration,
    openRegistration,
    openErrorSnack,
    closeLoginForm
  },
  withNamespaces(['startPage', 'Validation Messages'])(
    withStyles(styles)(LoginCard)
  )
);
