import { call, put } from 'redux-saga/effects';
import { logout } from '../actions/auth';
import { refreshToken } from '../sagas/auth';
import { addTask, deleteTask } from '../actions/task';
import { toastr } from 'react-redux-toastr';
import { openErrorSnack } from '../actions/startpage';
import { RequestError } from './exceptions';
import i18next from '../config/i18nconfig';

i18next.loadNamespaces('toastMessage');

export function* callHttp(...args) {
  const tokenExpired = localStorage.getItem('sessionExpired');

  if (tokenExpired && tokenExpired < Date.now()) {
    yield put(logout());
    return;
  }
  let checkSpinner = false;

  if (
    args[args.length - 1] !== undefined &&
    args[args.length - 1] !== null &&
    args[args.length - 1].constructor === Object &&
    args[args.length - 1].useWithoutSpinner
  )
    checkSpinner = true;
  /* if (process.env.REACT_APP_MOCK) return; */

  try {
    if (!checkSpinner) yield put(addTask());
    const data = yield call(...args);
    return data;
  } catch (err) {
    if (err.status === 401) {
      // For ExpiredTokenException case
      if (err.message.type === 'ApiException' || err.message.type === 'UsernameNotFoundException' || err.message.type === 'BadCredentialsException') {
        yield put(openErrorSnack(err.message));
      } else if (err.message.type === 'ExpiredTokenException') {
        yield call(refreshToken);
        try {
          if (!checkSpinner) yield put(addTask());
          const data = yield call(...args);
          return data;
        } catch (err2) {
          // probably err2.status should stay here
          if (err2.status === 502)
            toastr.info( i18next.t('toastMessage:Server offline'),  i18next.t('toastMessage:Please wait for api to be up.'));
        } finally {
          if (!checkSpinner) yield put(deleteTask());
        }
      } else {
        // For AuthenticationException, InvalidTokenException, AccessDeniedException
        yield put(logout());
        return;
      }
    } else if (err.status === 303) {
      throw new RequestError(err.status, err.message);
    } else {
      yield put(openErrorSnack(err.message));
      throw new RequestError(err.message.code, err.message.message);
    }
  } finally {
    if (!checkSpinner) yield put(deleteTask());
  }
}

export function* callHttpWithoutSpinner(...args) {
  return yield callHttp(...args, { useWithoutSpinner: true });

}
