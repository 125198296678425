export default theme => ({
  image: {
    width: '300px',
    height: 'auto'
  },
  imageBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  carousel: {
    width: '750px',
    margin: '0 10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  delimiter: {
    width: '90%'
  },
  arrow: {
    width: '35px'
  }
});
