import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { userTypes } from '../../../../constants/mock';

const LastNameField = (props) => {
  const { classes, role, handleSubmit, handleRoleChange, t } = props;

  return (
    <div className={classNames(classes.content_row, classes.controlMd)}>
      <div className={classes.textField}>
        <div className={classNames(classes.label, 'required-field')}>
          {t('userRole')}
        </div>
        <Select
          onKeyPress={(e) => e.which === 13 && handleSubmit(e)}
          value={role}
          onChange={handleRoleChange}
          className={classes.textField}
          name='role'
        >
          <MenuItem value='default' disabled>
            <div style={{ opacity: '0.5' }}>{t('userRole')}</div>
          </MenuItem>
          {userTypes &&
            userTypes.map((v, i) => (
              <MenuItem key={i} value={v}>
                {v}
              </MenuItem>
            ))}
        </Select>
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.role === nextProps.role;
}

export default withStyles(styles)(React.memo(LastNameField, areEqual));
