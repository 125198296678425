import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

const CommonTooltip = (props) => {
  const {
    title,
    placement,
    enterDelay,
    leaveDelay,
    element,
    interactive,
    TransitionComponent,
    enterTouchDelay,
    leaveTouchDelay,
    disableTouchListener,
  } = props;

  return (
    <Tooltip
      title={title}
      placement={placement ? placement : 'top'}
      enterDelay={enterDelay ? enterDelay : 300}
      leaveDelay={leaveDelay ? leaveDelay : 200}
      interactive={interactive ? interactive : false}
      enterTouchDelay={enterTouchDelay ? enterTouchDelay : 100}
      leaveTouchDelay={leaveTouchDelay ? leaveTouchDelay : 3000}
      disableTouchListener={disableTouchListener ? disableTouchListener : false}
      TransitionComponent={TransitionComponent ? TransitionComponent : Fade}
    >
      {element}
    </Tooltip>
  );
};

export default connectTo(
  (state) => ({}),
  {},
  withNamespaces('homePage')(withStyles(styles)(CommonTooltip))
);
