import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const CarouselPagination = props => {
  const {
    classes,
    curPage,
    totalPages,
    style,
    wrapperStyle,
    handleClick
  } = props;

  const pages = [];
  for (let i = 0; i < totalPages; i++)
    pages.push(
      <div
        className={classNames('clickable', classes.page, i === curPage && classes.selected)}
        key={i}
        style={style}
        onClick={() => handleClick(i)}
      />
    );

  return (
    <div className={classes.pages} style={wrapperStyle}>
      {pages}
    </div>
  );
};

export default withStyles(styles)(CarouselPagination);
