import React from 'react';

import SmallTableRow from '../../../../common/SmallTableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import IOSSwitch from '../../../../common/IOSSwitch';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import styles from '../style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../../utils/localization';
import { primaryColor } from '../../../../../theme';

const SmallTable = ({
  classes,
  t,
  users,
  handleSelect,
  colors,
  handleActivate,
  openEditUserForm,
  handleDelete,
  isSelected,
}) => (
  <div className={classes.table}>
    {users.map((user, i) => (
      <Accordion
        key={i}
        style={{
          backgroundColor: isSelected(user.id) ? primaryColor.selected : '#fff',
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Checkbox
            checked={isSelected(user.id)}
            onClick={(e) => {
              e.preventDefault();
              handleSelect(e, user.id);
            }}
          />
          <Avatar
            style={{ backgroundColor: colors[user.id] }}
            className={classes.smallAvatar}
          >
            {user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}
          </Avatar>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {user.userName}
          </span>
        </AccordionSummary>
        <AccordionDetails
          className={classes.rows}
          classes={{ root: classes.tableRoot }}
        >
          <Table className={classes.Table}>
            <TableBody>
              <SmallTableRow
                title={t('User')}
                item={
                  <div className={classes.smallUserName}>
                    <div className={classes.smallUserContact}>
                      <div className={classes.userName}>{user.userName}</div>
                      <div className={classes.userEmail}>{user.email}</div>
                    </div>
                  </div>
                }
              />
              <SmallTableRow
                title={t('Status')}
                item={
                  <div className={classes.productName}>
                    <IOSSwitch
                      checked={user.isEnabled}
                      handleActivate={(e) => handleActivate(e, user)}
                      label=''
                      value={String(user.id)}
                      title={
                        user.isEnabled
                          ? t('tooltips:deactivate')
                          : t('tooltips:activate')
                      }
                    />
                  </div>
                }
              />
              <SmallTableRow
                title={t('Type of User')}
                item={user.isAdmin ? 'Key User' : 'User'}
              />

              <SmallTableRow
                title={t('Cart Limit')}
                item={
                  user.cartLimit === null
                    ? t('accountPage:unlimited')
                    : formatMoney(user.cartLimit)
                }
              />
              <SmallTableRow
                title={t('Options')}
                item={
                  <div className={classes.actionRow}>
                    <Avatar
                      className={classes.iconAvatar}
                      classes={{ root: classes.avatarRoot }}
                    >
                      <img
                        alt='edit'
                        className={classes.actionIcon}
                        onClick={() => openEditUserForm(user)}
                        title={t('tooltips:edit')}
                        src='/images/icons/MyAccount/icon-edit.png'
                      />
                    </Avatar>
                    <Avatar
                      className={classes.iconAvatar}
                      classes={{ root: classes.avatarRoot }}
                    >
                      <img
                        alt={t('tooltips:delete')}
                        onClick={() => handleDelete(user.id)}
                        title={t('tooltips:delete')}
                        className={classes.actionIcon}
                        src='/updated_images/svg/bin 15px.svg'
                      />
                    </Avatar>
                  </div>
                }
              />
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
);

export default withNamespaces(['userManagement', 'accountPage', 'tooltips'])(
  withStyles(styles)(SmallTable)
);
