import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class TermDialog extends React.Component {
  render() {
    const { term, open, handleClose } = this.props;
    return (

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{term.labelLink}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={{__html: term.description}} />;
          </DialogContentText>
        </DialogContent>
      </Dialog>

    );
  }
}

export default TermDialog;