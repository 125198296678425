import React from 'react';

import styles from './style';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { getBatchProducts } from '../../../actions/quickorder';
import Button from '@material-ui/core/Button';

import Dropzone from 'react-dropzone';
import { csvReader } from '../../../utils/fileReader';

class SearchBar extends React.Component {
  state = {
    files: [],
    dropzoneActive: false
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  };

  onDrop = files => {
    csvReader(files[0], this.props.getBatchProducts);
    this.setState({
      dropzoneActive: false
    });
  };

  render() {
    const { t, classes, buttonClassName } = this.props;
    const { dropzoneActive } = this.state;

    return (
      <Dropzone
        disableClick
        style={{ position: 'relative' }}
        accept={[
          '.csv'
        ]}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
      >
        {({ open }) => (
          <div>
            {dropzoneActive && (
              <div className={classes.overlay}>Drop files...</div>
            )}
            <div className={classes.button}>
              <Button className={buttonClassName} onClick={() => open()}>
                {t('Upload CSV')}
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default connectTo(
  state => ({}),
  {
    getBatchProducts
  },
  withNamespaces('orderPage')(withRouter(withStyles(styles)(SearchBar)))
);
