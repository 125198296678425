import React from 'react';

import {Dialog} from '@material-ui/core';

import styles from './style';
import {withNamespaces} from 'react-i18next';
import {connectTo} from '../../../utils/generic';
import {withStyles} from '@material-ui/core/styles';

import RegistrationForm from './Form';
import RegistrationChoose from './Choose';
import RegistrationOverlay from './Overlay';

import {setRegistrationEmail} from '../../../actions/startpage';
import {getCountries, getTitles} from '../../../actions/metadata';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {selectLng} from "../../../utils/localization";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";

class ModalRegistration extends React.Component {
    componentDidMount() {
        this.props.getCountries();
        this.props.getTitles();
    }

    render() {
        const {classes, open, handleClose, t, fullScreen, isSending, registrationType, reCaptchaSiteKey} = this.props;

        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaSiteKey}
                language={selectLng()}
            >
                <Dialog
                    fullScreen={fullScreen}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                    open={open}
                    onClose={() => handleClose()}
                    classes={{
                        paperFullScreen: classes.paperFullScreen,
                    }}
                >
                    <div
                        className={
                            this.props.isSending ? classes.overlayContent : classes.formContent
                        }
                    >
                        <div className={classes.modalHeader}>
                          <div className='title-header'>{t('register')}</div>
                            <IconButton
                                className={classes.iconButton}
                                aria-label="close"
                                onClick={handleClose}
                            >
                              <CloseIcon/>
                            </IconButton>
                        </div>
                        {isSending ? (
                            <RegistrationOverlay
                                handleClose={handleClose}
                                fullScreen={fullScreen}
                            />
                        ) : (registrationType ?
                                <RegistrationForm fullScreen={fullScreen}/> :
                                <RegistrationChoose handleClose={handleClose}
                                                    fullScreen={fullScreen}/>

                        )}
                    </div>
                </Dialog>
            </GoogleReCaptchaProvider>
        );
    }
}

export default connectTo(
    (state) => ({
        isSending: state.start.IsRegistrationSend,
        registrationType: state.start.registrationType,
        reCaptchaSiteKey: state.staticContent.reCaptchaSiteKey,
    }),
    {
        getCountries,
        getTitles,
        setRegistrationEmail,
    },
    withNamespaces('startPage')(
        withStyles(styles)(withMobileDialog()(ModalRegistration))
    )
);
