import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { getTerms } from '../../../actions/cart';

import { connectTo } from '../../../utils/generic';
import Term from './Term/index';

class Terms extends React.Component {
  componentDidMount() {
    this.props.getTerms({category: this.props.category});
  }

  render() {
    const { classes, type, terms } = this.props;

    return (
      <div className={classes.checkboxlist}>
        {terms.map((term, i) => (
          <Term key={i} index={i} term={term} type={type} />
        ))}
      </div>
    );
  };
}

export default connectTo(
  state => ({
    terms: state.cart.terms
  }),
  {
    getTerms
  },
  withNamespaces('startPage')(withStyles(styles)(Terms))
);
