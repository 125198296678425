import React from 'react';
import {connectTo} from "../../../utils/generic";

/* import ReactDOM from 'react-dom'; */

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ExpansionPanel from './ExpansionPanel';
import Treeview from './Treeview';

import PriceInput from '../../common/ChangeableInput';
//import { Button } from '@material-ui/core';
import classNames from 'classnames';


class FilterSearchPage extends React.Component {
  state = {
    minPrice: this.props.params.minPrice || '',
    maxPrice: this.props.params.maxPrice || '',
    clear: false,
    // smallMode: window.innerWidth <= SMALL_SCREEN,
    toggleFilterGroup: null,
    checked: {},
    filterCodes: [],
    //availabilityChecked: ['available'],
  };

  /* constructor(props) {
    super(props);

    this.filterRefs = {};
  } */

  // updateDimensions = () => {
  //   if (
  //     (!this.state.smallMode && window.innerWidth <= SMALL_SCREEN) ||
  //     (this.state.smallMode && window.innerWidth > SMALL_SCREEN)
  //   )
  //     this.setState({ smallMode: !this.state.smallMode });
  // };

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateDimensions);
  // }

  componentDidMount() {
    // window.addEventListener('resize', this.updateDimensions);
    //this.props.handleInStockFilterChange(this.state.availabilityChecked);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.category !== this.props.params.category) {
      this.setState({
        minPrice: '',
        maxPrice: '',
        clear: true,
        checked: {},
        filterCodes: [],
        //availabilityChecked: ['available'],
      });
    }

    if (prevState.checked !== this.state.checked) {
      this.props.handleFilterChange(this.state.checked);
    }
  }

  clear = () => {
    this.setState({
      minPrice: '',
      maxPrice: '',
      clear: true,
      checked: {},
      filterCodes: [],
      //availabilityChecked: [],
    });
    this.props.clear();
  };

  handlePriceChange = (minPrice, maxPrice) => {
    this.setState({ minPrice, maxPrice });
    this.props.handlePriceSelect({
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
  };

  toggleFilterGroup = (code) => {
    if (code === this.state.toggleFilterGroup)
      this.setState((prevState) => {
        return {
          toggleFilterGroup: null,
        };
      });
    else
      this.setState((prevState) => {
        return {
          toggleFilterGroup: code,
        };
      });
  };

  renderFilter = (values, code) => {
    const { classes } = this.props;

    return (
      <div className={classes.checkboxRowContainer}>
        {values.map((value, i) => {
          return (
            <div className={classes.row} key={i}>
              <FormControlLabel
                key={`${[code] + '_' + i}`}
                classes={{
                  root: classes.formControlLabelRoot,
                  label: classes.summaryText,
                }}
                control={
                  <Checkbox
                    classes={{ root: classes.checkboxRoot }}
                    checked={
                      this.state.filterCodes &&
                        this.state.filterCodes.includes(`${[code] + '_' + i}`)
                    }
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        code,
                        value.content,
                        `${[code] + '_' + i}`
                      )
                    }
                    name={`${i}`}
                  />
                }
                label={value && value.content ? value.content : ''}
              />
              <div className={classes.number}>{value.count}</div>
            </div>
          );
        })}
      </div>
    );
  };

  handleCheckboxChange = (code, value, generatedCode) => {
    let checkedBoxes = this.state.checked;

    if (checkedBoxes) {
      if (!checkedBoxes.hasOwnProperty(code)) {
        checkedBoxes[code] = [value];
      } else {
        if (checkedBoxes[code] && checkedBoxes[code].includes(value)) {
          let index = checkedBoxes[code].indexOf(value);
          if (index > -1) {
            checkedBoxes[code].splice(index, 1);
          }
        } else {
          checkedBoxes[code] = [...checkedBoxes[code], value];
        }
      }

      this.props.handleFilterChange(checkedBoxes);

      if (this.state.filterCodes) {
        if (!this.state.filterCodes.includes(generatedCode)) {
          this.setState((prevState) => {
            return { filterCodes: [...this.state.filterCodes, generatedCode] };
          });
        } else {
          let a = [...this.state.filterCodes];
          let index = a.indexOf(generatedCode);
          a.splice(index, 1);
          this.setState((prevState) => {
            return { filterCodes: a };
          });
        }
      }

      this.setState((prevState) => {
        return {
          checked: checkedBoxes,
        };
      });
    }
  };

  clearSingleFilter = (code) => {
    let filterCodes = this.state.filterCodes.filter(
      (filterCode) => !filterCode.includes(code)
    );

    let copiedChecked = { ...this.state.checked };
    delete copiedChecked[code];

    this.setState((prevState) => {
      return { filterCodes, checked: copiedChecked };
    });
  };

/*  handleAvailabilityCheckboxChange = (name) => {
    let availabilityChecked = this.state.availabilityChecked;

    if (availabilityChecked) {
      if (availabilityChecked.includes(name)) {
        let index = availabilityChecked.indexOf(name);
        if (index > -1) {
          availabilityChecked.splice(index, 1);
        }
      } else {
        availabilityChecked = [...availabilityChecked, name];
      }
    }
    this.props.handleInStockFilterChange(availabilityChecked);

    this.setState((prevState) => {
      return {
        availabilityChecked,
      };
    });
  };*/

/*  clearAvailabilityFilter = () => {
    this.props.handleInStockFilterChange([]);
    this.setState((prevState) => {
      return { availabilityChecked: [] };
    });
  };*/

  render() {
    const { t, classes, params, filterAttributes } = this.props;
    // const { smallMode, availabilityChecked } = this.state;
    //const { availabilityChecked } = this.state;
    const currency = sessionStorage.getItem('currency');

    return (
      <div className={classNames(classes.content, 'search-filter')}
      style={{height: '100%'}}>
        <div className={classes.header}>
          <div className={classNames(classes.title, 'bold')}>{t('filter')}</div>
          <div className={classes.breakLabel} onClick={this.clear}>
            {t('clear')}
          </div>
        </div>

        <ExpansionPanel
          // defaultExpanded={!smallMode}
          summary={t('category')}
          classes={{
            summaryText: classes.summaryText,
            panel: classes.panel,
            root: classes.panelRoot,
          }}
        >
          <Treeview
            clear={this.state.clear}
            className={classes.tree}
            selectedAlias={params.category}
            handleCategoryChange={(alias) => {
              this.setState({ clear: false });
              this.props.handleCategoryChange(alias);
            }}
          />
        </ExpansionPanel>

        { (this.props.token || this.props.showPricesForGuest) && <ExpansionPanel
          // defaultExpanded={!smallMode}
          summary={`${t('price')}(${currency})`}
          classes={{ summaryText: classes.summaryText, panel: classes.panel }}
        >
          <div className={classes.inputContainer}>
            <PriceInput
              value={this.state.minPrice}
              handleChange={(minPrice) =>
                this.handlePriceChange(minPrice, this.state.maxPrice)
              }
              placeholder={t('from')}
            />
            <PriceInput
              value={this.state.maxPrice}
              handleChange={(maxPrice) =>
                this.handlePriceChange(this.state.minPrice, maxPrice)
              }
              placeholder={t('to')}
            />
          </div>
        </ExpansionPanel>}
 {/*       <ExpansionPanel
          // defaultExpanded={!smallMode}
          summary={t('productPage:availibility')}
          classes={{ summaryText: classes.summaryText, panel: classes.panel }}
        >
          <div className={classes.filterContainer}>
            <div
              className={classes.breakLabel}
              onClick={() => this.clearAvailabilityFilter()}
            >
              {t('clear')}
            </div>

          </div>
        </ExpansionPanel>*/}
        {filterAttributes && filterAttributes.length > 0
          ? filterAttributes.map((attribute, j) => {
              return attribute &&
                attribute.values &&
                attribute.values.length > 0 ? (
                <ExpansionPanel
                  key={j}
                  // defaultExpanded={!smallMode}
                  summary={attribute.name}
                  classes={{
                    summaryText: classes.summaryText,
                    panel: classes.panel,
                  }}
                >
                  <div className={classes.filterContainer}>
                    <div
                      className={classes.breakLabel}
                      onClick={() => this.clearSingleFilter(attribute.code)}
                    >
                      {t('clear')}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormGroup
                        row
                        /* ref={(ref) => {
                        this.filterRefs[ref] = j;
                      }} */
                        classes={{
                          row:
                            attribute.values &&
                            attribute.values.length > 20 &&
                            this.state.toggleFilterGroup !== attribute.code
                              ? classes.collapsedFilterGroup
                              : classes.filterGroup,
                        }}
                      >
                        {attribute &&
                        attribute.values &&
                        attribute.values.length > 0
                          ? this.state.toggleFilterGroup !== attribute.code
                            ? this.renderFilter(
                                attribute.values.slice(0, 20),
                                attribute.code
                              )
                            : this.renderFilter(
                                attribute.values,
                                attribute.code
                              )
                          : null}
                        {/* {attribute &&
                      attribute.values &&
                      attribute.values.length > 0
                        ? attribute.values.map((value, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                classes={{ root: classes.formControlLabelRoot }}
                                control={
                                  <Checkbox
                                    name='checkedB'
                                    color='primary'
                                  />
                                }
                                label={
                                  value && value.content ? value.content : ''
                                }
                              />
                            );
                          })
                        : null} */}
                      </FormGroup>
                      <span
                        onClick={() => {
                          this.toggleFilterGroup(attribute.code);
                        }}
                        className={classes.breakLabel}
                      >
                        {attribute.values && attribute.values.length > 20
                          ? this.state.toggleFilterGroup !== attribute.code
                            ? t('showMore')
                            : t('showLess')
                          : null}
                      </span>
                    </div>
                  </div>
                </ExpansionPanel>
              ) : null;
            })
          : null}
      </div>
    );
  }
}

export default connectTo(
    (state) => ({
      token: state.auth.token,
      showPricesForGuest: state.staticContent.showPricesForGuest,
    }),
    {},
    withNamespaces(['searchPage', 'productPage'])(
        withStyles(styles)(FilterSearchPage)
    )
);
