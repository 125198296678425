import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import FormBillingAddress from '../../../checkout/Checkout/BillingAddress';
import ShippingInfo from '../ShippingInfo';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ProgressBar from '../../../checkout/ProgressBar';

import OrderDetailsTable from '../OrderDetailsTable';
import OrderSummary from '../OrderSummary';
import Line from '../Line';

import {
  getOrdersIcons,
  statusValues,
} from '../../../../constants/progressBar';
import BillingDetails from "../BillingDetails";

const OrderDetails = (props) => {
  const { classes, t, selectedOrder } = props;
  const hasShipping =
    selectedOrder &&
    selectedOrder.shippingAddress &&
    selectedOrder.shippingAddress.lastName;

  if (selectedOrder && selectedOrder.items && selectedOrder.items.length !== 0)
    return (
      <div>
        <Line margin='10%' />
        <OrderDetailsTable order={selectedOrder} />
        <Line margin='90%' hasPointBottom={true} />
        <OrderSummary order={selectedOrder} />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={classNames(
                classes.title,
                'accordion-summary-typography-responsive'
              )}
            >
              {t('billingAddress')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormBillingAddress
              address={{
                ...selectedOrder.billingAddress,
                customer: {
                  companyName: selectedOrder.billingAddress.companyName,
                  additional: selectedOrder.billingAddress.additional,
                },
                country: {
                  name: selectedOrder.billingAddress.country,
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
        {selectedOrder &&
        selectedOrder.shippingMethod &&
        selectedOrder.shippingMethod.name ? (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={classNames(
                  classes.title,
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('checkoutPage:shippingOptions')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <span className={classes.paymentMethod}>
                {selectedOrder.shippingMethod.name}
              </span>
            </AccordionDetails>
          </Accordion>
        ) : null}

        {hasShipping && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={classNames(
                  classes.title,
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('shippingAddress')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShippingInfo
                address={selectedOrder.shippingAddress}
                comment={selectedOrder.comment}
              />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={classNames(
                classes.title,
                'accordion-summary-typography-responsive'
              )}
            >
              {t('Payment Method')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <span className={classes.paymentMethod}>
              {selectedOrder.paymentMethod.name}
            </span>
          </AccordionDetails>
        </Accordion>

        {selectedOrder && selectedOrder.billings && selectedOrder.billings.length >0 &&<Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
                className={classNames(
                    classes.title,
                    'accordion-summary-typography-responsive'
                )}
            >
              {t('Bill')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BillingDetails order={selectedOrder}/>
          </AccordionDetails>
        </Accordion>}

        <div className={classNames(classes.header, 'medium')}>
          {t('Job Status')}
        </div>
        <ProgressBar
          stage={statusValues[selectedOrder.status.code]}
          icons={getOrdersIcons(selectedOrder.shippingAddress)}
        />
      </div>
    );
  else return '';
};

export default withNamespaces(['accountPage', 'checkoutPage'])(
  withStyles(styles)(OrderDetails)
);
