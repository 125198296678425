import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../../utils/generic';

import Flags from '../Flags';

const LanguageHeader = (props) => {
  const { t, classes } = props;

  return (
    <div className={classes.topHeader}>
      <div className='main-content main-content-header'>
        <div className={classes.topContent}>
          <div className='row top-menu-row'>
            <Flags t={t} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connectTo(
  (state) => ({}),
  {},
  withNamespaces('startPage')(withRouter(withStyles(styles)(LanguageHeader)))
);
