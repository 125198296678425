import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

const Header = (props) => {
  const { classes, product, t, category } = props;

  return (
    <div className={classes.names}>
      <div className={classes.smallHeader}>
        {category !== 'my' ? t('standardMachines') : t('yourMachines')}
      </div>

      <div className={classNames(classes.bigHeader, 'price-medium')}>
        {product.name}
      </div>

      <div className={classes.underHeader}>{product.code}</div>
    </div>
  );
};

export default withNamespaces('productPage')(withStyles(styles)(Header));
