import { createAction } from 'redux-act';

export const login = createAction('login');
export const receiveAuthData = createAction('receive auth data');
export const receiveRefreshToken = createAction('receive refresh token');
export const refreshToken = createAction('refresh token');
export const logout = createAction('logout');
export const notLogout = createAction('notLogout')
export const wrongCredentials = createAction('wrong credentials');
export const networkError = createAction('network error');

// export const registerUserWithCode = createAction('registerUserWithCode');
// export const registerUserWithVatId = createAction('registerUserWithVatId');
export const registerUser = createAction('registerUser');

export const resetPassword = createAction('resetPassword');
export const unblockAccount = createAction('unblockAccount');
export const forgotPassword = createAction('forgotPassword');
export const successResetPassword = createAction('successResetPassword');
export const disableResetPassword = createAction('disableResetPassword');

export const getMyRole = createAction('getMyRole');
export const setMyRole = createAction('setMyRole');
export const openLoginForm = createAction('openLoginForm');
export const closeLoginForm = createAction('closeLoginForm');

