import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../utils/localization';
import PriceRow from './PriceRow';

const ShoppingCartSummary = (props) => {
  const { classes, t, order } = props;
  const hasShipping =
    order && order.shippingMethod && order.shippingMethod.usePrices;

  return (
    <div className={classes.priceContainer}>
      <div className={classes.price}>
        <PriceRow
          title={t('productCost')}
          price={formatMoney(order.total, order.currency)}
        />
        {hasShipping && (
          <PriceRow
            title={t('Shipping Cost')}
            price={formatMoney(order.shippingMethod.price, order.currency)}
          />
        )}
        <PriceRow
          title={`${t('taxValue')} (${order.tax}%)`}
          price={formatMoney(order.totalTax, order.currency)}
        />
        <hr />
        <PriceRow
          title={t('totalCost')}
          price={formatMoney(order.totalWithTaxAndShipping, order.currency)}
          showAsterisk={
            !hasShipping &&
            order &&
            order.shippingMethod &&
            order.shippingMethod.description &&
            order.shippingMethod.description.length > 0
              ? true
              : false
          }
        />
      </div>
      {!hasShipping &&
      order &&
      order.shippingMethod &&
      order.shippingMethod.description &&
      order.shippingMethod.description.length > 0 ? (
        <div className={classes.descriptionField}>
          <span style={{ color: '#c03d13' }}>*&nbsp;</span>
          <div
            dangerouslySetInnerHTML={{
              __html: order.shippingMethod.description,
            }}
            className='shipping-description'
          />
        </div>
      ) : null}
    </div>
  );
};

export default withNamespaces('checkoutPage')(
  withStyles(styles)(ShoppingCartSummary)
);
