import React from "react";
import {connectTo} from "../../../utils/generic";
import {getSettings} from "../../../actions/static";
import {Route} from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";

class Processor extends React.Component {

    componentDidMount() {
        this.props.getSettings()
    }

    render() {
        const {settings} = this.props;
        const allowGuestAccess = settings.find(x => x.name === 'allow_guest_access')?.value;

        return(
             <ProtectedRoute allowGuestAccess={allowGuestAccess} {... this.props}/>
        )
    }
}
export default connectTo(
    state => ({
        settings: state.staticContent.settings
    }),
    { getSettings },
    Processor
);