import React from 'react';

import styles from './style';
import {withStyles} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import {withNamespaces} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import {connectTo} from "../../../../../utils/generic";
import {addCartDiscount, removeCartDiscount} from "../../../../../actions/cart";
import classNames from "classnames";

class ShoppingCartSummary extends React.Component {

    state = {
        discountCode: '',
    };
    handleChange = (event) => {
        this.setState({
            discountCode: event.target.value,
        });
    };

    handleDiscountAdd = (e) => {
        e.preventDefault();
        if (this.state.discountCode) {
            this.props.addCartDiscount({code: this.state.discountCode});
            this.setState({
                discountCode: "",
            });
        }
    }

    handleDiscountRemove = (discountCode) => {
        this.props.removeCartDiscount({code: discountCode});
        this.setState({
            discountCode: "",
        });
    };

    render() {
        const {t, order, classes} = this.props;
        return (
            <div className={classes.discount}>
                <div className={classes.enterDiscountText}>{t('Enter Discount Code')}</div>
                <div>
                    <div className={classNames('checkout-discount', classes.discountBlock)}>
                        <form onSubmit={this.handleDiscountAdd}>
                            <TextField
                                name='discount'
                                className={classNames(classes.textInput)}
                                value={this.state.discountCode}
                                onChange={this.handleChange}
                            />
                            <Button className={classes.button} type={"submit"}>
                                {t('save')}
                            </Button>
                        </form>
                    </div>
                    <div>
                        {order?.discounts?.map((item, i) => {
                            return (
                                <div key={i} className={classes.removeDiscount}
                                     onClick={() => this.handleDiscountRemove(item.discount.discountCode)}>
                                    <>{t('Remove {{discountCode}} discount code', {discountCode: item.discount.discountCode})}</>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default connectTo(
    (state) => ({}),
    {
        addCartDiscount,
        removeCartDiscount
    },
    withNamespaces('checkoutPage')(
        withStyles(styles)(ShoppingCartSummary)
    )
);