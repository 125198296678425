import purple from '@material-ui/core/colors/purple';

export default (theme) => ({
  textField: {
    width: '100%',
    marginBottom: '9px',
  },
  closeIcon: {
    padding: 10,
    cursor: 'pointer',
  },
  label: {
    fontWeight: '400',
    textTransform: 'uppercase',
    color: 'black',
    fontSize: '0.825em',
    marginBottom: '5px',
  },
  controlMd: {
    width: '47%',
  },
  controlSm: {
    width: '40%',
  },
  controlLg: {
    width: '60%',
  },
  genderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
  },
  multiRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    backgroundColor: theme.palette.primary.buttonColor,
  },
  cancelButton: {
    color: '#4d4d4d',
    border: '2px solid #4d4d4d',
    backgroundColor: '#fff',
  },
  content: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0 0 0',
    outline: 'none',
    borderTop: '5px solid red',
    borderTopColor: theme.palette.primary.buttonColor,
  },
  headerContent: {
    fontSize: '1.4em',
    color: '#000',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px 0 20px',
    fontSize: '14px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px',
  },
  shipiingModalButton: {
    width: '150px',
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
  },
  imageInput: {
    display: 'flex',
    alignItems: 'center',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  colorSwitchBase: {
    color: purple[300],
    '&$colorChecked': {
      color: purple[500],
      '& + $colorBar': {
        backgroundColor: purple[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  cartLimitSwitch: {
    marginLeft: '80px',
    fontSize: '0.8em',
  },
});
