export default theme => ({
  /*content: {
    width: '250px',
    height: '350px',
    border: '1px solid',
    marginBottom: '45px',
    marginLeft: '9px'
  },
  image: {
    height: '50%',
    background: theme.palette.primary.grey,
    display: 'flex',
    alignItems: 'center'
  },
  info: {
    height: 'calc(50% - 20px)',
    padding: '10px'
  },
  name: {
    textTransform: 'uppercase',
    color: 'black',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: '5px'
  },
  matNumber: {
    color: 'black',
    fontSize: '0.75em',
    marginBottom: '5px'
  },
  descr: {
    fontSize: '0.8125em',
    lineHeight: '1.5em',
    textAlign: 'justify',
    marginBottom: '15px',
    height: '60px',
    overflowY: 'hidden'
  },
  price: {
    fontSize: '1.5em',
    textAlign: 'right',
    color: 'black'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    '-ms-flex': 1
  }*/
});
