import { put } from 'redux-saga/effects';

import { FIND_QUICK, PRODUCT, PRODUCTBYCODE, PRODUCTS } from '../constants/api';

import {
  setQuickOrderOptions,
  setProductInOrder,
  extendOrder
} from '../actions/quickorder';
import { callHttp, callHttpWithoutSpinner } from '../utils/api';

import { get, getArray } from '../utils/httpUtil';
import { openErrorSnack, openSimpleErrorSnack } from '../actions/startpage';
import i18next from '../config/i18nconfig';

i18next.loadNamespaces(['messages']);

export function* getQuickOrderOptions({ payload }) {
  try {
    const code = yield callHttpWithoutSpinner(
      get,
      FIND_QUICK(payload)
    );
    yield put(setQuickOrderOptions(code));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addProductInOrder({ payload }) {
  try {
    const { inputValue, quantity } = payload;
    // const product = yield callHttp(get, PRODUCT(alias));
    const product = yield callHttp(get, PRODUCTBYCODE(inputValue));
    // if ((product.price !== null) && (product.price !== 0)) {
    if (product.isOrderable) {
      product.quantity = quantity;
      yield put(setProductInOrder(product));
    } else {
      yield put(
        openSimpleErrorSnack(
          i18next.t('messages:Error Add In Cart', { code: product.code })
        )
      );
    }
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getBatchProducts({ payload }) {
  try {
    const data = payload.products;
    if (data && data.length) {
      const product = yield callHttp(
        getArray,
        PRODUCTS,
        data.map(i => i.code),
        'code'
      );
      yield put(
        extendOrder(
            {
              p: product.map((p) => {
                let d = data.find(element => element.code === p.code)
                p.quantity = d.quantity
                return p
              }), isCSVuploaded: payload.isCSVuploaded
            }
        )

      );
    }
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
