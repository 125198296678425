import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { HashLink as Link } from 'react-router-hash-link';

import styles from './style';

const Breadcrumbs = (props) => {
  const { classes, links } = props;
  return (
      <>
          <div className={classNames(classes.wrapper, 'md-collapse')}>
              <div className={classNames(classes.leftCorner, 'left-corner')}>
                  {links &&
                  links.map((k, i) => (
                      <div
                          key={i}
                          className={classNames(classes.crumb, 'medium', 'medium-font')}
                      >
                          { i !== (links.length -1) ?
                              <Link className={classes.link} to={k.link}>
                                  {k.name}
                              </Link>
                              :
                              <Link className={classes.linkBold} to={k.link}>
                                  {k.name}
                              </Link>
                          }

                          {i !== links.length - 1 && <div style={{color: 'black'}}> > </div>}
                      </div>
                  ))}
              </div>
          </div>
          <div className={classNames( 'md-wrapper')}>
              <div className={classNames(classes.leftCorner, 'left-corner')}>
                  {links &&
                  links.map((k, i) => (
                      <div
                          key={i}
                          className={classNames(classes.crumb, 'medium', 'medium-font')}
                      >
                          { i !== (links.length -1) ?
                              <Link className={classes.link} to={k.link}>
                                  {k.name}
                              </Link>
                              :
                              <Link className={classes.linkBold} to={k.link}>
                                  {k.name}
                              </Link>
                          }

                          {i !== links.length - 1 && <div style={{color: 'black'}}> > </div>}
                      </div>
                  ))}
              </div>
          </div>
      </>

  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.links === nextProps.links;
};

export default withStyles(styles)(React.memo(Breadcrumbs, areEqual));
