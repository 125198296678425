import { RequestError } from './exceptions';

export const headers = () => {
  const customerEmail = localStorage.getItem('service');

  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');
  if (customerEmail) {
    return {
      'X-Switch-User': customerEmail,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ? token : ''}`,
    };
  } else
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ? token : ''}`,
      /* 'Accept-Language': localStorage.getItem('i18nextLng'), */
    };
};

export const headersMultipart = () => ({
  Authorization: `Bearer ${
    localStorage.getItem('token') || sessionStorage.getItem('token')
  }`,
});

export const makePostOptions = (data) => ({
  method: 'POST',
  mode: 'cors',
  headers: headers(),
  body: JSON.stringify(data),
});

export const makeMultipartPostOptions = (data) => ({
  method: 'POST',
  mode: 'cors',
  headers: headersMultipart(),
  body: data.formData,
});

export const makePatchOptions = (data) => ({
  method: 'PATCH',
  mode: 'cors',
  headers: headers(),
  body: JSON.stringify(data),
});

export const getOptions = () => ({
  method: 'GET',
  mode: 'cors',
  headers: headers(),
});

export const deleteOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers(),
});

export const deleteManyUrl = (url, data) =>
  `${url}?${data.map((v, i) => 'users[]=' + v).join('&')}`;
// May be use arrayUrl? And another Many func
export const arrayUrl = (url, key, data) =>
  `${url}?${data.map((v) => `${key}[]=${v}`).join('&')}`;

const request = (url, options) =>
  fetch(adjustUrl(url, options), options).then((response) => {
    const { status } = response;

    if (status === 204) return {};

    const json = response.json().catch((ce) => {
      throw new RequestError(status); // as fail response is not valid json when logging in we should use this
    });
    if (status >= 200 && status < 300) return json;

    return json.then((message) => {
      throw new RequestError(status, message);
    });
  });

const requestBlob = (url, options) =>
  fetch(url, options)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    });

export const getBlob = (url) => requestBlob(url, getOptions());

export const get = (url) => request(url, getOptions());
export const getArray = (url, data, key) =>
  request(arrayUrl(url, key, data), getOptions());

export const post = (url, data) =>
  request(
    url,
    data.multipart ? makeMultipartPostOptions(data) : makePostOptions(data)
  );

export const del = (url, id) => request(url + id, deleteOptions());
//And there ---
export const delBody = (url, data) =>
  request(deleteManyUrl(url, data), deleteOptions());
// -----------
export const patch = (url, data) => request(url, makePatchOptions(data));

const adjustUrl = (url, options) => {
  const u = randomString();
  options.headers['User-Key'] = u;
  if (options.method !== 'GET') {
    return url;
  }

  const CACHE_BUILD = localStorage.getItem('cacheBuild') || null;
  let newUrl = url;

  const urlObject = new URL(url);
  if (CACHE_BUILD) {
    urlObject.searchParams.set('c', CACHE_BUILD);
  }

  urlObject.searchParams.set('u', u);

  if(!localStorage.getItem('token')){
    urlObject.searchParams.set('uType', "guest");
  }

  newUrl = urlObject.toString();
  return newUrl;
};

function randomString(length = 8) {
  let currentKey = localStorage.getItem('userKey');
  if (!currentKey) {
    const half = parseInt(length / 2, 10);
    currentKey =
      Math.random()
        .toString(36)
        .substring(2, half + 2) +
      Math.random()
        .toString(36)
        .substring(2, length - half + 2);
    localStorage.setItem('userKey', currentKey);
  }

  return currentKey;
}
