export default (theme) => ({
  row: {
    '&:hover': {
      backgroundColor: theme.palette.primary.selected,
    },
  },
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  inStockCell: {
    width: '100px',
    color: theme.palette.primary.green,
  },
  cartCell: {
    width: '50px',
    cursor: 'pointer',
  },
  table: {
    overflowX: 'auto',
    width: '100%',
  },
  cellCentred: {
    textAlign: 'center',
  },
  quantity: {
    width: '70px',
  },
  button: {
    width: '150px',
  },
  emptyButton: {
    backgroundColor: '#fff',
    border: `1.5px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
  },
  filledButton: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableTitle: {
    fontSize: '2.3em',
    marginBottom: '8px',
    color: '#000',
  },
  descriptionRow: {
    margin: '0.5em 0',
  },
  sku: {
    fontSize: '0.9em',
    color: 'gray',
  },
  productName: {
    fontSize: '1.1em',
    fontWeight: '500',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  detailsRow: {
    margin: '15px 0',
    width: '80%',
    overflow: 'hidden',
    height: '45px',
    lineHeight: '15px',
  },
  img: {
    maxWidth: '60px',
    maxHeight: '60px',
  },
  avatar: {
    margin: '5px auto',
    backgroundColor: 'grey',
    width: '60px',
    height: '60px',
  },
  circle: {
    width: '60px',
    height: '60px',
    margin: '3px',
  },
  notDiscountPrice: {
    textAlign: 'right',
    textDecoration: 'line-through',
    marginRight: '6px',
    color: theme.palette.primary.blackPrice
  },
  centred: {
    textAlign: 'center',
  },
  discountPrice: {
    marginBottom: '10px',
  },
  discount: {
    color: theme.palette.primary.blue,
  },
  shoppingCart: {
     paddingBottom: 70,
    position: 'relative'
  }
});
