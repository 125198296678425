import React from 'react';

import Node from './node';

import { connectTo } from '../../../../utils/generic';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';
import { withRouter } from 'react-router';

import qs from 'qs';

class Treeview extends React.Component {
  state = {
    selected: {},
    parents: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.clear === true)
      return {
        ...state,
        selected: {},
      };
    return null;
  }

  getParents = () => {
    const search = qs.parse(this.props.location.search.replace('?', ''));
    let parents = this.findParents(this.props.category, search.category);
    if (parents) parents = [...parents, this.props.category.alias];
    return parents;
  };

  findParents = (node, searchedAlias) => {
    if (node.alias === searchedAlias) {
      return [];
    }

    if (Array.isArray(node.children)) {
      for (var treeNode of node.children) {
        const childResult = this.findParents(treeNode, searchedAlias);

        if (Array.isArray(childResult)) {
          return treeNode.alias === searchedAlias &&
            treeNode.children &&
            treeNode.children.length === 0
            ? childResult
            : [treeNode.alias].concat(childResult);
        }
      }
    }
  };

  render() {
    const { classes, category, selectedAlias } = this.props;

    return (
      <div className={classNames(this.props.className, 'tree')}>
        <div className={classes.navigation}>
          <Node
            parents={this.getParents()}
            level={0}
            product={category}
            selectedProduct={selectedAlias}
            selectProduct={(alias) => {
              this.setState({ selected: { alias: alias } });
              this.props.handleCategoryChange(alias);
            }}
          />
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    category: state.listingPage.category,
  }),
  {},
  withStyles(styles)(withRouter(Treeview))
);
