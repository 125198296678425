import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = {
  buttonColor: /* '#0070b2' */ '#00BEFF',
  footerColor: '#575756',
  linkColor: '#595959',
  fontColor: '#595959',
  breadCrumbsColor: '#ffffff',
  selected: '#f1f1f1',

  slightlyGrey: '#f1f1f1',
  white: '#ffffff',
  blue: /* '#0070b2' */ '#00BEFF',
  dark: '#3b3b3b',
  lightBlue: '#78abb7',
  grey: '#ececec',
  darkGrey: '#d1d1d1',
  red: '#c03c12',
  lightRed: '#ff0149',
  darkRed: '#631c00',
  orange: '#cd6b19',
  yellow: '#fbb900',
  green: '#28ac7b',
  mustard: '#fbba02',
  blackPrice: 'rgba(0, 0, 0, 0.87)'
};

const secondaryColor = {
  yellow: '#DED47E',
  ocher: '#DBCA9F',
  brown: '#C58E4D',
  darkTeal: '#9EC1C9',
  lightTeal: '#E3EEF2',
  rose: '#EC8972',
  red: '#c03c12',
  lightBlue: '#87CCD3',
  mint: '#8EA998',
  darkGrayBrown: '#362617',
  middleGrayBrown: '#867D74',
  lightGrayBrown: '#D7D4D1',
};

const fontFamily = ['GTAmerica', 'sans-serif'].join(',');

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  /*typography: {
    fontFamily: fontFamily
  },*/
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        borderRadius: '0px',
        height: '2.5em',
        paddingTop: '0px !important',
        '&$focused': {
          border: '1px solid #ced4da',
        },
      },
      input: { paddingLeft: '0px !important' },
      endAdornment: {
        right: '0 !important',
      },
      popper: {
        '-webkit-box-shadow': '0px 5px 16px 1px rgba(0,0,0,0.21)',
        '-moz-box-shadow': '0px 5px 16px 1px rgba(0,0,0,0.21)',
        boxShadow: '0px 5px 16px 1px rgba(0,0,0,0.21)',
      },
      popupIndicator: {
        marginRight: '2px',
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: '1px solid',
          borderColor: '#ced4da',
          paddingLeft: '0px',
        },
        '&$disabled': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderTop: '0 !important',
            borderLeft: ' 0 !important',
            borderRight: '0 !important',
            borderColor: '#ced4da !important',
          },
        },
      },
      notchedOutline: {
        borderColor: '#ced4da',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0px',
      },
    },
    MuiBadge: {
      badge: {
        padding: 'unset',
        fontSize: '0.65rem',
      },
    },
    MuiButton: {
      root: {
        fontFamily: fontFamily,
        fontWeight: 400,
        backgroundColor: '#575d6d',
        color: 'white',
        '&:hover': {
          color: 'black',
        },
      },
    },
    MuiDialog: {
      paper: {
        maxHeight: '92vh',
        overflowY: 'unset',
      },
    },
    MuiInput: {
      input: {
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        fontSize: 16,
        fontWeight: '300',
        padding: '8px 8px',
        width: 'calc(100% - 16px)',

        '&$disabled': {
          color: `${primaryColor.dark} !important`,
          paddingLeft: '0 !important',
          paddingRight: '16px !important',
          borderTop: '0 !important',
          borderLeft: '0 !important',
          borderRight: '0 !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: fontFamily,
        lineHeight: 'unset',

        '&$disabled': {
          color: `${primaryColor.dark} !important`,
          paddingLeft: '0 !important',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiTab: {
      root: {
        fontFamily: fontFamily,

        '&$selected': {
          color: primaryColor.buttonColor,
        },
      },
    },
    MuiTable: {
      root: {
        fontFamily: fontFamily,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '20px 0',
      },
    },
    MuiTabs: {
      root: {
        position: 'relative',
        '&:before': {
          bottom: '-1px',
          left: 0,
          right: 0,
          height: '2px',
          content: '""',
          opacity: 1,
          position: 'absolute',
          transition:
            'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiTableRow: {
      head: {
        height: '24px',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: fontFamily,
        padding: '0 5px',
        // wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        '&:last-child': {
          paddingRight: '5px',
        },
        color: primaryColor.fontColor,
      },
      head: {
        borderBottom: `2px solid ${primaryColor.buttonColor}`,
        color: primaryColor.fontColor,
      },
      paddingNone: {
        padding: '5px 0',
      },
    },
    MuiAccordionPanel: {
      root: {
        '&:before': {
          height: '0',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        color: primaryColor.buttonColor,
        '&$expanded': {
          color: primaryColor.buttonColor,
        },
        padding: 0,
        minHeight: '36px !important',
        '&:before': {
          bottom: '-1px',
          left: 0,
          right: 0,
          height: '2px',
          content: '""',
          opacity: 1,
          position: 'absolute',
          transition:
            'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: '#7F868A',
        },
      },
      content: {
        margin: '12px 0 8px !important',
      },
      expandIcon: {
        '&$expanded': {
          marginRight: '-5px',
        },
      },
    },
    MuiTypography: {
      body1: {
        color: 'inherit',
        fontFamily: fontFamily,
        fontWeight: '300',
      },
      body2: {
        fontFamily: fontFamily,
      },
    },
    MuiIconButton: {
      root: {
        margin: '5px 0 4px',
        height: '20px',
        padding: 0,
        color: 'inherit',
      },
      edgeEnd: {
        marginRight: '-5px',
      },
    },
    MuiPrivateTabScrollButton: {
      root: {
        width: 'unset',
      },
    },
    MuiPrivateTextarea: {
      textarea: {
        overflowY: 'hidden',
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'wrap',
        minHeight: '24px',
        height: 'auto',
      },
    },
  },
  typography: {
    useNextVariants: true,
    title: {
      fontWeight: 'bold',
      color: '#575d6d',
    },
    headline: {
      fontWeight: 'bold',
      color: '#575d6d',
    },
  },
  palette: {
    primary: {
      buttonColor: primaryColor.buttonColor,
      footerColor: primaryColor.footerColor,
      linkColor: primaryColor.linkColor,
      //breadCrumbsColor: primaryColor.breadCrumbsColor,
      breadCrumbsColor: primaryColor.selected,

      slightlyGrey: primaryColor.slightlyGrey,
      white: primaryColor.white,
      main: primaryColor.white,
      blue: primaryColor.blue,
      dark: primaryColor.dark,
      lightBlue: primaryColor.lightBlue,
      lightRed: primaryColor.lightRed,
      grey: primaryColor.grey,
      darkGrey: primaryColor.darkGrey,
      red: primaryColor.red,
      darkRed: primaryColor.darkRed,
      orange: primaryColor.orange,
      yellow: primaryColor.yellow,
      green: primaryColor.green,
      mustard: primaryColor.mustard,
      blackPrice: primaryColor.blackPrice
    },
    secondary: {
      light: secondaryColor.red,
      main: primaryColor.buttonColor,
      dark: secondaryColor.red,
      lightTeal: secondaryColor.lightTeal,
      darkTeal: secondaryColor.darkTeal,
      disabled: secondaryColor.lightGrayBrown,
    },
    error: {
      light: secondaryColor.red,
      main: secondaryColor.red,
      dark: secondaryColor.red,
    },
  },
});

export default theme;
