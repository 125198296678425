import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import PaymentMethod from './PaymentMethod';
import OrderOption from './OrderOption';
import ShippingOptions from './ShippingOptions';
import BillingAddress from './BillingAddress';
import { connectTo } from '../../../utils/generic';

import ShippingAddress from './ShippingAddress';
import AdditionalInformation from './AdditionalInformation';
import { toastr } from 'react-redux-toastr';
import { getCountries } from '../../../actions/metadata';
import TermDialog from './TermDialog';
import { getBillingAddress } from '../../../actions/address';
import OrderDetails from './Summary';
import Terms from '../../../components/terms/Terms/index';
import { getSettings } from '../../../actions/static';
import { getCheckoutCart } from '../../../actions/cart';

class CheckoutInfo extends React.Component {
  componentDidMount() {
    this.props.getCountries();
    this.props.getBillingAddress();
    this.props.getSettings();
    this.props.getCheckoutCart();
  }

  componentDidUpdate() {
    this.props.cart.items &&
      this.props.cart.items.length === 0 &&
      this.props.history.replace('/cart');
  }

  handleOpen = (term) => {
    this.setState({
      open: true,
      term: term,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  state = {
    term: {},
    terms: [],
    open: false,
    read: false,
    agree: false,
  };

  validateForm = () => {
    const { t, terms, isShippingAddress, option, cart, internalOrderNumber, settings } = this.props;

    let result = true;
    let is_internal_order_number_mandatory = settings.find(
        (x) => x.name === 'is_internal_order_number_mandatory' && x.value !== null
    );
    terms.forEach((term) => {
      if (!term.checked) {
        toastr.error(t('toastMessage:error'), term.errorText);
        result = false;
      }
    });
    if (!(option && option.id)) {
      toastr.error(t('toastMessage:error'), t('missingShippingMethod'));
      result = false;
    } else  if (!cart.shippingMethod) {
      toastr.error(t('toastMessage:error'), t('Select Shipping Method'));
      result = false;
    }
    if (is_internal_order_number_mandatory.value == 1 && !internalOrderNumber ) {
      toastr.error(t('toastMessage:error'), t('missingInternalOrderNumber'));
      result = false;
    }

    if (option && option.isAddressRequired && isShippingAddress) {
      toastr.error(t('toastMessage:error'), t('confirm address'));
      result = false;
    }

    return result;
  };

  render() {
    const { classes, t, billingAddress, cart, settings } = this.props;
    const showOrderOptions = settings.find(
      (x) => x.name === 'show_order_options' && x.value !== null && parseInt(x.value) === 1
    );
    return (
      <div className='checkout-main-container'>
        <TermDialog
          open={this.state.open}
          handleClose={this.handleClose}
          term={this.state.term}
        />
        {/* <div className="checkout-cart-container">
                                  <ShoppingCartSummary />
                                </div> */}
        <div className={classes.infoContainer}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('orderSummary')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <OrderDetails selectedOrder={cart} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('billingAddress')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <BillingAddress address={billingAddress} />
            </AccordionDetails>
          </Accordion>
         
          {showOrderOptions ? <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('orderOption')}
              </div>
            </AccordionSummary>{' '}
            <AccordionDetails>
              <OrderOption />
            </AccordionDetails>
          </Accordion>:null}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive',
                  'required-field'
                )}
              >
                {t('shippingOptions')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ShippingOptions />
            </AccordionDetails>
          </Accordion>
             
          {this.props.option && this.props.option.isAddressRequired && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  className={classNames(
                    classes.panelHeader,
                    'medium',
                    'accordion-summary-typography-responsive',
                    'required-field'
                  )}
                >
                  {t('shippingAddress')}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ShippingAddress />
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive',
                  'required-field'
                )}
              >
                {t('payment')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentMethod />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                className={classNames(
                  classes.panelHeader,
                  'medium',
                  'accordion-summary-typography-responsive'
                )}
              >
                {t('additionalInformation')}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <AdditionalInformation />
            </AccordionDetails>
          </Accordion>
          <Terms category='checkout' type='checkbox' />
          <div className={classes.buttons}>
            <Button
              className={classNames(classes.button)}
              onClick={() => {
                this.validateForm() && this.props.next();
              }}
            >
              {t('buyNow')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    option: state.cart.shippingOption,
    terms: state.cart.terms,
    cart: state.cart.checkoutCart,
    isShippingAddress: state.cart.isEditable,
    billingAddress: state.address.billingAddress,
    internalOrderNumber: state.cart.internalOrderNumber,
    settings: state.staticContent.settings
  }),
  {
    getCountries,
    getBillingAddress,
    getSettings,
    getCheckoutCart
  },
  withNamespaces(['checkoutPage','toastMessage'])(withStyles(styles)(withRouter(CheckoutInfo)))
);
