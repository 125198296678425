import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';

const Delimiter = (props) => {
  const { classes, title } = props;

  return (
    <div className={classNames(classes.main, 'product-delimiter')}>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default withStyles(styles)(React.memo(Delimiter));
