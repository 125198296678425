import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { renderExtensionIcon } from '../../../utils/fileExtensions';
import { withStyles } from '@material-ui/core/styles';

import { hideDetails, getDocument } from '../../../actions/products';

class Documents extends React.Component {
  componentDidMount() {
    this.props.hideDetails();
  }

  render() {
    const { classes, selectedProduct, t } = this.props;
    return (
      <div>
        {selectedProduct &&
        selectedProduct.product &&
        selectedProduct.product.document &&
        selectedProduct.product.document.length > 0 ? (
          selectedProduct.product.document.map((d, i) => (
            <div
              key={i}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {renderExtensionIcon(d.url)}
              <div
                className={classNames(classes.document, 'clickable')}
                onClick={() =>
                  this.props.getDocument({
                    url: d.url,
                    name: d.title,
                  })
                }
              >
                {d.title}
              </div>
              <div>{d.description}</div>
            </div>
          ))
        ) : (
          <h3>{t('No Documents')}</h3>
        )}
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    product: state.productHome.product,
    selectedProduct: state.productHome.selectedProduct,
  }),
  {
    hideDetails,
    getDocument,
  },
  withNamespaces('productPage')(withStyles(styles)(Documents))
);
