export default theme => ({
  form: {
    width: '400px'
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1100px'
  },
  textField: {
    width: '100%',
    marginBottom: '9px'
  },
  controlMd: {
    width: '47%'
  },
  controlSm: {
    width: '40%'
  },
  controlLg: {
    width: '60%'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '7px'
  },
  multiRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  saveButton: {
    backgroundColor: theme.palette.primary.buttonColor
  },
  cancelButton: {
    marginRight: '20px',
    color: '#4d4d4d',
    border: '2px solid #4d4d4d',
    backgroundColor: '#fff'
  },
  right: {
    justifyContent: 'flex-end'
  },
  editIcon: {
    height: '30px',
    width: '30px'
  },
  email: {
    color: theme.palette.primary.buttonColor
  },
  smallTitle: {
    textTransform: 'none'
  },
  multiField: {
    justifyContent: 'space-between'
  }
});
