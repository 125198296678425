export default (theme) => ({
  tileContainer: {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-evenly',
    width: '100%',
    position: 'relative',
  },
  cursor: {
    cursor: 'pointer'
  },
  // no such className
  titleContainer2: {
    height: 100,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase'
  },
  titleText:{
    //  height: 30,
     textTransform: 'uppercase',
    // width: 250,
    // wordWrap: 'break-word',
    // textAlign: 'center',
     color:' black',
     fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    marginTop: 20,
    marginBottom: 8
  },
  tileImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  // no such className
  titleContainer: {
    /* width: '50%', */
    left: /* '25%' */ '0',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '1.7rem',
  },
  // no such className
  titleSpanContainer: {
    // display: 'inline-block',
    background: /* 'rgba(0, 0, 0, 0.6)' */ theme.palette.primary.buttonColor,
    color: 'white',
    padding: '3px',
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textAlign: 'center',
    width: '180px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15
  },
  // no such className
  subtitleContainer: {
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '1rem',
  },
  // no such className
  subtitleSpanContainer: {
    fontSize: '1rem',
    fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  deactivatedLink: {
    color: 'grey',
    cursor: 'default',
  },
});
