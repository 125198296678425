export default (theme) => ({
  tileContainerStart: {
    // width: '100%',
    // height: '100%',
    // position: 'relative',
    // display: 'inline-block',
    backgroundColor: 'black',
    height: '100%'

  },
  tileContainer: {
    backgroundColor: 'black',
  },
  titleContainer2: {
    height: 100,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase'

  },
  titleText:{
    width: 250,
    wordWrap: 'break-word',
    textAlign: 'center',
    color:' #00BEFF',
    fontWeight: 'bold',
    fontSize: 40,
    bottom: 10,
    position: 'relative',
  },
  tileImageStart: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxHeight: '300px',
    display:'block',
    margin:'auto',
    objectFit: 'scale-down',
    width: '100%'
  },
  tileImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleContainer: {
    /* width: '50%', */
    left: /* '25%' */ '0',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '1.7rem',
  },
  titleSpanContainer: {
    // display: 'inline-block',
    background: /* 'rgba(0, 0, 0, 0.6)' */ theme.palette.primary.buttonColor,
    color: 'white',
    padding: '3px',
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textAlign: 'center',
    width: '180px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitleContainer: {
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '1rem',
  },
  subtitleSpanContainer: {
    fontSize: '1rem',
    fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});
