import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { forgotPassword } from '../../../../actions/auth';
import { isEmailValid } from '../../../../utils/validation';
import { openErrorSnack } from '../../../../actions/startpage';

class ForgotPasswordForm extends React.Component {
  state = {
    email: '',
  };

  restorePassword() {
    this.props.forgotPassword({ email: this.state.email });
  }

  validate() {
    let message = '';
    if (!this.state.email) {
      message = this.props.t('Validation Messages:Email is Required!');
    } else if (!isEmailValid(this.state.email)) {
      message = this.props.t('Validation Messages:Wrong email format!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate() && this.restorePassword();
  };

  render() {
    const { classes, t } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classes.main_content}>
          <div
            style={{ fontSize: '1em', fontWeight: '300' }}
            className={classes.content_row}
          >
            {t('content')}
          </div>
          <div className={classes.content_row}>
            <div className={classNames('input-header', classes.emailHeader)}>
              Email
            </div>
            <TextField
              name='email'
              placeholder='Email'
              value={this.state.email}
              className={classes.content_el_row}
              onChange={(event) => this.setState({ email: event.target.value })}
            />
          </div>
          <div>
            <Button
              type='submit'
              className={classNames(
                classes.content_el_row,
                classes.lightButton
              )}
            >
              {t('passwordReset')}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default connectTo(
  (state) => ({}),
  {
    forgotPassword,
    openErrorSnack,
  },
  withNamespaces(['resetPassPage', 'Validation Messages'])(
    withStyles(styles)(ForgotPasswordForm)
  )
);
