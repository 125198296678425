import React from 'react';

// import styles from '../OrderList/style';
import TableDataDetailsLarge from './TableData/TableDataDetailsLarge';
import TableDataDetailsSmall from './TableData/TableDataDetailsSmall';
// import { withNamespaces } from 'react-i18next';
// import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';

class OrderDetailsTable extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  };

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const shouldShowLarge = this.state.width > 650;
    const { order } = this.props;
    return (
      <div>
        {shouldShowLarge ? (
          <TableDataDetailsLarge order={order} />
        ) : (
          <TableDataDetailsSmall order={order} />
        )}
      </div>
    );
  }
}

export default OrderDetailsTable;