import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { connectTo } from '../../../utils/generic';
import { withNamespaces } from 'react-i18next';

class IOSSwitch extends React.Component {
  render() {
    const { classes, checked, label, title, value } = this.props;

    return (
      <FormControlLabel
        control={
          <Switch
            classes={{
              root: classes.iosroot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            disableRipple
            checked={checked}
            onChange={() => this.props.handleActivate()}
            label={label}
            title={title}
            value={value ? value : null}
          />
        }
        label={label}
      />
    );
  }
}

export default connectTo(
  (state) => ({}),
  {},
  withNamespaces('customerSupport')(withStyles(styles)(IOSSwitch))
);
