import React from "react";
import i18n from '../../../../i18n';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { finalLocales } from '../../../../utils/localization';

let langs = {};
const fLocales = finalLocales()


const LanguageSelector = (props) => {
    const { t } = props;

    Object.keys(fLocales).forEach(key => {
        langs[key] = t(fLocales[key].label);
    });

    return (

        <div>
            <Box
                 sx={{
                     minWidth: 40,
                     display: 'flex',
                     '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                         padding: 'unset',
                         fontWeight: 100
                     },
                     '& .MuiSvgIcon-root.MuiSelect-icon': {
                         color: 'white',
                         left: 20,
                         top: -2,
                         display: 'none'
                     }
                 }}
            >
                <FormControl fullWidth>
                    <Select style={{color: 'white', fontSize: 15,paddingLeft: 4, margin:'0', width: 50, zIndex: 2}}
                            value={i18n.language}
                        onChange={(e) => {
                            i18n.changeLanguage(e.target.value)
                            localStorage.setItem('i18nextLng', e.target.value);
                          // reload() is to update the data backend, in case new data is added or removed ...
                          window.location.reload();
                          let url = new URL(window.location);
                          let params = new URLSearchParams(url.search.slice(1));
                          if(params.has('lng'))
                          {
                              url.searchParams.delete('lng');
                              window.location.href = url.toString()
                          }
                        }}
                    >
                        {Object.keys(langs).map((key) => (
                            <MenuItem
                                key={key}
                                value={key}
                            >
                                {langs[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <img
                    alt={'arrow down'}
                    style={{
                        position: 'relative', left: -23, height: 15, top: 1
                    }}
                    src='/updated_images/svg/down 15px.svg'/>
            </Box>

        </div>

    )
}

export default LanguageSelector
