import theme from '../theme';
import * as settings from "../settings";

export const services = (categories) => {
  let accessory =
      categories && categories.length
        ? categories.find((x) => x.alias === 'accessory')
        : null,
    fanshop =
      categories && categories.length
        ? categories.find((x) => x.alias === 'fanshop')
        : null;
  return settings && settings.show_fanshop ? [
    {
      name: accessory ? accessory.name : 'accessories',
      link: '/content/accessory',
      image:
          accessory && accessory.image
              ? accessory.image
              : '/images/cards/Visual_Produkt_DRILL-Bohrkopf.jpg',
    },
    {
      name: fanshop ? fanshop.name : 'fanshop',
      link: '/content/fanshop',
      image:
          fanshop && fanshop.image
              ? fanshop.image
              : '/images/backgrounds/fansHome.png',
    },
  ] :
  [
    {
      name: accessory ? accessory.name : 'accessories',
      link: '/content/accessory',
      image:
          accessory && accessory.image
              ? accessory.image
              : '/images/cards/Visual_Produkt_DRILL-Bohrkopf.jpg',
    }] ;
};

export const stickerImages = [
  '/updated_images/svg/headset 15px.svg',
  '/updated_images/svg/invoice 15px.svg',
  '/updated_images/svg/fast deliver  15px_black.svg',
];

export const stickerColors = [
  theme.palette.primary.blue,
  theme.palette.primary.blue,
  theme.palette.primary.blue,
];

export const mediaImages = [
  {
    backgroundColor: '#6081c4',
    imageSource: '/images/icons/FooterSocailIcons/png-40x40/icon-facebook.png',
    iconClass: 'icon-facebook',
    link: 'https://www.facebook.com/TractoTechnik',
    isBlank: true,
  },
  {
    backgroundColor: '#1cb8eb',
    imageSource: '/images/icons/FooterSocailIcons/png-40x40/icon-twitter.png',
    iconClass: 'icon-twitter',
    link: 'https://twitter.com/TractoTechnik',
    isBlank: true,
  },
  {
    backgroundColor: '#e52c27',
    imageSource: '/images/icons/FooterSocailIcons/png-40x40/icon-youtube.png',
    iconClass: 'icon-youtube',
    link: 'https://www.youtube.com/user/TractoTV',
    isBlank: true,
  },
  {
    backgroundColor: '#2897cf',
    imageSource: '/images/icons/FooterSocailIcons/png-40x40/icon-llinkedin.png',
    iconClass: 'icon-linkedin',
    link: 'https://www.linkedin.com/company/tracto-technik-gmbh-&-co--kg/',
    isBlank: true,
  },
  {
    backgroundColor: '#2897cf',
    imageSource: '/images/icons/FooterSocailIcons/png-40x40/icon-xing.png',
    iconClass: 'icon-xing',
    link: 'https://www.xing.com/companies/tracto-technikgmbh%26co.kg',
    isBlank: true,
  },
];

export const userTypes = ['Key User', 'User'];
