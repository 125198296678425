import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Titles = (props) => {
  const { classes, title, setUserTitle, titles } = props;

  return (
    <RadioGroup
      name='title'
      className={classes.group}
      value={title ? title : ''}
      onChange={(e) => setUserTitle(e.target.value)}
      style={{ marginBottom: '10px' }}
    >
      {titles &&
          titles.map((v, i) => (
          <FormControlLabel
            key={v.code}
            value={v.code}
            control={<Radio />}
            label={v.name}
            className={'required-field'}
          />
        ))}
    </RadioGroup>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.title === nextProps.title ||
    (prevProps.title &&
      nextProps.title &&
      prevProps.title === nextProps.title)
  );
}

export default withStyles(styles)(React.memo(Titles, areEqual));
