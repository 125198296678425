import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

import {TRANSLATIONS} from './constants/api';
import {selectLng} from './utils/localization';

const translationBuild = localStorage.getItem('translationsBuild');
const saveMissing = process.env.NODE_ENV !== 'production';

i18n
    .use(LanguageDetector)
    .use(Backend)
    .init({
        backend: {
            loadPath: TRANSLATIONS + '?ns={{ns}}' + (translationBuild ? '&t=' + translationBuild : ''),
            addPath: TRANSLATIONS,
            parsePayload: (namespace, key, fallbackValue) => {
                return {name: key, namespace: namespace}
            }
        },
        fallbackLng: false,
        saveMissing: saveMissing,
        lng: selectLng(),
        debug: false,

        // have a common namespace used around the full app
        ns: [],
        //defaultNS: 'translations',

        keySeparator: '<->', // we use content as keys. Added a fake separator just to exclude space as sa separator

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },
        initImmediate: false,
        react: {
            wait: true,
            withRef: false,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
            omitBoundRerender: false,
            //useSuspense: false
        }
    });

export default i18n;