export default theme => ({
  logoColumn: {
    width: '30%'
  },
  mainColumn: {
    width: '65%',
    marginRight: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  img: {
    display: 'block',
    marginLeft: '25%',
    marginRight: '20%',
    marginBottom: '25%',
    width: '55%',
    height: 'auto'
  },
  resetContainer: {
    width: '500px',
    display: 'flex',
    margin: '40px',
    height: '280px'
  },
  eye: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '37px',
    backgroundColor: theme.palette.primary.lightBlue
  },
  eyeImg: {
    height: '20px',
    width: '20px'
  },
  buttonBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    flex: 2,
    backgroundColor: theme.palette.primary.buttonColor
  },
  secondButton: {
    flex: 3,
    marginRight: '20px',
    backgroundColor: theme.palette.primary.white,
    border: '3px solid',
    color: theme.palette.primary.buttonColor,
    borderColor: theme.palette.primary.buttonColor
  },
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
