import React from 'react';

import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

import styles from './style';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { getCart } from '../../../../actions/cart';
import CartSummary from './CartSummary';
import classnames from 'classnames';
import ReactSVG from 'react-svg';

class CheckoutIcon extends React.Component {
  state = {
    hover: false
  };

  componentDidMount() {
    !localStorage.getItem("technician") && (this.props.token || this.props.allowGuestUser) && this.props.getCart();
  }

  render() {
    const { quantity, classes } = this.props;
    return (
      <div
        onMouseLeave={() => this.setState({ hover: false })}
        className={classes.checkout}
      >
        <Link to="/cart" style={{ position: 'relative' }}>
          <div
            className={classnames(classes.top, classes.checkoutLabel)}
            onMouseEnter={() => this.setState({ hover: true })}
          >
            <Badge
              badgeContent={quantity}
              color="primary"

              classes={
                { badge:
                       quantity < 10 ? classes.badge
                        : quantity >= 10 && quantity < 100 ? classes.badgeDoubleDigit
                               : classes.badgeTripleDigit
                      }
              }
            >
              <ReactSVG
                svgClassName="menu-icon colored-font"
                src="/updated_images/svg/cart filled 15px.svg"
                alt="My cart"
                // className={classes.badgeNumber}
              />

            </Badge>
            {/*remove the label of the shopping-cart*/}
            {/*<div className={classnames('menu-label')}>{t('shoppingCart')}</div>*/}
          </div>
        </Link>
        <CartSummary visible={this.state.hover} />
      </div>
    );
  }
}

export default connectTo(
  state => ({
    quantity: state.cart.cart.itemsQuantity || 0,
    allowGuestUser: state.staticContent.allowGuestUser
  }),
  {
    getCart
  },
  withStyles(styles)(CheckoutIcon)
);
