import React from 'react';

import qs from 'qs';

import keycode from 'keycode';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { setSearchString, findProducts } from '../../../actions/search';
import { getQuickOrderOptions } from '../../../actions/quickorder';
import InputAdornment from '@material-ui/core/InputAdornment';
import {BiSearch} from "react-icons/bi";

function renderInput(inputProps) {
  const { InputProps, classes, ref, handleClick, t, ...other } = inputProps;
  return (
        <div className={classNames(classes.items, 'search-box')}>
          <BiSearch
            alt='search'
            className={classes.searchIcon}
            onClick={handleClick}
          />
          <TextField
            placeholder=''
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  disablePointerEvents={false}
                  classes ={{
                    positionStart: classes.positionStart
                  }}
                  style={{
                    zIndex: '1'
                  }}
                ><IconButton />
                </InputAdornment>
              ),
              classes: {
                root: classes.inputRoot,
                input: classNames(
                  classes.inputInput,
                  'search-bar',
                  'search-bar-with-adroment'
                ),
              },
              ...InputProps,
            }}
            {...other}
          />
        </div>
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component='div'
    >
      {`${suggestion.code}: ${suggestion.name}`}
    </MenuItem>
  );
}

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: null,
      };
    default:
      return { ...changes, selectedItem: changes.inputValue };
  }
}

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: '',
    openFound: false,
  };

  findProducts = (pattern) => {
    if (!pattern) return;

    this.props.setSearchString(pattern);
    const search = qs.parse(this.props.location.search.replace('?', ''));

    // If currently on search page, we need to change one search parameter
    const newSearch =
      this.props.location.pathname === '/search'
        ? { ...search, searchValue: pattern }
        : { searchValue: pattern };

    this.props.history.push({
      pathname: '/search',
      search: `?${qs.stringify(newSearch)}`,
    });

    this.props.findProducts(newSearch);
  };

  handleKeyDown = (event, open, selectedIndex) => {
    this.setState({ openFound: true });

    if (keycode(event) !== 'enter') return;

    // If enter is pressed when list is open we just need to autocomplete instead of search
    if (!open || (open && selectedIndex === null))
      this.findProducts(this.state.inputValue);

    this.setState({ openFound: false });
  };

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
    this.props.getQuickOrderOptions({ searchValue: event.target.value });
  };

  handleChange = (item) => {
    localStorage.setItem('searchValue', item);
    this.setState({
      inputValue: item,
    });
  };

  deleteInputValue = () => {
    this.setState((prevState) => {
      return { inputValue: '' };
    });
  };

  render() {
    const { classes, t } = this.props;
    const { inputValue } = this.state;
    return (
      <Downshift
        id='downshift-multiple'
        inputValue={inputValue}
        onChange={this.handleChange}
        isOpen={this.state.openFound}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              handleClick: () => {
                this.setState({ openFound: false });
                this.findProducts(inputValue);
              },
              InputProps: getInputProps({
                onChange: this.handleInputChange,
                onKeyDown: (e) =>
                  this.handleKeyDown(e, isOpen, highlightedIndex),
                placeholder: t('search'),
              }),
            })}
            {isOpen && (
              <div className='search-bar-suggest'>
                <ClickAwayListener
                  onClickAway={() => this.setState({ openFound: false })}
                >
                  <Paper
                    className='search-bar-paper'
                    onClick={() => this.setState({ openFound: false })}
                    square
                  >
                    {this.props.suggestions &&
                      this.props.suggestions
                        .slice(0, 7)
                        .map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.name }),
                            highlightedIndex,
                          })
                        )}
                  </Paper>
                </ClickAwayListener>
              </div>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

export default connectTo(
  (state) => ({ suggestions: state.quickorder.suggestions }),
  {
    findProducts,
    setSearchString,
    getQuickOrderOptions,
  },
  withNamespaces('homePage')(withStyles(styles)(withRouter(DownshiftMultiple)))
);
