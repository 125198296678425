import React from 'react';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import Filter from '../../components/search/filter';
import SearchResult from '../../components/search/searchResult';

import qs from 'qs';
import classNames from 'classnames';

import { getIndividualProduct } from '../../actions/products';
import { connectTo } from '../../utils/generic';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

import { findProducts } from '../../actions/search';

class SearchResultPage extends React.Component {
  state = {
    page: 0,
    searchValue: '',
  };

  componentDidMount() {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');

    let params = qs.parse(this.props.location.search.replace('?', ''));
    let searchValue = localStorage.getItem('searchValue');

    if (searchValue) {
      if (searchValue !== params.searchValue) {
        localStorage.setItem('searchValue', params.searchValue);

        const newParams = {
          searchValue: params.searchValue,
          page: 1,
        };
        this.findProducts(newParams);

        this.setState((prevState) => {
          return {
            searchValue: params.searchValue,
          };
        });
      } else
        this.props.findProducts(
          qs.parse(this.props.location.search.replace('?', ''))
        );
    }
    this.props.token && this.props.getIndividualProduct();
  }

  componentDidUpdate(prevProps, prevState) {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
    let params = qs.parse(this.props.location.search.replace('?', ''));

    if (prevState.searchValue !== params.searchValue) {
      const newParams = {
        searchValue: params.searchValue,
        page: 1,
      };
      this.findProducts(newParams);
      this.setState((prevState) => {
        return {
          searchValue: params.searchValue,
          page: 1,
        };
      });
    }
  }

  clear = () => {
    const params = {
      searchValue: qs.parse(this.props.location.search.replace('?', ''))
        .searchValue,
    };
    this.findProducts(params);
  };

  handleSorting = (param) => {
    const params = {
      ...qs.parse(this.props.location.search.replace('?', '')),
      ...param,
    };
    this.findProducts(params);
  };

  static getDerivedStateFromProps(props) {
    !(props.token || props.allowGuestUser) && props.history.replace('/');
    let newState = { ...qs.parse(props.location.search.replace('?', '')) };
    if (newState.page) newState['page'] = parseInt(newState['page'] - 1, 10);

    return newState;
  }

  handlePageChange = (page) => {
    this.setState({ page });

    const newParams = {
      ...qs.parse(this.props.location.search.replace('?', '')),
      page: page + 1,
    };

    this.findProducts(newParams);
  };

  getParams = () => ({
    ...qs.parse(this.props.location.search.replace('?', '')),
  });

  findProducts = (params) => {
    this.props.history.push({
      search: '?' + qs.stringify(params, { arrayFormat: 'brackets' }),
    });
    this.props.findProducts(params);
  };

  render() {
    const { classes, t } = this.props;
    const requestParams = qs.parse(this.props.location.search.replace('?', ''));

    return (
      <Page>
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs links={[{ name: t('home'), link: '/home' }]} />
        </div>
        {/* <div className={'content-resize'}> */}
         {/*<SearchBox />*/}
        {/* </div> */}

        <div className='main-content'>
          <div className={classNames('search-content', classes.content)}>
            <Filter
              params={this.getParams()}
              clear={this.clear}
              handleTypeChange={(types) =>
                this.handleSorting({ productType: types, page: 1 })
              }
              handleYourTypeChange={(types) =>
                this.handleSorting({ individualProduct: types, page: 1 })
              }
              handlePriceSelect={(prices) =>
                this.handleSorting({ ...prices, page: 1 })
              }
            />
            <SearchResult
              page={this.state.page}
              handleChangePage={this.handlePageChange}
              searchString={requestParams.searchValue}
              changeSorting={(order) =>
                this.handleSorting({ order: order, page: 1 })
              }
            />
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    backgroundUrl: state.staticContent.backgroundUrl,
    allowGuestUser: state.staticContent.allowGuestUser,
  }),
  {
    getIndividualProduct,
    findProducts,
  },
  withNamespaces('searchPage')(withStyles(styles)(withRouter(SearchResultPage)))
);
