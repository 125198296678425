import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Carousel from '../../common/carousel';
import Delimiter from '../../common/Delimiter';
import classNames from 'classnames';

const ImageBar = props => {
  const { classes, products, product, t } = props;

  return (
    <div className={ classes.imageBar}>
      <div className={classes.productImage}>
        <img
          className={classes.image}
          alt="product"
          src={
            product.image && product.image.length
              ? product.image[0].url
              : '/images/product_example_image.png'
          }
        />
      </div>
      <div className={classNames('machine-carousel', classes.carousel)}>
        <div className={classes.delimiter}>
          <Delimiter title={t('furthetMachines')} />
        </div>
        <Carousel
          images={products}
          pageSize={3}
          color="gray"
          arrowClass={classes.arrow}
        />
      </div>
    </div>
  );
};

export default withNamespaces('productPage')(withStyles(styles)(ImageBar));
