import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { orderListColumns } from '../../../../constants/table';

class OrdersTableHead extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  updateDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  createSortHandler = (property) => (event) => {
    this.props.handleRequestSort(event, property);
  };

  render() {
    const { classes, order, orderBy, t } = this.props;
    let copyOfArray = [...orderListColumns];

    if (this.state.width <= 1000 && this.state.width > 850) {
      copyOfArray.splice(3, 1);
    } else if (this.state.width <= 850 && this.state.width > 700) {
      copyOfArray.splice(3, 1);
      copyOfArray.splice(4, 1);
    } else if (this.state.width <= 700 && this.state.width > 550) {
      copyOfArray.splice(2, 2);
      copyOfArray.splice(3, 1);
    } else if (this.state.width <= 550) {
      copyOfArray.splice(1, 3);
      copyOfArray.splice(2, 1);
    }

    return (
      <TableHead>
        <TableRow>
          {copyOfArray.map((row) => (
            <TableCell
              key={row.id}
              padding={row.disablePadding ? 'none' : 'default'}
              className={classNames(
                classes.cellCentred,
                'orderList_tableHeadCells'
              )}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.sortable ? (
                <Tooltip
                  title={t('tooltips:sort')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {t(row.label)}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                t(row.label)
              )}
            </TableCell>
          ))}
          <TableCell className={classes.cellCentred} />
        </TableRow>
      </TableHead>
    );
  }
}

export default withNamespaces('myOrders')(withStyles(styles)(OrdersTableHead));
