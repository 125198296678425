import React from 'react';

import Table from '@material-ui/core/Table';

import TableHead from './TableHead';
import TableBody from './TableBody';
import OrderDetails from '../OrderDetails';
import Pagination from '../../../account/userManagement/pagination';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { getOrders } from '../../../../actions/account';

import { getSorting, stableSort } from '../../../../utils/table';

import qs from 'qs';
import { withRouter } from 'react-router-dom';

class OrderList extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'createdAt',
    selectedId: -1,
    oderClicked: null,
    currentPage: 1,
    visiblePages: 7,
  };

  constructor(props) {
    super(props);

    this.listRef = React.createRef();
    this.detailsRef = React.createRef();
  }

  handleRequestSort = (event, property) => {
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc')
      order = 'asc';

    this.setState({ order, orderBy: property });
  };

  handleOrderSelect = (order) => {
    if (order.orderId === this.state.selectedId)
      this.setState({ selectedId: -1 });
    else this.setState({ selectedId: order.orderId });
    this.props.handleSelect(order);
    this.scrollToDetailsRef();
  };

  getParams = (params) => {
    return {
      order: params.order,
      orderBy: params.orderBy,
      page: params.currentPage,
    };
  };

  getRequestParams = () => {
    return qs.parse(this.props.location.search.replace('?', ''));
  };

  componentDidMount() {
    if (!this.props.location || !this.props.location.search) {
      this.props.getOrders(this.getParams(this.state));
    } else {
      const requestParams = this.getRequestParams();
      this.props.getOrders(this.getParams(requestParams));
      this.setState((prevState) => ({
        order: requestParams.order,
        orderBy: requestParams.orderBy,
        currentPage: requestParams.page,
      }));
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    let prevSortedOrders = stableSort(
      prevProps.orders,
      getSorting(prevState.order, prevState.orderBy)
    );
    let sortedOrders = stableSort(
      this.props.orders,
      getSorting(this.state.order, this.state.orderBy)
    );
    const prevEndIndex = prevSortedOrders.findIndex(
      (x) => x.orderId === prevState.selectedId
    );
    const endIndex = sortedOrders.findIndex(
      (x) => x.orderId === this.state.selectedId
    );

    if (
      prevEndIndex < endIndex &&
      this.detailsRef.current &&
      this.listRef.current
    ) {
      const list = this.listRef.current;
      return list.scrollHeight - list.scrollTop;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.order !== this.state.order ||
      prevState.orderBy !== this.state.orderBy ||
      prevState.currentPage !== this.state.currentPage
    ) {
      this.props.history.push({
        search:
          '?' +
          qs.stringify({
            page: this.state.currentPage,
            order: this.state.order,
            orderBy: this.state.orderBy,
          }),
      });
      this.props.getOrders({
        order: this.state.order,
        orderBy: this.state.orderBy,
        page: this.state.currentPage,
      });
    }
  }

  handleChangePage = (data) => {
    const { selectedOrder } = this.props;
    let selected = data + 1;
    this.props.history.push({
      search:
        '?' +
        qs.stringify({
          page: selected,
          order: this.state.order,
          orderBy: this.state.orderBy,
        }),
    });
    if (selectedOrder) this.handleOrderSelect(selectedOrder);
    this.setState((prevState) => {
      return { currentPage: selected };
    });
  };

  scrollToDetailsRef = () => {
    if (this.detailsRef && this.detailsRef.current)
      window.scrollTo({
        top: this.detailsRef.current.offsetTop,
        behavior: 'smooth', // optional
      });
  };

  render() {
    const {
      classes,
      t,
      orders,
      selectedOrder,
      currentPage,
      pagination,
    } = this.props;
    const { order, orderBy } = this.state;

    let sortedOrders = stableSort(orders, getSorting(order, orderBy));
    let endOrders = [];
    let startOrders = sortedOrders;
    const endIndex = sortedOrders.findIndex(
      (x) => x.orderId === this.state.selectedId
    );

    if (endIndex !== -1) {
      startOrders = sortedOrders.slice(0, endIndex + 1);
      endOrders = sortedOrders.slice(endIndex + 1, sortedOrders.length);
    }

    return (
      <div>
        <div className={classes.container}>
          <div
            className={classNames(
              classes.header,
              'bold',
              'account_panelHeader'
            )}
          >
            {t('Order History')}
          </div>
          <div
            className={classNames(classes.table, 'order-table')}
            ref={this.listRef}
          >
            <Table>
              <TableHead
                handleRequestSort={this.handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody
                createRef={this.createRef}
                orders={startOrders}
                handleOrderSelect={this.handleOrderSelect}
                selectedId={this.state.selectedId}
              />
            </Table>
          </div>
        </div>

        {selectedOrder && (
          <div ref={this.detailsRef} className='test-class'>
            <OrderDetails selectedOrder={selectedOrder} />{' '}
          </div>
        )}

        {endOrders.length !== 0 && (
          <div className={classes.container}>
            <div className={classNames(classes.header, 'bold')}>
              {t('Order History')}
            </div>
            <div className={classes.table}>
              <Table>
                <TableHead
                  createRef={this.createRef}
                  handleRequestSort={this.handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody
                  orders={endOrders}
                  handleOrderSelect={this.handleOrderSelect}
                  selectedId={this.state.selectedId}
                />
              </Table>
            </div>
          </div>
        )}
        <div className='center-all' style={{ margin: '20px' }}>
          <Pagination
            total={pagination.length}
            current={currentPage - 1}
            display={this.state.visiblePages}
            onChange={this.handleChangePage}
            selectedClass={classes.selectedPage}
          />
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    orders: state.account.orders,
    currentPage: state.account.currentPage,
    itemsCount: state.account.itemsCount,
    itemsTotal: state.account.itemsTotal,
    pagination: state.account.pagination,
  }),
  {
    getOrders,
  },
  withNamespaces('myOrders')(withRouter(withStyles(styles)(OrderList)))
);
