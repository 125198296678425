import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import {openLoginForm} from "../../../../actions/auth";

import { addProduct } from '../../../../actions/cart';
import { formatMoney } from '../../../../utils/localization';

import {
  selectProduct,
  showDetails,
  setHighlightedProduct,
  getSelectedProduct, openInquiry, setInquiryProduct,
} from '../../../../actions/products';
import { primaryColor } from '../../../../theme';
import { hexToRgb } from '../../../../utils/table';
import InquiryIconButton from '../../../common/Inquiry/Button' ;


const buttonColor = hexToRgb(primaryColor.buttonColor);

class TableLarge extends React.Component {
  hadleAddProduct = (product) => {
    this.props.addProduct({
      code: product.product ? product.product.code : product.code,
      quantity: 1,
    });
  };

  handleInquiry(product) {

    this.props.setInquiryProduct({product:{productName:  product.productName, productCode:  product.productCode, quantity: 1}})
    this.props.openInquiry()
  }

  render() {
    const {
      rows,
      classes,
      t,
      showDetails,
      selectedLabel,
      setHighlightedProduct,
      schemaRef
        /*,
      category*/,
    } = this.props;

    const getSelectedProduct =
      /*category === 'my'
        ? this.props.getMySelectedProduct
        : this.props.getSelectedProduct;*/
      this.props.selectProduct;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('position')}</TableCell>
            <TableCell>{t('materialNumber')}</TableCell>
            <TableCell>{t('materialName')}</TableCell>
            <TableCell className={classes.cellCentred}>
              {t('quantity')}
            </TableCell>
            <TableCell className={classes.cellCentred}>{t('unit')}</TableCell>
            <TableCell className={classes.cellCentred}>{t('price')}</TableCell>
            {this.props.token && <TableCell className={classes.cellCentred}>
              {t('availibility')}
            </TableCell>}
            <TableCell className={classes.cellCentred}>{t('cart')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((product, i) => (
            <TableRow
              className='row-hover'
              key={i}
              style={{
                backgroundColor:
                  product.label &&
                  /*selectedLabel === product.label*/ product.schemaMatches &&
                  product.schemaMatches.length &&
                  product.schemaMatches.find(
                    (item) => item.value === selectedLabel
                  )
                    ? `rgba(${buttonColor.r}, ${buttonColor.g}, ${buttonColor.b}, 0.12)`
                    : '#fff',
              }}
            >
              <TableCell
                onClick={() => {setHighlightedProduct(product.label)
                  window.scrollTo({
                    top: schemaRef.current.offsetTop,
                    behavior: 'smooth', // optional
                  });
                }}
                className='clickable'
              >
                {product.label}
              </TableCell>
              <TableCell
                className='clickable'
                onClick={() =>
                  (product.children && product.children.length) ||
                  product.isGroup
                    ? getSelectedProduct({ product })
                    : showDetails({ product: product.product })
                }
              >
                {product.product.code}
              </TableCell>
              <TableCell
                title={product.product.name}
                className={classes.nameCell}
                onClick={() =>
                  product.children.length || product.isGroup
                    ? getSelectedProduct({ product })
                    : showDetails({ product: product.product })
                }
              >
                {product.product.name}
              </TableCell>

              <TableCell className={classes.cellCentred}>
                {product.quantity}
              </TableCell>
              <TableCell className={classes.cellCentred}>
                {product.product.unit && product.product.unit.name}
              </TableCell>
              <TableCell className={classes.cellCentred}>
                {(this.props.token || this.props.showPricesForGuest) ? (product.product.price || product.product.discountPrice)
                  ? formatMoney(
                      product.product.discountPrice || product.product.price
                    )
                  :   t('requestPrice')
                 : t('guestPrice') }
              </TableCell>
              {this.props.token && <TableCell
                className={classNames(classes.inStockCell, classes.cellCentred)}
              >
                <img
                  alt='status'
                  className='in-stock-icon'
                  src={product.product.isOrderable
                        ? (product.product.inStock
                          ? '/images/icons/icon-available.svg'
                          : '/images/icons/icon-available-limited.svg')
                        : '/images/icons/icon-not-available.svg'
                  }
                />
              </TableCell>}
              <TableCell
                className={classNames(
                  product.product.isOrderable &&
                    product.product.price &&
                    classes.cartCell,
                  classes.cellCentred
                )}
              >
                {(this.props.token || this.props.showPricesForGuest) ? <img
                  alt='Cart'
                  className={classNames(
                    product.product.isOrderable && (product.product.price || product.product.discountPrice)
                      ? 'cart-plus-icon available'
                      : 'cart-plus-icon',
                      classes.available
                  )}
                  onClick={() => /*this.props.token ?*/ (
                    product.product.isOrderable && (product.product.price || product.product.discountPrice)
                      ? this.hadleAddProduct(product)
                      : null ) /*:
                      this.props.openLoginForm({centered:true})*/
                  }
                  src={
                    product.product.isOrderable && (product.product.price || product.product.discountPrice)
                      ? '/updated_images/svg/cart-plus 15px.svg'
                      : '/updated_images/svg/cart-plus 15px grey.svg'
                  }
                /> :   
 
              <InquiryIconButton
              asImage
              onClick={ ()=> {
                this.handleInquiry({productName: product.product.code, quantity: 1 })
              }}
              /> 
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default connectTo(
  (state) => ({
    selectedLabel: state.productHome.highlightedProductLabel,
    token: state.auth.token,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {
    selectProduct,
    showDetails,
    addProduct,
    setHighlightedProduct,
    getSelectedProduct,
    openInquiry,
    setInquiryProduct,
    openLoginForm
  },
  withNamespaces('productPage')(withStyles(styles)(TableLarge))
);
