export default theme => ({
  item: {
    border: '1px solid white',
    position: 'relative',
    cursor: 'pointer',
    height: '44px',
    width: '44px',
  },
  red: {
    backgroundColor: theme.palette.primary.red
  },
  blue: {
    backgroundColor: theme.palette.primary.blue
  },
  orange: {
    backgroundColor: theme.palette.primary.orange
  },
  img: {
    width: '32px',
    height: '32px',
    display: 'block',
    margin: 'auto',
    paddingTop: 6
  },
  stickyBar: {
    display: 'block',
    position: 'fixed',
    right: 0,
    // top: '35%',
    top: '140px',
    zIndex: 100
  },
  servicePaper: {
    position: 'absolute',
    width: '275px',
    backgroundColor: 'white',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    color: 'black',
    fontSize: '0.8em',
    padding: '10px',
    marginRight: '15px',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  serviceHeader: {
    textAlign: 'center',
    fontSize: '1.3em',
    color: theme.palette.primary.buttonColor,
    textTransform: 'uppercase'
  },
  link: {
    color: theme.palette.primary.linkColor,
    textDecoration: 'underline'
  }
});
