export default theme => ({
  content: {
    width: '100%',
    margin: '20px 0 0',
    display: 'flex',
    flexWrap: 'wrap'
  },
  mainContent: {
    flex: '3'
  },
  sidebar: {
    flex: '1',
    backgroundColor: theme.palette.primary.grey
  },
  title: {
    fontSize: '2.5em',
  },
  date: {
    marginTop: '10px',
    color: theme.palette.primary.buttonColor
  },
  image: {
    marginTop: '20px',
    width: '100%'
  },
  sidebarArticle: {
    margin: '5px'
  },
  sidebarHeader: {
    margin: '5px',
    fontSize: '1.2em',
  },
  articleTitle: {
    color: 'black',
    marginBottom: '10px'
  },
  articleDate: {
    color: 'black',
    fontSize: '0.8em',
    marginBottom: '10px'
  },
  link: {
    color: theme.palette.primary.linkColor,
    marginTop: '15px'
  }
});
