export default (theme) => ({
  row: {
    '&:hover': {
      backgroundColor: theme.palette.primary.selected,
    },
  },
  priceContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  inStockCell: {
    width: '100px',
    color: theme.palette.primary.green,
  },
  cartCell: {
    width: '50px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
  },
  cellCentred: {
    textAlign: 'center',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  Table: {
    width: '100%',
    margin: '0 auto',
    fontSize: '0.8em',
  },
  detailsRoot: {
    paddingBottom: 0,
  },
  discountPrice: {
    margin: '5px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'black'
  },
  discount: {
    color: theme.palette.primary.blue,
  },
  notDiscountPrice: {
    textAlign: 'right',
    textDecoration: 'line-through',
    color: theme.palette.primary.blackPrice,
  },
});
