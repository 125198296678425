export default theme => ({
  arrowUp: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    height: 'max-content',
    width: 'max-content'
  },
  arrowDown: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    width: 'max-content',
    height: 'max-content'
  },
  carousel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    overflow: 'hidden',
    width: '615px',
    height: '130px',
    position: 'relative'
  },
  img: {
    margin: '5px'
  },
  item: {
    position: 'absolute',
    width: '200px',
    height: '130px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
    cursor: 'pointer'
  },
  hideLeft: {
    width: 0,
    left: 0,
    height: '130px'
  },
  hideRight: {
    width: 0,
    left: 400,
    height: '130px'
  },
  slider: {
    position: 'absolute',
    width: '410px',
    height: '100%',
    transition: 'all 0.5s ease'
  }
});
