export default (theme) => ({
  row: {
    '&:hover': {
      backgroundColor: theme.palette.primary.selected,
    },
  },
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  inStockCell: {
    width: '100px',
    color: theme.palette.primary.green,
  },
  cartCell: {
    width: '50px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  Table: {
    width: '100%',
    margin: '0 auto',
    fontSize: '0.8em',
  },
  summaryTitleFont: {
    fontSize: '0.9rem',
  },
});
