export default theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    maxWidth: '550px',
    minWidth: '360px',
    outline: 'none'
  },
  topLine: {
    width: '100%',
    backgroundColor: theme.palette.primary.buttonColor,
    height: '7px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 10px 0 0',
    marginBottom: '10px',
    fontSize: '14px'
  },
  headerContent: {
    fontSize: '1em',
    padding: '11px 40px'
  },
  closeContainer: {
    padding: 10,
    cursor: 'pointer'
  },
  bottomButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    marginBottom: '10px'
  },
  button: {
    width: '150px',
    border: `1px solid ${theme.palette.primary.buttonColor}`,
    color: theme.palette.primary.buttonColor,
    backgroundColor: 'white'
  }
});
