import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Product from './product';
import styles from './style';

const ProductList = props => {
  const { classes, products } = props;

  return (
    <div className={classNames(classes.content, 'search-productlist')}>
      {products &&
        products.map((p, i) => (
          <Product key={i} product={p} link={`/product/${p.alias}`} />
        ))}
    </div>
  );
};

export default withStyles(styles)(React.memo(ProductList));
