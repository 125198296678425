import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';

import ShippingCard from './shippingCard';
import ShippingModal from '../modals/shippingModal';
import Arrow from '../../../common/Arrow';
import CarouselPagination from '../../../common/carousel/Pagination';

import {
  getShippingAddresses,
  closeShipping,
  addShippingAddress,
  postShippingAddress,
  patchShippingAddress,
} from '../../../../actions/address';

class ShippingCards extends React.Component {
  state = {
    pagesize: 3,
    page: 0,
    numPages: 0,
  };

  componentDidMount() {
    this.props.getShippingAddresses();
  }

  componentDidUpdate(prevProps, prevState) {
    const { addresses } = this.props;

    if (prevProps.addresses !== addresses && addresses) {
      let numPages = Math.ceil(addresses.length / this.state.pagesize);

      this.setState((prevState) => {
        return { numPages };
      });
    }
  }

  nextPage = () => {
    let page = this.state.page;
    if (
      this.props.addresses[
        this.state.pagesize * this.state.page + this.state.pagesize
      ]
    )
      page++;
    else page = 0;
    this.setState({ page: page });
  };

  prevPage = () => {
    let page = this.state.page;
    if (
      this.props.addresses[
        this.state.pagesize * this.state.page - this.state.pagesize
      ]
    )
      page--;
    else page = Math.floor(this.props.addresses.length / this.state.pagesize);
    this.setState({ page: page });
  };

  handleChangePage = (page) => {
    this.setState((prevState) => {
      return { page };
    });
  };

  render() {
    const { classes, isShippingOpen, isModeEdit, t } = this.props;
    const { numPages, page, pagesize } = this.state;

    let curPage;
    if (this.props.addresses) {
      curPage = this.props.addresses.slice(
        pagesize * page,
        pagesize * page + pagesize
      );
    }

    return (
      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <Arrow
            src='/images/icons/arrow-up-gray.svg'
            arrowVisible={page === 0 || numPages === 0 ? false : true}
            handleClick={() => this.prevPage()}
            className={classes.arrowUp}
          />

          <div className={classNames(classes.cards, 'shipping-cards')}>
            <ShippingCard onClick={() => this.props.addShippingAddress()}>
              <div className={classes.newAddressContent}>
                <img
                  width='80'
                  src='/images/icons/glyph-address.svg'
                  alt='new'
                />
                <div className={classNames(classes.newAddressLabel, 'bold')}>
                  {t('addNewAddress')}
                </div>
              </div>
            </ShippingCard>
            {this.props.addresses &&
              curPage.map((v, i) => <ShippingCard key={i} card={v} />)}

            {/*This part of code is needed if we want to fill the rest of the 
          space with empty cards*/}

            {/* {this.props.addresses &&
              Array.apply(
                null,
                Array(this.state.pagesize - curPage.length)
              ).map((v, i) => <ShippingCard key={i} card={v} />)}
            */}

            <ShippingModal
              open={isShippingOpen}
              title={isModeEdit ? t('changeAddress') : t('newAddress')}
              handleClose={() => this.props.closeShipping()}
              handleSubmit={() =>
                isModeEdit
                  ? this.props.patchShippingAddress({
                      address: this.props.address,
                    })
                  : this.props.postShippingAddress({
                      address: this.props.address,
                    })
              }
            />
          </div>

          <Arrow
            src='/images/icons/arrow-down-gray.svg'
            arrowVisible={
              page === numPages - 1 || numPages === 0 ? false : true
            }
            handleClick={() => this.nextPage()}
            className={classes.arrowDown}
          />
        </div>

        <CarouselPagination
          curPage={this.state.page}
          totalPages={this.state.numPages}
          handleClick={this.handleChangePage}
          style={{
            width: '10px',
            height: '10px',
            borderWidth: '1px',
          }}
          wrapperStyle={{
            width: '5%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        />
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    addresses: state.address.shippingAddresses,
    address: state.address.shippingAddress,
    isShippingOpen: state.address.isShippingOpen,
    isModeEdit: state.address.isModeEdit,
  }),
  {
    getShippingAddresses,
    closeShipping,
    addShippingAddress,
    postShippingAddress,
    patchShippingAddress,
  },
  withNamespaces('accountPage')(withStyles(styles)(ShippingCards))
);
