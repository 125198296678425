import React from 'react';

import Button from '@material-ui/core/Button';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const ForgotPasswordOverlay = props => {
  const { classes, handleClose, t } = props;

  return (
    <div className={classes.main_content}>
      <div
        style={{ fontSize: '18px', lineHeight: '24px' }}
        className={classes.content_row}
      >
        {t('submittedText')}
      </div>
      <div className={classes.content_row}>
        <Button
          onClick={handleClose}
          style={{ margin: 'auto' }}
          className={classNames(classes.content_el_row, classes.lightButton)}
        >
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default withNamespaces('resetPassPage')(
  withStyles(styles)(ForgotPasswordOverlay)
);
