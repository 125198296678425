import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import i18next from '../../../config/i18nconfig';
import CancelIcon from '@mui/icons-material/Cancel';
import {withNamespaces} from "react-i18next";


const lightblue = '#d5e7eb';
const green = '#629a37';
const red = '#c03d13';
const blue = '#78A8A6';

const getColors = stage => {
  let color = [];
  if (stage === 0 || stage === -2)
    color = [lightblue, lightblue, lightblue];
  else if (stage === 1)
    color = [red, lightblue, lightblue];
  else if (stage === 2)
    color = [blue, red, lightblue];
  else if (stage === 3)
    color = [blue, blue, green];
  return color;
}

const getProgressBarColors = stage => {
  let color = [];
  if (stage === 0 || stage === -2)
    color = [[lightblue, lightblue], [lightblue, lightblue]] ;
  else if (stage === 1)
    color = [[red, lightblue], [lightblue, lightblue]];
  else if (stage === 2)
    color = [[lightblue, lightblue], [red, lightblue]];
  else if (stage === 3)
    color = [[lightblue, lightblue], [lightblue, green]];
  return color;
}

const avatarStyle = (stage, id) => {
  const color = getColors(stage);
  return {
    backgroundColor: color[id],
    height: '65px',
    width: '65px',
    zIndex: 1
  };
};

const ProgressBar = props => {
  const { classes, stage, icons, t } = props;
  const colors = getProgressBarColors(stage);
  return (
    <div className={classNames(classes.container, 'progress-bar')}>
      {stage !== -1 && <div className={classes.lineContainer}>
        <div className={classes.line}
             style={{backgroundImage: `linear-gradient(to right, ${colors[0][0]}, ${colors[0][1]})`}}/>
        <div className={classes.line}
             style={{backgroundImage: `linear-gradient(to right, ${colors[1][0]}, ${colors[1][1]})`}}/>
      </div>}
      <div className={classes.progressBar}>
        {stage !== -1 ?
            icons.map((icon, i) => (
                <div className={classes.progressItemContainer} key={i}>
                  <div className={classes.avatar}>
                    <Avatar style={avatarStyle(stage, i)}>
                      <img
                          alt={icon.label}
                          className={classes.avatarIcon}
                          src={icon.src}
                      />
                    </Avatar>
                  </div>
                  <span
                      className={classNames(
                          classes.progressItemTitle,
                          stage <= i ? classes.textDisabled : ''
                      )}
                  >
              {i18next.t(icon.label)}
            </span>
                </div>
            )) :

            <div className={classes.progressItemContainer}>
              <div className={classes.avatar}>
                <Avatar style={
                  {
                    backgroundColor: "rgb(192, 61, 19)",
                    height: '65px',
                    width: '65px',
                    zIndex: 1
                  }}>
                  <CancelIcon className={classes.avatarIcon}/>
                </Avatar>
              </div>
              <span
                  className={classes.progressItemTitle}
              >
                    {t('Order Cancelled')}
                  </span>
            </div>
        }
      </div>
    </div>
  );
};

export default withNamespaces('myOrders')(withStyles(styles)(ProgressBar))
