export default (theme) => ({
  bottomHeader: {
    "@media (max-width: 1150px)": {
      display:'none'
    },
    width: '100%',
    minHeight: '70px',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    top: 6
  },
  bottomHeaderContainer: {
    marginLeft: 20,
    marginBottom: 0,
    marginTop: 0,
    position: 'relative',
    top: 5
  },
  logo: {
    color: 'red',
    marginBottom: '15px'
  },
  bottomContent: {
    width: '100%',
    // height: '100%',
    marginLeft: '24%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  menuItem: {
    height: 24,
    borderRadius: '1.2em',
    borderColor: 'white',
    alignItems: 'flex-end',
    paddingTop: '0.3em',
    paddingBottom: '0.3em',
    paddingLeft: '1em',
    paddingRight: '1em',
    '&:last-child': {
      marginRight: '5px',
    },
    // '&:not(:first-child):not(:last-child)': {
    '&:not(:last-child)': {
      marginRight: '8px',
      },
    width: 'fit-content',
    fontSize: '16px',
    color:'white',
    textTransform: 'uppercase',

    background: 'rgb(' + '30, 30, 30' +')',
      '&:hover': {
        background: '#00BEFF',
        color: 'black !important'
      }
      },
  // menuItem2: {
  //   borderRadius: '1.2em',
  //   borderColor: 'white',
  //   alignItems: 'flex-end',
  //   paddingTop: '0.5em',
  //   paddingBottom: '5px',
  //   paddingLeft: '1em',
  //   paddingRight: '1em',
  //   marginRight: '5px',
  //   '&:last-child': {
  //     marginRight: '5px',
  //   },
  //   background: 'rgb(' + '0, 155, 255' +')',
  //   color: 'black !important'
  // },
  logoHeader: {
      marginLeft: '30px',
      position: 'relative',
      bottom: 20
  },
  bottomRowContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '0',
    marginBottom: '0',
    alignSelf: 'flex-end',
  },
  bottomIcons: {
    height: '25px',
  },
  overlay: {
    position: 'absolute',
    top: '-7px',
    left: '-5px',
    right: '-5px',
    bottom: '-11px',
    background: 'white',
    zIndex: 0,
  },
  expansionTab: {
    position: 'relative',
  },
  selectedItem: {
    color: 'black',
    zIndex: 1,
  },
});
