import React from 'react';

import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';

import { Button, Collapse } from '@material-ui/core';
import ModalHeader from './Header';
import FirstNameInput from './FirstName';
import LastNameInput from './LastName';
import Titles from './Salutation';
import EmailInput from './Email';
import RoleInput from './UserRole';
import CartLimitInput from './CartLimit';

import { connectTo } from '../../../../utils/generic';
import { setNewUserInput, setUserTitle } from '../../../../actions/account';
import { getTitles } from '../../../../actions/metadata';
import { openErrorSnack } from '../../../../actions/startpage';
import { isEmailValid } from '../../../../utils/validation';

import { parseLocaleFloat } from '../../../../utils/localization';

class NewUserModal extends React.Component {
  state = {
    hasOrderLimit: false,
    role: 'default',
    useCustomerSupport: false,
  };

  componentDidMount() {
    this.props.getTitles();
  }

  handleLimitSwitch = () => {
    this.setState({
      hasOrderLimit: !this.state.hasOrderLimit,
    });
    if (this.state.hasOrderLimit)
      this.props.setNewUserInput({ name: 'cartLimit', value: '' });
  };

  handleRoleChange = (e) => {
    const val = e.target.value;
    this.setState({ role: val });

    this.props.setNewUserInput({ name: 'isAdmin', value: val === 'Key User' });
  };

  validateForm = () => {
    const user = this.props.user;
    let message = '';

    let isCartLimitValid = true;

    if (user.cartLimit) {
      try {
        parseLocaleFloat(user.cartLimit);
      } catch (e) {
        isCartLimitValid = false;
      }
    }

    if (!user.title) {
      message = this.props.t('Validation Messages:Salutation is Required!');
    } else if (!user.firstName) {
      message = this.props.t('Validation Messages:First Name is Required!');
    } else if (!user.lastName) {
      message = this.props.t('Validation Messages:Last Name is Required!');
    } else if (!user.email) {
      message = this.props.t('Validation Messages:Email is Required!');
    } else if (!isEmailValid(user.email)) {
      message = this.props.t('Validation Messages:Wrong email format!');
    } else if (user.isAdmin === null || user.isAdmin === undefined) {
      message = this.props.t('Validation Messages:User Role is Required!');
    } else if (this.state.hasOrderLimit && !user.cartLimit) {
      message = this.props.t(`Validation Messages:Cart limit can't be empty!`);
    } else if (!isCartLimitValid) {
      message = this.props.t(
        'Validation Messages:Cart limit is in wrong format!'
      );
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  };

  submitForm = () => {
    const formUser = this.props.user;

    let cartLimit;
    if (!this.state.hasOrderLimit || !formUser.cartLimit) cartLimit = null;
    else cartLimit = parseLocaleFloat(formUser.cartLimit);

    let user = {
      title: formUser.title,
      firstName: formUser.firstName,
      lastName: formUser.lastName,
      email: formUser.email,
      isAdmin: formUser.isAdmin,
      cartLimit,
      mobile: '',
      phone: '',
      canCreateTicket: this.state.useCustomerSupport,
    };

    // Add id editing mode for patch request
    if (formUser.id) user.id = formUser.id;

    this.props.handleSubmit(user);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateForm() && this.submitForm();
  };

  handleCustomerSupportSwitch = () => {
    this.setState({
      useCustomerSupport: !this.state.useCustomerSupport,
    });
    this.props.setNewUserInput({});
  };

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id) {
      this.setState({ hasOrderLimit: !!this.props.user.cartLimit });
    }

    if (this.props.user.canCreateTicket !== prevProps.user.canCreateTicket) {
      this.setState((prevState) => {
        return { useCustomerSupport: this.props.user.canCreateTicket };
      });
    }

    if (this.props.success && !prevProps.success) this.props.handleClose();

    if (this.props.user.isAdmin === undefined && this.state.role !== 'default')
      this.setState({ role: 'default' });

    if (
      prevProps.user.isAdmin === undefined &&
      this.props.user.isAdmin !== undefined
    )
      this.setState({ role: this.props.user.isAdmin ? 'Key User' : 'User' });
  }

  render() {
    const { classes, open, handleClose, t, title, titles } = this.props;

    return (
      <Dialog
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        className='modal'
        onClose={() => handleClose()}
      >
        <form
          className={classNames(classes.content, 'user-modal')}
          onSubmit={this.handleSubmit}
        >
          <ModalHeader handleClose={handleClose} title={title} />
          <DialogContent className='user-modal--dialog-content'>
            <Titles
              setUserTitle={this.props.setUserTitle}
              title={this.props.user.title}
              titles={titles}
            />
            <div className={classes.multiRow}>
              <FirstNameInput
                firstName={this.props.user.firstName}
                setNewUserInput={this.props.setNewUserInput}
                t={t}
              />
              <LastNameInput
                lastName={this.props.user.lastName}
                setNewUserInput={this.props.setNewUserInput}
                t={t}
              />
            </div>
            <div className={classes.multiRow}>
              <EmailInput
                t={t}
                email={this.props.user.email}
                setNewUserInput={this.props.setNewUserInput}
              />
              <RoleInput
                role={this.state.role}
                handleSubmit={this.handleSubmit}
                handleRoleChange={this.handleRoleChange}
                t={t}
              />
            </div>
            <div className={classes.content_row}>
              <div className={classes.textField}>
                <div className={classes.label}>{t('orderLimit')}</div>

                <CartLimitInput
                  handleLimitSwitch={this.handleLimitSwitch}
                  cartLimit={this.props.user.cartLimit}
                  hasOrderLimit={this.state.hasOrderLimit}
                  setNewUserInput={this.props.setNewUserInput}
                  t={t}
                />
                {this.state.hasOrderLimit && (
                  <div className={classes.cartLimitSwitch}>
                    {t('Shopping cart limit is based on net prices.')}
                  </div>
                )}
              </div>
            </div>
            {/* <div className={classes.content_row}>
              <div className={classes.label}>{t('customerSupport')}</div>
              <IOSSwitch
                checked={useCustomerSupport}
                handleActivate={() => this.handleCustomerSupportSwitch()}
                label={t('useCustomerSupport')}
                title={
                  useCustomerSupport
                    ? t('tooltips:deactivate')
                    : t('tooltips:activate')
                }
              />
            </div> */}
          </DialogContent>
          <DialogActions style={{ width: '100%' }}>
            <div className={classes.modalFooter}>
              <Button
                className={classes.shipiingModalButton}
                onClick={handleClose}
              >
                {t('cancel')}
              </Button>
              <Collapse in={this.props.isChanged}>
                <Button type='submit' className={classes.shipiingModalButton}>
                  {t('save')}
                </Button>
              </Collapse>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connectTo(
  (state) => ({
    user: state.account.newUser,
    isChanged: state.account.isNewUserChanged,
    titles: state.metadata.titles,
    success: state.account.successAdd,
  }),
  {
    setNewUserInput,
    getTitles,
    setUserTitle,
    openErrorSnack,
  },
  withStyles(styles)(NewUserModal)
);
