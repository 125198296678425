import React from 'react';

import styles from './style';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../../utils/localization';
import {connectTo} from "../../../../../utils/generic";
import {getShowDiscount} from "../../../../../utils/discounts";
import showDeliveryTime from "../../../../../utils/showDeliveryTime";
import {PRICE_TYPE_INDIVIDUAL} from "../../../../../constants/common";

const Product = (props) => {
  const { classes, product, t, link, settings, showPricesForGuest } = props;
    const showDiscount = getShowDiscount(settings)

  return (
    <div className={classes.content}>
        <Link to={{
          pathname: link,
          from: window.location.href
        }}>
        <div className={classes.image}>
          <img
            alt={product.name}
            className={classes.img}
            src={
              product.image && product.image.length
                ? product.image[0].url
                : '/images/product_example_image.png'
            }
          />
        </div>
      </Link>
      <div className={classes.info}>
        <div>
          <Link to={link}>
            <div className={classes.name} title={product.name}>
              {product.name}
            </div>
          </Link>
          <div className={classes.matNumber}>
            {t('Material Number')}: {product.code}
          </div>
            {props.token && <div className={classes.deliveryTime}>
                {(product.discountPrice > 0 || product.price > 0) && showDeliveryTime(product.inStock, product.deliveryTime)}
            </div>}
        </div>
        <div className={classes.price}>
          {
            (props.token || showPricesForGuest) ? ( product.discountPrice  ? ( // discountPrice is also used as the individual price in case the product has the individual price
            <div className={classes.priceContainer}>
                { (showDiscount || product.priceType === PRICE_TYPE_INDIVIDUAL) && product.discountPrice &&
                <span className={classes.priceLineThrough}>
                {product && product.price ? formatMoney(product.price) : ''}
                </span>
                }
                {<span>{formatMoney(product.discountPrice)}</span>}
            </div>
          )
                  :
                  product.price ?
                      formatMoney(product.price) : t('inquiry')
              ) : t('guestPrice')
          }

        </div>
      </div>
    </div>
  );
};

export default connectTo(
    (state) => ({
        settings: state.staticContent.settings,
        token: state.auth.token,
        showPricesForGuest: state.staticContent.showPricesForGuest,
    }),
    {},
    withNamespaces(['searchPage', 'productPage'])(withStyles(styles)(React.memo(Product))
));

