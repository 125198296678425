import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const SmallTableRow = ({ classes, title, item, className }) => (
  <TableRow>
    <TableCell
      className={classNames(
        classes.tableCell,
        classes.titleTableCell,
        'medium-font'
      )}
    >
      {title}
    </TableCell>
    <TableCell className={classNames(classes.tableCell, 'medium-font')}>
      {item}
    </TableCell>
  </TableRow>
);

export default withStyles(styles)(SmallTableRow);
