import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from '../style';
import { withNamespaces } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import EnhancedTableHead from '../../header/';
import IOSSwitch from '../../../../common/IOSSwitch';

import { formatMoney } from '../../../../../utils/localization';

const ROW_HEIGHT = 15;

const UserTableLarge = ({
  classes,
  t,
  users,
  order,
  orderBy,
  selected,
  emptyRows,
  handleSelectAllClick,
  handleRequestSort,
  handleSelect,
  colors,
  handleActivate,
  openEditUserForm,
  handleDelete,
  isSelected,
}) => (
  <Table className={classes.table} aria-labelledby='tableTitle'>
    <EnhancedTableHead
      numSelected={selected.length}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={handleSelectAllClick}
      onRequestSort={handleRequestSort}
      rowCount={users.length}
    />
    <TableBody>
      {users.map((n) => {
        const isUserSelected = isSelected(n.id);
        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isUserSelected}
            tabIndex={-1}
            key={n.id}
            selected={isUserSelected}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                checked={isUserSelected}
                onClick={(event) => handleSelect(event, n.id)}
              />
            </TableCell>
            <TableCell>
              <Avatar style={{ backgroundColor: colors[n.id] }}>
                {n.firstName[0].toUpperCase() + n.lastName[0].toUpperCase()}
              </Avatar>
            </TableCell>
            <TableCell component='th' scope='row' padding='none'>
              <div className={classes.userName}>{n.userName}</div>
              <div className={classes.userEmail}>{n.email}</div>
            </TableCell>
            <TableCell>
              <IOSSwitch
                checked={n.isEnabled}
                handleActivate={(e) => handleActivate(e, n)}
                label=''
                value={String(n.id)}
                title={
                  n.isEnabled
                    ? t('tooltips:deactivate')
                    : t('tooltips:activate')
                }
              />
            </TableCell>
            <TableCell>{n.isAdmin ? 'Key User' : 'User'}</TableCell>
            <TableCell>
              {n.cartLimit === null ? t('unlimited') : formatMoney(n.cartLimit)}
            </TableCell>
            <TableCell>
              <div className={classes.actionRow}>
                <Avatar
                  className={classes.iconAvatar}
                  classes={{ root: classes.avatarRoot }}
                >
                  <img
                    alt='edit'
                    className={classes.actionIcon}
                    onClick={() => openEditUserForm(n)}
                    title={t('tooltips:edit')}
                    src='/images/icons/MyAccount/icon-edit.png'
                  />
                </Avatar>
                <Avatar
                  className={classes.iconAvatar}
                  classes={{ root: classes.avatarRoot }}
                >
                  <img
                    alt='delete'
                    onClick={() => handleDelete(n.id)}
                    title={t('tooltips:delete')}
                    className={classes.actionIcon}
                    src='/updated_images/svg/bin 15px.svg'
                  />
                </Avatar>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
          {/* <TableCell colSpan={6} /> */}
        </TableRow>
      )}
    </TableBody>
  </Table>
);

export default withNamespaces([
  'accountPage',
  'tooltips',
  'Validation Messages',
])(withStyles(styles)(UserTableLarge));
