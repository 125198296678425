import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import TextField from '@material-ui/core/TextField';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';

class ShippingInfo extends React.Component {
  render() {
    const { classes, t, address, comment } = this.props;

    return (
      <form className={classes.shippingModal}>
        <InputWrapper header={(address && address.title) || t('firstName')}>
          <TextField
            disabled
            name="firstName"
            className={classes.textField}
            value={
              (address && address.firstName + ' ' + address.lastName) || ''
            }
          />
        </InputWrapper>

        <InputWrapper header={t('company')}>
          <TextField
            disabled
            name="companyName"
            className={classes.textField}
            value={(address && address.companyName) || ''}
          />
        </InputWrapper>

        <InputWrapper header={t('additional')}>
          <TextField
            disabled
            name="additional"
            className={classes.textField}
            value={(address && address.additional) || ''}
          />
        </InputWrapper>

        <div className="multi-field-row">
          <InputWrapper header={t('street')} style={{ flex: 5 }}>
            <TextField
              disabled
              name="streetName"
              value={
                this.props.address.streetName + ' ' +
                  this.props.address.streetNumber || ''
              }
              className={classes.textField}
            />
          </InputWrapper>
        </div>
        <div className="multi-field-row">
          <InputWrapper header={t('postalCode')} style={{ flex: 2 }}>
            <TextField
              disabled
              name="postalCode"
              value={address.postalCode || ''}
              className={classNames(classes.textField, 'disable-arrows')}
              inputProps={{maxLength:"16"}}
            />
          </InputWrapper>

          <InputWrapper header={t('city')} style={{ flex: 5 }}>
            <TextField
              disabled
              name="city"
              value={address.city || ''}
              className={classes.textField}
            />
          </InputWrapper>
        </div>

        <InputWrapper header={t('country')}>
          <TextField
            disabled
            className={classes.textField}
            value={address.country || ''}
          />
        </InputWrapper>
        <InputWrapper header={t('phone')}>
          <TextField
            disabled
            className={classes.textField}
            value={(address && address.phone) || ''}
          />
        </InputWrapper>
        {comment && (
          <InputWrapper header={t('comment')}>
            <TextField
              disabled
              multiline
              rowsMax="255"
              className={classes.textField}
              value={comment || ''}
            />
          </InputWrapper>
        )}
      </form>
    );
  }
}

export default withNamespaces('accountPage')(withStyles(styles)(ShippingInfo));
