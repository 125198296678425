import React from 'react';

import styles from './style';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import ReactSVG from 'react-svg';

const LoginIcon = (props) => {
  const { classes } = props;

  return (
    <div className={classes.top} onClick={() => props.history.push(`/`)}>
      <ReactSVG
        svgClassName='menu-icon colored-font'
        src='/images/icons/icon-log-out.svg'
        alt='Login'
      />
        {/*comment to remove the label:*/}
      {/*<div className='menu-label'>{t('login')}</div>*/}
    </div>
  );
};

export default connectTo(
  (state) => ({}),
  {},
  withRouter(withStyles(styles)(LoginIcon))
);
