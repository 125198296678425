export default theme => ({
  mainBox: {
    height: '75px',
    backgroundColor: theme.palette.primary.red,
    display: 'flex',
    alignItems: 'center',
    padding: '10px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchIcon: {
    position: 'absolute',
    right: '5px',
    zIndex: '10',
    bottom: '0',
    marginBottom: '3px',
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  },
  magnifier: {
    width: '40px',
    height: '40px',
    margin: '14px 18px 18px 17px'
  },
  items: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    //   flexGrow: 1,
    position: 'relative'
  },
  chip: {
    margin: `${theme.spacing(1/2)}px ${theme.spacing(1/4)}px`
  },
  inputRoot: {
    flexWrap: 'wrap',
    // width: 300
  },
  inputInput: {
    width: 'auto',
    //   flexGrow: 1
  },
  divider: {
    height: theme.spacing(2)
  },
  menuItem: {
    fontSize: '0.85rem',
    padding: '4px 12px'
  },
  goIcon: {
    width: 30,
    height: 30,
    margin: '0 -5px 0 5px',
    zIndex: 1,
    cursor: 'pointer'
  }
});
