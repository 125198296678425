import React from 'react';

import Node from './node';

import { connectTo } from '../../../utils/generic';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';

import {
  selectProduct,
  //getProductToTree,
  //getMyProductToTree,
  //getSelectedProduct,
  //getMySelectedProduct
} from '../../../actions/products';

const Treeview = props => {
  const { classes, product, selectedProduct/*, type*/ } = props;

  return (
    <div className={classNames(props.className, 'tree')}>
      <div className={classes.navigation}>
        <Node
          level={0}
          product={product}
          selectProduct={props.selectProduct}
          selectedProduct={selectedProduct}
          /*getSelectedProduct={
            type === 'my'
              ? props.getMySelectedProduct
              : props.getSelectedProduct
          }
          getProductToTree={
            type === 'my' ? props.getMyProductToTree : props.getProductToTree
          }*/
        />
      </div>
    </div>
  );
};

export default connectTo(
  state => ({
    product: state.productHome.product,
    selectedProduct: state.productHome.selectedProduct
  }),
  {
    selectProduct,
    //getProductToTree,
    //getMyProductToTree,
    //getSelectedProduct,
    //getMySelectedProduct
  },
  withStyles(styles)(Treeview)
);
