export default (theme => ({
  item: {

  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    overflow: 'hidden'
  },
  category: {
    position: 'absolute',
    width: '230px',
    height: '20px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'uppercase',
    textShadow: 'black 1px 1px 1px',
    fontWeight: 'bold',
    fontSize: '0.8em',
    marginLeft: 'calc(50% - 115px)',
    top: '105px',
    pointerEvents: 'none',
  },
  name: {
    position: 'absolute',
    width: '200px',
    height: '20px',
    lineHeight: '20px',
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    textShadow: 'black 1px 1px 1px',
    fontWeight: 'bold',
    fontSize: '1em',
    marginLeft: 'calc(50% - 100px)',
    top: '40px',
    pointerEvents: 'none',
  },
  red: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.buttonColor
  },
  orange: {
    color: theme.palette.primary.black,
    backgroundColor: theme.palette.primary.mustard
  },
  blue: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.blue
  }
}))