import { createReducer } from 'redux-act'
import * as a from '../actions/quickorder'

const DEFAULT_STATE = {
  suggestions: [],
  products: [],
  alias: null,
  codecache: []
}

export default createReducer(
  {
    [a.setQuickOrderOptions]: (state, suggestions) => ({
      ...state,
      suggestions: suggestions,
    }),
    [a.setProductInOrder]: (state, p) => {
      const products = [...state.products];
      const product = products.find(prod => prod.code === p.code);
      product ? product.quantity++ : products.push(p);
      localStorage.setItem('products', JSON.stringify(products));
      return ({
        ...state,
        products: products,
        code: null
      })
    },
    [a.extendOrder]: (state, p) => {
      const products = [...state.products];
      for (let i = 0; i < p.p.length; i++) {
        const product = products.find(prod => prod.code === p.p[i].code);
        if(product) {
            if (p.isCSVuploaded)
                product.quantity = parseInt(product.quantity, 10) + parseInt(p.p[i].quantity, 10)
            else product.quantity = parseInt(product.quantity, 10)
        }
        else
          products.push(p.p[i]);
      }
      localStorage.setItem('products', JSON.stringify(products))
      return ({
        ...state,
        products: products,
      })
    },
    [a.emptyOrder]: (state) => {
      localStorage.removeItem('products');
      return ({
        ...state,
        products: [],
      });
    },
    [a.deleteProductInOrder]: (state, { code }) => {
      localStorage.setItem('products', JSON.stringify(state.products.filter(p => p.code !== code)))
      return ({
        ...state,
        products: state.products.filter(p => p.code !== code),
      })
    },
    [a.editProductInOrder]: (state, { code, quantity }) => {
      const products = state.products.slice();
      const i = products.findIndex(p => code === p.code);
      products[i].quantity = parseInt(quantity, 10);
      localStorage.setItem('products', JSON.stringify(products));
      return ({
        ...state,
        products: products,
      })
    },
    [a.setAlias]: (state, alias) => ({
      ...state,
      alias: alias,
    }),
  },
  DEFAULT_STATE
)