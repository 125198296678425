import React from 'react';

import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';

import InputWrapper from '../../../common/InputTitleWrapper';
import EditableContacts from './EditableContacts';
import DisabledContacts from './DisabledContacts';
import { connectTo } from '../../../../utils/generic';
import { Button } from '@material-ui/core';

import {
  getContactData,
  setContactDataInput,
  patchContactData,
  setContactDataTitle,
  successChangeContactData,
  editContactData,
} from '../../../../actions/address';
import { getTitles } from '../../../../actions/metadata';
import { openErrorSnack } from '../../../../actions/startpage';

class FormContactData extends React.Component {
  componentDidMount() {
    this.props.getContactData();
    this.props.getTitles();
  }

  validateForm = () => {
    let message = '';
    if (!this.props.contact.firstName) {
      message = this.props.t('Validation Messages:First Name is Required!');
    } else if (!this.props.contact.lastName) {
      message = this.props.t('Validation Messages:Last Name is Required!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateForm() &&
      this.props.patchContactData({
        address: this.props.contact,
      });
  };

  handleCancelClick = () => {
    this.props.successChangeContactData();
    this.props.getContactData();
  };

  render() {
    const { classes, t, titles, contact } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className='account--contact-data'>
        {this.props.isEditable ? (
          <EditableContacts
            contact={this.props.contact}
            setContactDataInput={this.props.setContactDataInput}
            setContactDataTitle={this.props.setContactDataTitle}
            titles={titles}
          />
        ) : (
          <DisabledContacts contact={this.props.contact} titles={titles} />
        )}
        <div className={classes.contentRow} style={{ marginBottom: '10px' }}>
          <InputWrapper
            header='Email'
            headerClass={classes.smallTitle}
            isRequire={true}
          >
            <div className={classes.email}>
              {contact && contact.email ? contact.email : 'You@gmail.com'}
            </div>
          </InputWrapper>
          {this.props.isEditable || (
            <div
              onClick={() => this.props.editContactData()}
              className='clickable'
            >
              <img
                alt='edit'
                title={t('tooltips:edit')}
                className={classNames(classes.editIcon, 'cart-icon')}
                src='/images/icons/icon-edit.svg'
              />
            </div>
          )}
        </div>

        {this.props.isEditable && (
          <div>
            <Button
              onClick={this.handleCancelClick}
              className={classes.cancelButton}
            >
              {t('cancel')}
            </Button>
            <Button className={classes.saveButton} type='submit'>
              {t('save')}
            </Button>
          </div>
        )}
      </form>
    );
  }
}

export default connectTo(
  (state) => ({
    contact: state.address.contactData,
    isEditable: state.address.isEditableContactData,
    titles: state.metadata.titles,
  }),
  {
    getContactData,
    setContactDataInput,
    patchContactData,
    setContactDataTitle,
    getTitles,
    successChangeContactData,
    editContactData,
    openErrorSnack,
  },
  withNamespaces(['accountPage', 'Validation Messages'])(
    withStyles(styles)(FormContactData)
  )
);
