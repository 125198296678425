import React from 'react';

import SmallTableRow from '../../../../../common/SmallTableRow';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import styles from './style';
import classNames from 'classnames';
import {withNamespaces} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';

import {formatMoney} from '../../../../../../utils/localization';
import {connectTo} from "../../../../../../utils/generic";
import {getCartItemDiscountDetails, getShowDiscount} from "../../../../../../utils/discounts";

class TableSmall extends React.Component {

    render() {
        const {classes, t, order, settings} = this.props;
        const showDiscount = getShowDiscount(settings)

        return (
            <div className={classes.table}>
                {order.items.map((item, i) => {
                    const {
                        rowPrice,
                        discountValue,
                        discountPriceCellClass,
                        haveDiscounts
                    } = getCartItemDiscountDetails(item, showDiscount);

                    return (
                        <Accordion key={i}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography
                                    className={classNames(
                                        'accordion-summary-typography-responsive',
                                        'accordion-summary-responsive'
                                    )}
                                >
                                    {item.product.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                className={classes.rows}
                                classes={{root: classes.detailsRoot}}
                            >
                                <Table className={classes.Table}>
                                    <TableBody>
                                        <SmallTableRow title={t('Position')} item={i + 1}/>
                                        <SmallTableRow
                                            title={t('checkoutPage:materialNumber')}
                                            item={
                                                <div
                                                    title={item.product.code}
                                                    className={classes.nameCell}
                                                >
                                                    {item.product.code}
                                                </div>
                                            }
                                        />

                                        <SmallTableRow
                                            title={t('Price')}
                                            item={
                                                showDiscount && haveDiscounts ? (
                                                    <div>
                                                        <div
                                                            className={classNames(
                                                                discountPriceCellClass,
                                                                classes.priceContainer
                                                            )}
                                                        >
                                                            {rowPrice ? formatMoney(rowPrice) : t('requestPrice')}

                                                            {showDiscount && haveDiscounts &&
                                                                <div className={classes.notDiscountPrice}>
                                                                    {item.price > 0 && formatMoney(item.price)}
                                                                </div>}
                                                        </div>
                                                        {showDiscount && discountValue &&
                                                            <div className={classes.discount}>
                                                                {t('cart:discount')}{' '}
                                                                {discountValue}%
                                                            </div>}
                                                    </div>
                                                ) : rowPrice ? (
                                                    formatMoney(rowPrice)
                                                ) : (
                                                    t('requestPrice')
                                                )
                                            }
                                        />
                                        <SmallTableRow
                                            title={t('Quantity')}
                                            item={item.quantity}
                                        />
                                        <SmallTableRow
                                            title={t('Subtotal')}
                                            item={formatMoney(rowPrice * item.quantity)}
                                        />
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        );
    }
}

export default connectTo(
    (state) => ({
        settings: state.staticContent.settings
    }),
    {},
    withNamespaces(['myOrders', 'cart', 'checkoutPage'])(
        withStyles(styles)(TableSmall)
    )
);