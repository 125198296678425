import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';

import { connectTo } from '../../../utils/generic';
import { withRouter } from 'react-router-dom';

import CategoryTile from '../../../components/home/CategoryTile';
import Arrow from '../../common/Arrow';
import { setProductType } from '../../../actions/products';
import { Link } from 'react-router-dom';

class ProductsList extends React.Component {
  state = { page: 0, pagesize: 9 };

  nextPage = () => {
    let page = this.state.page;
    if (
      this.props.products[
        this.state.pagesize * this.state.page + this.state.pagesize
      ]
    )
      page++;
    else page = 0;
    this.setState({ page: page });
  };

  prevPage = () => {
    let page = this.state.page;
    if (
      this.props.products[
        this.state.pagesize * this.state.page - this.state.pagesize
      ]
    )
      page--;
    else page = Math.floor(this.props.products.length / this.state.pagesize);
    this.setState({ page: page });
  };

  render() {
    const { classes, products, type } = this.props;
    let curPage = [];
    let arrowsVisible = false;
    if (products) {
      curPage = products.slice(
        this.state.pagesize * this.state.page,
        this.state.pagesize * this.state.page + this.state.pagesize
      );
      arrowsVisible = products.length > this.state.pagesize;
    }

    const getImages =(productImage) =>
      Array.isArray(productImage) && 
        productImage.length > 0 ? 
        productImage[0].url :
        productImage;
 

    return (
      <div id={this.props.id} className={classes.productList}>
        <Arrow
          src='/images/icons/arrow-up-gray.svg'
          arrowVisible={arrowsVisible}
          handleClick={() => this.prevPage()}
          className={classes.arrowUp}
        />

        <div className='category-grid'>
          {curPage.map((product, i) => (
            <Link key={i} className='product-link-responsive' to={product.link || '#'}>
              <div
                onClick={
                  product.link
                    ? () => {}
                    : type === 'my' && product.alias ? () => this.props.history.push(`/my/machine/${product.alias}`): () => this.props.setProductType({ product: product })
                }
              >
                <CategoryTile
                  src={product?.image ? getImages(product.image) : ''}
                  alt={product ? product.name : ''}
                  tileTitle={product ? product.name : ''}
                  tileSubtitle={''}
                />
              </div>
            </Link>
          ))}
        </div>

        <Arrow
          src='/images/icons/arrow-down-gray.svg'
          arrowVisible={arrowsVisible}
          handleClick={() => this.nextPage()}
          className={classes.arrowDown}
        />
      </div>
    );
  }
}

export default connectTo(
  (state) => state,
  { setProductType },
  withStyles(styles)(withRouter(ProductsList))
);
