export default theme => ({
  defaultContent: {
    backgroundColor: theme.palette.secondary.lightTeal
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',
    zIndex: 1,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start"
  },

  cardContainer: {
    flex: 1,
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    alignContent: "flex-start",
    flexWrap: "wrap",
    color: "#000",
  },
  cardRow: {
    width: "100%",
    textAlign: "center",
    fontSize: "0.75em",
    marginBottom: '3px'
  },
  cardTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "15px",
    marginTop: "10px",
    fontSize: "0.75em"
  },
  column: {
    width: "43%"
  },
  cardHeader: {
    display: "flex",
    justifyContent: "flex-end",
  },
  footer: {
    display: 'flex',
    width: 'calc(100% - 10px)',
    margin: '1px 6px',
    justifyContent: 'space-between',
    alignItems: 'flex-end' 
  },
  rightBottomCorner: {
    position: 'absolute',
    width: '35px',
    height: '35px',
    borderTopLeftRadius: '35px',
    right: 0,
    bottom: 0,
    // hardcode. Im lazy
    backgroundColor: '#f0f4f5',
    zIndex: -1,
  },
  defaultRightBottomCorner: {
    backgroundColor: theme.palette.primary.white
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  customChekbox: {
    width: '20px', 
    height: '20px',
    color: theme.palette.secondary.darkTeal,
  },
  // arrowUp: {
  //   marginBottom: "10px",
  //   cursor: 'pointer'
  // },
  // arrowDown: {
  //   marginTop: "10px",
  //   cursor: 'pointer'
  // },
  newAddressContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 5px 5px 5px',
    width: '100%',
    cursor: 'pointer'
  },
  wrapper: {
    height: 'inherit',
  },
  image: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    bottom: 0,
    transition: 'all 0.5s ease'
  },

  arrowUp: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    height: 'max-content',
    width: 'max-content'
  },
  arrowDown: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
    width: 'max-content',
    height: 'max-content'
  },
  carousel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    overflow: 'hidden',
    height: '182px',
    position: 'relative'
  },
  img: {
    margin: '5px'
  },
  item: {
    width: '200px',
    height: '180px',

    position: 'absolute',
    alignItems: 'center',

    border: '1px solid #d8d5d5',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#ececec'
    }
  },
  hideLeft: {
    width: 0,
    left: 0,
    height: '130px'
  },
  hideRight: {
    width: 0,
    left: 400,
    height: '130px'
  },
  slider: {
    position: 'absolute',
    display: 'flex',
    width: '210px',
    height: '100%',
    transition: 'all 0.5s ease'
  }
});
