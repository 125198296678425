import React from 'react';

import styles from './style';
import {withStyles} from '@material-ui/core/styles';

import TableLarge from './TableLarge';
import TableSmall from './TableSmall';

class ProductsList extends React.Component {
    state = {
        width: 0,
        height: 0,
    };

    updateDimensions = () => {
        this.setState({height: window.innerHeight, width: window.innerWidth});
    };

    /* componentWillMount() {
      this.updateDimensions();
    } */

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const shouldShowLarge = this.state.width > 750;
        const {order} = this.props;
        return (
            <div>
                {shouldShowLarge ? (
                    <TableLarge order={order}/>
                ) : (
                    <TableSmall order={order}/>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(ProductsList);