import React from 'react';

import styles from './style';
import { connectTo } from '../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import { getArticle } from '../../actions/news';
import classNames from 'classnames';
import Page from '../page';

class SiteManagement extends React.Component {
  componentDidMount() {
    const alias = this.props.match.params.alias;
    this.props.getArticle({ alias });
  }

  render() {
    const { classes, article } = this.props;
    return (
      <Page>
        <div className='order-document main-content'>
          <div className={classes.content}>
            <div className={classNames(classes.title, 'bold')}>
              {article && article.title ? article.title : ''}
            </div>
            {article && article.image ? (
              <img
                alt={article.title}
                className={classes.image}
                src={article.image}
              />
            ) : null}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  article && article.description ? article.description : '',
              }}
            />
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    article: state.news.article,
  }),
  {
    getArticle,
  },
  withStyles(styles)(SiteManagement)
);
