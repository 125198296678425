import React from 'react';

import Button from '@material-ui/core/Button';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import {
  setRegistrationType
} from '../../../../actions/startpage';

const RegistrationChoose = props => {
  const { classes, t, fullScreen, setRegistrationType } = props;

  return (
    <div className={classes.content}>
      {!fullScreen ?
        <div className={classes.logoColumn}>
          <img
            alt="registraion"
            className={classes.img}
            height="100"
            width="100"
            src="/images/glyphs/svg/glyph-registration-green.svg#reg"
          />
        </div> : null
      }
      <div className={classes.mainColumn} style={{ width: !fullScreen ? '75%' : '100%' }}>
          {/*we set an 'existing' if selected --existing option, and 'new' if selected --new*/}
        <Button className={classes.button} classes={{ root: classes.chooseBtnRoot }} onClick={() => setRegistrationType('existing')}>
          {t('existingCustomer')}
        </Button>
          <Button className={classes.button} classes={{ root: classes.chooseBtnRoot }}  onClick={() => setRegistrationType('new')}>
              {t('newCustomer')}
          </Button>
      </div>
    </div>
  );
};

export default connectTo(
  state => ({
  }),
  {
      setRegistrationType
  },
  withNamespaces('startPage')(withStyles(styles)(RegistrationChoose))
);
