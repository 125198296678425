import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  getMachineByType,
  closeTypeModal,
  setMachinesByType
} from '../../../actions/products';

import { Link } from 'react-router-dom';

class TypeModal extends React.Component {
  state = {
    type: [],
    product: 0,
    types: [],
    isLastSelectOpen: false
  };

  componentDidMount() {
    if (this.props.type.children && this.props.type.children.length) {
      this.setState(prevState => ({
        types: [this.props.type],
        type: [-1]
      }));
    }

    this.props.setMachinesByType([]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type.alias !== this.props.type.alias) {
      if (this.props.type.children && this.props.type.children.length) {
        this.setState({
          types: [this.props.type],
          type: [-1]
        });
        this.props.setMachinesByType([]);
      } else if (this.props.type.children && !this.props.type.children.length) {
        this.setState({
          types: [],
          type: []
        });
        this.props.getMachineByType({
          type: this.props.type
        });
      } else {
        this.setState({
          types: [],
          type: []
        });
        this.props.setMachinesByType([]);
      }
    }
  }

  handleChange = (e, i) => {
    const type = this.state.type;
    type[i] = e.target.value;

    if (this.state.types[i].children[e.target.value].children.length) {
      this.setState(prevState => {
        return {
          types: [
            ...prevState.types.slice(0, i + 1),
            prevState.types[i].children[e.target.value]
          ],
          type: [...type.slice(0, i + 1), -1]
        };
      });
      this.props.setMachinesByType({ type: [] });
    } else {
      this.props.getMachineByType({
        type: this.state.types[i].children[e.target.value]
      });
      this.setState(prevState => {
        return {
          types: prevState.types.slice(0, i + 1),
          type: type.slice(0, i + 1)
        };
      });
    }
  };

  render() {
    const { classes, open, handleClose, t, machinesByType } = this.props;
    const { type, types } = this.state;
    const last = types.length - 1;

    return (
      <Dialog open={open} className="modal" onClose={() => handleClose()}>
        <div className={classNames(classes.paper, 'type-modal-paper')}>
          <div className={classes.imageSection}>
            <IconButton
                color='inherit'
                aria-label='Open drawer'
                className={classNames(
                    classes.closeIcon,
                )}
                onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <span className={classNames(classes.title, 'bold')}>{t('chooseMachines')}</span>
          </div>
          <div className={classes.otherSection}>
            {this.state.types.map((k, i) => (
              <div key={i} className={classes.inputRow}>
                <div>{k.name}</div>
                <Select
                  name="type"
                  value={this.state.type[i]}
                  className={classes.textInput}
                  onChange={e => this.handleChange(e, i)}
                >
                  <MenuItem disabled value={-1}>
                    {t('selectProduct')}
                  </MenuItem>
                  {k.children &&
                    k.children.length &&
                    k.children.map((v, i) => (
                      <MenuItem key={i} value={i}>
                        {v.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            ))}
            {machinesByType && machinesByType.length ? (
              <div className={classes.inputRow}>
                <div>
                  {/* last */}
                  {types[last] &&
                  types[last].children &&
                  types[last].children[type[last]]
                    ? types[last].children[type[last]].name
                    : null}
                </div>
                <Select
                  value={this.state.product}
                  onChange={e => this.setState({ product: e.target.value })}
                  name="product"
                  className={classes.textInput}
                >
                  {machinesByType &&
                    machinesByType.map((v, i) => (
                      <MenuItem key={i} value={i}>
                        {v.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            ) : null}

            {machinesByType && machinesByType.length ? (
              <div className={classes.buttonRow}>
                <Link
                  className="type-modal-button-container"
                  onClick={() => this.props.handleClose()}
                  to={
                    machinesByType && machinesByType[this.state.product]
                      ? `/machine/${machinesByType[this.state.product].alias}`
                      : '#'
                  }
                >
                  <Button
                    className={classNames(classes.button, 'type-modal-button')}
                  >
                    {t('openProduct')}
                  </Button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default connectTo(
  state => ({
    type: state.home.productType,
    machinesByType: state.home.machinesByType
  }),
  {
    getMachineByType,
    closeTypeModal,
    setMachinesByType
  },
  withNamespaces('homePage')(withRouter(withStyles(styles)(TypeModal)))
);
