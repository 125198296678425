export default (theme) => ({
  names: {
    marginTop: '20px',
    width: '100%',
  },
  smallHeader: {
    color: 'black',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  bigHeader: {
    color: 'black',
    fontSize: '2em',
    textTransform: 'uppercase',
  },
  underHeader: {
    margin: '10px 0 10px 0',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
});
