export default theme => ({
  panelHeader: {
    textTransform: 'uppercase'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  infoContainer: {
    flex: 3
  },
  cartContainer: {
    flex: 2,
    marginLeft: '30px'
  },
  button: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
    marginBottom: 20
  }
});
