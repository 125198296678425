export default (theme) => ({
  titleContent: {
    margin: '1.5em 1em',
    fontSize: '20px',
    width: '100%',
  },
  mainTitle: {
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '1.8em',
    textTransform: 'uppercase',
    color: theme.palette.primary.buttonColor,
  },
  subTitle: {
    margin: '20px auto',
    textAlign: 'center',
    fontSize: '1.1em',
    color: 'black',
  },
  listTitle: {
    marginLeft: '20px',
    fontSize: '28px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  primary: {
    color: theme.palette.primary.main,
  },
});
