import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';

const Line = props => {
  const { margin, classes, hasPointBottom } = props;
  return (
    <div style={{ marginLeft: margin }}>
      <div className={classes.line} />
      {hasPointBottom && <div className={classes.point} />}
    </div>
  );
};

export default withStyles(styles)(Line);
