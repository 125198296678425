import React from 'react';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../utils/generic';
import ShoppingCartSummary from '../ShoppingCartSummary';

const OrderCompleted = props => {
  const { classes, t, order } = props;

  return (
    <div className={classNames(classes.mainContainer, 'order-completed')}>
      <div className={classNames(classes.infoContainer, 'order-completed--info')}>
        <div>
          <div className={classNames(classes.resultHeader, 'bold')}>{t('thanks for order')}</div>
          <div className={classNames(classes.resultText, 'order-completed--text')}>
            {t('your order number is')}{' '}
            <span className={classNames(classes.orderId, 'bold')}>{order.orderId}.</span>{' '}
            {t('make ready')}.
          </div>
        </div>
        <Link to="/home">
          <Button className={classes.backButton}>{t('back to shop')}</Button>
        </Link>
      </div>

      <div className='order-completed--summary'>
        <ShoppingCartSummary order={order} />
      </div>
    </div>
  );
};

export default connectTo(
  state => ({
    order: state.cart.orderResult
  }),
  {},
  withNamespaces('checkoutPage')(withStyles(styles)(OrderCompleted))
);
