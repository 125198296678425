import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../../utils/localization';
import PriceRow from './PriceRow';
import PriceDiscount from "./PriceDiscount";
import classNames from "classnames";
import {DISCOUNT_VALUE_PERCENT} from "../../../../../constants/common";

const ShoppingCartSummary = (props) => {
  const { classes, t, order } = props;
  const hasShipping =
    order && order.shippingMethod && order.shippingMethod.usePrices;

  const voucherDiscount = order.discounts.filter(discount => discount.discount.valueType === DISCOUNT_VALUE_PERCENT)[0] || null;
  const totalDiscount = voucherDiscount?.discountAmount || 0;

  return (
    <div className={classNames(classes.priceContainer)}>
      <PriceDiscount order={order}/>
      <div className={classes.price}>
        <PriceRow title={t('productCost')} price={formatMoney(order.total)} />
        {hasShipping && (
          <PriceRow
            title={t('Shipping Cost')}
            price={formatMoney(order.shippingMethod.price)}
          />
        )}
        {order.paymentMethod ? (
          <PriceRow
            title={`${t('payment')} (${order.paymentMethod.name})`}
            price={null}
          />
        ) : null}
        <PriceRow
          title={`${t('taxValue')} (${order.tax}%)`}
          price={formatMoney(order.allTaxes)}
        />
        <hr />
        <PriceRow
          title={t('totalCost')}
          price={formatMoney(order.totalWithTaxAndShipping)}
          showAsterisk={
            !hasShipping &&
            order &&
            order.shippingMethod &&
            order.shippingMethod.description &&
            order.shippingMethod.description.length > 0
              ? true
              : false
          }
        />
        {totalDiscount > 0 && <div className={classes.discountDetails}>
              <span >{t('Total discount')} {voucherDiscount.discount.value}%</span>
              <div style={{width: '140px'}}>{formatMoney(totalDiscount)}</div>
          </div>}
      </div>

      {!hasShipping &&
      order &&
      order.shippingMethod &&
      order.shippingMethod.description &&
      order.shippingMethod.description.length > 0 ? (
        <div className={classes.descriptionField}>
          <span style={{ color: '#c03d13' }}>*&nbsp;</span>
          <div
            dangerouslySetInnerHTML={{
              __html: order.shippingMethod.description,
            }}
            className='shipping-description'
          />
        </div>
      ) : null}
    </div>
  );
};

export default withNamespaces('checkoutPage')(
  withStyles(styles)(ShoppingCartSummary)
);
