export default theme => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  money: {
    textAlign: 'right'
  },
  optionsRadioGroup: {
    alignItems: 'flex-start'
  },
  options: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
});