import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const CategoryTileStandardMachine = (props) => {

  const { classes, src, alt, tileTitle, deactivateBuyButton, productTypeAlias  } = props;


  const TileObject =({})=>{
      return( <div className={classNames(classes.tileContainer, !deactivateBuyButton && classes.cursor)} >
                <div className={classes.imageContainer}>
                      <img className={classes.tileImage} src={src && Array.isArray(src)? src.length > 0 ? src[0] : "/images/product_example_image.png"  : src ? src :"/images/product_example_image.png"} alt={alt} />
                </div>
                <span className={classes.titleText}>
                    {tileTitle && tileTitle.length > 45 ? tileTitle.substring(0, 45) + "..." : tileTitle }
                </span>
              </div>
      )}

  const ObjectWithLink = (props) =>{
    return (
    <Link to ={deactivateBuyButton ? `#` : `/content/standard-machines?category=${productTypeAlias}`} className={props.className}>
      <TileObject/>
    </Link>
    )}

    return (
    <>
     {!deactivateBuyButton ? (
         <ObjectWithLink />
      ) : (
        <ObjectWithLink onClick={`(event) => event.preventDefault()`} className={classes.deactivatedLink} />
      )}
    </>
  );
};
export default withStyles(styles)(React.memo(CategoryTileStandardMachine));
