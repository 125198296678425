import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';

import {
  getShippingOptions,
  addShippingOption,
} from '../../../../actions/cart';

import { formatMoney } from '../../../../utils/localization';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

class ShippingOptions extends React.Component {
  componentDidMount() {
    this.props.getShippingOptions();
  }

  render() {
    const { classes, options, option } = this.props;
    return (
      <div className={classes.group}>
        <RadioGroup
          className={classNames(classes.col, classes.optionsRadioGroup)}
          value={option && option.id ? option.id.toString() : ''}
          onChange={(e) => {
            this.props.addShippingOption({ option: e.target.value });
          }}
        >
          {options.map((o, i) => (
            <div key={i} className={classes.options}>
              <FormControlLabel
                key={i}
                checked={
                  option &&
                  option.id &&
                  o.id.toString() === option.id.toString()
                    ? true
                    : false
                }
                value={o.id.toString()}
                control={<Radio />}
                label={o.name}
              />
              {o && o.description.length > 0 ? (
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{ __html: o.description }}
                      className='shipping-description'
                    />
                  }
                  placement={'top-start'}
                  interactive
                  TransitionComponent={Fade}
                  enterDelay={300}
                  leaveDelay={300}
                  enterTouchDelay={100}
                  leaveTouchDelay={3000}
                  disableTouchListener={false}
                >
                  <ReactSVG
                    svgClassName='info-icon'
                    src={`/images/icons/Checkout/info-white-48dp.svg`}
                    alt='Info'
                  />
                </Tooltip>
              ) : null}
            </div>
          ))}
        </RadioGroup>

        <div className={classNames(classes.col, 'bold')}>
          {options.map((o, i) => (
            <div key={i} className={classes.money}>
              {o.price ? formatMoney(o.price) : ''}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    options: state.cart.shippingOptions,
    option: state.cart.shippingOption,
  }),
  {
    getShippingOptions,
    addShippingOption,
  },
  withStyles(styles)(ShippingOptions)
);
