import React from 'react';

import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import { withNamespaces } from 'react-i18next';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

import { setMyRole } from '../../../../actions/auth';
import { getServiceCustomers } from '../../../../actions/static';

class ServiceHeader extends React.Component {
  state = {
    customer: null,
    customerEmail: null,
    companyName: null,
    disableProceed: true,
  };
  componentDidMount() {
    this.props.getServiceCustomers();
    this.filterCustomers();
  }
  componentDidUpdate(prevProps) {
    const { serviceCustomers } = this.props;

    if (prevProps.serviceCustomers !== serviceCustomers) {
      this.filterCustomers();
    }
  }

  filterCustomers = () => {
    const { serviceCustomers } = this.props;
    const customerEmail = localStorage.getItem('service');
    let customer = null;
    if (customerEmail && serviceCustomers) {
      customer = serviceCustomers.filter((item) => {
        return item.user.email === customerEmail;
      });
    }
    this.setState((prevState) => {
      return {
        customer: customer[0],
        companyName: customer[0] ? customer[0].companyName : null,
      };
    });
  };

  handleCustomerChange = (customer) => {
    if (customer && customer.user && customer.user.email) {
      sessionStorage.setItem('currency', customer.currency);
      this.setState((prevState) => {
        return {
          customer,
          customerEmail: customer.user.email,
          disableProceed: false,
        };
      });
    }
  };

  handleProceed = () => {
    const { customerEmail, customer } = this.state;
    localStorage.setItem('service', customerEmail);
    sessionStorage.setItem('currency', customer.currency);
    window.location.replace(`/home`);
    this.props.setMyRole({ code: 'ROLE_USER' });
    this.setState((prevState) => {
      return {
        companyName: customer.companyName,
      };
    });
  };

  render() {
    const { t, classes, serviceCustomers } = this.props;
    const { companyName } = this.state;
    return (
      <div className={classes.topBar}>
        <div className={classNames('HolyGrail-content', 'main-content')}>
          <div className={classes.wrapper}>
            <div className={classes.topbarWrapper}>
              <div className={classes.link}>
                <span style={{ color: 'grey' }}>
                  {t('You are logged in as')}:
                </span>
                {companyName ? companyName : ''}
              </div>

              <div
                className={classes.autocompleteWrapper}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Autocomplete
                  className={classes.autocompleteWrapper}
                  getOptionLabel={(option) => option.companyName || ''}
                  getOptionSelected={(option, value) =>
                    option._code === value._code
                  }
                  id='combo-box-demo'
                  onChange={(e, value) =>
                    value ? this.handleCustomerChange(value) : null
                  }
                  options={serviceCustomers}
                  placeholder={t('customer')}
                  renderInput={(params) => (
                    <TextField {...params} label='' variant='outlined' />
                  )}
                  style={{ width: '100%', backgroundColor: 'white' }}
                />

                <Button
                  disabled={this.state.disableProceed}
                  className={classNames(
                    this.state.disableProceed
                      ? classes.buttonDisabled
                      : classes.button,
                    classes.proceedButton
                  )}
                  classes={{ root: classes.disabledOnHover }}
                  onClick={() => this.handleProceed()}
                >
                  {t('Proceed as user')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({ serviceCustomers: state.staticContent.serviceCustomers }),
  { setMyRole, getServiceCustomers },
  withNamespaces('startPage')(withStyles(styles)(ServiceHeader))
);
