export default (theme) => ({
  defaultContent: {
    backgroundColor: theme.palette.secondary.lightTeal,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',
    zIndex: 1,
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },

  cardContainer: {
    flex: 1,
    display: 'flex',
    // justifyContent: "center",
    // alignItems: "center",
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    color: '#000',
  },
  cardRow: {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.75em',
    marginBottom: '3px',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: '15px',
    marginTop: '10px',
    fontSize: '0.75em',
  },
  column: {
    width: '43%',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'flex',
    width: 'calc(100% - 10px)',
    margin: '1px 6px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  rightBottomCorner: {
    position: 'absolute',
    width: '35px',
    height: '35px',
    borderTopLeftRadius: '35px',
    right: 0,
    bottom: 0,
    // hardcode. Im lazy
    backgroundColor: '#f0f4f5',
    zIndex: -1,
  },
  defaultRightBottomCorner: {
    backgroundColor: theme.palette.primary.white,
  },
  checkboxLabel: {
    paddingTop: '1px',
    marginLeft: '7px',
    width: '73%',
    height: '20px',
    lineHeight: '10px',
    fontSize: '10px',
    color: theme.palette.secondary.darkTeal,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  customChekbox: {
    width: '20px',
    height: '20px',
    color: theme.palette.secondary.darkTeal,
  },
  arrowUp: {
    marginBottom: '10px',
    cursor: 'pointer',
  },
  arrowDown: {
    marginTop: '10px',
    cursor: 'pointer',
  },
  newAddressContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 5px 5px 5px',
    width: '100%',
    cursor: 'pointer',
  },
  newAddressLabel: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.darkTeal,
    margin: '10px',
  },
  wrapper: {
    height: 'inherit',
    width: '100%',
  },
  image: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    bottom: 0,
    transition: 'all 0.5s ease',
  },
  edit: {
    width: '30px',
    height: '30px',
  },
});
