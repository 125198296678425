import React from 'react';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { formatMoney } from '../../../../../utils/localization';

const ShoppingCartSummary = props => {
  const { classes, t, cart } = props;

  let subtotal = 0;
  if (cart.items && cart.items.length)
    subtotal = cart.items.reduce(
      (a, v) => a + v.quantity * (v.discountPrice || v.price),
      0
    );

  return (
    <div className={classes.priceContainer}>
      <div className={classes.priceRow}>
        <div>{t('subtotal')}: </div>
        <div className={classes.price}>{formatMoney(subtotal)}</div>
      </div>
      <div className={classes.info}>{t('cart:taxInfo')}</div>
    </div>
  );
};

export default withNamespaces(['checkoutPage', 'cart'])(
  withStyles(styles)(ShoppingCartSummary)
);
