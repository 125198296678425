import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import Arrow from '../Arrow';
import Product from './product';
import Pagination from './Pagination';

class Carousel extends React.Component {
  state = {
    pos: 0,
    arrowsVisibleLeft: false,
    arrowsVisibleRight: true,
    pageSize: 3
  };

  componentDidUpdate(prevProps) {
    if (prevProps.images.length !== this.props.images.length)
      if (this.props.images && this.props.images.length <= this.state.pageSize)
        this.setState({ arrowsVisibleRight: false });
      else 
        this.setState({ arrowsVisibleRight: true })
  }

  nextPage = () => {
    this.checkPosition(this.state.pos + 1);
    if (this.state.pos < this.props.images.length - 1)
      this.setState({ pos: this.state.pos + 1 });
  };

  prevPage = () => {
    this.checkPosition(this.state.pos - 1);
    if (this.state.pos > 0) this.setState({ pos: this.state.pos - 1 });
  };

  handleChangePage = page => {
    this.checkPosition(page);
    this.setState({ pos: page });
  };

  checkPosition = pos => {
    this.setState({
      arrowsVisibleLeft: pos > 0,
      arrowsVisibleRight: pos < this.props.images.length - this.state.pageSize
    });
  };

  render() {
    const { classes, images, color, arrowClass } = this.props;
    const { arrowsVisibleLeft, arrowsVisibleRight, pos } = this.state;
    return (
      <div>
        <div className={classes.carousel}>
          <Arrow
            src={`/images/icons/arrow-up${color && '-' + color}.svg`}
            arrowVisible={arrowsVisibleLeft}
            handleClick={() => this.prevPage()}
            className={classes.arrowUp}
            arrowClass={arrowClass}
          />

          <div className={classes.list}>
            <div
              className={classes.slider}
              style={{
                transform: `translateX(-${pos * 50}%)`,
                left: 0
              }}
            >
              {images.map((p, i) => (
                <Product
                  className={classNames(classes.item)}
                  style={{ left: `calc(${i * 50}%)` }}
                  key={i}
                  product={p}
                />
              ))}
            </div>
          </div>

          <Arrow
            src={`/images/icons/arrow-down${color && '-' + color}.svg`}
            arrowVisible={arrowsVisibleRight}
            handleClick={() => this.nextPage()}
            className={classes.arrowDown}
            arrowClass={arrowClass}
          />
        </div>

        <Pagination
          curPage={pos}
          totalPages={images.length - this.state.pageSize + 1}
          handleClick={this.handleChangePage}
          style={{
            width: '10px',
            height: '10px',
            borderWidth: '1px'
          }}
          wrapperStyle={{
            marginTop: '10px'
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Carousel);
