import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import { Link } from 'react-router-dom';
import NumberInput from '../../../common/NumberInput';

import classNames from 'classnames';
import styles from '../style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { connectTo } from '../../../../utils/generic';
import { formatMoney } from '../../../../utils/localization';
import {
  updateProduct,
  deleteProduct,
  updateEmptyProduct,
} from '../../../../actions/cart';
import {getShowDiscount} from "../../../../utils/discounts";
import {PRICE_TYPE_DISCOUNTED, PRICE_TYPE_INDIVIDUAL} from "../../../../constants/common";

class CartDetailsTableLarge extends React.Component {
  componentDidMount() {
  }
  render() {
    const {classes, t, cart, settings} = this.props;
    const showDiscount = getShowDiscount(settings)

    return (
      <div className={'machine-table'}>
        <div className={classes.tableTitle}>{t('cart')}</div>
        <div className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellCentred}>
                  {t('position')}
                </TableCell>
                <TableCell />
                <TableCell>{t('data')}</TableCell>
                {(showDiscount || this.props.cart.items.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL)) &&  <TableCell />}
                <TableCell>{t('productPrice')}</TableCell>
                <TableCell>{t('quantity')}</TableCell>
                <TableCell>{t('subtotal')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items.map((row, i) => {
                const rowPrice = row.discountPrice || row.price;
                return (
                  <TableRow className={classes.row} key={i}>
                    <TableCell className={classes.cellCentred}>
                      {i + 1}
                    </TableCell>
                    <TableCell>
                      <div
                        className={classNames(classes.circle, 'image-cropper')}
                      >
                        <img
                          alt={row.product.name}
                          src={
                            row.product.image && row.product.image.length
                              ? row.product.image[0].url
                              : '/images/product_example_image.png'
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell className={classes.nameCell}>
                      <Link
                        to={`/product/${row.product.alias}`}
                        className={classes.description}
                      >
                        <div
                          className={classNames(
                            classes.productName,
                            classes.descriptionRow
                          )}
                        >
                          {row.product.name}
                        </div>
                        <div
                          className={classNames(
                            classes.descriptionRow,
                            classes.sku
                          )}
                        >
                          {`${t('materialNumber')}: ${
                            row.product.code
                          }`}
                        </div>
                        {row.product.details?.shortDescription ? (
                          <div
                            className={classNames(
                              classes.detailsRow,
                              'long-column',
                              'long-descr'
                            )}
                            dangerouslySetInnerHTML={{
                              __html:
                                row.product.details.shortDescription,
                            }}
                          />
                        ) : null}
                      </Link>
                    </TableCell>

                    {(showDiscount || this.props.cart.items.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL)) &&
                    <TableCell>
                      {row.discountPrice ? (
                          <div className={classes.notDiscountPrice}>
                            {row.price > 0 && (showDiscount || row.priceType === PRICE_TYPE_INDIVIDUAL) && formatMoney(row.price)}
                          </div>
                      ) : null}
                    </TableCell>}

                    <TableCell>
                      {(row.discountPrice) ? (
                          <div>
                            <div
                                className={
                                    classNames(row.priceType === PRICE_TYPE_INDIVIDUAL && 'individualPrice' ,
                                    (showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED) ? 'discountPriceWithOriginalPrice' :'discountPrice'
                                )}
                            >
                            {rowPrice
                              ? formatMoney(rowPrice)
                              : t('requestPrice')}
                              {row.priceType ===PRICE_TYPE_INDIVIDUAL && '*'}
                          </div>
                          {(showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED) &&
                          <div className={classes.discount}>
                            {t('cart:discount')} {row.discountValue}%
                          </div>}
                        </div>
                      ) : rowPrice > 0 ? (
                        formatMoney(rowPrice)
                      ) : (
                        t('requestPrice')
                      )}
                    </TableCell>

                    <TableCell>
                      <NumberInput
                        value={row.quantity}
                        className={classes.quantity}
                        onChange={(val) => {
                          if (val !== 0) {
                            this.props.updateProduct({
                              code: row.code, //row.product ? row.product.sku : row.sku,
                              quantity: val,
                            });
                          } else {
                            this.props.updateEmptyProduct(
                              row.product.code
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {rowPrice > 0
                        ? formatMoney(rowPrice * row.quantity)
                        : t('requestPrice')}
                    </TableCell>
                    <TableCell>
                      <img
                        alt='cart'
                        className='icon-default clickable cart-icon deleteIcon'
                        onClick={() => {
                          this.props.deleteProduct({
                            code: row.code, //row.product ? row.product.sku : row.sku
                          });
                        }}
                        title={t('tooltips:delete')}
                        src='/updated_images/svg/bin 15px.svg'
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
export default connectTo(
  (state) => ({
    settings: state.staticContent.settings
  }),
  { updateProduct, deleteProduct, updateEmptyProduct  },
  withNamespaces(['checkoutPage', 'tooltips'])(withStyles(styles)(CartDetailsTableLarge))
);