import * as settings from "../settings";

const locales = {
  de: {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    name: 'DE',
    label: 'DE'
  },
  en: {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    name: 'GB',
    label: 'EN'
  },
  fr: {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    name: 'FR',
    label: 'FR'
  },
  pl: {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    name: 'PL',
    label: 'PL'
  },
  es: {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    name: 'ES',
    label: 'ES'
  },
};

export const finalLocales = () =>  {
  var fin = {};
  settings.default_locales.map(al => {
    fin[al] = {...locales[al]};
  })

  return fin;
}

export const lngList = () =>  {
  return Object.keys(finalLocales());
}

export const selectLng = () => {
  const lng = localStorage.getItem('i18nextLng');
  const queryParams = new URLSearchParams(window.location.search);
  const lngFromUrl = queryParams.get('lng');
  if(lngFromUrl)
  {
    if(isLocaleValid(lngFromUrl) && settings.default_locales.indexOf(lngFromUrl) > -1) {
      return lngFromUrl;
    }
  }
  if (lngList().indexOf(lng) > -1) return lng;
  else
  {
    if(isLocaleValid(settings.default_locale_language) && settings.default_locales.indexOf(settings.default_locale_language) > -1)
      return settings.default_locale_language;
    else
    {
      console.error("no default language "+settings.default_locale_language+" available in the list of locales ")
      return settings.default_locales[0];
    }
  }
};

export const getLocaleTimeString = (date) =>
  new Date(Date.parse(date)).toLocaleTimeString(selectLng());

export const getLocaleDateString = (date) =>
  new Date(Date.parse(date)).toLocaleDateString(selectLng());

export const getLocaleDateStringLong = (date) =>
  new Date(Date.parse(date)).toLocaleDateString(selectLng(), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const capitalizeFirstLetter = (s) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const parseLocaleFloat = (x) => {
  const options = finalLocales()[selectLng()].delimiters;

  // We can get 2 different spaces (with codes 32 and 160)
  // Space with code 160 (&nbsp;) is coming when field is autofilled
  let res = x
    .split(String.fromCharCode(160))
    .join(' ')
    .split(options.thousands)
    .join('');

  return parseFloat(res.split(options.decimal).join('.'));
};

export const bigNumber = (n) => (n < 99 ? n : '+');

const isLocaleValid = (locale) => (locale.length == 2);

export const formatMoney = (x, code) => {

  let cashSymbol = code;
  if (cashSymbol === null || cashSymbol === undefined)
    cashSymbol = sessionStorage.getItem('currency');
  if (!cashSymbol) cashSymbol = 'EUR';

  try {
    return (
        new Intl.NumberFormat(
            `${selectLng()}`,
            {
              style: 'currency',
              currency: cashSymbol,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
            .format(x)
    )
  }
  catch (e) {
    return (
        new Intl.NumberFormat(
            `${selectLng()}`,
            {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
            .format(x)
    )
  }
};
