import React from 'react';
import TopHeader from './TopHeader';
import BottomHeader from './BottomHeader';
import Drawer from './Drawer';
import {connectTo} from "../../../utils/generic";
import {withRouter} from "react-router";

class Header extends React.Component {
  state = {
    drawerOpen: false
  };


render() {
    const { token, location } = this.props
    return (
      <div>
        <Drawer
          drawerOpen={this.state.drawerOpen}
          handleDrawerClose={() => this.setState({ drawerOpen: false })}
        />

        <div className="header" style={{backgroundColor: !token && location.pathname ==='/' ? 'transparent':null}}>
          <TopHeader
            handleDrawerOpen={() => this.setState({ drawerOpen: true })}
            isDrawerOpen={this.state.drawerOpen}
          />

          <BottomHeader startPage={location.pathname ==='/'}/>
        </div>
      </div>
    );
  }
}

export default connectTo(
    (state) => ({
        token: state.auth.token,
    }),
    {},
    withRouter(Header)
)
