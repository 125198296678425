import React from "react";

import styles from "./style";
import { withNamespaces } from "react-i18next";
import { connectTo } from "../../../utils/generic";
import { withStyles } from "@material-ui/core/styles";
import { ResponsiveLine } from "@nivo/line";

import {
  getMachineSensorData
} from "../../../actions/products";

/*const data1 = [
  {
    id: "humidity",
    color: "hsl(124, 70%, 50%)",
    data: [
      {
        y: 60,
        x: "2020-02-03T10:11:38+00:00"
      },
      {
        y: 29,
        x: "2020-02-03T10:11:40+00:00"
      },
      {
        y: 37,
        x: "2020-02-03T10:11:50+00:00"
      },
      {
        y: 15,
        x: "2020-02-03T10:11:52+00:00"
      },
      {
        y: 49,
        x: "2020-02-03T10:11:55+00:00"
      },
      {
        y: 32,
        x: "2020-02-03T10:11:57+00:00"
      },
      {
        y: 10,
        x: "2020-02-03T10:11:58+00:00"
      },
      {
        y: 87,
        x: "2020-02-03T10:11:59+00:00"
      },
      {
        y: 37,
        x: "2020-02-03T10:12:00+00:00"
      }
    ]
  }
];*/

class VisualizeSensorData extends React.Component {
  componentDidMount() {
    this.intervalID = setInterval(() => this.getData(), 10000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedProduct &&
      this.props.selectedProduct.serialNumber &&
      prevProps.selectedProduct.serialNumber !== this.props.selectedProduct.serialNumber
    ) {
      this.props.getMachineSensorData({
        alias: this.props.product.product.alias,
        serialNumber: this.props.selectedProduct.serialNumber
      });
    }
  }
  getData() {
    this.props.getMachineSensorData({
      alias: this.props.product.product.alias,
      serialNumber: this.props.selectedProduct.serialNumber
    });
  }

  transformSensorData = (key, value) => {
    let data = value.map(item => {
      return { y: item.value, x: item.generatedAt };
    });
    let transformedSensorData = {
      id: key,
      color: "hsl(230, 70%, 50%)",
      data
    };
    return [transformedSensorData];
  };

  visualizeSensorData = data => {
    return (
      <ResponsiveLine
        data={data}
        width={700}
        height={600}
        margin={{ top: 50, right: 50, bottom: 140, left: 110 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false
        }}
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 7,
          tickRotation: -14,
          legend: "Date",
          legendOffset: 60,
          legendPosition: "middle"
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Value",
          legendOffset: -40,
          legendPosition: "middle"
        }}
        colors={{ scheme: "category10" }}
        pointSize={7}
        pointColor={{ theme: "background" }}
        pointBorderWidth={1}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableArea={false}
        areaOpacity={0.1}
        useMesh={true}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -31,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 74,
            itemHeight: 16,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    );
  };

  render() {
    const { /*classes, t,*/ sensorData } = this.props;

    return sensorData && Object.entries(sensorData).length > 0 ? (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {Object.entries(sensorData).map(([key, value]) => {
          let transformedSensorData = this.transformSensorData(key, value);
          return this.visualizeSensorData(transformedSensorData);
        })}
      </div>
    ) : null;
  }
}

export default connectTo(
  state => ({
    product: state.productHome.product,
    sensorData: state.productHome.sensorData,
    selectedProduct: state.productHome.selectedProduct
  }),
  { getMachineSensorData },
  withNamespaces("productPage")(withStyles(styles)(VisualizeSensorData))
);
