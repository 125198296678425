import './utils/compatibility';

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/css/index.css';
import './styles/css/fontello/css/fontello.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const VERSION_NAME = 'v0.8.2';

console.log(VERSION_NAME);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <App />
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);
//registerServiceWorker();
registerServiceWorker.unregister();