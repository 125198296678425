import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { getStickers } from '../../../actions/static';
import { connectTo } from '../../../utils/generic';

import { stickerImages, stickerColors } from '../../../constants/mock';
import { ClickAwayListener } from '@material-ui/core';

const Sticker = props => {
  const { classes, sticker } = props;

  return (
    <div className={classNames(classes.servicePaper, 'light', 'sticker-paper')}>
      <div className={classNames(classes.serviceHeader, 'bold')}>{sticker.title}</div>
      <div
        className={classNames(classes.serviceBody, 'sticker')}
        dangerouslySetInnerHTML={{ __html: sticker.description }}
      />
    </div>
  );
};

class StickySideBar extends React.Component {
  state = {
    stickersVisible: []
  };

  componentDidMount() {
    if(!this.props.stickers.length)this.props.getStickers();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.stickers &&
      state.stickersVisible.length !== props.stickers.length
    )
      return { stickersVisible: new Array(props.stickers.length).fill(false) };
    return state;
  }

  handleClickAway = e => {
    this.setState({
      stickersVisible: this.state.stickersVisible.fill(false)
    });
  };

  handleSelect = i => {
    let stickersVisible = this.state.stickersVisible;

    const wasVisible = stickersVisible[i];

    stickersVisible = stickersVisible.fill(false);
    stickersVisible[i] = !wasVisible;
    this.setState({ stickersVisible });
  };

  render() {
    const { classes, stickers } = this.props;

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className={classNames(classes.stickyBar, 'mui-fixed')} ref={node => (this.node = node)}>
          {stickers.map((v, i) =>
            this.state.stickersVisible[i] ? (
              <Sticker classes={classes} key={i} sticker={v} />
            ) : (
                ''
              )
          )}
          <div className={classes.icons}>
            {stickers.map((v, i) => (
              <div
                className={classNames(classes.item, 'sticker-item')}
                key={v.alias}
                style={{ backgroundColor: stickerColors[i] }}
              >
                <img
                  onClick={() => this.handleSelect(i)}
                  className={classNames(classes.img, 'sticker-image')}
                  src={v.image || stickerImages[i]}
                  alt={v.title}
                />
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default connectTo(
  state => ({
    stickers: state.staticContent.stickers
  }),
  { getStickers },
  withStyles(styles)(StickySideBar)
);
