import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from './MenuItem';
import Logo from '../TopHeader/Logo';
import * as settings from "../../../../settings";
import SearchBoxHeader from "../../SearchBoxHeader";

const BottomHeader = (props) => {
  const { t, token, classes, startPage } = props;

  return (
    <div className={classNames('md-bottomHeader', classes.bottomHeader)}>
        <div  className={classNames(classes.logoHeader, 'static-logo')}>
            <Logo token={token} />
        </div>
        <div
        className={classNames(
          classes.bottomHeaderContainer,
          'main-content main-content-header'
        )}
      >
        <div className='bottomContent'>
            <div className={classes.bottomRowContainer}>
{/*                <MenuItem
                    label={t('homePage:offers')}
                    link='/content/aktion'
                />
              {!startPage && <MenuItem
                  label={t('homePage:standard')}
                  link='/standard-machines-content'
              />}*/}

              <MenuItem
                    label={t('productPage:Spare Parts')}
                    link='/machines'
                />
              <MenuItem
                label={t('equipment')}
                link='/content/accessory'
              />

              {settings && settings.show_fanshop  ? <MenuItem
                label={t('fanshop')}
                link='/content/fanshop'
              /> : null}

              {(props.token || props.showPricesForGuest) &&  <MenuItem
                label={t('quickOrder')}
                link='/quickorder'
              />}
            </div>
        </div>
      </div>
        <div className='md-hide-searchbox' style= {{padding: "unset"}}>
            <SearchBoxHeader />
        </div>
    </div>
  );
};

export default connectTo(
  (state) => ({
    token: state.auth.token,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {},
  withNamespaces(['startPage', 'homePage', 'productPage'])(withStyles(styles)(BottomHeader))
);
