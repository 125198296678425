import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import CommonTooltip from '../Tooltip';

const SocialIcon = (props) => {
  const {
    classes,
    /*backgroundColor,*/ imageSource,
    link,
    isBlank,
    /*iconClass,*/
    name
  } = props;

  return (
      <CommonTooltip
          title={name}
          placement={'bottom'}
          enterDelay={100}
          leaveDelay={200}
          interactive={false}
          enterTouchDelay={100}
          leaveTouchDelay={3000}
          disableTouchListener={false}
          element={
            <a
                href={link || '#'}
                className={classNames(classes.socialButton, 'footer-social-icons')}
                target={isBlank ? '_blank' : ''}
            >
              <img src={imageSource} alt={name} className={classes.iconImage} />
              {/* <i className={iconClass}></i> */}
            </a>
          }
      />
  );
};

/*SocialIcon.propTypes = {
  backgroundColor: PropTypes.string,
  imageSource: PropTypes.string
};*/

export default withStyles(styles)(SocialIcon);
