export default (theme) => ({
  content: {
    margin: '40px 0 50px 0',
    textAlign: 'left',
    width: '100%',
  },
  title: {
    fontSize: '2.5em',
  },
});
