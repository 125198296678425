export const colors = [
  '#c03d13',
  '#8c2808',
  '#585858',
  '#857a76',
  '#857b93',
  '#4f4f4f',
  '#be3d15',
  '#a20171',
  '#126dc0',
  '#a20070',
];

export const orderListColumns = [
  {
    id: 'createdAt',
    disablePadding: true,
    label: 'Order Date',
    sortable: true,
  },
  {
    id: 'orderId',
    disablePadding: true,
    label: 'Order Number',
    sortable: true,
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Order Status',
    sortable: true,
  },
  {
    id: 'total',
    disablePadding: false,
    label: 'Order Sum',
    sortable: true,
  },
  {
    id: 'orderedBy',
    disablePadding: false,
    label: 'Ordered By',
    sortable: true,
  },
];

export const userManagementColumns = [
  { id: 'icon', numeric: false, disablePadding: true, label: '' },
  {
    id: 'userName',
    numeric: false,
    disablePadding: true,
    label: 'User',
  },
  {
    id: 'isEnabled',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'isAdmin',
    numeric: false,
    disablePadding: false,
    label: 'Type of User',
  },
  {
    id: 'cartLimit',
    numeric: false,
    disablePadding: false,
    label: 'Cart Limit',
  },
  {
    id: 'option',
    numeric: false,
    disablePadding: false,
    label: 'Options',
  },
];
