import React from 'react';

import styles from './style';
import classNames from 'classnames';
import {withNamespaces} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import {connectTo} from '../../utils/generic';
import {withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as settings from "../../settings";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Page from '../page';

import {toastr} from 'react-redux-toastr';

import {getArticles} from '../../actions/info';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import {selectLng} from "../../utils/localization";
import Header from "../../components/common/header/index";
import Grid from "@material-ui/core/Grid";
import CategoryTileHomePage from "../../components/home/CategoryTileHomePage";
import {GridItems} from "../../utils/showCategoryTiles";
import {notLogout} from "../../actions/auth";

class StartPage extends React.Component {
    componentDidMount() {
        const {t, token, location, role} = this.props;

        if (
            location &&
            location.state &&
            location.state.from &&
            !location.state.from.key
        ) {
            toastr.info(t('loginRequired'), t('pleaseLogIn'));
        }
        if (token && role === 'ROLE_SERVICE') this.props.history.replace('service');
        else if (token && role === 'ROLE_USER') this.props.history.replace('home');
        this.props.getArticles();
        !token && this.props.notLogout()
    }

    componentDidUpdate(prevProps) {
        const {t, token, role} = this.props;
        if (token && role === 'ROLE_SERVICE') this.props.history.replace('service');
        else if (token && role === 'ROLE_USER') {
            // here we check if we have from value and redirect to the proper page in case we access login page from the side link
            if (this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname != "/") {
                this.props.history.replace(this.props.location.state.from.pathname.substring(1));
            } else {
                this.props.history.replace("home")
            }
        }
        this.props.sessionExpired &&
        !prevProps.sessionExpired &&
        toastr.info(t('toastMessage:logout'));
    }

    changeLocation = (lng) => {
        settings.default_locations.map((a, i) => {
            if (a.country === lng) {
                window.location.href = a.url + '?lng=' + a.lng
            }
        })
    }

    currentLocation = () => {
        const url = window.location.origin;

        let result = settings.default_locations.find((a) => {
            if (a.url === url && a.lng === selectLng()) {
                return true
            }
        })
        //if we cant find the url with a proper language then we try to find first url
        if (!result) {
            result = settings.default_locations.find((a) => {
                if (a.url === url) {
                    return true
                }
            })
        }
        if (!result) {
            //take first country if still no results
            result = settings.default_locations[0]
        }
        return result
    }

    render() {
        const {t, classes, token} = this.props;
        const l = this.currentLocation()
        const customStyles = {
            backgroundImage: `url('${this.props.backgroundUrl}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'color',
            backgroundColor: 'rgb(0,0,0,0.7)',
            backgroundPosition: 'center'
        };

        const customHeader = (
            <div className={classNames(classes.pageHeader)}>
                <Header/>
            </div>
        )
        let itemsContent = GridItems()
        if (!token) {
            itemsContent = itemsContent.filter(item => item.linkTo !== '/quickorder' && item.linkTo !== '/standard-machines-content' && item.linkTo !== '/content/aktion')
        }
        return (
            <Page pageStyles={customStyles} pageHeader={customHeader} showStickyBar={false} additionalClass='LoginPage'>
                <div className={classNames('loginHolyGrail', classes.localContainer)}>
                    <div className={classNames(
                        'layout-section',
                        'login-section',
                        'loginStyle',
                        classes.background
                    )}
                         style={{
                             //  background: /`url('${this.props.backgroundUrl}') no-repeat`/,
                             background: 'transparent',
                         }}
                    >
                        <div className={classNames(classes.greetingContainer)}>
                            <div className={classNames(classes.greetingTitleContainer)}>
                                <div className={classNames('greeting-big-header', classes.contentTitle)}>
                                    {t('contentTitle')}
                                </div>
                            </div>

                            <div className={classNames(classes.greetingTextContainer)}>
                                <div className={classNames('greeting-demo-text')}>
                                    {t('firstStepsText')}
                                </div>
                            </div>

                            <div className={classNames(classes.greetingLoginContainer)}>
                                {/*<LoginCard className={classNames('greetingLoginCard', classes.customLoginCard)}/>*/}
                            </div>
                        </div>
                    </div>
                    <Grid container direction="row"
                          alignItems="center"
                          columns="3"
                          style={{maxWidth: '100%', alignSelf: 'center', marginBottom: 2}}
                    >
                        {itemsContent.map((item, index) => (
                            <Grid item={true} key={index}
                                  xs={12} sm={6}
                                  style={{border: '0.5px solid grey'}}
                            >
                                <Link to={item.linkTo} flexwrap="wrap">
                                    <CategoryTileHomePage
                                        src={item.src}
                                        alt={t(item.alt)}
                                        tileTitle={t(item.title)}
                                        tileSubtitle={''}
                                        startPage={true}
                                    />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>


                    <div className={classes.locationInformation}>
                        <div className={classes.locationText}>
                            {t('chooseYourRegion')}
                        </div>
                        <div className={classes.locationText}>
                            <Box
                                sx={{
                                    minWidth: 40,
                                    backgroundColor: 'black',
                                    /*backgroundColor: 'black',*/

                                    '& .MuiSelect-icon ': {
                                        color: 'white'

                                    },
                                    '& .MuiMenu-paper': {
                                        backgroundColor: 'black',
                                        color: 'white'
                                    },
                                    '& .MuiInput-input ': {
                                        backgroundColor: '#202020',
                                        borderColor: '#202020'
                                    },
                                    '& .Mui-focused ': {backgroundColor: 'black'}
                                }}
                            >
                                <FormControl>
                                    <Select
                                        onChange={e => this.changeLocation(e.target.value)}
                                        style={{width: '200px', color: 'white'}}
                                        IconComponent={KeyboardArrowDown}
                                        value={l.country}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    backgroundColor: '#202020',
                                                    borderColor: '#202020',
                                                    borderRight: 0,
                                                    color: 'white',
                                                    marginTop: '45px'
                                                },
                                            }
                                        }}
                                    >
                                        {settings.default_locations.map((a, i) => (
                                            <MenuItem value={a.country} key={a.country}>
                                                <div>
                                                    {t(a.country)}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default connectTo(
    (state) => ({
        token: state.auth.token,
        articles: state.start.articles,
        backgroundUrl: state.staticContent.backgroundUrl,
        sessionExpired: state.auth.sessionExpired,
        role: state.auth.role
    }),
    {
        getArticles,
        notLogout
    },
    withRouter(withNamespaces([
        'startPage',
        'toastMessage',
        'accountPage',
        'homePage',
        'orderPage',
        'productPage',])
    (withStyles(styles)(StartPage)))
);
