import React from 'react';

import { getPublicData } from '../../../actions/static';
import { connectTo } from '../../../utils/generic';

class PublicData extends React.Component {
  componentDidMount() {
    this.props.getPublicData();
  }

  render() {return(<div />);}
}

export default connectTo(
  state => ({}),
  { getPublicData },
  PublicData
);
