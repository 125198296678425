import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const CategoryTileHomePage = (props) => {

  const { classes, src, alt, tileTitle, startPage } = props;

  return (
      <div className={startPage?classes.tileContainerStart:classes.tileContainer}>
        <img className={startPage?classes.tileImageStart:classes.tileImage} src={src && Array.isArray(src)? src.length > 0 ? src[0] : "/images/product_example_image.png"  : src ? src :"/images/product_example_image.png"} alt={alt} />
        <div className={classes.titleContainer2}>
            <div className={classes.titleText}>
                {tileTitle && tileTitle.length > 45 ? tileTitle.substring(0, 45) + "..." : tileTitle }
            </div>
        </div>
      </div>
  );
};
export default withStyles(styles)(React.memo(CategoryTileHomePage));
