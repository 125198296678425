export default theme => ({
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.buttonColor
  },
  paymentMethod: {
    color: 'black'
  },
  header: {
    fontSize: '2em',
    margin: '5px',
    color: '#000',
    textAlign: 'center',
    marginTop: '20px'
  },
})