import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../utils/generic';
import { withNamespaces } from 'react-i18next';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider, Button } from '@material-ui/core';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import TechnicalData from '../../components/common/technicalData';

import * as actions from '../../actions/products';
import { getCategory } from '../../actions/listingPage';
import { addProduct } from '../../actions/cart';
import { formatMoney } from '../../utils/localization';
import { capitalizeFirstLetter } from '../../utils/localization';
import ReactSVG from 'react-svg';
import NumberInput from '../../components/common/NumberInput';
import {getShowDiscount} from "../../utils/discounts";
import showDeliveryTime from "../../utils/showDeliveryTime";
import {openLoginForm} from "../../actions/auth";
import {PRICE_TYPE_INDIVIDUAL} from "../../constants/common";
import QuizIcon from "@mui/icons-material/Quiz";
import {openInquiry, setInquiryProduct} from "../../actions/products";
import InquiryIconButton from "../../components/common/Inquiry/Button"

class ProductPage extends React.Component {
  state = {
    count: 1,
  };
  handleInquiry(product) {
    this.props.setInquiryProduct({product:{productName:  product.productName, productCode: product.productCode, quantity: product.quantity}})
    this.props.openInquiry()
  }
  componentDidMount() {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.push('/');
    const alias = this.props.match.params.id;
    this.props.getProduct({ alias: alias });

    const category = this.props.match.params.category;
    if (category) {
      this.props.getCategory({ alias: category });
    }
  }


  // may we need in auth component
  componentDidUpdate() {
      !(this.props.token || this.props.allowGuestUser) && this.props.history.push('/');
  }

  makeBread = () => {
    const { t, match, name, product } = this.props;
    return !match.params.parent
      ? [
          { name: t('home'), link: '/home' },
          match.params.category
            ? {
                name: capitalizeFirstLetter(name ? name : ''),
                link: `/content/${match.params.category}`,
              }
            : { name: t('standardMachines'), link: '/home#standard' },
          { name: product && product.code ? product.code : '', link: '#' },
        ]
      : [
          { name: t('home'), link: '/home' },
          { name: t('standardMachines'), link: '/home#standard' },
          {
            name: match.params.parent,
            link: `/machine/${match.params.parent}`,
          },
          { name: product && product.code ? product.code : '', link: '#' },
        ];
  };

  render() {
    const { t, product, classes, settings } = this.props;

    const showDiscount = getShowDiscount(settings)
    return (
      <Page isSearchBar={true}>

        <div
          className='layout-section home-section'
        >
          <BreadCrumbs links={this.makeBread()} />
        </div>

        {product.code ? (
          <div className={classNames('main-content', classes.container)}>
            <div className={classNames(classes.content, 'pdp-content')}>
                <img
                    alt={t('productPage:back')}
                    onClick={() => this.props.history.goBack()}
                    className={'back-icon'}
                    src={'/images/icons/back-icon-32.svg'}
                />
              <div className={classNames(classes.bigHeader, 'bold')}>
                {product.name}
              </div>
              <div className={classNames(classes.infoBar, 'info-bar')}>
                <div className='pdp-image-container'>
                  <img
                    className={classNames(classes.image, 'pdp-image')}
                    alt='product'
                    src={
                      product.image && product.image.length
                        ? product.image[0].url
                        : '/images/product_example_image.png'
                    }
                  />
                </div>
                <div
                  className={classNames(
                    classes.contentColumn,
                    'details-content-column'
                  )}
                >
                    <div className={classes.statusRow}>
                    <div className={classes.smallHeader}>
                      {t('materialNumber')}:
                    </div>
                    <div className={classes.matNumber}>{product.code}</div>
                        {this.props.token && <div
                      className={classNames(
                        product.isOrderable
                          ? (product.inStock
                            ? classes.goodStatus
                            : classes.limitedStatus)
                          : classes.badStatus,
                        'center-all',
                        'bold'
                      )}

                    >
                      <img
                        alt='status'
                        className={classes.statusImage}
                        src={product.isOrderable
                              ? (product.inStock
                                ? '/images/icons/icon-available.svg'
                                : '/images/icons/icon-available-limited.svg')
                              : '/images/icons/icon-not-available.svg'}
                      />
                      {
                      product.isOrderable
                        ? (product.discountPrice > 0 || product.price > 0) && showDeliveryTime(product.inStock, product.deliveryTime)

                          : t('notAvailable') }
                    </div>}
                  </div>

                  <div
                    className={classes.infoRow}
                    dangerouslySetInnerHTML={{
                      __html: `${
                        product.details ? product.details.shortDescription : ''
                      }`,
                    }}
                  />

                  <Divider className={classes.divider} />
                  { (this.props.token || this.props.showPricesForGuest) ? ((product.price || product.priceType === PRICE_TYPE_INDIVIDUAL) ? (
                    <div>
                      <div className={classes.buyInfoRow}>
                        <div>
                          <div className={classes.selectTitle}>
                            {t('amount')}
                          </div>
                          <NumberInput
                            value={this.state.count}
                            className={classes.amountInput}
                            onChange={(val) =>
                              this.setState({
                                count: val,
                              })
                            }
                          />
                        </div>
                        <div className={classes.priceColumn}>
                          {product.discountPrice > 0 ? (showDiscount || product.priceType === PRICE_TYPE_INDIVIDUAL)  ? (
                            <div className='pdp-price-column'>
                              <div
                                className={classNames(
                                  classes.price,
                                  classes.discountPrice,
                                  'bold'
                                )}
                              >
                                {product.price > 0 && formatMoney(this.state.count * product.price)}
                              </div>
                              <div
                                className={classNames(classes.price, 'bold')}
                              >
                                {formatMoney(
                                  this.state.count * product.discountPrice
                                )}
                                  {product.priceType === PRICE_TYPE_INDIVIDUAL && '*'}
                              </div>
                             </div>
                          ) : (
                            <div className={classNames(classes.price, 'bold')}
                                 style = {{color: (!showDiscount  && product.priceType !== PRICE_TYPE_INDIVIDUAL) &&'black'}}>
                                {formatMoney(this.state.count * product.discountPrice)}
                            </div>
                          ) : (
                              <div className={classNames(classes.originalPrice, 'bold')}>
                                  {product.price > 0 && formatMoney(this.state.count * product.price)}
                              </div>)
                          }
                          <div className={classes.address}>{t('cart:taxInfo')}</div>
                        </div>
                      </div>
                      <div className={classes.buttonRow}>
                        <Button
                          className={classNames(
                            classes.buyButton,
                            product.isOrderable ? '' : classes.buyButtonDisabled
                          )}
                          classes={{
                            root: classes.buttonRoot,
                          }}
                          onClick={ ()=> (//this.props.token ?
                            this.props.addProduct({
                              code: product.product
                                ? product.product.code
                                : product.code,
                              quantity: this.state.count,
                            })/*:
                              this.props.openLoginForm({centered:true})*/
                          )}
                        >
                            <ReactSVG
                                alt='Add To Cart'
                                svgClassName={
                                    product.isOrderable
                                        ?
                                        classNames(classes.cartIcon)
                                        :
                                        classNames(classes.cartIcon, 'colored-gray')
                                }
                                src='/updated_images/svg/cart filled 15px.svg'
                            />
                          {t('cart:intoCart')}
                        </Button>
                      </div>
                    </div>
                  ) : (


                        <div className={classNames(classes.price, 'bold')}>
                          {t('requestPrice')}
                        </div>

                  )):
                      (
                          <div>
                            <div className={classNames(classes.price, 'bold')}>
                              <div className={classes.buyInfoRow}>
                                <div>
                                  <div className={classes.selectTitle}>
                                    {t('amount')}
                                  </div>
                                  <NumberInput
                                      value={this.state.count}
                                      className={classes.amountInput}
                                      onChange={(val) =>
                                          this.setState({
                                            count: val,
                                          })
                                      }
                                  />
                                </div>
                                {t('guestPrice')}
                              </div>
                          </div>
                            <div className={classes.buttonRow}>
                      <InquiryIconButton
                        onClick={ ()=> {
                          this.handleInquiry({productCode: product.code, productName: product.name, quantity: this.state.count})
                        }}
                        />
                        </div>
                      </div>)}
                </div>
              </div>
              <div className={classes.tabs}>
                {product.details && product.details.longDescription ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className='title-exapnsion-panel'>
                        {t('details')}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.details.longDescription,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className='title-exapnsion-panel'>
                      {t('technicalData')}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0' }}>
                    <TechnicalData detail={product} />
                  </AccordionDetails>
                </Accordion>
                {product.document && product.document.length ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className='title-exapnsion-panel'>
                        {t('document')}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.documents}>
                      {product.document.map((d, i) => (
                        <div key={i}>
                          <div
                            className={classNames(
                              classes.document,
                              'clickable'
                            )}
                            onClick={() =>
                              this.props.getDocument({
                                url: d.url,
                                name: d.title,
                              })
                            }
                          >
                            {d.title}
                          </div>
                          <div>{d.description}</div>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </div>
                {product.priceType === PRICE_TYPE_INDIVIDUAL &&
                <div className= 'footNote'>* {t('checkoutPage:No Further Discount Applied')}</div>
                }

            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    product: state.productHome.product,
    backgroundUrl: state.staticContent.backgroundUrl,
    name: state.listingPage.category.name,
    settings: state.staticContent.settings,
    allowGuestUser: state.staticContent.allowGuestUser,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  { ...actions, addProduct, getCategory, openLoginForm,openInquiry,setInquiryProduct},
  withNamespaces(['productPage', 'cart', 'checkoutPage'])(
    withStyles(styles)(ProductPage)
  )
);
