export default (theme) => ({
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '20px 0',
  },
  priceValue: {
    fontSize: '1.5em',
    marginLeft: '5px',
    width: '140px',
    color: 'black',
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    textAlign: 'right',
  },
  taxText: {
    fontSize: '0.8em',
  },
  priceText: {
    color: 'black',
  },
  descriptionField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
