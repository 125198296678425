import React from 'react';

import styles from './style';
import {withNamespaces} from 'react-i18next';
import {connectTo} from '../../../utils/generic';
import {withStyles} from '@material-ui/core/styles';
import {formatMoney} from '../../../utils/localization';
import classNames from 'classnames';
import {getCartItemDiscountDetails} from "../../../utils/discounts";

const ShippingCartSummary = props => {
    const {classes, t, order} = props;
    const itemsQuantity = order.items.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0);

    return (
        <div className={classNames(classes.container, 'light')}>
            <div className={classNames(classes.header, 'bold')}>{t('orderSummary')}</div>
            <div className={classes.rows}>
                <div className={classNames(classes.subHeader, 'medium')}>
                    {itemsQuantity} {t('items')}
                </div>
                <div className={classes.items}>
                    {order.items.map((p, i) => {
                        const {rowPrice} = getCartItemDiscountDetails(p, false);
                        return (
                            <div className={classes.item} key={i}>
                                <div>
                                    {p.quantity} x {p.name}
                                </div>
                                <div className={classes.price}>
                                    {formatMoney(rowPrice)}
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className={classNames(classes.cartSummary, 'medium')}>
                    <div>{t('subTotal')}</div>
                    <div>{formatMoney(order.total)}</div>
                </div>
                <div className={classes.rowTax}>
                    <div>
                        {t('shippingCost')}{' '}
                        {order.shippingMethod ? `(${order.shippingMethod.name})` : ''}
                    </div>
                    <div>
                        {formatMoney(order.shippingMethod ? order.shippingMethod.price : 0)}
                    </div>
                </div>
                <div className={classes.rowTax}>
                    <div>
                        {t('payment')}{' '}
                        {order.paymentMethod ? `(${order.paymentMethod.name})` : ''}
                    </div>
                    <div/>
                </div>
                <div className={classes.rowMainTax}>
                    <div>{`${t('tax')}: (${order.tax}%)`}</div>
                    <div>{formatMoney(order.totalTax)}</div>
                </div>
                <div className={classNames(classes.rowResult, 'bold')}>
                    <div>{t('ordersum')}</div>
                    <div>{formatMoney(order.totalWithTaxAndShipping)}</div>
                </div>
            </div>
        </div>
    );
};

export default connectTo(
    state => ({
        //cart: state.cart.cart
    }),
    {},
    withNamespaces('checkoutPage')(withStyles(styles)(ShippingCartSummary))
);