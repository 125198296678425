import React from 'react';

import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

import TextField from '@material-ui/core/TextField';

import InputWrapper from '../../../common/InputTitleWrapper';

const ContactsDisabled = (props) => {
  const { classes, t, contact, titles } = props;
  const selectedTitle = contact.title ? titles.find(item => item.code === contact.title) : null

  return (
    <div>
      <InputWrapper
        headerClass={classes.smallTitle}
        header={selectedTitle ? selectedTitle.name : ''}
        isRequire={true}
      >
        <TextField
          name='firstName'
          value={contact.firstName + ' ' + contact.lastName || ''}
          className={classes.textField}
          disabled
        />
      </InputWrapper>

      <InputWrapper header={t('phone')} headerClass={classes.smallTitle}>
        <TextField
          name='phone'
          value={contact.phone || ''}
          className={classes.textField}
          disabled
        />
      </InputWrapper>
      <InputWrapper header={t('mobile')} headerClass={classes.smallTitle}>
        <TextField
          name='mobile'
          value={contact.mobile || ''}
          className={classes.textField}
          disabled
        />
      </InputWrapper>
    </div>
  );
};

export default withNamespaces('accountPage')(
  withStyles(styles)(ContactsDisabled)
);
