import React from 'react';
import ReactFlagsSelect from 'react-flags-select';

import i18n from '../../../../i18n';
import { finalLocales } from '../../../../utils/localization';

let langs = {},
    langsInverse = {},
    customLabels = {};
const fLocales = finalLocales()


const Flags = props => {
  const { t } = props;

  Object.keys(fLocales).forEach(key => {
    const langName = fLocales[key].name;

    langs[key] = langName;
    langsInverse[langName] = key;
    customLabels[langName] = t(fLocales[key].label);
  });

  return (
    <ReactFlagsSelect
      selected={langs[i18n.language]}
      countries={Object.values(langs)}
      customLabels={customLabels}
      onSelect={lang => {
          i18n.changeLanguage(langsInverse[lang]);
          localStorage.setItem('i18nextLng', langsInverse[lang]);
          window.location.reload();
      }}
      className="lang-select"
      selectButtonClassName="lang-selected"
      selectedSize={"1em"}
      optionsSize={14}
     />
  );
};

export default React.memo(Flags, () => true);