import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const Product = props => {
  const { classes, product } = props;

  return (
    <div
      className={props.className}
      style={{
        ...props.style,
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${product.image}') no-repeat 0% 0% / 100%`
      }}
      onClick={() => product.handleClick()}
    >
      <div className={classes.name}>{product.name}</div>
    </div>
  );
};

export default withStyles(styles)(Product);
