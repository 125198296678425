import { createReducer } from 'redux-act';
import * as a from '../actions/products';

const DEFAULT_STATE = {
  userProducts: [],
  standardProducts: [],
  productType: {},
  machinesByType: [],
  isOpenTypeModal: false,
  foundProducts: [],
  carouselProducts: []
};

export default createReducer(
  {
    [a.setIndividualProduct]: (state, { products }) => ({
      ...state,
      userProducts: products
    }),
    [a.setProductType]: (state, { product }) => ({
      ...state,
      productType: product,
      isOpenTypeModal: true
    }),
    [a.closeTypeModal]: state => ({
      ...state,
      isOpenTypeModal: false
    }),
    [a.setMachinesByType]: (state, products) => ({
      ...state,
      machinesByType: products,
    }),
    [a.addMyProductToTypes]: (state, { products }) => ({
      ...state,
      // carouselProducts: state.carouselProducts.concat(products)

    }),
    

    [a.setProductTypes]: (state, { product }) => {
      return {
        ...state,
        standardProducts: product,
        // carouselProducts: state.carouselProducts.concat(product)

      };
    },
    // [a.setStandardProducts]: (state, { products }) => ({
    //   ...state,
    //   standardProducts: products
    // })
  },
  DEFAULT_STATE
);
