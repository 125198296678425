import { put } from 'redux-saga/effects';

import {
  GET_STICKERS,
  GET_SETTINGS,
  GET_MENU_BY_ID,
  PUBLICDATA,
  GET_SOCIAL,
  SERVICE_CUSTOMERS,
} from '../constants/api';

import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';
import {
  setStickers,
  setFooter,
  setSettings,
  setBackground,
  setSocialLinks,
  setServiceCustomers,
  setReCaptchaSiteKey,
  setAllowGuestUser,
  setShowPricesForGuest
} from '../actions/static';
import {lngList} from '../utils/localization';

export function* getStickers() {
  try {
    const stickers = yield callHttp(get, GET_STICKERS);

    yield put(setStickers(stickers));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMenu() {
  try {
    const settings = yield callHttp(get, GET_SETTINGS);
    let footer = settings.find(
      (x) => x.name === 'footer_menu' && x.value !== null
    );

    if (footer) {
      footer = yield callHttp(get, GET_MENU_BY_ID(footer.value));
      yield put(setFooter(footer));
    }
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getSettings() {
  try {
    const settings = yield callHttp(get, GET_SETTINGS);

    //set reCaptcha site key
    let key = settings.find((x) => x.name === 'recaptcha_site_key');
    if (key && key.value) {
      yield put(setReCaptchaSiteKey(key.value));
    }

    let guestUser = settings.find((x) => x.name === 'allow_guest_access');
    if (guestUser && guestUser.value) {
      yield put(setAllowGuestUser(guestUser.value));
    }

    let guestAccess = settings.find((x) => x.name === 'show_prices_for_guest');
    if (guestAccess && guestAccess.value) {
      yield put(setShowPricesForGuest(guestAccess.value));
    }

    if(!localStorage.getItem('token')) {
      let defaultCurrency = settings.find((x) => x.name === 'default_currency')?.value;
      if(defaultCurrency)
      {
        sessionStorage.setItem('currency', defaultCurrency)
      }
    }


    yield put(setSettings(settings));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getBackground() {
  try {
    const settings = yield callHttp(get, GET_SETTINGS);
    let background = settings.find((x) => x.name === 'background_image_url');

    if (background && background.value) {
      yield put(setBackground(background.value));
    }
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getPublicData() {
  const publicData = yield callHttp(get, PUBLICDATA);

  localStorage.setItem('cacheBuild', publicData.cacheBuild);

  if (localStorage.getItem('translationsBuild')) {
    if (
      localStorage.getItem('translationsBuild') !== publicData.translationBuild
    ) {
      localStorage.removeItem('translationsBuild');
      lngList().forEach(lng => localStorage.removeItem(lng + '_cache'));
    }
  } else {
    localStorage.setItem('translationsBuild', publicData.translationBuild);
  }
}

export function* getSocialLinks() {
  try {
    const social = yield callHttp(get, GET_SOCIAL);
    yield put(setSocialLinks(social));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getServiceCustomers() {
  try {
    const customers = yield callHttp(get, SERVICE_CUSTOMERS);
    yield put(setServiceCustomers(customers));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
