import { put } from 'redux-saga/effects';

import {
  PRODUCTS,
  MYMACHINES,
  PRODUCT,
  MACHINE,
  MYMACHINE,
  MYMACHINESENSORDATA,
  PRODUCTTYPE,
  MACHINEBYTYPE, INQUIRY_PRODUCT,
} from '../constants/api';

import {
  setIndividualProduct,
  addMyProductToTypes,
  setStandardProducts,
  setProductToTree,
  setProductTypes,
  setMachinesByType,
  setMachineSensorData,
  setProduct,
  setImages,
  setImage,
  selectProduct, setInquiryProduct, closeInquiry,
} from '../actions/products';
import { callHttp, callHttpWithoutSpinner } from '../utils/api';
import {get, getBlob, post} from '../utils/httpUtil';
import {openErrorSnack, openSuccessSnack} from '../actions/startpage';
import i18next from "../config/i18nconfig";
// import { fileDownload } from 'js-file-download';

export function* getStandardProducts() {
  try {
    const products = yield callHttp(get, PRODUCTS);
    yield put(setStandardProducts({ products: products }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getIndividualProduct() {
  try {
    const products = yield callHttp(get, MYMACHINES);
    yield put(setIndividualProduct({ products: products }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMyProductToTypes() {
  try {
    const products = yield callHttp(get, MYMACHINES);
    yield put(addMyProductToTypes({ products: products }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getProduct({ payload }) {
  try {
    yield put(setImages({ images: [] }));
    yield put(setProduct({ product: {} }));
    const { alias } = payload;
    const product = yield callHttp(get, PRODUCT(alias));
    yield put(setProduct({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMachine({ payload }) {
  try {
    yield put(setImages({ images: [] }));
    yield put(setProduct({ product: {} }));
    const { alias } = payload;
    const product = yield callHttp(get, MACHINE(alias));
    yield put(setProduct({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMachineSensorData({ payload }) {
  try {
    const { alias, serialNumber } = payload;
    const sensorData = yield callHttpWithoutSpinner(
      get,
      MYMACHINESENSORDATA(alias, serialNumber)
    );
    yield put(setMachineSensorData(sensorData));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getProductTypes() {
  try {
    const product = yield callHttp(get, PRODUCTTYPE);
    yield put(setProductTypes({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMachineByType({ payload }) {
  try {
    const { type } = payload;
    const product = yield callHttp(get, MACHINEBYTYPE(type.alias));
    yield put(setMachinesByType(product));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getProductToTree({ payload }) {
  try {
    const { alias } = payload;

    const product = yield callHttp(get, PRODUCT(alias));

    yield put(setProductToTree({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getSelectedProduct({ payload }) {
  try {
    const { alias } = payload;

    const product = yield callHttp(get, PRODUCT(alias));

    yield put(selectProduct({ product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

/*export function* getMySelectedProduct({ payload }) {
  try {
    const { sku } = payload;

    const product = yield callHttp(get, MYPRODUCT(sku));

    yield put(selectProduct({ product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMyProductToTree({ payload }) {
  try {
    const { sku } = payload;
    const product = yield callHttp(get, MYPRODUCT(sku));

    yield put(setProductToTree({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}*/

export function* getMyProduct({ payload }) {
  try {
    yield put(setImages({ images: [] }));
    yield put(setProduct({ product: {} }));
    const { alias } = payload;

    const product = yield callHttp(get, MYMACHINE(alias));
    yield put(setProduct({ product: product }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getImage({ payload }) {
  try {
    const { url } = payload;
    const image = yield callHttp(getBlob, url);

    yield put(setImage({ image: image }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getImages({ payload }) {
  try {
    const { schemas } = payload;
    let newSchemas = [];

    for (let i = 0; i < schemas.length; i++) {
      let schema = schemas[i];
      const image = yield callHttp(getBlob, schema.url);
      schema.url = image;
      newSchemas.push(schema);
    }

    yield put(setImages({ images: newSchemas }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

const fileDownload = (url, name) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = url;
  link.setAttribute('download', name);
  link.click();
  document.body.removeChild(link);
};

export function* getDocument({ payload }) {
  try {
    const { url } = payload;
    const docs = yield callHttp(getBlob, url);
    const parts = url.split('/');
    const name = parts[parts.length - 1];
    fileDownload(docs, name);
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* sendInquiryProduct({ payload }) {
  try {
    const { product } = payload;
    const response = yield callHttp(post, INQUIRY_PRODUCT, payload);
    yield put(setInquiryProduct({}));
    yield put(closeInquiry());
    yield put(openSuccessSnack(i18next.t('messages:sentInquiry')));

  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}