
 export function GridItems () {
     var itemsContent = [
         {
             linkTo: '/content/aktion',
             src: '/images/homePage/aktion.jpg',
             alt: 'homePage:offers',
             title: 'homePage:offers',
         },
         {
             linkTo: '/standard-machines-content',
             src: '/updated_images/homePage/kachel-maschinenuebersicht-1-resized.jpg',
             alt: 'homePage:standardCategory',
             title: 'homePage:standardCategory',
         },
         {
             linkTo: '/machines',
             src: '/images/homePage/spare_parts.jpg',
             alt: 'productPage:Spare Parts',
             title: 'productPage:Spare Parts',
         },
         {
           linkTo: '/content/accessory',
           src: '/updated_images/homePage/kachel-zubehoer-1-resized.jpg',
           alt: 'startPage:equipment',
           title: 'startPage:equipment',
         },
         {
             linkTo: '/quickorder',
             src: '/images/homePage/quickorder.jpg',
             alt: 'orderPage:quickOrderCategory',
             title: 'orderPage:quickOrderCategory',
         },

     ];

     return itemsContent
 }


