import React from 'react';

import { Button } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../../utils/generic';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  setShippingAddressInput,
  setShippingTitle,
  setShippingCountry,
} from '../../../../actions/address';
import { openErrorSnack } from '../../../../actions/startpage';

import InputWrapper from '../../../../components/common/InputTitleWrapper/index';

class ShippingModal extends React.Component {
  state = {
    countries: [],
    country: {},
  };

  componentDidMount() {
    const { countries } = this.props;

    if (countries) {
      this.setState((prevState) => {
        return {
          countries,
        };
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { countries, address } = this.props;

    if (prevProps.countries !== countries) {
      this.setState((prevState) => {
        return {
          countries,
        };
      });
    }
    if (address !== prevProps.address) {
      if (address.country) {
        this.setState((prevState) => {
          return {
            country: address.country,
          };
        });
      } else {
        this.setState((prevState) => {
          return { country: {} };
        });
      }
    }
  }

  validateForm = () => {
    let message = '';

    if (!this.props.address.companyName) {
      message = this.props.t('Validation Messages:Company Name is Required!');
    } else if (!this.props.address.streetName) {
      message = this.props.t('Validation Messages:Street Name is Required!');
    } /*else if (!this.props.address.streetNumber) {
      message = this.props.t('Validation Messages:Street Number is Required!');
    }*/ else if (!this.props.address.postalCode) {
      message = this.props.t('Validation Messages:Postal Code is Required!');
    } else if (!this.props.address.city) {
      message = this.props.t('Validation Messages:City is Required!');
    } else if (!this.props.address.country) {
      message = this.props.t('Validation Messages:Country is Required!');
    } else {
      return true;
    }
    this.props.openErrorSnack({ message: message });
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.submitForm();
  };

  submitForm = () => {
    this.validateForm() && this.props.handleSubmit();
  };

  setShippingCountry = (country) => {
    this.props.setShippingCountry(country);
    this.setState((prevState) => {
      return {
        country,
      };
    });
  };

  render() {
    const {
      classes,
      open,
      handleClose,
      t,
      title,
      titles,
      address,
      fullScreen,
    } = this.props;
    const { countries, country } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        className='modal'
        onClose={() => handleClose()}
      >
        <form className={classes.shippingModal} onSubmit={this.handleSubmit}>
          <div style={{ width: '100%' }}>
            <div className={classNames(classes.modalHeader, 'bold')}>
              <div className={classes.headerContent}>{title}</div>
              <div
                style={{ padding: 10, cursor: 'pointer' }}
                onClick={() => handleClose()}
              >
                <img
                  alt='close'
                  height='20'
                  width='20'
                  src='/images/icons/X-black.png'
                />
              </div>
            </div>
          </div>
          <DialogContent>
            <div className={classes.contentRow}>
              <RadioGroup
                name='title'
                className={classes.group}
                value={(address && address.title) || ''}
                onChange={(e) => this.props.setShippingTitle(e.target.value)}
              >
                {titles &&
                    titles.map((v, i) => (
                    <FormControlLabel
                      key={v.code}
                      value={v.code}
                      control={<Radio />}
                      label={v.name}
                    />
                  ))}
              </RadioGroup>
            </div>

            <InputWrapper header={t('firstName')} isRequire={false}>
              <TextField
                name='firstName'
                className={classes.textField}
                value={(address && address.firstName) || ''}
                onChange={(e) => this.props.setShippingAddressInput(e.target)}
              />
            </InputWrapper>

            <InputWrapper header={t('secondName')} isRequire={false}>
              <TextField
                name='lastName'
                className={classes.textField}
                value={(address && address.lastName) || ''}
                onChange={(e) => this.props.setShippingAddressInput(e.target)}
              />
            </InputWrapper>

            <InputWrapper header={t('company')} isRequire={true}>
              <TextField
                name='companyName'
                className={classes.textField}
                value={(address && address.companyName) || ''}
                onChange={(e) => this.props.setShippingAddressInput(e.target)}
              />
            </InputWrapper>

            <InputWrapper header={t('additional')}>
              <TextField
                name='additional'
                className={classes.textField}
                value={(address && address.additional) || ''}
                onChange={(e) => this.props.setShippingAddressInput(e.target)}
              />
            </InputWrapper>

            <div className='multi-field-row'>
              <InputWrapper
                header={t('street')}
                isRequire={true}
                style={{ flex: 5 }}
              >
                <TextField
                  name='streetName'
                  value={this.props.address.streetName || ''}
                  className={classes.textField}
                  onChange={(e) => this.props.setShippingAddressInput(e.target)}
                />
              </InputWrapper>
              <InputWrapper
                header={t('no')}
                isRequire={false}
                style={{ flex: 2 }}
              >
                <TextField
                  name='streetNumber'
                  value={this.props.address.streetNumber || ''}
                  className={classes.textField}
                  onChange={(e) => this.props.setShippingAddressInput(e.target)}
                />
              </InputWrapper>
            </div>
            <div className='multi-field-row'>
              <InputWrapper
                header={t('postalCode')}
                isRequire={true}
                style={{ flex: 2 }}
              >
                <TextField
                  name='postalCode'
                  value={this.props.address.postalCode || ''}
                  onChange={(e) => this.props.setShippingAddressInput(e.target)}
                  className={classNames(classes.textField, 'disable-arrows')}
                  inputProps={{ maxLength: '16' }}
                />
              </InputWrapper>
              <InputWrapper
                header={t('city')}
                isRequire={true}
                style={{ flex: 5 }}
              >
                <TextField
                  name='city'
                  value={this.props.address.city || ''}
                  onChange={(e) => this.props.setShippingAddressInput(e.target)}
                  className={classes.textField}
                />
              </InputWrapper>
            </div>

            <InputWrapper isRequire={true} header={t('country')}>
              <Autocomplete
                className={classes.textField}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option._id === value._id}
                id='combo-box-demo'
                onChange={(e, value) =>
                  value ? this.setShippingCountry(value) : null
                }
                options={countries}
                placeholder={t('country')}
                renderInput={(params) => (
                  <TextField {...params} label='' variant='outlined' />
                )}
                style={{ width: '100%' }}
                value={countries.find((v) => v.code === country) || {}}
              />
            </InputWrapper>
            <InputWrapper header={t('phone')}>
              <TextField
                name='phone'
                className={classes.textField}
                value={(address && address.phone) || ''}
                onChange={(e) => this.props.setShippingAddressInput(e.target)}
              />
            </InputWrapper>
          </DialogContent>
          <DialogActions style={{ width: '100%' }}>
            <div className={classes.modalFooter}>
              <Button className={classes.button} onClick={() => handleClose()}>
                {t('cancel')}
              </Button>
              <Button className={classes.button} type='submit'>
                {t('save')}
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connectTo(
  (state) => ({
    address: state.address.shippingAddress,
    // isChanged: state.address.isChangeNewAddress,
    countries: state.metadata.countries,
    titles: state.metadata.titles,
  }),
  {
    setShippingAddressInput,
    setShippingTitle,
    setShippingCountry,
    openErrorSnack,
  },
  withNamespaces(['accountPage', 'Validation Messages'])(
    withStyles(styles)(withMobileDialog()(ShippingModal))
  )
);
