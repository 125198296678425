export default (theme) => ({
  textField: {
    width: '100%',
    marginBottom: '9px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexGrow: '0.4',
    maxWidth: '472px',
    width: '472px',
  },
  formResponsive: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexGrow: '1',
    maxWidth: '472px',
  },
  button: {
    background: theme.palette.primary.buttonColor,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
});
