import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import classNames from 'classnames';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { connectTo } from '../../../../../utils/generic';

import { formatMoney } from '../../../../../utils/localization';
import { getLocaleDateString } from '../../../../../utils/localization';
import { addProduct } from '../../../../../actions/cart';
// import ReactSVG from 'react-svg';
import {getCartItemDiscountDetails, getShowDiscount} from "../../../../../utils/discounts";

class DetailsTableLarge extends React.Component {
  // state = {};
  render() {
    const { classes, t, order, settings } = this.props;
    const showDiscount = getShowDiscount(settings)
    return (
      <div className={classes.container} style={{ marginTop: 0 }}>
        <div className={classNames(classes.header, classes.headerRed)}>
          <div className={classes.headerTitle}>{t('Order Details')}</div>
          <div className={classes.orderDetails}>
            {t('Order Date')} {getLocaleDateString(order.createdAt)} |{' '}
            {t('Order Number')} {order.orderId} | {t('User')}{' '}
            {order.user && order.user.firstName + ' ' + order.user.lastName}
          </div>
        </div>
        <div className={classes.table}>
          <Table style={{ padding: '0 10px' }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellCentred}>
                  {t('Position')}
                </TableCell>
                <TableCell className={classes.cellCentred}>
                  {t('Details')}
                </TableCell>
                {/*{(showDiscount || this.props.order.items.find(e => e.priceType === PRICE_TYPE_INDIVIDUAL)) &&  */}<TableCell />{/*}*/}
                <TableCell className={classes.cellCentred}>
                  {t('Price')}
                </TableCell>
                <TableCell className={classes.cellCentred}>
                  {t('Quantity')}
                </TableCell>
                <TableCell className={classes.cellCentred}>
                  {t('Subtotal')}
                </TableCell>
                <TableCell className={classes.cellCentred} />
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item, i) => {
                const {
                  rowPrice,
                  discountValue,
                  discountPriceCellClass,
                  haveDiscounts
                } = getCartItemDiscountDetails(item, showDiscount);
                return (
                  <TableRow className={classes.row} key={i}>
                    <TableCell className={classes.cellCentred}>
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.nameCell,
                        classes.cellCentred
                      )}
                    >
                      <div className={classes.productName}>
                        {item.name}
                      </div>
                      <div>
                        {t('Material Number')}: {item.code}
                      </div>
                    </TableCell>
                    {/*{(showDiscount || this.props.order.items.find(e => e.product.priceType === PRICE_TYPE_INDIVIDUAL)) &&*/}
                    <TableCell>
                      {showDiscount && haveDiscounts ? (
                          <div className={classes.notDiscountPrice}>
                            {item.price > 0 /*&& (showDiscount || item.product.priceType === PRICE_TYPE_INDIVIDUAL) */&& formatMoney(item.price, order.currency)}
                          </div>
                      ) : null}
                    </TableCell>
                  {/*}*/}

                    <TableCell className={classes.cellCentred}>
                      {showDiscount && haveDiscounts ? (
                        <div>
                          <div className={discountPriceCellClass}>
                            {formatMoney(rowPrice, order.currency)}
                          </div>
                          {showDiscount && discountValue &&
                              <div className={classes.discountValue}>
                                {t('cart:discount')} {discountValue}%
                              </div>
                          }
                        </div>
                      ) : (
                        formatMoney(rowPrice, order.currency)
                      )}
                    </TableCell>

                    <TableCell className={classes.cellCentred}>
                      {item.quantity}
                    </TableCell>
                    <TableCell className={classes.cellCentred}>
                      <div>
                        {formatMoney(
                          rowPrice * item.quantity,
                          order.currency
                        )}
                      </div>
                    </TableCell>
                    <TableCell className={classes.cellCentred}>
                      {item.isOrderable ? (
                        <img
                          alt={t('Re-Order')}
                          onClick={() =>
                            this.props.addProduct({
                              code: item.code,
                              quantity: item.quantity,
                            })
                          }
                          title={t('Re-Order')}
                          className={classNames(
                              classes.available,
                            classes.cartIcon,
                            'cart-plus-icon',
                            'available'
                          )}
                          style={{
                            position: 'relative',
                          }}
                          src='/updated_images/svg/cart-plus 15px.svg'
                        />
                      ) : (
                        <img
                          alt={t('Re-Order')}
                          title={t('Re-Order')}
                          className={classNames(
                            classes.cartIcon,
                            'cart-icon'
                          )}
                          style={{
                            position: 'relative',
                          }}
                          src='/updated_images/svg/cart-plus 15px grey.svg'
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
export default connectTo(
  (state) => ({
    settings: state.staticContent.settings
  }),
  {
    addProduct,
  },
  withNamespaces(['myOrders', 'cart'])(withStyles(styles)(DetailsTableLarge))
);
