import { createReducer } from 'redux-act';
import * as a from '../actions/static';

const DEFAULT_STATE = {
  stickers: [],
  menu: {},
  footer: {},
  settings: [],
  backgroundUrl: '',
  social: [],
  analyticsActivated: undefined,
  serviceCustomers: [],
  reCaptchaSiteKey: "",
  allowGuestUser: "",
  showPricesForGuest: "",
};

export default createReducer(
  {
    [a.setStickers]: (state, stickers) => ({
      ...state,
      stickers,
    }),
    [a.setFooter]: (state, footer) => ({
      ...state,
      footer,
    }),
    [a.setSettings]: (state, settings) => ({
      ...state,
      settings,
    }),
    [a.setBackground]: (state, backgroundUrl) => ({
      ...state,
      backgroundUrl,
    }),
    [a.setSocialLinks]: (state, social) => ({
      ...state,
      social,
    }),
    [a.setAnalytics]: (state, analyticsActivated) => {
      return {
        ...state,
        analyticsActivated,
      };
    },
    [a.setServiceCustomers]: (state, serviceCustomers) => ({
      ...state,
      serviceCustomers,
    }),
    [a.setReCaptchaSiteKey]: (state, reCaptchaSiteKey) => ({
        ...state,
        reCaptchaSiteKey,
    }),
      [a.setAllowGuestUser]: (state, allowGuestUser) => ({
          ...state,
          allowGuestUser,
      }),
      [a.setShowPricesForGuest]: (state, showPricesForGuest) => ({
        ...state,
        showPricesForGuest,
      }),
  },
  DEFAULT_STATE
);
