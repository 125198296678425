export default (theme) => ({
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  inStockCell: {
    width: '100px',
    color: theme.palette.primary.green,
    position: 'relative',
  },
  cartCell: {
    width: '50px',
  },
  table: {
    overflowX: 'auto',
    width: '100%',
  },
  cellCentred: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  available: {
    cursor: 'pointer',
  }
});
