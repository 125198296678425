import { put } from 'redux-saga/effects';

import {
  USERS,
  USER,
  ME,
  FILTER_ORDER,
  GROUP_USERS,
  USER_ORDER_DETAILS,
  MYTICKETS,
  NEWTICKET,
  FILTER_MESSAGES,
  MESSAGE_READ,
} from '../constants/api';

import { setUsers, setMe } from '../actions/account';
import * as a from '../actions/account';

import { callHttp, callHttpWithoutSpinner } from '../utils/api';
import { get, patch, del, post, delBody, getBlob } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';

export function* getMe() {
  try {
    const user = yield callHttp(get, ME);

    yield put(setMe(user));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getUsers() {
  try {
    const allUsers = yield callHttp(get, USERS);

    var userList = Object.values(allUsers);

    yield put(setUsers({ users: Object.values(userList) }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getOrders({ payload }) {
  try {
    const orders = yield callHttp(get, FILTER_ORDER(payload));
    yield put(a.setOrders(orders));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getOrderDetails({ payload }) {
  try {
    const { orderId } = payload;
    const order = yield callHttp(get, USER_ORDER_DETAILS(orderId));

    yield put(a.setOrderDetails({ order }));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* patchUser({ payload }) {
  const { user } = payload;

  try {
    yield callHttp(patch, USER(user.id), user);
    yield put(a.getUsers());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* deleteUser({ payload }) {
  try {
    const { id } = payload;
    yield callHttp(del, USER(id), '');
    yield put(a.closeDeleteUser());
    yield put(a.getUsers());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addUser({ payload }) {
  const { user } = payload;
  try {
    yield callHttp(post, USERS, user);
    yield put(a.getUsers());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* patchMany({ payload }) {
  const { users } = payload;
  try {
    yield callHttp(patch, GROUP_USERS, users);
    yield put(a.getUsers());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* deleteMany({ payload }) {
  const { userIds } = payload;
  try {
    yield callHttp(delBody, GROUP_USERS, userIds);
    yield put(a.closeDeleteUser());
    yield put(a.getUsers());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* postNewTicketTitle({ payload }) {
  try {
    yield callHttpWithoutSpinner(post, MYTICKETS, payload);
    yield put(a.setNewTicketTitle(payload.title));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getCustomerTickets() {
  try {
    const tickets = yield callHttpWithoutSpinner(get, MYTICKETS);
    yield put(a.setCustomerTickets(tickets));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* postNewTicketMessage({ payload }) {
  try {
    const { code, message, attachedFiles } = payload;
    let formData = new FormData();

    if (message.length > 0) formData.append('message', message);

    if (attachedFiles.length > 0) {
      attachedFiles.map((file) => formData.append('files[][file]', file));
    }

    const messageCreated = yield callHttpWithoutSpinner(post, NEWTICKET(code), {
      formData,
      multipart: true,
    });
    yield put(a.setLastMessage(messageCreated));
    yield put(a.setLastMessageSent(messageCreated.createdAt));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMessages({ payload }) {
  try {
    const { order, page, perPage } = payload;

    const params = {
      order,
      page,
      perPage,
    };

    const messages = yield callHttpWithoutSpinner(
      get,
      FILTER_MESSAGES(payload.code, params)
    );
    yield put(a.setMessages(messages));
    if (messages && messages.items && messages.items.length > 0)
      yield put(
        a.setLastMessageSent(
          messages.items[messages.items.length - 1].createdAt
        )
      );
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMessagesFromDate({ payload }) {
  try {
    const { order, fromDate } = payload;
    const params = { order, fromDate };

    const messages = yield callHttpWithoutSpinner(
      get,
      FILTER_MESSAGES(payload.code, params)
    );
    yield put(a.setMessagesFromDate(messages));
    if (messages && messages.items && messages.items.length > 0)
      yield put(
        a.setLastMessageSent(
          messages.items[messages.items.length - 1].createdAt
        )
      );
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getMessageFile({ payload }) {
  try {
    const { url, name } = payload;
    const file = yield callHttp(getBlob, url);
    const a = document.createElement('a');
    a.href = file;
    a.download = name;
    a.click();
    a.remove();
    document.addEventListener('focus', (w) => {
      window.URL.revokeObjectURL(file);
    });
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* postMessageRead({ payload }) {
  try {
    const { code } = payload;
    yield callHttpWithoutSpinner(post, MESSAGE_READ(code), {
      multipart: false,
    });
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
