import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  label: {
    marginBottom: '2px'
  }
};

const InputWrapper = props => {
  const { children, header, isRequire, classes, headerClass, tip } = props;
  return (
    <div
      className={props.className}
      style={props.style}
    >
      <div
        title={tip ? tip : null} 
        className={classNames('input-header', classes.label, headerClass)} 
        style={{cursor: tip ? 'pointer' : null}}
      >
        {header}
        {isRequire && <span>*</span>}
        {tip && <span>**</span>}
        
      </div>
      {children}
    </div>
  );
};

export default withStyles(styles)(InputWrapper);
