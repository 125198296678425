export default (theme) => ({
  linkText: {
    color: '#00BEFF',
    fontSize: 15,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  titleContent: {
    margin: '1.5em 1em 0',
    fontSize: '20px',
    width: '100%',
  },
  mainTitle: {
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '1.8em',
    textTransform: 'uppercase',
    color: theme.palette.primary.buttonColor,
  },
});
