import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import styles from './style';

class Panel extends React.Component {
  render() {
    const { classes, summary, children } = this.props;
    return (
      // <Accordion defaultExpanded={defaultExpanded} className={classes.panel}>
      <Accordion className={classes.panel}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.summaryText}>{summary}</div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(Panel);
