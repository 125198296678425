import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';

import OrderList from './OrderList';

class MyOrders extends React.Component {
	state = {
		selectedOrder: null
	};

	handleOrderSelect = order => {
		let selectedOrder = order;

		// Unselect item if it is already selected
		if (
			this.state.selectedOrder &&
			this.state.selectedOrder.orderId === order.orderId
		)
			selectedOrder = null;

		this.setState({ selectedOrder });
	};

	render() {
		/*const { classes } = this.props;*/
		return (
			<div /* className={classes.content} */>
				<OrderList
					handleSelect={this.handleOrderSelect}
					selectedOrder={this.state.selectedOrder}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(MyOrders);
