export const statusValues = {
  pending: 0,
  inprogress: 1,
  ready: 2,
  shipped: 2,
  completed: 3,
  rejected: 0,
  canceled: -1,
  parked: -2
}

export const cartIcons = [
  {
    src: '/updated_images/svg/cart filled 15px white.svg',
    label: 'checkoutPage:In Cart'
  },
  {
    src: '/updated_images/svg/cart checkout 15px.svg',
    label: 'checkoutPage:checkout'
  },
  {
    src: '/updated_images/svg/clipboard-tick 15px.svg',
    label: 'checkoutPage:order'
  }
];

export const getOrdersIcons = shipped => [
  {
    src: '/updated_images/svg/cart checkout 15px.svg',
    label: 'myOrders:Order is Processing'
  },
  shipped
    ? {
        src: '/updated_images/svg/fast deliver  15px.svg',
        label: 'myOrders:Order has been Shipped'
      }
    : {
        src: '/updated_images/svg/deliver 15px.svg',
        label: 'myOrders:Order Ready for Pickup'
      },
  {
    src: '/updated_images/svg/clipboard-tick 15px.svg',
    label: 'myOrders:Order Completed'
  }
];
