import { createReducer } from 'redux-act';
import * as a from '../actions/metadata';

const DEFAULT_STATE = {
  countries: [],
  titles: [],
  statuses: [],
  term: {}
};

export default createReducer(
  {
    [a.setCountries]: (state, { countries }) => ({
      ...state,
      countries: countries
    }),
    [a.setTitles]: (state, { titles }) => ({
      ...state,
      titles: titles
    }),
    [a.setOrderStatuses]: (state, statuses) => ({
      ...state,
      statuses: statuses
    }),
    [a.setTerm]: (state, term) => ({
      ...state,
      term: term
    })
  },
  DEFAULT_STATE
);
