export default (theme) => ({
    footerMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        // marginTop: 100,
    },
    footerContainer: {
        backgroundColor: 'black' /* theme.palette.primary.footerColor */ ,
        display: 'flex',
        padding: '35px 30px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        "@media (max-width: 800px)": {
            flexDirection: 'column',
            paddingBottom: 60
        },
    },
    footer: {
        fontSize: '16px',
        // color: '#696969 !important',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '24px',
    },
    footerListItem: {
        // color: '#696969 !important'
        color: 'white'
    },
    footerImages: {
        width: '100%',
        padding: '44px 1% 36px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    socialIcons: {
        "@media (max-width: 800px)": {
            marginTop: 35,
        },
    },
    socialButton: {
        color: '#fff',
        width: 33,
        height: 35,
        border: '1px solid #383E42',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        marginLeft: 20,
        "@media (max-width: 800px)":{
            '&:first-child': {
                marginLeft: '0px'
            }
        },
    },
    socialImage: {
        position: 'absolute',
        top: '0',
        left: '0',
    },
    iconImage: {
        maxWidth: '40px'
    },
    sloganArea: {
        // color: '#696969 !important',
        fontSize: '30px',
        fontWeight: '600',
        backgroundColor: '#101010',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 180,
        color: 'white',
        textAlign: 'center'
    },
    popup: {
        background: '#BEC2C9 100%',
        zIndex: 10,
        textAlign: 'center',
        padding: '10px',
        border: '1px solid rgba(187, 187, 187, 1)',
    },
    copyright: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        color: 'white'
    },
    companyName: {
        // color: '#696969 !important',
        fontSize: 25,
        fontWeight: 700,
        margin: 'unset',
        lineHeight: '35px',
    },
    mediaArea: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "@media (max-width: 800px)": {
            flexDirection: 'column',
            margin: '10px auto auto auto',
        },
    },
});
