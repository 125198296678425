import { createReducer } from 'redux-act';
import * as a from '../actions/listingPage';

const DEFAULT_STATE = {
  category: {},
  searchResult: [],
  categories: []
};

export default createReducer(
  {
    [a.setCategory]: (state, category) => ({
      ...state,
      category
    }),
    [a.setCategories]: (state, categories) => ({
      ...state,
      categories
    }),
    [a.setSearchResult]: (state, result) => ({
      ...state,
      searchResult: result
    })
  },
  DEFAULT_STATE
);
