import { put } from 'redux-saga/effects';

import { COUNTRIES, TITLES, ORDER_STATUS, TERM } from '../constants/api';

import {
  setOrderStatuses,
  setCountries,
  setTitles,
  setTerm
} from '../actions/metadata';
import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';

export function* getCountries() {
  const countries = yield callHttp(get, COUNTRIES);
  yield put(setCountries({ countries: countries }));
}

export function* getTitles() {
  const titles = yield callHttp(get, TITLES);
  yield put(setTitles({ titles: titles }));
}

export function* getOrderStatuses() {
  const statuses = yield callHttp(get, ORDER_STATUS);
  yield put(setOrderStatuses(statuses));
}

export function* getTerm({ payload }) {
  try {
    const { alias } = payload;
    const data = yield callHttp(get, TERM(alias));
    yield put(setTerm(data));
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}
