export default theme => ({
  panel: {
    background: theme.palette.primary.grey,
  },
  summaryText: {
    color: 'black',
    textTransform: 'uppercase',
    fontSize: '16px'
  },
  summary: {
    margin: 0,
  },
  root: {
    color: 'red',
    background: 'red'
  }
});