import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import HomePage from './pages/Home';
import NewHomePage from './pages/NewHome';
import StandardMachinePage from './pages/StandardMachinePage';
import StartPage from './pages/Start';
import Product from './pages/Machine';
import SearchPage from './pages/Search';
import ProductDetails from './pages/Pdp';
import AccountPage from './pages/Account';
import OrderPage from './pages/Order';
import CheckoutPage from './pages/Checkout';
import ResetPasswordPage from './pages/Resetpass';
import ListingPage from './pages/ListingPage';
import ArticlePage from './pages/Article';
import TermContentPage from './pages/TermContent';
import SnackBar from './components/common/snackbar/Error';
import Spinner from './components/common/Spinner';
import PublicData from './components/common/PublicData';
import SiteManagementPage from './pages/SiteManagement';
import ServiceHomePage from './pages/ServiceTechnician';
import ProtectedRoute from './components/common/ProtectedRoute';
import PageNotFound from './pages/PageNotFound';
import UnblockUserPage from './pages/UnblockUser';

import store from './store';
import saga from './sagas';

import { sagaMiddleware } from './middleware';

import theme from './theme';
import RouteProcessor from "./components/common/RouteProcessor";

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <div>
        <PublicData />
        <SnackBar />
        <Spinner />
        <Switch>
          {/* Private Protected Routes */}
          <RouteProcessor
            path='/machines'
            component={(props) => <HomePage {...props} />}
          />

          {/* Private Protected Routes */}
          <ProtectedRoute
            path='/service'
            allowGuestAccess={""}
            component={(props) => <ServiceHomePage {...props} />}
          />

          <RouteProcessor
              path='/home'
              component={(props) => <NewHomePage {...props} />}
          />

          <RouteProcessor
            path='/standard-machines-content'
            component={(props) => <StandardMachinePage {...props} />}
          />

          {/* Checkout */}
          <RouteProcessor
            exact
            path='/cart'
            //allowGuestAccess={""}
            component={(props) => <CheckoutPage {...props} />}
          />
          <ProtectedRoute
            exact
            path='/checkout'
            allowGuestAccess={""}
            component={(props) => <CheckoutPage {...props} toCheckout={true} />}
          />

          {/* QuickOrder */}
          <RouteProcessor
            path='/quickorder'
            component={(props) => <OrderPage {...props} />}
          />

          {/* Categories */}
          <RouteProcessor
            path='/content/:alias'
            render={(props) => <ListingPage {...props} />}
          />

          {/* Product */}
          <RouteProcessor
            exact
            path='/product/:id/'
            component={(props) => <ProductDetails {...props} />}
          />
          <ProtectedRoute
            exact
            path='/my/product/:id/'
            allowGuestAccess={""}
            component={(props) => <ProductDetails {...props} />}
          />
          <RouteProcessor
            exact
            path='/category/:category/:id'
            component={(props) => <ProductDetails {...props} />}
          />
          <RouteProcessor
            exact
            path='/machine/:id/'
            component={(props) => <Product {...props} category='standard' /*from={props.history.location}*//>}
          />
          <ProtectedRoute
            exact
            path='/my/machine/:id/'
            allowGuestAccess={""}
            component={(props) => <Product {...props} category='my' />}
          />

          {/* Search */}
          <RouteProcessor
            exact
            path='/search'
            render={(props) => <SearchPage {...props} />}
          />

          {/* Account */}
          <ProtectedRoute
            exact
            path='/account'
            allowGuestAccess={""}
            component={() => <Redirect to='/account/mydata' />}
          />
          <ProtectedRoute
            exact
            path='/account/mydata'
            allowGuestAccess={""}
            component={(props) => <AccountPage {...props} value={0} />}
          />
          <ProtectedRoute
            path='/account/orders'
            render={(props) => <AccountPage {...props} value={1} />}
          />
          <ProtectedRoute
            exact
            path='/account/management'
            allowGuestAccess={""}
            component={(props) => <AccountPage {...props} value={2} />}
          />
          {/* <ProtectedRoute
            exact
            path='/account/support'
            render={(props) => <AccountPage {...props} value={3} />}
          />
          <ProtectedRoute
            exact
            path='/account/support/:code'
            render={(props) => <AccountPage {...props} value={3} />}
          /> */}

          {/* Public unprotected Routes */}

          {/* Start */}
          <Route exact path='/' component={StartPage} />

          {/* Password */}
          <Route path='/term/:alias' component={TermContentPage} />
          <Route
            path='/password/reset'
            component={(props) => <ResetPasswordPage {...props} type='reset' />}
          />
          <Route
            path='/password/new'
            component={(props) => <ResetPasswordPage {...props} type='new' />}
          />
          <Route
              path='/account/unblock'
              component={UnblockUserPage}
          />

          {/* Site Management */}
          <Route
            exact
            path='/page/:alias'
            component={(props) => (
              <SiteManagementPage {...props} category='page' />
            )}
          />

          {/* Article */}
          <Route
            exact
            path='/article/:alias'
            component={(props) => <ArticlePage {...props} category='article' />}
          />

          <Route path='*' component={PageNotFound} />
        </Switch>
      </div>
    </Provider>
  </MuiThemeProvider>
);

export default App;

sagaMiddleware.run(saga);
