import { put } from 'redux-saga/effects';

import * as a from '../actions/cart';

import { callHttp } from '../utils/api';
import { get, patch, del, post } from '../utils/httpUtil';
import {
  CART,
  ORDER,
  TERMS,
  CART_ITEMS,
  CART_ITEMS_CODE,
  PAYMENT_METHOD,
  SHIPPING_OPTION,
  BILLING_ADDRESS,
  SHIPPING_ADDRESS,
  CART_PAYMENT_METHOD,
  CART_SHIPPING_ADDRESS,
  CART_SHIPPING_OPTION,
  CART_DISCOUNT_SET,
  CART_DISCOUNT_DELETE,
  CHECKOUT_CART
} from '../constants/api';

import { openErrorSnack, openSuccessSnack, closeSnack } from '../actions/startpage';
import { emptyOrder } from '../actions/quickorder';
import i18next from '../config/i18nconfig';

i18next.loadNamespaces(['messages']);

export function* order({ payload }) {
  try {
    const { comment, internalOrderNumber } = payload;
    const body = {
      saveShippingAddress: false,
    };
    if (body) {
      body.comment = comment;
      body.internalOrderNumber = internalOrderNumber;
    }
    localStorage.removeItem('comment');
    localStorage.removeItem('internalOrderNumber');

    const order = yield callHttp(post, ORDER, body);

    yield put(a.setComment(''));
    yield put(a.setInternalOrderNumber(''));
    yield put(a.setOrderResult(order));
    yield put(a.getCart());
    yield put(a.getCheckoutCart());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getCart() {
  try {
    const cart = !localStorage.getItem("technician")/*&& localStorage.getItem("token")*/ ?  yield callHttp(get, CART) : [];

    yield put(a.setCart(cart));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getCheckoutCart() {
  try {
    const cart = !localStorage.getItem("technician")&& localStorage.getItem("token") ? yield callHttp(get, CHECKOUT_CART):[];

    yield put(a.setCheckoutCart(cart));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addCartDiscount({ payload }) {
  const { code } = payload;

  try {
    const cart = yield callHttp(post, CART_DISCOUNT_SET, {code});
    yield put(openSuccessSnack(i18next.t('messages:Discount code added')));

    yield put(a.setCheckoutCart(cart));

  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* removeCartDiscount({ payload }) {
  const { code } = payload;

  try {
    yield callHttp(del, CART_DISCOUNT_DELETE(code), '') ;
    yield put(openSuccessSnack(i18next.t('messages:Discount code removed')));

    yield put(a.getCheckoutCart());

  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addProduct({ payload }) {
  try {
    const { code, quantity } = payload;
    const product = { product: code, quantity };
    const cart = yield callHttp(post, CART_ITEMS, [product]);

    yield put(a.setCart(cart));
    yield put(a.getCheckoutCart());
    yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
    yield put(closeSnack(''))
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addProductsFromOrder({ payload }) {
  try {
    const products = payload.map((p) => ({
      product: p.code,
      quantity: p.quantity,
    }));

    const cart = yield callHttp(post, CART_ITEMS, products);

    yield put(a.setCart(cart));
    yield put(a.getCheckoutCart());
    yield put(emptyOrder());
    // yield put(openSuccessSnack('Product successfully added to cart!'));
    yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* updateProduct({ payload }) {
  try {
    const { code, quantity } = payload;
    const product = { quantity };
    const cart = yield callHttp(patch, CART_ITEMS_CODE(code), product);
    yield put(a.setCart(cart));
  } catch (err) {
    yield put(a.getCart());
    yield put(openErrorSnack(err.message));
  }
  yield put(a.getCheckoutCart());
}

export function* deleteProduct({ payload }) {
  try {
    const { code } = payload;
    yield callHttp(del, CART_ITEMS_CODE(code), '');
    const cart = yield callHttp(get, CART);

    if (cart.items === undefined) {
      localStorage.removeItem('comment');
      localStorage.removeItem('internalOrderNumber');
      yield put(a.setComment(''));
      yield put(a.setInternalOrderNumber(''));
    }

    /* yield put(a.getCart()); */
    yield put(a.setCart(cart));
    yield put(a.getCheckoutCart());
    yield put(openSuccessSnack(i18next.t('messages:Removed from cart')));
    yield put(closeSnack(''))
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* clearCart() {
  try {
    yield callHttp(del, CART);
    yield put(a.getCart());
    yield put(a.getCheckoutCart());

    yield put(openSuccessSnack(i18next.t('messages:Cart cleaned')));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getDefaultShippingAddress() {
  try {
    const addresses = yield callHttp(get, SHIPPING_ADDRESS);
    yield put(a.setDefaultShippingAddress(addresses));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getAsDefaultBillingAddress() {
  try {
    const address = yield callHttp(get, BILLING_ADDRESS);
    yield put(a.setAsDefaultBillingAddress(address));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getShippingOptions() {
  try {
    const options = yield callHttp(get, SHIPPING_OPTION);

    yield put(a.setShippingOptions(options));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getPaymentMethods() {
  try {
    const options = yield callHttp(get, PAYMENT_METHOD);
    yield put(a.setPaymentMethods(options));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addCartShippingAddress({ payload }) {
  try {
    const { address } = payload;
    delete address.id;
    delete address.code;
    delete address.isDefault;

    const cart = yield callHttp(post, CART_SHIPPING_ADDRESS, address);

    yield put(a.setCartShippingAddress());
    yield put(a.setCheckoutCart(cart));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addPaymentMethod({ payload }) {
  try {
    const { method } = payload;
    const cart = yield callHttp(post, CART_PAYMENT_METHOD, {
      id: parseInt(method, 10),
    });
    // yield put(a.setPaymentMethod(newMethod));
    yield put(a.setCheckoutCart(cart));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addShippingOption({ payload }) {
  try {
    const { option } = payload;
    const cart = yield callHttp(post, CART_SHIPPING_OPTION, {
      id: parseInt(option, 10),
    });
    // yield put(a.setShippingOption(newOption));
    yield put(a.setCheckoutCart(cart));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* postNewShippingAddress({ payload }) {
  try {
    const { address } = payload;
    delete address.id;
    address.isDefault = false;
    yield callHttp(post, SHIPPING_ADDRESS, address);
    // yield put(a.setShippingOption(newOption));
    yield put(a.setNewShippingAddress());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getTerms({ payload }) {
  try {
    const terms = yield callHttp(get, TERMS),
      category = payload.category,
      choosedTerms = terms.filter((term) => term.category === category);
    yield put(a.setTerms(choosedTerms));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
