export default (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
  },
  topHeader: {
    width: '100%',
    height: '40px',
    paddingTop: '6px',
    zIndex: 2,
    borderTop: '2px solid',
    // borderTopColor: theme.palette.primary.blue,
    borderTopColor: '#00BEFF',
  },
  accountContainer: {
    alignItems: 'flex-start',
    margin: '8px 0 0 0',
    marginBottom: 'auto',
    gap:12,
    display: 'flex'
  },
  accountContainerLogo: {
    alignItems: 'flex-start',
    margin: '8px -17px 0 0',
    marginBottom: 'auto',
    gap:12,
  },

  top: {
    alignItems: 'center',
    display: 'flex',
  },
  mdLanguageselector: {
    position: 'relative',
    top: 11,
    right: -20
  },
  md_Languageselector_LogOut: {
    position: 'relative',
    top: 11
  },
  mdSearchBox: {
    position: 'relative',
    right: 4,
    display: 'inline-block',
  },
  mainIcon: {
    position: 'relative',
  },
  topAccount: {
    marginLeft: 3,
    paddingTop: '1px',
  },
  tooltipAccount: {
    ':hover > &': {
        visibility: 'visible'
    },
    '&::after': {
      content:"' '",
      position:'absolute',
      bottom: '100%',
      left: '50%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'transparent transparent white transparent'
    },
    visibility: 'hidden',
    width: 120,
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'center',
    borderRadius: 6,
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    top: '150%',
    left: '50%',
    marginLeft: -60
  },

  tooltipLogOut: {
    ':hover > &': {
      visibility: 'visible'
    },
    '&::after': {
      content:"' '",
      position:'absolute',
      bottom: '100%',
      left: '80%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'transparent transparent white transparent'
    },
    visibility: 'hidden',
    width: 80,
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'center',
    borderRadius: 6,
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    top: '150%',
    left: '-40%',
    marginLeft: -60
  },


  topLogOut: {
    paddingTop: '1px',
    position: 'relative',
    left: -17
  },

  topContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    // marginRight:'20px'
  },
  badge: {
    width: '15px',
    height: '15px',
    top: 5,
    right: 13,
    color: '#00BEFF',
    backgroundColor: 'transparent',
    fontSize: 13
  },
  badgeDoubleDigit: {
    width: '15px',
    height: '15px',
    top: 5,
    right: 13,
    color: '#00BEFF',
    backgroundColor: 'transparent',
    fontSize: 10
  },
  badgeTripleDigit: {
    width: '15px',
    height: '15px',
    top: 5,
    right: 13,
    color: '#00BEFF',
    backgroundColor: 'transparent',
    fontSize: 7
  },

  badgeNumber:  {
    filter: "invert(13%) sepia(100%) saturate(3994%) hue-rotate(243deg) brightness(93%) contrast(157%)"
  },

  checkout: {
    color: 'black',
    position: 'relative'
  },
  checkoutLabel: {
    justifyContent: 'center',
  },
  menuButton: {
    marginRight: 20,
    marginTop:6.5,
    color: 'white'
  },
  hide: {
    display: 'none',
  },
});
