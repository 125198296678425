import React from 'react';

import styles from './style';

import OrderSummary from './OrderSummary';
import OrderDetailsTable from './OrderDetailsTable';
import Line from '../../../account/myOrders/Line';

import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";


const OrderDetails = props => {
  const { classes, t, selectedOrder } = props;

  return selectedOrder && selectedOrder.items && selectedOrder.items.length !== 0 ?
    <div className={classes.container}>
      <div className={classNames(classes.items, 'light')}>
        <span className='medium'>{selectedOrder.itemsQuantity}</span> {t('items')}
      </div>
      <OrderDetailsTable order={selectedOrder} />
      <Line margin="90%" hasPointBottom={true} />
      <OrderSummary order={selectedOrder} />
    </div> : null;
};

export default withNamespaces('checkoutPage')(withStyles(styles)(OrderDetails));
