import React from 'react';

import styles from './style';
import { connectTo } from '../../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import { logout, setMyRole } from '../../../../actions/auth';
import { setServiceCustomers } from '../../../../actions/static';

import ReactSVG from 'react-svg';

const LogoutIcon = (props) => {
  const { t, logout, classes, setMyRole, setServiceCustomers } = props;

  return (
      <div className={classes.mainIcon}>
          <div
          className={classes.topLogOut}
          onClick={() => {
            logout() && setMyRole({}) && setServiceCustomers([]);
          }}
        >
          <ReactSVG
            svgClassName='menu-icon colored-font'
            src='/updated_images/svg/logout 15px.svg'
            alt='Logout'
            style={{}}
          />
            {/*comment the command below to remove the label*/}
          {/*<div className='menu-label'>{t('logout')}</div>*/}
        </div>
        <span className={classes.tooltipLogOut}>{t('logout')}</span>
      </div>

);
};

export default connectTo(
  (state) => ({}),
  {
    logout,
    setMyRole,
    setServiceCustomers,
  },
  withStyles(styles)(LogoutIcon)
);
