import React from 'react';

import { Checkbox } from '@material-ui/core';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from './ExpansionPanel';
import PriceInput from '../../common/ChangeableInput';
import classNames from 'classnames';

//const SMALL_SCREEN = 750;

class FilterSearchPage extends React.Component {
  state = {
    selectedTypes: [],
    selectedYourTypes: [],
    minPrice: '',
    maxPrice: '',
    // smallMode: window.innerWidth <= SMALL_SCREEN,
  };

  // updateDimensions = () => {
  //   if (
  //     (!this.state.smallMode && window.innerWidth <= SMALL_SCREEN) ||
  //     (this.state.smallMode && window.innerWidth > SMALL_SCREEN)
  //   )
  //     this.setState({ smallMode: !this.state.smallMode });
  // };

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateDimensions);
  // }

  // componentDidMount() {
    // window.addEventListener('resize', this.updateDimensions);
  // }

  handleTypeChange = (type) => {
    let types = this.state.selectedTypes;
    const typeId = type.alias;

    if (types.find((v) => v === typeId))
      types = types.filter((v, i) => v !== typeId);
    else types.push(typeId);

    this.setState({
      selectedTypes: types,
    });

    this.props.handleTypeChange(types);
  };

  handleYourTypeChange = (type) => {
    let types = this.state.selectedYourTypes;
    const typeId = type.code;

    if (types.find((v) => v === typeId))
      types = types.filter((v) => v !== typeId);
    else types.push(typeId);

    this.setState({
      selectedYourTypes: types,
    });

    this.props.handleYourTypeChange(types);
  };

  handlePriceChange = (minPrice, maxPrice) => {
    this.setState({ minPrice, maxPrice });
    this.props.handlePriceSelect({
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      selectedTypes: props.params.productType || [],
      selectedYourTypes: props.params.individualProduct || [],
      minPrice: props.params.minPrice || '',
      maxPrice: props.params.maxPrice || '',
    };
  }

  render() {
    const { t, classes, searchResult } = this.props;
    //const { smallMode } = this.state;
    const types =
      searchResult.filterResults && searchResult.filterResults.productType;
    const yourTypes =
      searchResult.filterResults &&
      searchResult.filterResults.individualProduct;
    const currency = sessionStorage.getItem('currency');

    return (
      <div className={classNames(classes.content, 'search-filter')}
      style={{height: '100%'}}>
        <div className={classes.header}>
          <div className={classNames(classes.title, 'bold')}>{t('filter')}</div>
          <div className={classes.breakLabel} onClick={this.props.clear}>
            {t('clear')}
          </div>
        </div>

        <ExpansionPanel
          summary={t('standardMachines')}
          // defaultExpanded={!smallMode}
        >
          <div className={classes.width}>
            {types &&
              types.length !== 0 &&
              types.map((type, i) => (
                <div className={classes.row} key={i}>
                  <Checkbox
                    checked={
                      this.state.selectedTypes &&
                      this.state.selectedTypes.findIndex(
                        (v) => v === type.alias
                      ) !== -1
                    }
                    onChange={() => this.handleTypeChange(type)}
                    classes={{
                      root: classNames(classes.root, 'checkbox-comp'),
                    }}
                  />
                  <div className='search-filter--name'>{type.name}</div>

                  <div className={classes.number}>{type.count}</div>
                </div>
              ))}
          </div>
        </ExpansionPanel>

        { (this.props.token || this.props.showPricesForGuest) && <ExpansionPanel
          summary={t('yourMachines')}
          // defaultExpanded={!smallMode}
        >
          <div className={classes.width}>
            {yourTypes &&
              yourTypes.length !== 0 &&
              yourTypes.map((type, i) => (
                <div className={classes.row} key={i}>
                  <Checkbox
                    checked={
                      this.state.selectedYourTypes &&
                      this.state.selectedYourTypes.findIndex(
                        (v) => v === type.code
                      ) !== -1
                    }
                    onChange={() => this.handleYourTypeChange(type)}
                    classes={{
                      root: classNames(classes.root, 'checkbox-comp'),
                    }}
                  />
                  <div className='search-filter--name'>{type.name}</div>

                  <div className={classes.number}>{type.count}</div>
                </div>
              ))}
          </div>
        </ExpansionPanel>}

        { (this.props.token || this.props.showPricesForGuest) && <ExpansionPanel
          summary={`${t('price')}(${currency})`}
          // defaultExpanded={!smallMode}
        >
          <div className={classes.width}>
            <PriceInput
              className={classNames(classes.formBlock, 'form-block')}
              value={this.state.minPrice}
              handleChange={(minPrice) =>
                this.handlePriceChange(minPrice, this.state.maxPrice)
              }
              placeholder={t('from')}
            />
            <PriceInput
              className={classNames(classes.formBlock, 'form-block')}
              value={this.state.maxPrice}
              handleChange={(maxPrice) =>
                this.handlePriceChange(this.state.minPrice, maxPrice)
              }
              placeholder={t('to')}
            />
          </div>
        </ExpansionPanel> }
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    searchResult: state.search.searchResult,
    showPricesForGuest: state.staticContent.showPricesForGuest,
    token: state.auth.token,
  }),
  {},
  withNamespaces('searchPage')(withStyles(styles)(FilterSearchPage))
);
