import React from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { connectTo } from '../../../../utils/generic';
import { getPaymentMethods, addPaymentMethod } from '../../../../actions/cart';

class PaymentMethod extends React.Component {
  componentDidMount() {
    this.props.getPaymentMethods();
  }

  handleSelect = (e, val) => {};

  render() {
    return (
      <div>
        <RadioGroup
          value={this.props.method && this.props.method.id ? this.props.method.id.toString() : ''}
          onChange={e =>
            this.props.addPaymentMethod({ method: e.target.value })
          }
        >
          {this.props.methods.map((m, i) => (
            <FormControlLabel
              key={i}
              value={m.id.toString()}
              control={<Radio />}
              label={m.name}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

export default connectTo(
  state => ({
    methods: state.cart.paymentMethods,
    method: state.cart.paymentMethod
  }),
  {
    getPaymentMethods,
    addPaymentMethod
  },
  PaymentMethod
);
