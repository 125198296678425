import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connectTo } from '../../utils/generic';
import styles from './style';

import Page from '../page';
import MyData from '../../components/account/myData/';
import MyOrders from '../../components/account/myOrders/';
import UserManagement from '../../components/account/userManagement/';
import BreadCrumbs from '../../components/common/Breadcrumbs/';

import { getMe } from '../../actions/account';

class MyAccount extends React.Component {
  state = {
    value: 0,
  };

  shouldComponentUpdate = (nextProps, nextState) =>
    nextProps.me !== this.props.me ||
    nextState.value !== this.state.value ||
    nextProps.backgroundUrl !== this.props.backgroundUrl ||
    nextProps.token !== this.props.token;

  handleChange = (event, value) => {
    if (value === 0) this.props.history.push(`/account/mydata`);
    if (value === 1) this.props.history.push(`/account/orders`);
    if (value === 2) this.props.history.push(`/account/management`);
    /* if (value === 3) this.props.history.push(`/account/support`); */
    this.setState({ value });
  };

  componentDidMount() {
    this.setState((prevState) => {
      return { value: this.props.value };
    });
    !this.props.token && this.props.history.replace('/');
    this.props.getMe();
  }

  componentDidUpdate(prevProps) {
    !this.props.token && this.props.history.replace('/');
  }

  render() {
    const { classes, t } = this.props;
    const { customer } = this.props.me;
    const { value } = this.state;

    return (
      <Page>
        <div
          className='layout-section account-section'
        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              { name: t('myAccount'), link: '/account' },
            ]}
          />
        </div>
        <div className={classNames('main-content', classes.mainColumn)}>
          <div
            className={classNames(
              classes.title,
              classes.content,
              classes.bigHeader,
              'big-header'
            )}
          >
            {t('myAccount')}
          </div>
          <div
            className={classNames(
              classes.title,
              classes.content,
              classes.smallHeader,
              'small-header'
            )}
          >
            {t('customerNumber')}: {customer && customer.code}
          </div>
          <div className={classes.content}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant='scrollable'
              classes={{
                flexContainer: 'account_tabContainer',
              }}
            >
              <Tab
                label={t('myData')}
                className={classNames(classes.tab, 'vertical-tab')}
                classes={{ root: 'line-height', wrapper: 'tab-wrapper' }}
              />
              <Tab
                label={t('myOrders')}
                className={classNames(classes.tab, 'vertical-tab')}
                classes={{ root: 'line-height', wrapper: 'tab-wrapper' }}
              />
              {this.props.me && this.props.me.isAdmin && (
                <Tab
                  label={t('userManagement')}
                  className={classNames(classes.tab, 'vertical-tab')}
                  classes={{ root: 'line-height', wrapper: 'tab-wrapper' }}
                />
              )}
              {/* {canCreateTicket && (
                <Tab
                  label={t('customerSupport')}
                  className={classNames(classes.tab, 'vertical-tab')}
                  classes={{ root: 'line-height', wrapper: 'tab-wrapper' }}
                />
              )} */}
            </Tabs>
            {value === 0 && <MyData />}
            {value === 1 && <MyOrders />}
            {value === 2 && <UserManagement />}
            {/* {value === 3 && <CustomerSupport />} */}
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    me: state.account.me,
    backgroundUrl: state.staticContent.backgroundUrl,
  }),
  { getMe },
  withNamespaces('accountPage')(withStyles(styles)(MyAccount))
);
