import { createReducer } from 'redux-act';
import * as a from '../actions/auth';

const DEFAULT_STATE = {
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  isSuccessResetPassword: false,
  remember: false,
  sessionExpired: false,
  redirectToLogin: false,
  role: 'ROLE_USER',
  isLoginDialogOpened: false,
  centered: false
};

export default createReducer(
  {
    [a.receiveAuthData]: (state, { token, refreshToken, role }) => {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);

      return { ...state, token, refreshToken, redirectToLogin: false, role: role };
    },
    [a.logout]: (state, sessionExpired) => {
      localStorage.removeItem('token');
      sessionStorage.removeItem('currency');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('sessionExpired');
      localStorage.removeItem('userKey');
      localStorage.removeItem('service');
      localStorage.removeItem('technician');
      window.location.pathname='';

      return {
        ...state,
        token: '',
        refreshToken: '',
        remember: false,
        sessionExpired: sessionExpired || false,
        redirectToLogin: true,
        role: ""
      };
    },
    [a.notLogout]: (state) => {
        return {
            ...state,
            redirectToLogin: false,
        }
    },
    [a.successResetPassword]: (state) => ({
      ...state,
      isSuccessResetPassword: true,
    }),
    [a.disableResetPassword]: (state) => ({
      ...state,
      isSuccessResetPassword: false,
    }),
    [a.setMyRole]: (state, role) => ({
      ...state,
      role: role && role.code ? role.code : null,
    }),
      [a.openLoginForm]: (state, form) => ({
          ...state,
          isLoginDialogOpened: true,
          centered: form.centered
      }),
      [a.closeLoginForm]: (state) => ({
          ...state,
          isLoginDialogOpened: false,
      }),

  },
  DEFAULT_STATE
);
