import { put } from 'redux-saga/effects';

import {
  BILLING_ADDRESS,
  SHIPPING_ADDRESS,
  SHIPPING_ADDRESS_ID,
  ME
} from '../constants/api';

import {
  setBillingAddress,
  setShippingAddresses,
  setContactData,
  successChangeBillingAddress,
  successChangeShippingAddress,
  successChangeContactData
} from '../actions/address';
import * as a from '../actions/address';

import { openErrorSnack } from '../actions/startpage';

import { callHttp } from '../utils/api';
import { get, patch, del, post } from '../utils/httpUtil';

export function* getBillingAddress() {
  try {
    const address = yield callHttp(get, BILLING_ADDRESS);
    yield put(setBillingAddress({ address: address }));
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* patchBillingAddress({ payload }) {
  try {
    const { address } = payload;
    yield callHttp(patch, BILLING_ADDRESS, address);
    yield put(successChangeBillingAddress());
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* getShippingAddresses() {
  try {
    const addresses = yield callHttp(get, SHIPPING_ADDRESS);
    yield put(setShippingAddresses({ addresses: addresses }));
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* patchShippingAddress({ payload }) {
  try {
    const { address } = payload;
    yield callHttp(patch, SHIPPING_ADDRESS_ID(address.id), address);
    yield put(a.getShippingAddresses());
    yield put(successChangeShippingAddress());
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* deleteShippingAddress({ payload }) {
  try {
    const { address } = payload;
    yield callHttp(del, SHIPPING_ADDRESS_ID(address.id), '');
    yield put(a.getShippingAddresses());
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* postShippingAddress({ payload }) {
  try {
    const { address } = payload;
    yield callHttp(post, SHIPPING_ADDRESS, address);
    yield put(a.getShippingAddresses());
    yield put(successChangeShippingAddress());
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* getContactData() {
  try {
    const contactData = yield callHttp(get, ME);
    yield put(setContactData({ contactData: contactData }));
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}

export function* patchContactData({ payload }) {
  try {
    const { address } = payload;
    yield callHttp(patch, ME, address);
    yield put(successChangeContactData());
  } catch (err) {
     yield put(openErrorSnack(err.message));
  }
}
