import React from 'react';

import { Link } from 'react-router-dom';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import ReactSVG from 'react-svg';
import {openLoginForm} from "../../../../actions/auth";
import {connectTo} from "../../../../utils/generic";
const AccountIcon = props => {
  const { t, classes, openLoginForm, token } = props;

  return (
      <div className={classes.mainIcon}>
          {token ?
              <Link to={"/account"}>
              <div className={classes.topAccount}>
                  <ReactSVG
                      svgClassName="menu-icon colored-font"
                      src="/updated_images/svg/user-circle 15px.svg"
                  />
              </div>
          </Link>
              :
                  <div className={classes.topAccount}>
                      <ReactSVG
                          svgClassName="menu-icon colored-font"
                          src="/updated_images/svg/user-circle 15px.svg"
                          onClick={ openLoginForm}
                      />
                  </div>
          }

          <span className={classes.tooltipAccount}>{t('myAccount')}</span>
      </div>
);
};

export default connectTo(
    (state) => ({
        token: state.auth.token,
    }),
    {
        openLoginForm,
    },
 withStyles(styles)(React.memo(AccountIcon)))
