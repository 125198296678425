export default theme => ({
  checkboxContainer: {
    display: 'flex',
    fontWeight: 'bold',
    color: 'black',
    alignItems: 'center',
    fontSize: '0.8125em',
  
  },
  link:{
    color: theme.palette.primary.linkColor,
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    cursor: 'pointer'
  },
});
