export default theme => ({
  panel: { background: "black",
    //background: theme.palette.primary.grey,
  },
  summaryText: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 16
  },
  summary: {
    margin: 0,
  },
  root: {
    color: 'red',
    background: 'red'
  }
});
