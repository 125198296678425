export default (theme) => ({
  notDiscountPrice: {
    textAlign: 'right',
    textDecoration: 'line-through',
    marginRight: '6px',
  },
  centred: {
    textAlign: 'center',
  },
  discountPrice: {
    margin: '5px 0',
  },
  discount: {
    margin: '5px 0',
    color: theme.palette.primary.blue,
  },
  productName: {
    fontSize: '1.1em',
    fontWeight: '500',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
