import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import qs from 'qs';

import { connectTo } from '../../utils/generic';
import styles from './style';

import Page from '../page';
import classNames from 'classnames';
import ChooseCustomerCard from '../../components/start/cards/ChooseCustomerCard';

class ServiceHomePage extends React.Component {
  componentDidMount() {
    !this.props.token && this.props.history.replace('/');
  }

  componentDidUpdate(prevProps) {
    !this.props.token && this.props.history.replace('/');

    if (prevProps.match.params.alias !== this.props.match.params.alias) {
      const category = this.props.match.params.alias;
      if (category) {
        this.props.getCategory({ alias: category });
        this.props.findProducts(this.getParams());
      }
    }
  }

  getParams = () => {
    const requestParams = qs.parse(this.props.location.search.replace('?', ''));
    const category = this.props.match.params.alias;
    const params = {
      ...requestParams,
      category: requestParams.category || category,
    };
    return params;
  };

  render() {
    const { classes } = this.props;

    return (
      <Page>
        <div
          className={classNames(
            'layout-section',
            'login-section',
            classes.background
          )}
          style={{
            background: /*linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ `url('${this.props.backgroundUrl}') no-repeat`,
            height: '100%',
          }}
        >
          <ChooseCustomerCard className='loginCard' />
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,

    backgroundUrl: state.staticContent.backgroundUrl,
    role: state.auth.role,
  }),
  {},
  withNamespaces([
    'accountPage',
    'homePage',
    'orderPage',
    'startPage',
    'productPage',
  ])(withStyles(styles)(ServiceHomePage))
);
