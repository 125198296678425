import {DISCOUNT_VALUE_PERCENT, PRICE_TYPE_DISCOUNTED, PRICE_TYPE_INDIVIDUAL} from "../constants/common";

export function getShowDiscount  (settings) {
    if (settings && settings.length) {
        const showDiscount = settings.find(x => x.name === 'show_discount');
        if (showDiscount && showDiscount.value === '1') return true
    }
    else return false
}

export const getCartItemDiscountDetails = (cartItem, showDiscount) => {
    const voucherDiscount = cartItem.discounts.filter(discount => discount.discount.valueType === DISCOUNT_VALUE_PERCENT)[0] || null;
    const rowPrice = (cartItem.discountPrice || cartItem.price) - (voucherDiscount?.discountAmountPerItem || 0);
    const discountValue = voucherDiscount ? voucherDiscount.discount.value : (cartItem.priceType === PRICE_TYPE_DISCOUNTED ? cartItem.discountValue : null);

    const discountPriceCellClass = cartItem.priceType === PRICE_TYPE_INDIVIDUAL ? 'individualPrice' : (showDiscount && (cartItem.priceType === PRICE_TYPE_DISCOUNTED || voucherDiscount) ? 'discountPriceWithOriginalPrice' : '');
    const haveDiscounts =
        cartItem.priceType === PRICE_TYPE_INDIVIDUAL ||
        cartItem.priceType === PRICE_TYPE_DISCOUNTED ||
        voucherDiscount;

    return {rowPrice, discountValue, discountPriceCellClass, haveDiscounts}
}