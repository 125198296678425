import React from 'react';

import Table from '@material-ui/core/Table';
import SmallTableRow from '../../../../common/SmallTableRow';
/* import TableRow from '@material-ui/core/TableRow'; */
import TableBody from '@material-ui/core/TableBody';

import classNames from 'classnames';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { connectTo } from '../../../../../utils/generic';

import { formatMoney } from '../../../../../utils/localization';
import { getLocaleDateString } from '../../../../../utils/localization';
import { addProduct } from '../../../../../actions/cart';
// import ReactSVG from 'react-svg';
import {getCartItemDiscountDetails, getShowDiscount} from "../../../../../utils/discounts";

class DetailsTableSmall extends React.Component {
  // state = {};
  // componentDidMount() {}

  render() {
    const { classes, t, order, settings } = this.props;
    const showDiscount = getShowDiscount(settings)

    return (
      <div className={classes.container} style={{ marginTop: 0 }}>
        <div
          className={classNames(
            classes.header,
            classes.headerRed,
            'account_panelHeader'
          )}
        >
          <div className={classes.headerTitle}>{t('Order Details')}</div>
          <div className={classes.orderDetails}>
            {t('Order Date')} {getLocaleDateString(order.createdAt)} |{' '}
            {t('Order Number')} {order.orderId} | {t('User')}{' '}
            {order.user && order.user.firstName + ' ' + order.user.lastName}
          </div>
        </div>
        <div className={classes.table}>
          {order.items.map((item, i) => {
              const {
                  rowPrice,
                  discountValue,
                  discountPriceCellClass,
                  haveDiscounts
              } = getCartItemDiscountDetails(item, showDiscount);
            return (
              <Table className={classes.Table}>
                <TableBody>
                  <SmallTableRow title={t('Details')} item={i + 1} />
                  <SmallTableRow
                    title={t('Position')}
                    item={
                      <div>
                        <div className={classes.productName}>
                          {item.productName}
                        </div>
                        <div>
                          {t('Material Number')}: {item.code}
                        </div>
                      </div>
                    }
                  />
                  <SmallTableRow
                    title={t('Price')}
                    item={
                      showDiscount && haveDiscounts ? (
                          <div>
                              <div
                                  className={classNames(
                                      discountPriceCellClass,
                                      classes.priceContainer
                                  )}
                              >
                                  <div style={{marginLeft: -4}}> {formatMoney(rowPrice, order.currency)} </div>
                                  {showDiscount && haveDiscounts &&
                                  <div className={classes.notDiscountPrice}>
                                      {item.price > 0 && formatMoney(item.price, order.currency)}
                                  </div>
                                  }
                              </div>
                              {showDiscount && discountValue &&
                                <div className={classes.discountValue}>
                                {t('cart:discount')} {discountValue}%
                            </div>}
                        </div>
                      ) : (
                        formatMoney(rowPrice, order.currency)
                      )
                    }
                  />

                  <SmallTableRow title={t('Quantity')} item={item.quantity} />
                  <SmallTableRow
                    title={t('Subtotal')}
                    item={formatMoney(
                      rowPrice * item.quantity,
                      order.currency
                    )}
                  />

                  <SmallTableRow
                    title={''}
                    item={
                      item.isOrderable ? (
                        <img
                          alt={t('Re-Order')}
                          onClick={() =>
                            this.props.addProduct({
                              code: item.code,
                              quantity: item.quantity,
                            })
                          }
                          title={t('Re-Order')}
                          className={classNames(
                              classes.cartIcon,
                              classes.available,
                              'cart-icon-small',
                            'available'
                          )}
                          style={{
                            position: 'relative',
                          }}
                          src='/updated_images/svg/cart-plus 15px.svg'
                        />
                      ) : (
                        <img
                          alt={t('Re-Order')}
                          title={t('Re-Order')}
                          className={classNames(
                            classes.cartIcon,
                              'cart-icon-small',
                            'cart-icon'
                          )}
                          style={{
                            position: 'relative',
                          }}
                          src='/updated_images/svg/cart-plus 15px grey.svg'
                        />
                      )
                    }
                  />
                </TableBody>
              </Table>
            );
          })}
        </div>
      </div>
    );
  }
}
export default connectTo(
  (state) => ({
      settings: state.staticContent.settings
  }),
  { addProduct },
  withNamespaces(['myOrders', 'cart'])(withStyles(styles)(DetailsTableSmall))
);
