import { createReducer } from 'redux-act';
import * as a from '../actions/account';

import { formatMoney } from '../utils/localization';

const DEFAULT_STATE = {
  users: [],
  newUser: {},
  userRoles: [],
  isUserFormOpen: false,
  isNewUserChanged: false,
  successAdd: false,
  isModeEdit: false,
  me: {},
  isDataChanged: false,
  orders: [],
  currentPage: 1,
  itemsCount: null,
  itemsTotal: null,
  pagination: [],
  selectedOrder: {},
  isDeleteUserOpen: false,
  newTicketTitle: null,
  customerTicketsList: [],
  ticketMessages: {},
  messagesFromDate: {},
  lastMessageSent: null,
  messageSent: {},
};

export default createReducer(
  {
    [a.setMe]: (state, user) => {
        sessionStorage.setItem('currency', user.customer.currency);
      return {
        ...state,
        me: user,
      };
    },
    [a.setUsers]: (state, { users }) => ({
      ...state,
      isUserFormOpen: false,
      users: users.map((u) => ({
        ...u,
        userName: u.firstName + ' ' + u.lastName,
      })),
      isDataChanged: true,
    }),
    [a.setChanged]: (state) => ({
      ...state,
      isDataChanged: false,
    }),
    [a.setNewUserInput]: (state, target) => ({
      ...state,
      isNewUserChanged: true,
      newUser: {
        ...state.newUser,
        [target.name]: target.value,
      },
    }),
    [a.openNewUserForm]: (state) => ({
      ...state,
      isUserFormOpen: true,
      isModeEdit: false,
      newUser: {},
    }),
    [a.openDeleteUser]: (state) => ({
      ...state,
      isDeleteUserOpen: true,
    }),
    [a.closeDeleteUser]: (state) => ({
      ...state,
      isDeleteUserOpen: false,
    }),
    [a.openEditUserForm]: (state, user) => ({
      ...state,
      isNewUserChanged: false,
      isUserFormOpen: true,
      isModeEdit: true,
      newUser: {
        ...user,
        cartLimit: user.cartLimit ? formatMoney(user.cartLimit) : '',
      },
    }),
    [a.closeNewUserForm]: (state) => ({
      ...state,
      isUserFormOpen: false,
      newUser: {},
    }),
    [a.setUserRoles]: (state, { roles }) => ({
      ...state,
      userRoles: roles,
    }),
    [a.setUserTitle]: (state, title) => ({
      ...state,
      isNewUserChanged: true,
      newUser: {
        ...state.newUser,
        title: title,
      },
    }),
    [a.setOrders]: (state, orders) => ({
      ...state,
      orders: orders.items,
      currentPage: orders.currentPage,
      itemsCount: orders.itemsCount,
      itemsTotal: orders.itemsTotal,
      pagination: orders.pagination,
    }),
    [a.setOrderDetails]: (state, order) => ({
      ...state,
      selectedOrder: order,
    }),
    [a.setNewTicketTitle]: (state, title) => ({
      ...state,
      newTicketTitle: title,
    }),
    [a.setCustomerTickets]: (state, customerTicketsList) => ({
      ...state,
      customerTicketsList,
    }),
    [a.setMessages]: (state, ticketMessages) => ({
      ...state,
      ticketMessages,
    }),
    [a.setMessagesFromDate]: (state, messagesFromDate) => ({
      ...state,
      messagesFromDate,
    }),
    [a.setLastMessageSent]: (state, lastMessageSent) => {
      if (state.lastMessageSent === null) {
        return {
          ...state,
          lastMessageSent,
        };
      } else {
        var date1 = Date.parse(lastMessageSent);
        var date2 = Date.parse(state.lastMessageSent);
        if (date1 > date2) {
          return {
            ...state,
            lastMessageSent,
          };
        } else {
          return {
            ...state,
          };
        }
      }
    },
    [a.setLastMessage]: (state, messageSent) => ({
      ...state,
      messageSent,
    }),
  },
  DEFAULT_STATE
);
