import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import {withNamespaces} from 'react-i18next';

import {connectTo} from '../../utils/generic';
import styles from './style';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import TypeModal from '../../components/home/TypeModal';
import classNames from 'classnames';
import {getProductTypes} from '../../actions/products';
import CategoryTileStandardMachine
  from "../../components/home/CategoryTileStandardMachine";

class StandardMachinePage extends React.Component {
  componentDidMount() {
    // !this.props.token && this.props.history.replace('/');
    this.props.getProductTypes();
  }

  findMachines = (productType) => {
      if (productType && productType.machines && productType.machines.length > 0)
      return true;
    else {
      if (
        productType &&
        productType.children &&
        productType.children.length > 0
      ) {
        let i;
        let result = null;
        for (i = 0; !result && i < productType.children.length; i++) {
          result = this.findMachines(productType.children[i]);
        }
        return result;
      } else return false;
    }
  };

  render() {
    const { classes, t, standardProducts } = this.props;

    return (
      <Page>
        <TypeModal
          open={this.props.open?this.props.open:false}
          handleClose={() => this.props.closeTypeModal()}
        />
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              {
                name: t('homePage:standard'),
                link: '/standard-machines-content',
              },
            ]}
          />
        </div>
         {/*<SearchBox />*/}
        <div
          className='main-content'
          style={{ flexWrap: 'wrap', marginBottom: '2em' }}
        >
          <div className={classNames(classes.titleContent, 'title-content')}>
            <div
              className={classNames(classes.mainTitle, 'light', 'main-title')}
            >
              {t('homePage:standard')}
            </div>
          </div>
          <div className='category-grid'>
            {standardProducts && standardProducts.length > 0
              ? standardProducts.map((productType, i) => {
                  return (
                    <div className='box' key={i}>
                      <CategoryTileStandardMachine
                        src={productType.image}
                        alt={productType.name}
                        tileTitle={productType.name}
                        tileSubtitle={''}

                        productTypeAlias={productType.alias}
                        deactivateBuyButton={!this.findMachines(productType)}
                      />
                        <a target="_blank"
                              href={productType.layoutLink ? productType.layoutLink
                            : 'https://www.tracto.com'}
                              className={classes.linkText}
                        >
                            <div>
                                {t('startPage:learnMore')}
                            </div>
                        </a>
                    {/*  <StandardMachineTileBar*/}
                    {/*    productTypeAlias={productType.alias}*/}
                    {/*    deactivateBuyButton={!this.findMachines(productType)}*/}
                    {/*    link={*/}
                    {/*      productType.layoutLink*/}
                    {/*        ? productType.layoutLink*/}
                    {/*        : 'https://www.tracto-technik.de'*/}
                    {/*    }*/}
                    {/*  />*/}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    standardProducts: state.home.standardProducts,
    backgroundUrl: state.staticContent.backgroundUrl,
  }),
  {
    getProductTypes,
  },
  withNamespaces(['accountPage', 'homePage', 'orderPage', 'startPage'])(
    withStyles(styles)(StandardMachinePage)
  )
);
