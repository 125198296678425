import { createReducer } from 'redux-act';
import * as a from '../actions/search';

const DEFAULT_STATE = {
  searchString: '',
  searchResult: {}
};

export default createReducer(
  {
    [a.setSearchString]: (state, str) => ({
      ...state,
      searchString: str
    }),
    [a.setSearchResult]: (state, searchResult) => ({
      ...state,
      searchResult: searchResult
    })
  },
  DEFAULT_STATE
);
