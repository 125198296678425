import { createAction } from 'redux-act';

export const getMe = createAction('getMe');
export const setMe = createAction('setMe');

export const getUsers = createAction('getUsers');
export const setUsers = createAction('setUsers');
export const addUser = createAction('addUser');
export const patchUser = createAction('patchUser');
export const deleteUser = createAction('deleteUser');

export const setNewUserInput = createAction('setNewUserInput');
export const openNewUserForm = createAction('openNewUserForm');
export const openEditUserForm = createAction('openEditUserForm');
export const closeNewUserForm = createAction('closeNewUserForm');

export const getUserRoles = createAction('getUserRoles');
export const setUserRoles = createAction('setUserRoles');

export const setUserTitle = createAction('setUserTitle');

export const setAddUserState = createAction('setAddUserState');
export const setChanged = createAction('setChanged');

export const patchMany = createAction('patchMany');
export const deleteMany = createAction('deleteMany');

export const getOrders = createAction('getOrders');
export const setOrders = createAction('setOrders');
export const getOrderDetails = createAction('getOrderDetails');
export const setOrderDetails = createAction('setOrderDetails');

export const openDeleteUser = createAction('openDeleteUser');
export const closeDeleteUser = createAction('closeDeleteUser');

export const setNewTicketTitle = createAction('setNewTicketTitle');
export const postNewTicketTitle = createAction('postNewTicketTitle');

export const getCustomerTickets = createAction('getCustomerTickets');
export const setCustomerTickets = createAction('setCustomerTickets');

export const postNewTicketMessage = createAction('postNewTicketMessage');

export const getMessages = createAction('getMessages');
export const setMessages = createAction('setMessages');

export const getMessagesFromDate = createAction('getMessagesFromDate');
export const setMessagesFromDate = createAction('setMessagesFromDate');

export const getMessageFile = createAction('getMessageFile');
export const postMessageRead = createAction('postMessageRead');

export const setLastMessageSent = createAction('setLastMessageSent');

export const setLastMessage = createAction('setLastMessage');
