import purple from '@material-ui/core/colors/purple';

export default (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    width: '100%',
  },
  cellCentred: {
    textAlign: 'center',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  smallUserName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  smallUserContact: {},
  iconAvatar: {
    backgroundColor: 'inherit',
    cursor: 'pointer',
  },
  actionIcon: {
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectedPage: {
    color: theme.palette.primary.buttonColor + ' !important',
  },
  colorSwitchBase: {
    color: purple[300],
    '&$colorChecked': {
      color: purple[500],
      '& + $colorBar': {
        backgroundColor: purple[500],
      },
    },
  },
  userEmail: {
    color: 'gray',
  },
  userName: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  colorBar: {},
  colorChecked: {},
  smallAvatar: {
    height: '30px',
    width: '30px',
    margin: '0 7px',
  },
  lastCell: {
    borderBottom: 'unset',
  },
  Table: {
    width: '100%',
    margin: '0 auto',
    fontSize: '0.8em',
  },
  tableRoot: { padding: '0 0 20px 0' },
  avatarRoot: { marginRight: '10px', width: '30px'},
});
