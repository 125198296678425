export default theme => ({
	detailRow: {
		display: 'flex',
		padding: '5px 0',
		borderBottom: '1px solid #dbdbdb'
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	firstRow: {
		color: theme.palette.primary.buttonColor,
		fontSize: '16px',
		alignText: 'left',
		width: '22%',
		margin: 'auto 0',
		height: '100%'
	},
	secondRow: {
		alignText: 'left',
		width: '78%',
		fontSize: '16px',
		wordWrap: 'break-word'
	}
});
