export default (theme) => ({
 iconWrapper: {
		height: 28,
    paddingTop: '10px'
	},
 inquiryButton: {
	background: 'white',
    border: '3px solid ',
    color: theme.palette.primary.buttonColor,
    borderColor: theme.palette.primary.buttonColor,
    letterSpacing: '1px',
    "&:hover": {
      color: theme.palette.primary.buttonColor,
    },
},
buttonText: {
	marginLeft: '10px' 
},
  inquiryIcon: {
    height: "12px",
    width: "17px",
    cursor: 'pointer'
  },
});
