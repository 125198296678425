import { lighten } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    marginBottom: '15px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.primary.slightlyGrey, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 20%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbarTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  toolbarBottomRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  allUsers: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
  },
  users: {
    width: '2em',
    height: '1.5em',
    borderRadius: '45%',
    marginRight: '5px',
  },
  activeUsers: {
    backgroundColor: '#d2d9df',
    color: '#000',
  },
  statsRow: {
    display: 'flex',
    marginRight: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '5px',
  },
  newIcon: {
    height: '25px',
    width: '25px',
    marginRight: '5px',
  },
  addUser: {
    color: theme.palette.primary.buttonColor,
    fontSize: '0.9em',
  },
  searchInput: {
    padding: '5px 0',
    width: '200px',
  },
  tableHeader: {
    fontSize: '2em',
    color: theme.palette.primary.linkColor,
  },
  bottomUserText: {
    fontSize: '0.85em',
  },
  addUserCol: {
    marginRight: '20px',
  },
  selectAction: {
    fontSize: '12.8px',
  },
  multipleActionContainer: {
    width: '168px',
  },
});
