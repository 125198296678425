import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import { withNamespaces } from 'react-i18next';

import FormBillingAddress from './forms/formBillingAddress';
import FormContactDetails from './forms/formContactDetails';
import ShippingCards from './tiles/shippingCards';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const MyData = (props) => {
  const { t, classes } = props;
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className='accordion-summary-typography-responsive'>
            {t('billingAddress')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormBillingAddress />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className='accordion-summary-typography-responsive'>
            {t('shippingAddress')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.shippingPanel}>
          <ShippingCards />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className='accordion-summary-typography-responsive'>
            {t('contactDetails')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormContactDetails />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withNamespaces('accountPage')(withStyles(styles)(MyData));
