import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import InputWrapper from '../../common/InputTitleWrapper';

import {
  getServiceCustomers,
  setServiceCustomers,
} from '../../../actions/static';
import { logout, login, setMyRole } from '../../../actions/auth';

import { openErrorSnack } from '../../../actions/startpage';
import { withRouter } from 'react-router';

class ChooseCustomerCard extends React.Component {
  state = {
    password: '',
    remember: true,
    customerEmail: null,
    disableProceed: true,
  };

  componentDidMount() {
    this.props.getServiceCustomers();
  }

  handleCustomerChange = (customer) => {
    if (customer && customer.user && customer.user.email) {
      sessionStorage.setItem('currency', customer.currency);
      this.setState((prevState) => {
        return { customerEmail: customer.user.email, disableProceed: false };
      });
    }
  };

  handleProceed = () => {
    const { customerEmail } = this.state;
    localStorage.removeItem('technician');
    localStorage.setItem('service', customerEmail);
    this.props.history.push('/home');
    this.props.setMyRole({ code: 'ROLE_USER' });
  };

  handleCancel = () => {
    this.props.logout();
    this.props.setMyRole({});
    this.props.setServiceCustomers([]);
    this.props.history.push('/');
  };

  render() {
    const { t, classes, serviceCustomers } = this.props;

    return (
      <div>
        <div className={classes.loginCard}>
          {/* <div className={classes.logoColumn}>
            <img
              className={classes.img}
              src='/images/glyphs/png/icon-account.png'
              alt='logo'
            />
          </div> */}
          <div className={classes.mainColumn}>
            <div className={classNames(classes.title, 'blue-header', 'medium')}>
              {t('startPage:Choose a customer')}
            </div>
            <div className='block-with-text'>
              {t('startPage:Please choose a customer')}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
              }}
            >
              <InputWrapper isRequire={true} header={t('startPage:customer')} headerClass={classes.customerHeader}>
                <Autocomplete
                  className={classes.textField}
                  classes={{ inputRoot: classes.autocompleteRoot }}
                  getOptionLabel={(option) => option.companyName || ''}
                  getOptionSelected={(option, value) =>
                    option._code === value._code
                  }
                  id='combo-box-demo'
                  onChange={(e, value) =>
                    value ? this.handleCustomerChange(value) : null
                  }
                  options={serviceCustomers}
                  placeholder={t('startPage:customer')}
                  renderInput={(params) => (
                    <TextField {...params} label='' variant='outlined' />
                  )}
                  style={{ width: '100%' }}
                />
              </InputWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  className={classNames(classes.proceedButton)}
                  classes={{ root: classes.signinBtnRoot }}
                  type='submit'
                  onClick={() => {
                    this.handleCancel();
                  }}
                >
                  {t('accountPage:cancel')}
                </Button>
                <Button
                  disabled={this.state.disableProceed}
                  classes={{ root: classes.signinBtnRoot }}
                  className={classNames(
                    this.state.disableProceed
                      ? classes.buttonDisabled
                      : classes.button,
                    classes.proceedButton
                  )}
                  type='submit'
                  onClick={() => this.handleProceed()}
                >
                  {t('startPage:Proceed as user')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    error: state.start.error,
    serviceCustomers: state.staticContent.serviceCustomers,
  }),
  {
    logout,
    login,
    setMyRole,
    getServiceCustomers,
    setServiceCustomers,
    openErrorSnack,
  },
  withRouter(
    withNamespaces(['accountPage', 'startPage'])(
      withStyles(styles)(ChooseCustomerCard)
    )
  )
);
