export default (theme) => ({
  topBar: {
    padding: '10px 0',
    backgroundColor: '#1b1b1b',
  },
  wrapper: {
    width: '100%',
    maxWidth: '1460px',
    margin: '0 auto',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  topbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 15px 0 0',
    msFlex: 1,
    flex: 1,
    maxWidth: '300px',
    color: '#fff',
  },
  autocompleteWrapper: {
    display: 'flex',
    flex: 3,
    justifyContent: 'flex-end',
  },
  proceedButton: {
    margin: '0 0 0 15px',
  },
  buttonDisabled: {
    backgroundColor: theme.palette.secondary.disabled,
  },
  button: {
    backgroundColor: theme.palette.primary.buttonColor,
  },
  disabledOnHover: {
    '&:hover': {
      color: 'grey',
    },
  },
});
