import { createReducer } from 'redux-act';
import * as a from '../actions/cart';

const DEFAULT_STATE = {
  cart: {},
  checkoutCart: {},
  shippingAddress: {},
  shippingOptions: [],
  shippingOption: {},
  paymentMethods: [],
  paymentMethod: {},
  orderResult: {},
  orderCompleted: false,
  isNewAddress: false,
  isEditable: false,
  // infoIsEditable: true,
  terms: [],
  comment: '',
};

export default createReducer(
  {
    [a.setCart]: (state, cart) => {
      return {
        ...state,
        cart: {
          ...cart,
          items:
            cart && cart.items
              ? cart.items.sort((a, b) => {
                  const aCode = a.product.code;
                  const bCode = b.product.code;
                  if (aCode > bCode) return 1;
                  else if (aCode < bCode) return -1;
                  else return 0;
                })
              : [],
        },
        paymentMethod: cart && cart.paymentMethod ? cart.paymentMethod : {},
        shippingOption: cart && cart.shippingMethod ? cart.shippingMethod : {},
        orderCompleted: false,
      };
    },
    [a.setCheckoutCart]: (state, cart) => {
          return {
              ...state,
              checkoutCart: {
                  ...cart,
                  items:
                      cart && cart.items
                          ? cart.items.sort((a, b) => {
                              const aCode = a.product.code;
                              const bCode = b.product.code;
                              if (aCode > bCode) return 1;
                              else if (aCode < bCode) return -1;
                              else return 0;
                          })
                          : [],
              },
              paymentMethod: cart && cart.paymentMethod ? cart.paymentMethod : {},
              shippingOption: cart && cart.shippingMethod ? cart.shippingMethod : {},
              orderCompleted: false,
          };
    },
    [a.setOrderResult]: (state, orderResult) => ({
      ...state,
      orderResult: orderResult,
      orderCompleted: true,
    }),
    [a.setCartShippingAddress]: (state, cart) => {
      return {
        ...state,
        isEditable: false,
        /*  isNewAddress: state.cart.shippingAddress ? (state.cart.shippingAddress.saveAddress !== undefined ?
          state.cart.shippingAddress.saveAddress : false) : false, */
      };
    },
    [a.setNewAddress]: (state, value) => {
      return {
        ...state,
        isNewAddress: value,
      };
    },

    [a.setDefaultShippingAddress]: (state, addresses) => {
      const result = addresses.filter((a) => a.isDefault);
      return {
        ...state,
        shippingAddress: state.cart.shippingAddress
          ? state.cart.shippingAddress
          : result.length
          ? result[0]
          : {},
        isNewAddress: state.cart.shippingAddress
          ? state.cart.shippingAddress.saveAddress !== undefined
            ? state.cart.shippingAddress.saveAddress
            : false
          : false,
        isEditable:
          state.cart.shippingAddress &&
          state.cart.shippingAddress.saveAddress !== undefined
            ? false
            : true,
        /*comment:  state.cart.shippingAddress ?  state.cart.comment  : '', */
      };

      // const address = result.length ? result[0] : {};
      // return {
      //   ...state,
      //   shippingAddress: address,
      //   isNewAddress: false
      // };
    },
    [a.setEditableShippingAddress]: (state) => ({
      ...state,
      isEditable: !state.isEditable,
    }),
    // [a.setEditableInfo]: (state) => ({
    //   ...state,
    //   infoIsEditable: !state.infoIsEditable,
    // }),
    [a.setInternalOrderNumber]: (state, value) => ({
      ...state,
      internalOrderNumber: value,
    }),
    [a.updateEmptyProduct]: (state, code) => {
      const products = state.cart.items.map((p) =>
        p.product.code === code ? { ...p, quantity: '' } : p
      );
      return {
        ...state,
        cart: {
          ...state.cart,
          items: products,
        },
      };
    },
    [a.setAsDefaultBillingAddress]: (state, address) => {
      let shippingAddress = {
        ...address
      };
      delete shippingAddress.customer;
      shippingAddress.code = address.customer.code;
      shippingAddress.companyName = address.customer.companyName;

      return {
        ...state,
        shippingAddress: shippingAddress,
        isNewAddress: false,
        isEditable: true,
      };
    },
    [a.setShippingAddressInput]: (state, target) => {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          [target.name]: target.value,
        },
        isNewAddress: true,
      };
    },

    [a.setSaveAddress]: (state, value) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        saveAddress: value,
      },
      isNewAddress: true,
    }),

    [a.setShippingTitle]: (state, title) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        title: title,
      },
      isNewAddress: true,
    }),
    [a.setShippingCountry]: (state, country) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        country: country.code
      },
      isNewAddress: true,
    }),
    [a.setShippingAddress]: (state, address) => ({
      ...state,
      shippingAddress: address,
      isNewAddress: false,
      isEditable: true,
    }),
    [a.setNewShippingAddress]: (state) => ({
      ...state,
      isNewAddress: false,
      isEditable: false,
    }),

    [a.setShippingOptions]: (state, options) => ({
      ...state,
      shippingOptions: options,
    }),
    [a.setShippingOption]: (state, option) => ({
      ...state,
      shippingOption: {
        id: option.shippingMethod.id.toString(),
      },
    }),

    [a.setPaymentMethods]: (state, options) => ({
      ...state,
      paymentMethods: options,
    }),
    [a.setPaymentMethod]: (state, option) => ({
      ...state,
      paymentMethod: {
        id: option.paymentMethod.id.toString(),
      },
    }),
    [a.setTerms]: (state, terms) => {
      const parsed = terms.map((t) => {
        if (t.label.match('LINK].*[/LINK]')) {
          const labelLink = t.label.match('LINK].*[/LINK]')[0].slice(5, -6);
          const start = t.label.match(/.*\[LINK\]/)[0].slice(0, -6);
          const end = t.label.match(/\[\/LINK\].*/)[0].slice(7);
          return {
            ...t,
            labelLink: labelLink,
            start: start,
            end: end,
            checked: false,
          };
        } else return t;
      });

      return {
        ...state,
        terms: parsed,
      };
    },
    [a.setComment]: (state, value) => ({
      ...state,
      comment: value,
    }),
    [a.checkTerm]: (state, i) => {
      const terms = [...state.terms];
      terms[i].checked = !terms[i].checked;
      return {
        ...state,
        terms: terms,
      };
    },
  },
  DEFAULT_STATE
);
