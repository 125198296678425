export const WORD_EXTENSIONS = [
  ".doc",
  ".docm",
  ".docx",
  ".docb",
  ".dot",
  ".dotm",
  ".odt",
];
export const EXCEL_EXTENSIONS = [
  ".xls",
  ".xlt",
  ".xlm",
  ".xlsx",
  ".xlsm",
  ".xltx",
  ".xltm",
  ".csv",
];
export const AUDIO_EXTENSIONS = [
  ".3gp",
  ".aac",
  ".flac",
  ".m4a",
  ".m4p",
  ".mp3",
  ".ogg",
  ".wav",
  ".webm",
  ".wma",
  ".mpga",
];
export const VIDEO_EXTENSIONS = [
  ".mp4",
  ".3gp",
  ".ogg",
  ".wmv",
  ".webm",
  ".flv",
  ".avi",
  ".mov",
];
export const IMAGE_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".svg",
  ".tiff",
];
export const PDF_EXTENSIONS = [".pdf"];
export const TXT_EXTENSIONS = [".txt"];
