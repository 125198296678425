import React from 'react';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../utils/generic';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs/';

import Checkout from '../../components/checkout/Checkout';
import ProgressBar from '../../components/checkout/ProgressBar';
import ShoppingCart from '../../components/checkout/ShoppingCart';
import OrderCompleted from '../../components/checkout/OrderCompleted';

import { getDefaultShippingAddress } from '../../actions/cart';

import { getCart, order } from '../../actions/cart';
import { cartIcons } from '../../constants/progressBar';

class CheckoutPage extends React.Component {
  state = {
    stage: 1,
  };

  componentDidMount() {
    !(this.props.token || this.props.showPricesForGuest) && this.props.history.replace('/');
    this.props.toCheckout && this.setState({ stage: 2 });
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
    !localStorage.getItem("technician") && this.props.getCart();
    this.state.stage !== 1 && this.props.getDefaultShippingAddress();
  }

  componentDidUpdate(prevProps) {
    !(this.props.token || this.props.allowGuestUser) && this.props.history.replace('/');
    prevProps.orderCompleted !== this.props.orderCompleted &&
      this.props.orderCompleted &&
      this.setState({ stage: 3 });
  }

  render() {
    const { classes, t } = this.props;
    const { stage } = this.state;

    return (
      <Page>
        <div
          className='layout-section home-section'
        >
          <BreadCrumbs
            links={[
              { name: t('home'), link: '/home' },
              { name: t('cart'), link: '#' },
            ]}
          />
        </div>
        <div className={classNames('main-content', classes.content)}>
          <div className={classes.header}>{t('jobStatus')}</div>

          <ProgressBar stage={this.state.stage} icons={cartIcons} />

          <div className={classes.tableContainer}>
            {stage === 1 && (
              <ShoppingCart
                prev={() => this.props.history.goBack()}
                next={() => this.props.history.push('/checkout')}
              />
            )}

            {stage === 2 && (
              <Checkout
                prev={() => this.setState({ stage: stage - 1 })}
                next={() =>
                  this.props.order({
                    comment: this.props.comment,
                    internalOrderNumber: this.props.internalOrderNumber,
                  })
                }
              />
            )}
            {stage === 3 && <OrderCompleted />}
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  (state) => ({
    products: state.cart.products,
    token: state.auth.token,
    comment: state.cart.comment,
    internalOrderNumber: state.cart.internalOrderNumber,
    orderCompleted: state.cart.orderCompleted,
    backgroundUrl: state.staticContent.backgroundUrl,
    allowGuestUser: state.staticContent.allowGuestUser,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {
    getCart,
    order,
    getDefaultShippingAddress,
  },
  withNamespaces('checkoutPage')(withStyles(styles)(withRouter(CheckoutPage)))
);
