export default (theme) => ({
  tileContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'inline-block',
    marginBottom: '73.5px',
  },
  // no such className - titleContainer2
  titleContainer2: {
    // height: 100,
    // backgroundColor: 'black',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // textTransform: 'uppercase'

  },
  // no such className - titleText
  titleText:{
    // width: 250,
    // wordWrap: 'break-word',
    // textAlign: 'center',
    // color:' #00BEFF',
    // fontWeight: 'bold',
    // fontSize: 40,
    // bottom: 10,
    // position: 'relative',
  },
  tileImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleContainer: {
    width: '100%', 
    left: /* '25%' */ '0',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    position: 'absolute',
    // bottom: '1.7rem',
    wordWrap: 'break-word',
    padding: '5px 3px',
    marginTop: '10px',
  },
  titleSpanContainer: {
    // display: 'inline-block',
    // background: /* 'rgba(0, 0, 0, 0.6)' */ theme.palette.primary.buttonColor,
    // color: 'white',
    // padding: '3px',
    fontWeight: 'bold',
    // letterSpacing: '0.05em',
    // textAlign: 'center',
    // width: '180px',
    // height: '60px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    fontSize: '26px',
    lineHeight: '28px'
  },
  subtitleContainer: {
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '1rem',
  },
  subtitleSpanContainer: {
    fontSize: '1rem',
    fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});
