import React from "react";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import styles from "./style";
import { withStyles } from "@material-ui/core/styles";
import { withNamespaces } from "react-i18next";

const InquiryIconButton = (props) => {
  const { t, classes, onClick, asImage } = props;
  const buttonText = t("guestInquiry");

  return (
<>{asImage ?
      <div  className={classes.iconWrapper}>
      <img
        alt={buttonText}
        onClick={onClick}
        src="/images/icons/icon-price-inquiry.svg"
        className={classes.inquiryIcon}
        />
    </div> 

 :
      <Button className={classes.inquiryButton} onClick={onClick}>
        {
          <img
            src={"/images/icons/icon-price-inquiry.svg"}
            alt={buttonText}
            className={classes.inquiryIcon}
          />
        }
        <span className={classes.buttonText}>{buttonText}</span>
      </Button>
    }
  </>
    
  )
};

export default withNamespaces("productPage")(
  withStyles(styles)(InquiryIconButton)
);
