import { createReducer } from 'redux-act';
import * as a from '../actions/startpage';
import * as art from '../actions/info';

const DEFAULT_STATE = {
  registrationEmail: '',
  IsRegistrationOpen: false,
  registrationType: '',
  IsRegistrationSend: false,
  IsForgotPassOpen: false,
  IsForgotPassSend: false,
  error: [],
  articles: [],
  successMessage: '',
};

export default createReducer(
  {
    [a.closeForgotPass]: (state) => ({
      ...state,
      IsForgotPassSend: false,
      IsForgotPassOpen: false,
    }),
    [a.receiveForgotPass]: (state) => ({
      ...state,
      IsForgotPassSend: true,
    }),
    [a.openForgotPass]: (state) => ({
      ...state,
      IsForgotPassOpen: true,
    }),

    [a.openRegistration]: (state) => ({
      ...state,
      IsRegistrationOpen: true,
    }),
      [a.setRegistrationType]: (state, type) => ({
      ...state,
        registrationType: type,
    }),
    [a.closeRegistration]: (state) => ({
      ...state,
        registrationType: '',
        IsRegistrationSend: false,
      IsRegistrationOpen: false,
    }),
    [a.receiveRegisterData]: (state) => ({
      ...state,
      IsRegistrationSend: true,
    }),
    [a.setRegistrationEmail]: (state, email) => ({
      ...state,
      registrationEmail: email,
    }),
    [a.openErrorSnack]: (state, error) => {
      let errors = [];

      if (
        error.code === 400 &&
        error.fields &&
        error.fields.length &&
        error.fields.length !== 0
      ) {
        errors = error.fields;
      } else if (error.code === 500) {
        errors.push({
          name: '500 Error',
          message: 'Internal Server Error',
        });
      } else {
        errors.push({
          name: `${error.code} Error`,
          message: error.message,
        });
      }

      return {
        ...state,
        error: errors,
      };
    },

    [a.openSimpleErrorSnack]: (state, error) => ({
      ...state,
      error: [
        {
          name: 'Error',
          message: error,
        },
      ],
    }),
    [a.closeSnack]: (state) => ({
      ...state,
      successMessage: '',
    }),
    [a.openSuccessSnack]: (state, message) => ({
      ...state,
      successMessage: message,
    }),
    [art.setArticles]: (state, articles) => ({
      ...state,
      articles: articles,
    }),
  },
  DEFAULT_STATE
);
