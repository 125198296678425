import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';

import {
  setAlias,
  getQuickOrderOptions
} from '../../../actions/quickorder';

import Downshift from '../../../components/common/Downshift';
import classNames from 'classnames';

class SearchBar extends React.Component {
  render() {
    const { classes, suggestions } = this.props;

    return (
      <div className={classes.content}>
        <div className={classNames('order-search-bar', classes.bar)}>
          <Downshift
            suggestions={suggestions}
            setSearchString={text => this.props.getQuickOrderOptions({ searchValue: text, onlyOrderable: true })}
            selectProduct={alias => this.props.setAlias(alias)}
          />
          <div className={classNames('order-triangle', classes.triangle)} />
        </div>
      </div>
    );
  }
}

export default connectTo(
  state => ({
    alias: state.quickorder.alias,
    suggestions: state.quickorder.suggestions
  }),
  {
    setAlias,
    getQuickOrderOptions,
  },
  withNamespaces('orderPage')(withStyles(styles)(SearchBar))
);
