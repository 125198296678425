import { createReducer } from "redux-act";
import * as a from "../actions/products";

const DEFAULT_STATE = {
  product: {},
  selectedProduct: {},
  isShowingDetails: false,
  selectedDetail: {},
  schema: {},
  schemas: [],
  highlightedProductLabel: null,
  sensorData: {},
  isInquiryOpen: false,
  inquiryProduct: {},
};

export default createReducer(
  {
    [a.openInquiry]: (state) => ({
      ...state,
      isInquiryOpen: true,
    }),
    [a.closeInquiry]: (state) => ({
      ...state,
      isInquiryOpen: false,
      inquiryProduct: {}
    }),
    [a.setInquiryProduct]: (state, { product }) => ({
      ...state,
      inquiryProduct: product,
    }),
    [a.setProduct]: (state, { product }) => ({
      ...state,
      product: product,
      selectedProduct: product
    }),
    [a.selectProduct]: (state, { product }) => ({
      ...state,
      selectedProduct: product,
      isShowingDetails: false
    }),
    [a.showDetails]: (state, { product }) => ({
      ...state,
      isShowingDetails: true,
      selectedDetail: product
    }),
    [a.hideDetails]: state => ({
      ...state,
      isShowingDetails: false
    }),
    [a.setImage]: (state, { image }) => ({
      ...state,
      schema: image
    }),
    [a.setImages]: (state, { images }) => ({
      ...state,
      schemas: images
    }),
    [a.setHighlightedProduct]: (state, label) => ({
      ...state,
      highlightedProductLabel: label !== state.highlightedProductLabel ? label : null
    }),
    [a.setProductToTree]: (state, { product }) => {
      const root = Object.assign({}, state.product);
      let stack = [];
      stack = stack.concat(root.children);
      while (stack.length) {
        let head = stack.pop();
        if (head.product.code === product.code) {
          head.product.children = product.children;
          // break;
        }
        stack = stack.concat(head.product.children);
      }

      return {
        ...state,
        product: root
      };
    },
    [a.setMachineSensorData]: (state, sensorData) => ({
      ...state,
      sensorData
    })
  },
  DEFAULT_STATE
);
