import React from 'react';

import classNames from 'classnames';
import NumericInput from 'react-numeric-input';
import { TextField } from '@material-ui/core';

const SMALL_SCREEN = 750;

/* <TextField
    id="outlined-number"
    value={row.quantity}
      onChange={e => {
        let value = 1;
        if (e.target.value !== '') {
          value = e.target.value.replace(/([^0-9])/, '');
          this.props.updateProduct({
            sku: row.product ? row.product.sku : row.sku,
            quantity: value
          });
        } else {
          this.props.updateEmptyProduct(
            row.product ? row.product.sku : row.sku
          );
        }
      }}
      type="number"
      className={classes.quantity}
 /> */


class InputWrapper extends React.Component {
  state = {
    smallMode: window.innerWidth <= SMALL_SCREEN
  };

  updateDimensions = () => {
    if (
      (!this.state.smallMode && window.innerWidth <= SMALL_SCREEN) ||
      (this.state.smallMode && window.innerWidth > SMALL_SCREEN)
    )
      this.setState({ smallMode: !this.state.smallMode });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    const { className, value, onChange } = this.props;
    const { smallMode } = this.state;
    return !smallMode ?
      <NumericInput
        value={value}
        min={0}
        className={classNames('number-input', className)}
        strict
        mobile={false}
        onChange={onChange}
      />
      :
      <TextField
        value={value}
        type="number"
        className={className}
        onChange={e => onChange(e.target.value.replace(/([^0-9])/, ''))}
      />
      ;
  }
};

export default InputWrapper;
