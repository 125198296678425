export default theme => ({
	main: {
		display: 'flex',
		marginTop: '10px',
		width: '100%',
		flexWrap: 'wrap'
	},
	treeColumn: {
		flex: 1,
		margin: '10px 20px 0 0',
		width: 'calc(30% - 20px)',
		paddingRight: 15,
		maxHeight: 500,
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxHeight: 200,
		},
	},
	contentColumn: {
		minWidth: '70%',
		paddingRight: 20,
		maxHeight: 500,
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxHeight: 300
		},
	}
});
