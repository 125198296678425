import React from 'react';

import Table from '@material-ui/core/Table';
import SmallTableRow from '../../../common/SmallTableRow';
import TableBody from '@material-ui/core/TableBody';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import classNames from 'classnames';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { connectTo } from '../../../../utils/generic';

import { formatMoney } from '../../../../utils/localization';
import {
  updateProduct,
  deleteProduct,
  updateEmptyProduct,
} from '../../../../actions/cart';
import { Link } from 'react-router-dom';
import NumberInput from '../../../common/NumberInput';
import {getShowDiscount} from "../../../../utils/discounts";
import {PRICE_TYPE_DISCOUNTED, PRICE_TYPE_INDIVIDUAL} from "../../../../constants/common";

class CartDetailsTableSmall extends React.Component {
  render() {
    const { classes, t, cart, settings } = this.props;
    const showDiscount = getShowDiscount(settings)

    return (
      <div className={'machine-table'}>
        <div className={classes.tableTitle}>{t('cart')}</div>

        {cart.items.map((row, i) => {
          const rowPrice = row.discountPrice || row.price;
          return (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div className={classNames(classes.circle, 'image-cropper')}>
                    <img
                      alt={row.product ? row.product.name : ''}
                      src={
                        row.product.image && row.product.image.length
                          ? row.product.image[0].url
                          : '/images/product_example_image.png'
                      }
                    />
                  </div> */}
                <Typography
                  className={classNames(
                    'accordion-summary-typography-responsive',
                    'accordion-summary-responsive'
                  )}
                  /* style={{ marginLeft: '10px' }} */
                >
                  {row.product.name}
                </Typography>
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails
                className={classes.rows}
                classes={{ root: classes.detailsRoot }}
              >
                <Table className={classes.Table}>
                  <TableBody>
                    <SmallTableRow title={t('position')} item={i + 1} />

                    <SmallTableRow
                      title={t('materialNumber')}
                      item={
                        <Link
                          to={`/product/${row.product.alias}`}
                          className={classes.description}
                        >
                          <div
                            className={classNames(
                              classes.descriptionRow,
                              classes.sku
                            )}
                          >
                            {row.product.code}
                          </div>
                        </Link>
                      }
                    />
                    <SmallTableRow
                      title={t('productPrice')}
                      item={
                        row.discountPrice ? (
                          <div /* className={classes.discountCol} */>
                            <div
                                className={classNames(
                                    row.priceType === PRICE_TYPE_INDIVIDUAL && 'individualPrice' ,
                                    (showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED) ? 'discountPriceWithOriginalPrice' :'discountPrice',
                                    classes.priceContainer
                              )}
                            >
                              <div>
                                {rowPrice
                                  ? formatMoney(rowPrice)
                                  : t('requestPrice')}
                                {row.priceType === PRICE_TYPE_INDIVIDUAL && '*'}

                              </div>

                              {
                                  showDiscount && row.discountPrice ? (
                                    <div className={classes.notDiscountPrice}>
                                      { row.price > 0 && formatMoney(row.price)}
                                    </div>
                                ) : null
                              }
                            </div>
                            {showDiscount && row.priceType === PRICE_TYPE_DISCOUNTED &&
                            <div className={classes.discountValue}>
                              {t('cart:discount')} {row.discountValue}%
                            </div>}
                          </div>
                        ) : rowPrice ? (
                          formatMoney(rowPrice)
                        ) : (
                          t('requestPrice')
                        )
                      }
                    />
                    <SmallTableRow
                      title={t('quantity')}
                      item={
                        <NumberInput
                          value={row.quantity}
                          className={classes.quantity}
                          onChange={(val) => {
                            if (val !== 0) {
                              this.props.updateProduct({
                                code: row.code, //row.product ? row.product.sku : row.sku,
                                quantity: val,
                              });
                            } else {
                              this.props.updateEmptyProduct(
                                row.product.code
                              );
                            }
                          }}
                        />
                      }
                    />
                    <SmallTableRow
                      title={t('subtotal')}
                      item={
                        rowPrice
                          ? formatMoney(rowPrice * row.quantity)
                          : t('requestPrice')
                      }
                    />
                    <SmallTableRow
                      title={''}
                      item={
                        <img
                          alt='cart'
                          className='icon-default clickable cart-icon deleteIcon'
                          onClick={() => {
                            this.props.deleteProduct({
                              code: row.code, //row.product ? row.product.sku : row.sku
                            });
                          }}
                          title={t('tooltips:delete')}
                          src='/updated_images/svg/bin 15px.svg'
                        />
                      }
                    />
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    settings: state.staticContent.settings
  }),
  { updateProduct, deleteProduct, updateEmptyProduct },
  withNamespaces(['checkoutPage', 'tooltips'])(withStyles(styles)(CartDetailsTableSmall))
);
