import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';
import { withNamespaces } from 'react-i18next';

import Paper from '@material-ui/core/Paper';

import TableLarge from './TableLarge';
import TableSmall from './TableSmall';
import DeleteUser from '../DeleteUser';
import Pagination from '../pagination/';
import EnhancedTableToolbar from '../toolbar/';

import {
  getUsers,
  deleteUser,
  patchUser,
  addUser,
  setUsers,
  openEditUserForm,
  patchMany,
  deleteMany,
  openDeleteUser,
  closeDeleteUser,
  setChanged,
} from '../../../../actions/account';

import { getColor, stableSort, getSorting } from '../../../../utils/table';

const SMALL_SCREEN = 750;

class UserTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'userName',
    selected: [],
    active: [],
    filteredData: [],
    colors: {},
    page: 0,
    rowsPerPage: 10,
    visiblePages: 7,
    allUsers: 0,
    activeUsers: 0,
    deleteId: -1,
    smallMode: window.innerWidth <= SMALL_SCREEN,
  };

  updateDimensions = () => {
    if (
      (!this.state.smallMode && window.innerWidth <= SMALL_SCREEN) ||
      (this.state.smallMode && window.innerWidth > SMALL_SCREEN)
    )
      this.setState({ smallMode: !this.state.smallMode });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidMount() {
    this.props.getUsers();
    window.addEventListener('resize', this.updateDimensions);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.isChanged) {
      const users = props.data;
      const userColors = {};
      users.map(
        (user) =>
          (userColors[user.id] = state.colors[user.id]
            ? state.colors[user.id]
            : getColor())
      );

      props.setChanged();
      return {
        ...state,
        allUsers: users.length,
        activeUsers: users.filter((x) => x.isEnabled === true).length,
        filteredData: users,
        colors: userColors,
      };
    }
    return state;
  }

  handleFilterChange = (e) => {
    var nameFilter = e.target.value.toLowerCase();
    var filteredData = this.props.data.filter((x) =>
      (x.firstName + ' ' + x.lastName).toLowerCase().includes(nameFilter)
    );

    this.setState({
      filteredData,
      allUsers: filteredData.length,
      activeUsers: filteredData.filter((x) => x.isEnabled === true).length,
      page: 0,
    });
  };

  handleRequestSort = (event, property) => {
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc')
      order = 'asc';

    this.setState({ order, orderBy: property });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked)
      this.setState((state) => ({
        selected: state.filteredData.map((n) => n.id),
      }));
    else this.setState({ selected: [] });
  };

  handleSelect = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) newSelected = newSelected.concat(selected, id);
    else newSelected = selected.filter((e) => e !== id);

    this.setState({ selected: newSelected });
  };

  handleActivate = (event, user) => {
    this.props.patchUser({ user: { id: user.id, isEnabled: !user.isEnabled } });
  };

  handleChangePage = (page) => {
    this.setState({ page });
  };

  handleActivateMany = () => {
    const data = this.state.selected.map((v) => ({ id: v, isEnabled: true }));
    this.props.patchMany({ users: data });
    this.setState({ selected: [] });
  };

  handleDeactivateMany = () => {
    const data = this.state.selected.map((v) => ({ id: v, isEnabled: false }));
    this.props.patchMany({ users: data });
    this.setState({ selected: [] });
  };

  handleDeleteMany = () => {
    this.props.deleteMany({ userIds: this.state.selected });
    this.setState({ selected: [] });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  showDeleteUser = () => (
    <DeleteUser
      open={this.props.deleteOpen}
      handleDelete={() =>
        this.props.deleteUser({
          id: this.state.deleteId,
        })
      }
      handleClose={() => {
        this.setState({ deleteId: -1 });
        this.props.closeDeleteUser();
      }}
    />
  );

  handleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
    this.props.openDeleteUser();
  };

  render() {
    const { classes, t } = this.props;
    const {
      filteredData,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
    } = this.state;

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        {this.props.deleteOpen && this.showDeleteUser()}
        <EnhancedTableToolbar
          numSelected={selected.length}
          allUsers={this.state.allUsers}
          activeUsers={this.state.activeUsers}
          handleFilterChange={this.handleFilterChange}
          handleActivateMany={this.handleActivateMany}
          handleDeactivateMany={this.handleDeactivateMany}
          handleDeleteMany={this.handleDeleteMany}
          t={t}
        />
        <div className={classes.tableWrapper}>
          {this.state.smallMode ? (
            <TableSmall
              users={stableSort(
                this.state.filteredData,
                getSorting(order, orderBy)
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              order={order}
              orderBy={orderBy}
              selected={selected}
              emptyRows={emptyRows}
              handleSelectAllClick={this.handleSelectAllClick}
              handleRequestSort={this.handleRequestSort}
              handleSelect={this.handleSelect}
              colors={this.state.colors}
              handleActivate={this.handleActivate}
              openEditUserForm={this.props.openEditUserForm}
              handleDelete={this.handleDelete}
              isSelected={this.isSelected}
            />
          ) : (
            <TableLarge
              users={stableSort(
                this.state.filteredData,
                getSorting(order, orderBy)
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              order={order}
              orderBy={orderBy}
              selected={selected}
              emptyRows={emptyRows}
              handleSelectAllClick={this.handleSelectAllClick}
              handleRequestSort={this.handleRequestSort}
              handleSelect={this.handleSelect}
              colors={this.state.colors}
              handleActivate={this.handleActivate}
              openEditUserForm={this.props.openEditUserForm}
              handleDelete={this.handleDelete}
              isSelected={this.isSelected}
            />
          )}
        </div>
        <div className='center-all' style={{ margin: '20px' }}>
          <Pagination
            total={Math.ceil(filteredData.length / this.state.rowsPerPage)}
            current={page}
            display={this.state.visiblePages}
            onChange={this.handleChangePage}
            selectedClass={classes.selectedPage}
          />
        </div>
      </Paper>
    );
  }
}

export default connectTo(
  (state) => ({
    data: state.account.users,
    isChanged: state.account.isDataChanged,
    deleteOpen: state.account.isDeleteUserOpen,
  }),
  {
    getUsers,
    setUsers,
    deleteUser,
    patchUser,
    addUser,
    setChanged,
    openEditUserForm,
    patchMany,
    deleteMany,
    openDeleteUser,
    closeDeleteUser,
  },
  withNamespaces(['accountPage', 'tooltips', 'Validation Messages'])(
    withStyles(styles)(UserTable)
  )
);
