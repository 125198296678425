import React from 'react';
import Modal from '@material-ui/core/Modal';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';

import ForgotPasswordForm from './Form';
import ForgotPasswordOverlay from './Overlay';
import classNames from 'classnames';

const ForgotPassword = props => {
  const { classes, open, handleClose, t } = props;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      className="modal"
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <div className={classNames(classes.modalHeader, 'bold')}>
          <div className={classes.header_content}>{t('passwordReset')}</div>
          <div className={classes.closeContainer} onClick={handleClose}>
            <img
              alt="cross"
              height="20"
              width="20"
              src="/images/icons/X-black.png"
            />
          </div>
        </div>
        {props.isSending ? (
          <ForgotPasswordOverlay handleClose={handleClose} />
        ) : (
          <ForgotPasswordForm />
        )}
      </div>
    </Modal>
  );
};

export default connectTo(
  state => ({
    isSending: state.start.IsForgotPassSend,
  }),
  {},
  withNamespaces('resetPassPage')(withStyles(styles)(ForgotPassword))
);
