import { createAction } from 'redux-act';

export const setStickers = createAction('setStickers');
export const getStickers = createAction('getStickers');

export const setMenu = createAction('setMenu');
export const getMenu = createAction('getMenu');

export const setSettings = createAction('setSettings');
export const getSettings = createAction('getSettings');

export const setFooter = createAction('setFooter');
export const getBackground = createAction('getBackground');
export const setBackground = createAction('setBackground');

export const getPublicData = createAction('getPublicData');

export const getSocialLinks = createAction('getSocialLinks');
export const setSocialLinks = createAction('setSocialLinks');

export const setAnalytics = createAction('setAnalytics');

export const getServiceCustomers = createAction('getServiceCustomers');
export const setServiceCustomers = createAction('setServiceCustomers');

export const setReCaptchaSiteKey = createAction('setReCaptchaSiteKey');

export const setAllowGuestUser = createAction('setAllowGuestUser');

export const setShowPricesForGuest = createAction('setShowPricesForGuest');