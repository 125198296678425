import { takeLatest } from 'redux-saga/effects';

import * as authActions from '../actions/auth';
import * as authSagas from './auth';

import * as newsActions from '../actions/news';
import * as newsSagas from './news';

import * as genericActions from '../actions/generic';
import * as genericSagas from './generic';

import * as productsActions from '../actions/products';
import * as productsSagas from './products';

import * as tipsActions from '../actions/tips';
import * as tipsSagas from './tips';

import * as metadataActions from '../actions/metadata';
import * as metadataSagas from './metadata';

import * as addressActions from '../actions/address';
import * as addressSagas from './address';

import * as accountActions from '../actions/account';
import * as accountSagas from './account';

import * as infoActions from '../actions/info';
import * as infoSagas from './info';

import * as cartActions from '../actions/cart';
import * as cartSagas from './cart';

import * as searchActions from '../actions/search';
import * as searchSagas from './search';

import * as staticActions from '../actions/static';
import * as staticSagas from './static';

import * as quickorderActions from '../actions/quickorder';
import * as quickorderSagas from './quickorder';

import * as listingPageActions from '../actions/listingPage';
import * as listingPageSagas from './listingPage';

export default function* saga() {
  const relations = [
    [newsActions, newsSagas],
    [authActions, authSagas],
    [tipsActions, tipsSagas],
    [infoActions, infoSagas],
    [cartActions, cartSagas],
    [staticActions, staticSagas],
    [searchActions, searchSagas],
    [accountActions, accountSagas],
    [addressActions, addressSagas],
    [genericActions, genericSagas],
    [productsActions, productsSagas],
    [metadataActions, metadataSagas],
    [quickorderActions, quickorderSagas],
    [listingPageActions, listingPageSagas]
  ];

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName];
     
      if (saga) yield takeLatest(action.getType(), saga)
    }
  }
}
