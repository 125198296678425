export default theme => ({
  contentRow: {
    display: 'flex',
    maxWidth: '1100px'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '7px'
  },
  textField: {
    width: '100%',
    marginBottom: '9px'
  },
  textInput: {
    width: '100%'
  },

  controlMd: {
    width: '47%'
  },
  controlSm: {
    width: '40%'
  },
  controlLg: {
    width: '60%'
  },
  multiRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  saveButton: {
    backgroundColor: theme.palette.primary.buttonColor
  },
  cancelButton: {
    color: '#4d4d4d',
    border: '2px solid #4d4d4d',
    backgroundColor: '#fff'
  },
  shippingModal: {
    maxWidth: '550px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0 0 0',
    outline: 'none',
    borderTop: '5px solid red',
    borderTopColor: theme.palette.primary.buttonColor
  },
  headerContent: {
    fontSize: '1.4em',
    color: '#000',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px 0 20px',
    fontSize: '14px'
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 20px'
  },
  button: {
    width: '150px',
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff'
  }
});
