import { createReducer } from 'redux-act';
import * as a from '../actions/address';

const DEFAULT_STATE = {
  shippingAddresses: [],
  shippingAddress: {
    isDefault: false
  },
  billingAddress: {},
  isEditableBillingAddress: false,
  contactData: {},
  isEditableContactData: false,
  isShippingOpen: false,
  isModeEdit: true,
  useAsShipping: false,
  error: ''
};

export default createReducer(
  {
    ///// Billing may be we need separated file?
    [a.setBillingAddress]: (state, { address }) => {
      return {
        ...state,
        billingAddress: address
      };
    },
    [a.setBillingAddressInput]: (state, target) => ({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        [target.name]: target.value
      }
    }),
    [a.setBillingCustomerInput]: (state, target) => ({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        customer: {
          ...state.billingAddress.customer,
          [target.name]: target.value
        }
      }
    }),
    [a.setBillingAddressCountry]: (state, value) => ({
      ...state,
      billingAddress: {
        ...state.billingAddress,
        country: value
      }
    }),
    [a.successChangeBillingAddress]: state => ({
      ...state,
      isEditableBillingAddress: false
    }),
    [a.editBillingAddress]: state => ({
      ...state,
      isEditableBillingAddress: true
    }),
    [a.useAsShippingAddress]: state => {
      let shippingAddress = {
        ...state.billingAddress,
        ...state.billingAddress.customer
      };
      delete shippingAddress.customer;
      delete shippingAddress.code;

      return {
        ...state,
        isShippingOpen: true,
        isModeEdit: false,
        useAsShipping: true,
        shippingAddress: shippingAddress
      };
    },
    ////////////

    //// Shipping ///
    [a.setShippingAddressInput]: (state, target) => {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          [target.name]: target.value
        }
      };
    },
    [a.setShippingTitle]: (state, title) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        title: title
      }
    }),
    [a.setShippingCountry]: (state, country) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        country: country.code
      }
    }),

    [a.addShippingAddress]: state => ({
      ...state,
      isShippingOpen: true,
      isModeEdit: false,
      shippingAddress: {}
    }),
    [a.editShippingAddress]: (state, address) => ({
      ...state,
      isShippingOpen: true,
      isModeEdit: true,
      shippingAddress: address
    }),
    [a.successChangeShippingAddress]: state => ({
      ...state,
      isShippingOpen: false
    }),

    [a.setShippingAddresses]: (state, { addresses }) => {
      if (addresses) addresses.sort((a, b) => b.isDefault - a.isDefault);
      return {
        ...state,
        shippingAddresses: addresses
      };
    },

    [a.closeShipping]: state => ({
      ...state,
      isShippingOpen: false,
      useAsShipping: false
    }),
    /////

    ///// Contact data /////
    [a.setContactData]: (state, { contactData }) => ({
      ...state,
      contactData: contactData
    }),
    [a.setContactDataInput]: (state, target) => ({
      ...state,
      contactData: {
        ...state.contactData,
        [target.name]: target.value
      }
    }),
    [a.setContactDataTitle]: (state, title) => ({
      ...state,
      contactData: {
        ...state.contactData,
        title: title
      }
    }),
    [a.successChangeContactData]: state => ({
      ...state,
      isEditableContactData: false
    }),
    [a.editContactData]: state => ({
      ...state,
      isEditableContactData: true
    })
  },
  DEFAULT_STATE
);
