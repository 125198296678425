import React from 'react';
import classNames from 'classnames';

import { Select, MenuItem } from '@material-ui/core';
import styles from './style';
import { withNamespaces } from 'react-i18next';
import { connectTo } from '../../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import ProductList from './productList';
import Pagination from '../../account/userManagement/pagination';
import qs from 'qs';
import ReactSVG from 'react-svg';

class SearchResult extends React.Component {
  state = {
    sort: 'asc',
    rowsPerPage: 20,
    visiblePages: 7,
    searchValue: '',
  };
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.scrollToContentRef();
    }
    const search = qs.parse(this.props.location.search.replace('?', ''));
    if (prevState.searchValue !== search.searchValue) {
      this.setState((prevState) => {
        return { searchValue: search.searchValue };
      });
    }
  }

  scrollToContentRef = () => {
    window.scrollTo({
      top: this.contentRef.current.offsetTop,
      behavior: 'smooth', // optional
    });
  };

  handleSortingChange = (e) => {
    const val = e.target.value;
    this.setState({ sort: val });
    this.props.changeSorting(val);
  };

  render() {
    const { t, classes, searchResult, handleChangePage, page } = this.props;
    const products = searchResult.items;

    return (
      <div
        ref={this.contentRef}
        className={classNames('search-result', classes.content)}
      >
        <div className={classNames(classes.header, 'search-results-header')}>
          <div>
            {t('searchResult')} "{this.props.searchString}"
          </div>
          <div className={classes.sortBar}>
            <div className={classes.sortLabel}>{t('sortBy')}</div>
            <Select
              value={this.state.sort}
              onChange={this.handleSortingChange}
              classes={{ root: 'search-results-select' }}
            >
              <MenuItem
                value={'asc'}
                className='search-results-select'
                classes={{ padding: classes.muiListPadding }}
              >
                A-Z
              </MenuItem>
              <MenuItem
                value={'desc'}
                className='search-results-select'
                classes={{ padding: classes.muiListPadding }}
              >
                Z-A
              </MenuItem>
            </Select>
          </div>
        </div>
        {searchResult && searchResult.itemsTotal ? (
          <div className={classNames(classes.itemsTotal, 'items-total')}>
            {searchResult.itemsTotal}{' '}
            {searchResult.itemsTotal === 1
              ? t('itemsTotalSingular')
              : t('itemsTotal')}
          </div>
        ) : null}
        {products && products.length === 0 ? (
          <div className={classes.noResultsInfo}>
            <ReactSVG
              svgClassName='info-icon'
              src={`/images/icons/Checkout/info-white-48dp.svg`}
              alt='Info'
            />
            <span style={{ marginLeft: '10px' }}>
              {t(
                'Unfortunatelly no results could be found for the search term "{{ text }}". Please try again with another search term.',
                {
                  text: this.state.searchValue,
                }
              )}
            </span>
          </div>
        ) : null}
        <div>
          <ProductList products={products} />
        </div>
        <div className='center-all'>
          <Pagination
            total={searchResult.pagination && searchResult.pagination.length}
            current={page}
            display={this.state.visiblePages}
            onChange={handleChangePage}
            selectedClass={classes.selectedPage}
          />
        </div>
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    searchResult: state.search.searchResult,
  }),
  {},
  withNamespaces('searchPage')(withStyles(styles)(withRouter(SearchResult)))
);
