import { createAction } from 'redux-act'

export const getQuickOrderOptions = createAction('getQuickOrderOptions');
export const setQuickOrderOptions = createAction('setQuickOrderOptions');

export const getBatchProducts = createAction('getMultiProducts');
export const extendOrder = createAction('extendOrder');
export const addProductInOrder = createAction('addProductInOrder');
export const emptyOrder = createAction('emptyOrder');
export const setProductInOrder = createAction('setProductInOrder');
export const deleteProductInOrder = createAction('deleteProductInOrder');
export const editProductInOrder = createAction('editProductInOrder');

export const setAlias = createAction('setAlias');
