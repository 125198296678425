export default theme => ({
  cellCentred: {
    textAlign: 'left',
    padding: '10px 20px',
    color: 'black'
  },
  row: {
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
})