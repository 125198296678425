import React from 'react';

import {Dialog} from '@material-ui/core';

import styles from './style';
import {withNamespaces} from 'react-i18next';
import {connectTo} from '../../../utils/generic';
import {withStyles} from '@material-ui/core/styles';

import InquiryForm from './Form';
import {getTitles} from '../../../actions/metadata';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {selectLng} from "../../../utils/localization";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";


class ModalInquiry extends React.Component {
  componentDidMount() {
    this.props.getTitles();
  }


  render() {
    const {classes, open, handleClose, t, reCaptchaSiteKey, fullScreen} = this.props;

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={reCaptchaSiteKey}
            language={selectLng()}
        >
          <Dialog
              fullScreen={fullScreen}
              aria-labelledby='simple-modal-title'
              aria-describedby='simple-modal-description'
              open={open}
              onClose={() => handleClose()}
              classes={{
                paperFullScreen: classes.paperFullScreen,
              }}
          >
            <div
                className={
                  classes.formContent
                }
            >
              <div className={classes.modalHeader}>
                <div className='title-header'>{t('productInquiry')}</div>
                <IconButton
                    className={classes.iconButton}
                    aria-label="close"
                    onClick={handleClose}
                >
                  <CloseIcon/>
                </IconButton>
              </div>
              <InquiryForm fullScreen={fullScreen}/>
            </div>
          </Dialog>
        </GoogleReCaptchaProvider>
    );
  }
}

export default connectTo(
    (state) => ({
      reCaptchaSiteKey: state.staticContent.reCaptchaSiteKey,
      isInquiryOpen: state.productHome.isInquiryOpen,
    }),
    {
      getTitles,
    },
    withNamespaces('inquiryPage')(withStyles(styles)(withMobileDialog()(ModalInquiry)))
);
