export class RequestError {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

export class ValidationError {
  constructor(message) {
    this.message = message;
  }
}
