import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ShippingCards from './Tiles/shippingCards';
import classnames from 'classnames';

class ShippingModal extends React.Component {
  render() {
    const { classes, open, handleClose, t } = this.props;

    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        className={classnames('modal')}
        maxWidth="md"
        onClose={() => handleClose()}
      >
        <div className={classes.dialog}>
          <div style={{ width: '100%' }}>
            <div className={classes.modalHeader}>
              <div className={classes.headerContent}>
                {t('shippingAddress')}
              </div>
              <div
                style={{ padding: 10, cursor: 'pointer' }}
                onClick={() => handleClose()}
              >
                <img
                  alt="close"
                  height="20"
                  width="20"
                  src="/images/icons/X-black.png"
                />
              </div>
            </div>
          </div>
          <DialogContent>
            <ShippingCards handleClose={handleClose} />
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

export default withNamespaces('checkoutPage')(
  withStyles(styles)(ShippingModal)
);
