import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import styles from './style';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connectTo } from '../../../../utils/generic';

import LogoutIcon from './LogoutIcon';
import LoginIcon from './LoginIcon';
import AccountIcon from './AccountIcon';
import CheckoutIcon from './CheckoutIcon';
import Logo from './Logo';
import LanguageSelector from "./LanguageSelector";
import SearchBoxHeader from "../../SearchBoxHeader";

const TopHeader = (props) => {
  const { t, classes, handleDrawerOpen, isDrawerOpen, token, showPricesForGuest, match } = props;

  return (
    <div className={classes.topHeader}>
      <div className='main-content main-content-header'>
        <div className={classes.topContent}>
          <div className='row top-menu-row'>
            <div className={classNames(
                classes.accountContainerLogo,
                'header-items',
                'logo-topHeader'
            )}>
              <Logo token={token} />
            </div>
            <div className='md-search-box'>
                  <>
                    <div className={classes.mdSearchBox}>
                      <SearchBoxHeader />
                    </div>
                  </>
            </div>

              <div className={classNames(
                  classes.accountContainer,
                  'header-items' )}>

                { (token || showPricesForGuest) && <span  className={'md-collapse'}>
                  <CheckoutIcon t={t}/>
                 </span>}
                 
                 {token ?  <span className={'md-collapse'}> <AccountIcon t={t} /> </span> : <AccountIcon t={t} /> }
               
                <LanguageSelector t={t} />
                {token && <span className={'md-collapse'}>
                 <LogoutIcon t={t}/>
                 </span>}
              </div>

            { !token && match.path === '/page/:alias' ? (
              <div
                className={classNames(
                  classes.accountContainer,
                  'header-items',
                  'md-collapse'
                )}
              >
                <LoginIcon t={t} />
              </div>
            ) : null}

            {/*{token ? (*/}
            {/*    <div*/}
            {/*        className={classNames(*/}
            {/*            classes.accountContainer,*/}
            {/*            'header-items',*/}
            {/*            'md-collapse'*/}
            {/*        )}*/}
            {/*    >*/}
            {/*      <LogoutIcon t={t} />*/}
            {/*    </div>*/}
            {/*) : match.path === '/page/:alias' ? (*/}
            {/*    <div*/}
            {/*        className={classNames(*/}
            {/*            classes.accountContainer,*/}
            {/*            'header-items',*/}
            {/*            'md-collapse'*/}
            {/*        )}*/}
            {/*    >*/}
            {/*      <LoginIcon t={t} />*/}
            {/*    </div>*/}
            {/*) : null}*/}

              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  isDrawerOpen && classes.hide,
                  'menu-collapse',
                  'colored-font'
                )}
              >
                <MenuIcon />
              </IconButton>
            {/*remove Flags: */}
            {/*<Flags t={t} />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.isDrawerOpen === nextProps.isDrawerOpen ||
    prevProps.token === nextProps.token
  );
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    showPricesForGuest: state.staticContent.showPricesForGuest,
  }),
  {},
  withNamespaces('startPage')(
    withRouter(withStyles(styles)(React.memo(TopHeader, areEqual)))
  )
);
