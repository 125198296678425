export default theme => ({
  content: {
    position: 'relative'
  },
  bar: {
    background: theme.palette.primary.buttonColor,
    padding: '20px 0',
    position: 'relative',
    right: 'calc(50% + 165px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textContainer: {
    width: '250px',

    // position: 'relative',
    // right: '10px'
  },
  text: {
    fontSize: '1.25em',
  },
  triangle:{
    position: 'absolute',
    left: '100%',
    top: '0',
    borderRightColor: 'transparent',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    border: 'calc(77px / 2) solid',
    borderColor: theme.palette.primary.buttonColor
  }
});