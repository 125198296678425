export default (theme) => ({
  tableCell: {
    verticalAlign: 'middle',
    padding: '5px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  titleTableCell: {
    width: '40%',
    fontWeight: '500',
  },
  itemTableCell: {
    width: '60%',
    fontWeight: '100',
  },
});
