import { createAction } from 'redux-act'

export const setTitles = createAction('setTitles')
export const getTitles = createAction('getTitles')

export const setOrderStatuses = createAction('setOrderStatuses')
export const getOrderStatuses = createAction('getOrderStatuses')

export const getCountries = createAction('getCountries')
export const setCountries = createAction('setCountries')

export const getTerm = createAction('getTerm')
export const setTerm = createAction('setTerm')
