export default theme => ({
  list: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    margin: '35px 0'
  },
  productList: {
      width: '100%'
  }
});
