export default (theme) => ({
  content: {
    flexWrap: 'wrap',
    /* justifyContent: 'flex-start' */
  },
  details: {
    margin: '20px 0 0 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    paddingBottom: 70
  },
  tabBar: {
    width: '100%',
  },
  tabPage: {
    width: '100%',
  },
});
