import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

const EmailField = (props) => {
  const { classes, email, setNewUserInput, t } = props;

  return (
    <div className={classNames(classes.content_row, classes.controlMd)}>
      <div className={classes.textField}>
        <div className={classNames(classes.label, 'bold', 'required-field')}>
          {t('email')}
        </div>
        <TextField
          name='email'
          value={email || ''}
          placeholder='Email'
          className={classes.textField}
          onChange={(e) => setNewUserInput(e.target)}
        />
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.email === nextProps.email;
}

export default withStyles(styles)(React.memo(EmailField, areEqual));
