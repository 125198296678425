import { createReducer } from 'redux-act';
import * as a from '../actions/task';

const DEFAULT_STATE = {
  tasks: 0
};

export default createReducer(
  {
    [a.addTask]: state => ({
      ...state,
      tasks: state.tasks + 1
    }),
    [a.deleteTask]: state => ({
      ...state,
      tasks: state.tasks - 1
    })
  },
  DEFAULT_STATE
);
