import { put, call } from 'redux-saga/effects';

import {
  LOGIN,
  RESETPASSWORD,
  UNBLOCKACCOUNT,
  FORGOTPASSWORD,
  REFRESHTOKEN,
  ME,
  MY_ROLE,
  //REGISTER_WITH_VAT,
  //REGISTER_WITH_CODE,
  REGISTER
} from '../constants/api';

import { SESSION_EXPIRATION } from '../constants/common';

import {
  logout,
  receiveAuthData,
  successResetPassword,
  setMyRole,
} from '../actions/auth';

import {
  receiveForgotPass,
  receiveRegisterData,
  openErrorSnack,
  openSimpleErrorSnack,
  openSuccessSnack,
} from '../actions/startpage';

import { callHttp } from '../utils/api';
import { post, get } from '../utils/httpUtil';
import { toastr } from 'react-redux-toastr';
import i18next from '../config/i18nconfig';

import { sagaMiddleware } from '../middleware';

i18next.loadNamespaces(['messages', 'unblockAccount', 'toastMessage']);

export function* login({ payload }) {
  const { email, password, remember } = payload;
  try {
    const data = yield callHttp(post, LOGIN, { email, password });
    if(data) {
      if (!remember) {
        setTimeout(() => {
          sagaMiddleware.run(function* f() {
            yield put(logout(true));
          });

          toastr.info(
              i18next.t('toastMessage:session expired'),
              i18next.t('toastMessage:re-enter credentials')
          );
        }, SESSION_EXPIRATION);
      }

      yield put(
          receiveAuthData({
            token: data.token,
            refreshToken: data.refreshToken,
            remember: remember,
          })
      );
      const role = yield callHttp(get, MY_ROLE);
      yield put(setMyRole(role));
      if (role && role.code && role.code === 'ROLE_SERVICE') {
        localStorage.setItem('technician', true);
      }
      if (role && role.code && role.code === 'ROLE_USER') {
        const user = yield callHttp(get, ME);
        sessionStorage.setItem('currency', user.customer.currency);
      }
      setTimeout(() => {
        window.location.reload()
      }, 0);
    }
  } catch (err) {
    yield put(openSimpleErrorSnack(i18next.t('messages:Wrong creds')));
  }
}

export function* refreshToken() {
  try {
    localStorage.removeItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    let remember = true;
    const tokenExpired = localStorage.getItem('sessionExpired');

    if (tokenExpired) remember = false;

    const data = yield call(post, REFRESHTOKEN, {
      refreshToken: refreshToken,
    });

    yield put(
      receiveAuthData({
        token: data.token,
        refreshToken: data.refreshToken,
        remember,
      })
    );
  } catch (err) {
    yield put(logout());
  }
}

// export function* registerUserWithCode({ payload }) {
//   const { data } = payload;
//   try {
//     yield callHttp(post, REGISTER_WITH_CODE, data);
//     yield put(receiveRegisterData());
//   } catch (err) {
//     yield put(openErrorSnack(err.message));
//   }
// }
//
// export function* registerUserWithVatId({ payload }) {
//   const { data } = payload;
//   try {
//     yield callHttp(post, REGISTER_WITH_VAT, data);
//     yield put(receiveRegisterData());
//   } catch (err) {
//     yield put(openErrorSnack(err.message));
//   }
// }

export function* registerUser({ payload }) {
  const { data } = payload;
  try {
    yield callHttp(post, REGISTER, data);
    yield put(receiveRegisterData());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* forgotPassword({ payload }) {
  const { email } = payload;
  try {
    yield callHttp(post, FORGOTPASSWORD, { email });
    yield put(receiveForgotPass());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* unblockAccount({ payload }) {
  try {
    const { email, token } = payload;
    yield callHttp(post, UNBLOCKACCOUNT, {
      email: email,
      token: token,
    });
    yield put(openSuccessSnack(i18next.t('unblockAccount:User with email {{ email }} unblocked', {email: email})));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* resetPassword({ payload }) {
  try {
    const { email, password, token } = payload;
    yield callHttp(post, RESETPASSWORD, {
      email: email,
      password: password,
      token: token,
    });
    yield put(successResetPassword());
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
  // null to empty string. Warning input
  // yield put(setBillingAddress({ address: address }))
}

export function* getMyRole() {
  try {
    const data = yield callHttp(get, MY_ROLE);
    yield put(setMyRole(data));
  } catch (err) {
    yield put(logout());
  }
}
