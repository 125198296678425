export default (theme) => ({
    textCard: {
        margin: '30px',
        textAlign: 'center',
        width: '60%',
    },
    textHeader: {
        marginBottom: '20px',
    },
    aboutSection: {
        width: '100%',
        minHeight: '370px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.slightlyGrey,
    },
    aboutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    cardRow: {
        margin: '15px',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    localContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        flex: '1 1'
    },

    locationInformation: {
        textAlign: 'center',
        backgroundColor: '#202020',
        padding: '10px 5px',
        height: '44px'
    },

  locationText: {
      textAlign: 'center',
      color: '#fff',
      fontSize: '16px',
      fontWeight: '300',
      display: 'inline-block',
      padding: '0 5px',
      verticalAlign: 'middle'
  },
  pageHeader: {
      paddingTop: '2px',
      },
  contentTitle: {
      display: 'flex',
      justifySelf: 'center',
      flexDirection: 'row',
      "@media (max-width: 450px)": {
        fontSize: '1.5rem',
      },
      "@media (min-width: 451px)": {
        fontSize: '1.75rem',
      }
  },

  customLoginCard: {
      display: 'flex',
      flexDirection: 'row',
  },
  greetingContainer: {
    height: '50vh',
      "@media (max-width: 900px)": {
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '0.5fr 3fr 0.5fr',
          alignItems: 'center',
      },
      "@media (min-width: 901px)": {
          display: 'grid',
          gridTemplateColumns: '3fr 2fr',
          gridTemplateRows: '0.5fr 2fr',
          width: '100%',
          alignItems: 'space-between',
          height: '60vh'
      },
      "@media (min-width: 1151px)": {
            maxHeight: '70vh',
            marginTop: '-15px'
      },
  },
greetingTitleContainer: {
    height: 'fit-content',
      "@media (max-width: 900px)": {
          display: 'grid',
          gridColumn: 1,
          gridRow: 1,
          padding: '25px 25px 0',
          "& .greeting-big-header": {
              fontSize: '1.8rem',
      },
    },

      "@media (min-width: 901px)": {
          display: 'grid',
          gridColumn: 1,
          gridRow: 1,
          padding: '45px 15px 0 25px',
          marginBottom: '50px',
          alignItems: 'start',

      "& .greeting-big-header": {
              fontSize: '2rem',
          },
      },
      "@media (min-width: 1151px)": {
          padding: '55px 0 15px 45px',
        '& .greeting-big-header': {
            fontSize: '2.5rem',
        }
      },
      "@media (min-width: 1280px)": {
      "& .greeting-big-header": {
          fontSize: '2.75rem'
        }
      },
      "@media (min-width: 1500px)": {
      '& .greeting-big-header': {
          fontSize: '3rem',
      }
  },
      "@media (min-width: 1920px)": {
        width: '65rem',
        padding: '27px 5px 25px 47px',

      '& .greeting-big-header': {
          fontSize: '3.25rem',
      }
      }
  },

greetingLoginContainer: {
  "@media (max-width: 900px)": {
          display: 'grid',
          gridColumn: 1,
          gridRow: 2,
          justifyItems: 'center',

      },

  "@media (min-width: 901px)": {
    display: 'grid',
    gridColumn: 2,
    gridRow: '1/3',
    justifyItems: 'center',
    paddingRight: '15px',

  },
    "@media (min-width: 1151px)": {
        paddingTop: '20px',
        marginTop: '-20px'
    },
//   "@media (min-width: 1280px)": {

//   },
// "@media (min-width: 1500px)": {
// }
},
greetingTextContainer: {
    height: 'fit-content',

  "@media (max-width: 900px)": {
          display: "grid",
          gridColumn: 1,
          gridRow: 3,
          margin: "0 15px",
          padding: "0 25px 25px",
          lineHeight: 1,
          "& .greeting-demo-text": {
              fontSize: "0.95rem"
          }
      },
      "@media (min-width: 901px)": {
          display: "grid",
          gridColumn: 1,
          padding: '15px 15px 15px 25px',
          gridRow: 2,
          width: '20.5rem',
        "& .greeting-demo-text": {
          fontSize: '0.98rem',
        }
      },
      "@media (min-width: 1151px)": {
          width: '35rem',
          padding: '15px 15px 0 45px',
          marginTop: '-20px',


      '& .greeting-demo-text': {
          fontSize: '1.1rem'
      }
      },
      "@media (min-width: 1280px)": {

"        & .greeting-demo-text": {
          fontSize: '1.2rem',
      }
      },
    "@media (min-width: 1500px)": {
      '& .greeting-demo-text': {
          fontSize: '1.3rem',
      }
      },
    "@media (min-width: 1920px)": {
      top: '300px',
      padding: '0 45px',


      '& .greeting-demo-text': {
          fontSize: '1.5rem',

      }
    }

},
customisedCard:{
"@media (min-width: 1280px)": {
  "& div":{
    padding: '26.3px',
    width: '280px',
    }
}
}

});
