export default (theme) => ({
  progressBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',

    position: 'relative',
  },
  container: {
    width: '100%',
    position: 'relative',
  },
  progressItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressItemTitle: {
    width: '140px',
    textAlign: 'center',
    fontSize: '0.85em',
    color: 'black',
    textTransform: 'uppercase',
    marginTop: '5px',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
  },
  line: {
    width: '50%',
    height: '100%',
  },
  lineContainer: {
    display: 'flex',
    position: 'absolute',
    width: '70%',
    left: '15%',
    height: '2px',
    // border: '1.2px solid #d5e7eb',
    top: '31.5px',
    zIndex: 0,
  },
  avatarIcon: {
    height: '30px',
    width: '30px',
  },
  textDisabled: {
    color: '#cdcdcd',
  },
});
