import React from 'react';

import Page from '../page';
import BreadCrumbs from '../../components/common/Breadcrumbs';
import Filter from '../../components/listingPage/Filter';
import SearchResult from '../../components/listingPage/SearchResult';

import qs from 'qs';
import classNames from 'classnames';

import {
  getCategories,
  getCategory,
  findProducts,
} from '../../actions/listingPage';
import { connectTo } from '../../utils/generic';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';

class ListingPage extends React.Component {
  componentDidMount() {
    // !this.props.token && this.props.history.replace('/');

    const category = this.props.match.params.alias;
    if (category) {
      this.props.getCategory({ alias: category });
      this.props.findProducts(this.getParams());
    }
  }

  componentDidUpdate(prevProps) {
    // !this.props.token && this.props.history.replace('/');
    if (prevProps.match.params.alias !== this.props.match.params.alias) {
      const category = this.props.match.params.alias;
      if (category) {
        this.props.getCategory({ alias: category });
        this.props.findProducts(this.getParams());
      }
    }
  }

  getParams = () => {
    const requestParams = qs.parse(this.props.location.search.replace('?', ''));
    const category = this.props.match.params.alias;
    const params = {
      ...requestParams,
      category: requestParams.category || category,
    };

    return params;
  };

  getPage = () => parseInt(this.getParams().page, 10) - 1 || 0;

  handleSorting = (param) => {
    const params = {
      ...this.getParams(),
      ...param,
    };

    this.findProducts(params);
  };

  handlePageChange = (page) => {
    this.setState({ page });

    const newParams = {
      ...this.getParams(),
      page: page + 1,
    };

    this.findProducts(newParams);
  };

  findProducts = (params) => {
    this.props.history.push({
      search:
        '?' + qs.stringify(params, { arrayFormat: 'brackets', encode: false }),
    });

    this.props.findProducts(params);
  };

  clear = () => {
    this.findProducts({ category: this.props.match.params.alias });
  };

  getLinks = (name, t) => [
    { name: t('home'), link: '/home' },
    { name, link: '#' },
  ];

/*  handleInStockFilterChange = (availabilityChecked) => {
    let inStock = null;
    if (availabilityChecked && availabilityChecked.length === 1) {
      if (availabilityChecked.includes('available')) {
        inStock = true;
      } else if (availabilityChecked.includes('unavailable')) {
        inStock = false;
      }
    }
    let newParams = null;
    if (inStock === null) {
      newParams = {
        ...this.getParams(),
        page: 1,
      };
      delete newParams.inStock;
    } else {
      newParams = {
        ...this.getParams(),
        page: 1,
      };
    }

    this.findProducts(newParams);
  };*/

  render() {
    const { classes, t, propsCategory, searchResult } = this.props;
    const requestParams = qs.parse(this.props.location.search.replace('?', ''));
    const category = this.props.match.params.alias;

    return category ? (
      <Page>
        <div
          className='layout-section home-section'

        >
          <BreadCrumbs
            links={this.getLinks(propsCategory ? propsCategory.name : '', t)}
          />
        </div>

        <div className='main-content'>
          <div className={classNames(classes.content, 'search-content')}>
            <Filter
              clear={this.clear}
              params={this.getParams()}
              handleCategoryChange={(category) =>
                this.handleSorting({ category: category, page: 1 })
              }
              handlePriceSelect={(prices) =>
                this.handleSorting({ ...prices, page: 1 })
              }
              handleFilterChange={(attribute) => {
                this.handleSorting({ attribute, page: 1 });
              }}
              filterAttributes={
                searchResult &&
                searchResult.filterResults &&
                searchResult.filterResults.attributes
                  ? searchResult.filterResults.attributes
                  : null
              }
/*              handleInStockFilterChange={(inStock) =>
                this.handleInStockFilterChange(inStock)
              }*/
            />

            <SearchResult
              page={this.getPage()}
              category={category}
              handleChangePage={this.handlePageChange}
              searchString={requestParams.searchValue}
              changeSorting={(order) =>
                this.handleSorting({ order: order, page: 1 })
              }
            />
          </div>
        </div>
      </Page>
    ) : null;
  }
}

export default connectTo(
  (state) => ({
    token: state.auth.token,
    propsCategory: state.listingPage.category,
    backgroundUrl: state.staticContent.backgroundUrl,
    searchResult: state.listingPage.searchResult,
  }),
  {
    getCategories,
    getCategory,
    findProducts,
  },
  withNamespaces('searchPage')(withStyles(styles)(withRouter(ListingPage)))
);
