export default (theme) => ({
  tableTitle: {
    fontSize: '2.3em',
    marginBottom: '8px',
    color: '#000',
  },
  notDiscountPrice: {
    textAlign: 'right',
    textDecoration: 'line-through',
    marginRight: '6px',
    color: theme.palette.primary.blackPrice,
  },
  quantity: {
    width: '70px',
  },
  sku: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
  descriptionRow: {
    margin: '0.5em 0',
  },
  table: {
    marginTop: '10px',
    display: 'flex',
    padding: '0 10px',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'column',
  },
  row: {
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  cart: {
    height: '35',
    width: '35',
    cursor: 'pointer',
  },
  nameCell: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  cellCentred: {
    textAlign: 'left',
    padding: '0 5px',
  },
  productName: {
    fontSize: '0.9em',
    fontWeight: '500',
  },
  discountValue: {
    color: theme.palette.primary.blue,
  },
  discountPrice: {
    marginBottom: '10px',
  },
  selectedPage: {
    color: theme.palette.primary.buttonColor + ' !important',
  },

  tableCell: {
    verticalAlign: 'middle',
    padding: '5px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  lastCell: {
    borderBottom: 'unset',
  },
  Table: {
    width: '100%',
    margin: '1em auto',
    fontSize: '0.8em',
  },
  cartIcon: {
    padding: 0,
  },
  available: {
    cursor: 'pointer',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailsRoot: {
    padding: 0,
  },
});
