import { put } from 'redux-saga/effects';

import { FIND_PRODUCT } from '../constants/api';

import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';
import { setSearchResult } from '../actions/search';

export function* findProducts({ payload }) {
  try {
  	payload.perPage = 24;
    const searchResult = yield callHttp(get, FIND_PRODUCT(payload));

    yield put(setSearchResult(searchResult));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}
