import React from 'react';

import styles from './style';
import { connectTo } from '../../utils/generic';
import { withStyles } from '@material-ui/core/styles';
import { getTerm } from '../../actions/metadata';
import Page from '../page';

class ContentPage extends React.Component {
  componentDidMount() {

    const alias = this.props.match.params.alias;
    this.props.getTerm({ alias: alias });
  }

  componentDidUpdate() {
    //!this.props.token && this.props.history.replace('/');
  }

  render() {
    const { classes, term } = this.props;
    return (
      <Page>
        <div className={classes.content}>
          <div className="order-document main-content">
            <div dangerouslySetInnerHTML={{ __html: term.description }} />
          </div>
        </div>
      </Page>
    );
  }
}

export default connectTo(
  state => ({
    token: state.auth.token,
    term: state.metadata.term
  }),
  {
    getTerm
  },
  withStyles(styles)(ContentPage)
);
