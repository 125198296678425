import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import styles from './style';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const DeleteUser = props => {
  const { classes, open, handleClose, t, handleDelete } = props;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      className="modal"
      onClose={handleClose}
    >
      <div className={classes.paper}>
        <div className={classes.topLine} />
        <div className={classNames(classes.modalHeader, 'bold')}>
          <div className={classes.headerContent}>{t('Are You Sure?')}</div>
          <div className={classes.closeContainer} onClick={handleClose}>
            <img
              alt="cross"
              height="20"
              width="20"
              src="/images/icons/X-black.png"
            />
          </div>
        </div>
        <div className={classes.bottomButtons}>
          <Button className={classes.button} onClick={handleDelete}>
            {t('Ok')}
          </Button>
          <Button className={classes.button} onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withNamespaces('Delete User Modal')(
  withStyles(styles)(DeleteUser)
);
