import React from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { formatMoney } from '../../../../../utils/localization';
import { connectTo } from '../../../../../utils/generic';
import { updateProduct, deleteProduct } from '../../../../../actions/cart';
import Summary from './summary';
import {openLoginForm} from "../../../../../actions/auth";

const Cart = (props) => {
  const { t, classes, cart, visible, token, openLoginForm } = props;

  return (
    <div
      className={classes.main}
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <div className={classes.triangleContainer}>
        <div className={classes.emptyLeft} />
        <div className={classes.triangle} />
        <div className={classes.empty} />
      </div>
      <Paper className={classes.rectangleContent}>
        <div className='machine-table'>
          {cart.items && cart.items.length ? (
            <div>
              <div className={classes.header}>
                <div className={classes.count}>{cart.itemsQuantity}</div>
                <div className={classes.tableTitle}>{t('itemsInCart')}</div>
              </div>
              <div>
                <Table className='scroll'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('data')}</TableCell>
                      <TableCell className={classes.centred}>
                        {t('quantity')}
                      </TableCell>
                      <TableCell className={classes.centred}>
                        {t('price')}
                      </TableCell>
                      <TableCell className={classes.centred} />
                      {cart.items.length > 3 && (
                        <TableCell className='fake-scroll' />
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cart.items.map((row, i) => {
                      const rowPrice = row.discountPrice || row.price;
                      return (
                        <TableRow key={i}>
                          <TableCell className={classes.centred}>
                            <div className='image-cropper'>
                              <img
                                alt={row.product.name}
                                className={classes.img}
                                src={
                                  row.product.image?.length
                                    ? row.product.image[0].url
                                    : '/images/product_example_image.png'
                                }
                              />
                            </div>
                          </TableCell>

                          <TableCell
                            className={classes.nameCell}
                            title={row.product.name}
                            onClick={() =>
                              (window.location.href = `/product/${row.product.alias}`)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {row.product.name}
                          </TableCell>

                          <TableCell className={classes.centred}>
                            {row.quantity}
                          </TableCell>
                          <TableCell className={classes.centred}>
                            {rowPrice
                              ? formatMoney(rowPrice * row.quantity)
                              : t('requestPrice')}
                          </TableCell>
                          <TableCell className={classes.centred}>
                            <img
                              alt='cart'
                              className='icon-default clickable  cart-icon deleteIcon'
                              onClick={() => {
                                props.deleteProduct({
                                  code: row.code, //row.product ? row.product.sku : row.sku
                                });
                              }}
                              title={t('tooltips:delete')}
                              src='/updated_images/svg/bin 15px.svg'
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>

              {cart.total && <Summary cart={cart} />}

              <div className={classes.buttons}>
                <Link to='/cart'>
                  <Button
                    className={classNames(classes.button, classes.emptyButton)}
                  >
                    {t('toCart')}
                  </Button>
                </Link>
                {token ?
                    <Link to='/checkout'>
                      <Button
                        className={classNames(classes.button, classes.filledButton)}
                      >
                        {t('confirmation')}
                      </Button>
                    </Link> :
                    <Button
                        className={classNames(classes.button, classes.filledButton)}
                        onClick={() => openLoginForm({centered:true})}
                    >
                      {t('confirmation')}
                    </Button>
                }
              </div>
            </div>
          ) : (
            <h3 className={classes.emptyCart}>{t('Cart Is Empty')}</h3>
          )}
        </div>
      </Paper>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if (
    prevProps.cart.items &&
    nextProps.cart.items &&
    nextProps.cart.items.length !== prevProps.cart.items.length
  )
    return false;

  if (
    nextProps.cart.items &&
    nextProps.cart.items.length === 0 &&
    nextProps.visible === false &&
    prevProps.visible === true
  )
    return false;

  return (
    !nextProps.cart.items ||
    !nextProps.cart.items.length ||
    nextProps.visible === prevProps.visible
  );
}

export default connectTo(
  (state) => ({
    cart: state.cart.cart,
    token: state.auth.token
  }),
  {
    updateProduct,
    deleteProduct,
    openLoginForm
  },
  withNamespaces(['checkoutPage', 'tooltips'])(withStyles(styles)(React.memo(Cart, areEqual)))
);
