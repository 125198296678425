export default (theme) => ({
  content: {
    width: '250px',
    height: '350px',
    border: '1px solid white',
    marginBottom: '45px',
    marginLeft: '9px',
  },
  image: {
    height: '50%',
    background: 'black',
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    height: 'calc(50% - 20px)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'lightgray',
  },
  name: {
    textTransform: 'uppercase',
    // color: theme.palette.primary.buttonColor,
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '5px',
    fontSize: '26px',
    lineHeight: '28px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },
  matNumber: {
    color: 'gray',
    fontSize: '0.75em',
    marginBottom: '5px',
  },
  descr: {
    fontSize: '0.8125em',
    lineHeight: '1.5em',
    textAlign: 'justify',
    marginBottom: '15px',
    height: '40px',
    overflowY: 'hidden',
  },
  price: {
    fontSize: '1.5em',
    textAlign: 'right',
    color: theme.palette.primary.buttonColor,
    fontWeight: '300',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    '-ms-flex': 1,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  priceLineThrough: {
    fontSize: '.7em',
    color: theme.palette.primary.blackPrice,
    textDecoration: 'line-through',
  },
  deliveryTime: {
    marginBottom: 0
  }
});
