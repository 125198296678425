import { put } from 'redux-saga/effects';

import { GET_ARTICLE, GET_ARTICLES } from '../constants/api';

import { setArticle, setArticles } from '../actions/news';
import { callHttp } from '../utils/api';
import { get } from '../utils/httpUtil';
import { openErrorSnack } from '../actions/startpage';

export function* getArticles() {
  try {
    const articles = yield callHttp(get, GET_ARTICLES);
    yield put(setArticles(articles));
  } catch (err) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getArticle({ payload }) {
  const { alias } = payload;

  try {
    const article = yield callHttp(get, GET_ARTICLE(alias));
    yield put(setArticle(article));
  } catch (err) {
    if (
      err.status === 303 &&
      err.message.lang === localStorage.getItem('i18nextLng')
    ) {
      window.location.href = '/article/' + err.message.alias;
    }
  }
}
