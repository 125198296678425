import { createAction } from "redux-act";

export const setIndividualProduct = createAction("setIndividualProduct");
export const getIndividualProduct = createAction("getIndividualProduct");

export const setProduct = createAction("setProduct");
export const getProduct = createAction("getProduct");

export const openInquiry = createAction("openInquiry");
export const closeInquiry = createAction("closeInquiry");

export const setInquiryProduct = createAction("setInquiryProduct");

export const sendInquiryProduct = createAction("sendInquiryProduct");

export const getMachine = createAction("getMachine");

export const getMachineSensorData = createAction("getMachineSensorData");
export const setMachineSensorData = createAction("setMachineSensorData");

export const getDocument = createAction("getDocument");

export const getStandardProducts = createAction("getStandardProducts");
export const setStandardProducts = createAction("setStandardProducts");

export const selectProduct = createAction("selectProduct");
export const getProductToTree = createAction("getProductToTree");
//export const getMyProductToTree = createAction('getMyProductToTree')
export const setProductToTree = createAction("setProductToTree");
export const getSelectedProduct = createAction("getSelectedProduct");
//export const getMySelectedProduct = createAction('getSelectedProduct')

export const getMyProduct = createAction("getMyProduct");
export const setMyProduct = createAction("setMyProduct");

export const getProductTypes = createAction("getProductTypes");
export const setProductTypes = createAction("setProductTypes");

export const getMachineByType = createAction("getMachineByType");
export const setMachinesByType = createAction("setMachineByType");

export const setProductType = createAction("setProductType");
export const closeTypeModal = createAction("closeTypeModal");

export const getImages = createAction("getImages");
export const setImages = createAction("setImages");
export const getImage = createAction("getImage");
export const setImage = createAction("setImage");

export const showDetails = createAction("showDetails");
export const hideDetails = createAction("hideDetails");

export const getMyProductToTypes = createAction("getMyProductToTypes");
export const addMyProductToTypes = createAction("addMyProductToTypes");

export const setHighlightedProduct = createAction("setHighlightedProduct");
