import React from 'react';

import { Link } from 'react-router-dom';

import { connectTo } from '../../../../utils/generic';
import { getSettings } from '../../../../actions/static';

const getLogo = settings => {
  if (settings && settings.length) {
    const logo = settings.find(x => x.name === 'logo_url');
    if (logo) return { logoUrl: logo.value };
  }
  return null;
};

class Logo extends React.Component {
  state = {
    logoUrl: '/images/icons/logo.png'
  };

  shouldComponentUpdate = (nextProps, nextState) =>
    nextState.logoUrl !== this.state.logoUrl;

  componentDidMount() {
    if (!getLogo(this.props.settings)) this.props.getSettings();
  }

  static getDerivedStateFromProps(props) {
    return getLogo(props.settings);
  }

  render() {
    const homeLink = this.props.token ? '/home' : '/';

    return (
        <div>
        <Link to={homeLink}>
          <img alt="logo" src={this.state.logoUrl} />
        </Link>
      </div>
    )
  };
}

export default connectTo(
  state => ({
    settings: state.staticContent.settings
  }),
  {
    getSettings
  },
  Logo
);
