import csv from 'csvtojson';


const fetchOrderData = data => {
  // Delete separator
  if (data[0][0] === "SEP=")
    data.shift();
  // Delete header
  data.shift();
  return data.map(item => ({ code: item[0], quantity: parseInt(item[1], 10) }))
}

export const csvReader = (files, onLoad) => {
  const reader = new FileReader();
  reader.onload = evt => {
    csv({
      output: "csv",
      noheader: true,
      delimiter: [",",";"]
    })
      .fromString(evt.target.result)
      .then(jsonObj => {
        let load = {products: fetchOrderData(jsonObj), isCSVuploaded: true} //isCSVuploaded = true => a csv file is uploaded
        onLoad(load);
      })

  };
  reader.readAsText(files);
}
