import { createReducer } from 'redux-act'
import * as a from '../actions/tips'

const DEFAULT_STATE = {
  tips: {
    "service": [],
    "bill": [],
    "shipping": []
  }
}

export default createReducer(
  {
    [a.setFooterTips]: (state, { tips }) => ({
      ...state,
      tips: tips,
    })
  },
  DEFAULT_STATE
)