import React from 'react';

import styles from './style';
import { withStyles } from '@material-ui/core/styles';

import Arrow from '../../../common/Arrow';
import Pagination from '../../../common/carousel/Pagination';
import { connectTo } from '../../../../utils/generic';
import { setHighlightedProduct } from '../../../../actions/products';
import { hexToRgb } from '../../../../utils/table';

class Carousel extends React.Component {
  state = {
    pos: 0,
    arrowsVisibleLeft: false,
    arrowsVisibleRight: true,
    pageSize: 1,
    widthScale: 1,
    heightScale: 1,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.images.length !== this.props.images.length)
      this.setState({ pos: 0 });
    if (prevProps.images !== this.props.images) {
      this.props.setHighlightedProduct(null);
    }
  }

  updateDimensions = (i) => {
    this.props.images &&
      this.props.images.length &&
      this.setState({
        // heightScale: this.refs[`Image${i}`].naturalHeight / this.refs[`Image${i}`].height,
        // widthScale: this.refs[`Image${i}`].naturalWidth / this.refs[`Image${i}`].width
        heightScale: this.refs[`Image${i}`].height,
        widthScale: this.refs[`Image${i}`].width,
      });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.images.length === 1)
      return { arrowsVisibleLeft: false, arrowsVisibleRight: false };
    if (state.pos === 0)
      return { arrowsVisibleRight: props.images.length > state.pageSize };

    return null;
  }

  nextPage = () => {
    this.checkPosition(this.state.pos + 1);
    if (this.state.pos < this.props.images.length - 1)
      this.setState({ pos: this.state.pos + 1 });
  };

  prevPage = () => {
    this.checkPosition(this.state.pos - 1);
    if (this.state.pos > 0) this.setState({ pos: this.state.pos - 1 });
  };

  handleChangePage = (page) => {
    this.checkPosition(page);
    this.setState({ pos: page });
  };

  checkPosition = (pos) => {
    this.setState({
      arrowsVisibleLeft: pos > 0,
      arrowsVisibleRight: pos < this.props.images.length - this.state.pageSize,
    });
  };

  handleHotSpotClick = (point) => {
    const { tableRef } = this.props;
    this.props.setHighlightedProduct(point.value);
    window.scrollTo({
      top: tableRef.current.scrollIntoView(),
      behavior: 'smooth', // optional
    });
  };

  render() {
    const { classes, images, selectedLabel, theme } = this.props;
    const { arrowsVisibleLeft, arrowsVisibleRight } = this.state;
    const buttonColor = hexToRgb(theme.palette.primary.buttonColor);

    return (
      <div className={classes.width}>
        <div className={classes.carousel}>
          <Arrow
            src={`/images/icons/arrow-up-gray.svg`}
            arrowVisible={arrowsVisibleLeft}
            handleClick={() => this.prevPage()}
            className={classes.arrowUp}
            arrowClass={classes.arrow}
          />

          <div className={classes.list}>
            <div
              className={classes.slider}
              style={{
                transform: `translateX(-${this.state.pos * 100}%)`,
                left: 0,
              }}
            >
              {images.map((schema, k) => (
                <div key={k} className={classes.fullItem}>
                  {schema.matches && schema.matches.length
                    ? schema.matches.map((m, i) => {
                        return (
                          <div
                            className={classes.hotSpot}
                            key={i}
                            style={{
                              width: `${(m.width * 100) / schema.width}%`,
                              height: `${(m.height * 100) / schema.height}%`,
                              top: `${(m.yCoord * 100) / schema.height}%`,
                              left: `${(m.xCoord * 100) / schema.width}%`,
                              backgroundColor:
                                selectedLabel === m.value
                                  ? `rgba(${buttonColor.r}, ${buttonColor.g}, ${buttonColor.b}, 0.27)`
                                  : 'transparent',
                            }}
                            onClick={() => this.handleHotSpotClick(m)}
                          />
                        );
                      })
                    : null}

                  <img
                    // onLoad={() => this.updateDimensions(i)}
                    ref={'Image' + k}
                    alt='schema'
                    src={
                      schema.url
                        ? schema.url
                        : '/images/product_example_image.png'
                    }
                    className={classes.item}
                    style={{ left: `calc(${k * 100}%` }}
                  />
                </div>
              ))}
            </div>
          </div>

          <Arrow
            src={`/images/icons/arrow-down-gray.svg`}
            arrowVisible={arrowsVisibleRight}
            handleClick={() => this.nextPage()}
            className={classes.arrowDown}
            arrowClass={classes.arrow}
          />
        </div>
        {images.length > 1 ? (
          <Pagination
            curPage={this.state.pos}
            totalPages={images.length - this.state.pageSize + 1}
            handleClick={this.handleChangePage}
            style={{
              width: '10px',
              height: '10px',
              borderWidth: '1px',
            }}
            wrapperStyle={{
              marginTop: '10px',
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connectTo(
  (state) => ({
    selectedLabel: state.productHome.highlightedProductLabel,
  }),
  {
    setHighlightedProduct,
  },
  withStyles(styles, { withTheme: true })(Carousel)
);
