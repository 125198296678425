export default (theme) => ({
  content: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '100%'

    }
  },
  logoColumn: {
    width: '25%',
  },
  img: {
    display: 'block',
    marginLeft: '25%',
    marginRight: '20%',
    marginBottom: '25%',
    width: '55%',
    height: 'auto',
  },
  mainColumn: {
    width: '75%',
    marginRight: '5%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      justifyContent: 'flex-start'
    }
  },
  title: {
    fontSize: '0.95em',
    marginBottom: '1px',
  },
  afterTitle: {
    fontSize: '1em',
  },
  contentRow: {
    width: '100%',
    display: 'flex',
    margin: '3px 0',
  },
  lastContentRow: {
    width: '100%',
    display: 'flex',
    margin: '3px 0 20px 0',
  },
  textField: {
    width: '100%',
    marginBottom: '2px',
  },
  multiField: {
    marginRight: '20px',
  },
  button: {
    minWidth: '160px',
    backgroundColor: theme.palette.primary.buttonColor,
  },
  textInput: {
    background: 'white',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
  },
  input: {
    padding: 0
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  header: {
    color: 'white'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  genderRow: {
    marginBottom: '5px',
    marginTop: '5px',
  },
  radioButton: {
    '&.MuiRadio-root': {
      color: 'white'
    },
    '&.Mui-checked': {
      color: theme.palette.primary.buttonColor
    }
  },
  inquiryBtnRoot: {
    '&:hover': {
      color: 'white',
    },
  },
});
