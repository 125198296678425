export default theme => ({
  page: {
    borderRadius: '50%',  
    height: '15px',
    width: '15px',
    backgroundColor: '#a0a0a0',
    margin: '3px'
  },
  selected: {
    backgroundColor: theme.palette.primary.buttonColor
  },
  pages: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
});
