export default (theme) => ({
  link: {
    // display: 'inline-block',
    // height: '100%',
  },
  titleContent: {
    margin: '1.5em 1em 0',
    fontSize: '20px',
    width: '100%',
  },
  mainTitle: {
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '1.8em',
    textTransform: 'uppercase',
    color: theme.palette.primary.buttonColor,
  },
});
