export default theme => ({
  container: {
    border: `1px solid ${theme.palette.primary.darkGrey}`,
    borderRadius: '3px',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.darkGrey}`,
    background: '#f8f8f8',
    '-moz-box-shadow': 'inset 0 0 50px #FFFFFF',
    '-webkit-box-shadow': 'inset 0 0 50px #FFFFFF',
    boxShadow: 'inset 0 0 50px #FFFFFF',
    padding: '20px',
    textTransform: 'uppercase',
    fontSize: '0.8125em'
  },
  rows: {
    padding: '10px'
  },
  subHeader: {
    borderBottom: `2px solid ${theme.palette.primary.buttonColor}`,
    padding: '5px 0',
    fontSize: '0.75em',
  },
  items: {
    margin: '5px 0'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    fontSize: '0.8125em'
  },
  cartSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    fontSize: '0.9em'
  },
  rowMainTax: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${theme.palette.primary.buttonColor}`,
    padding: '5px 0',
  },
  rowTax: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.8125em',
    padding: '4px 0',
  },
  rowResult: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.1em',
    padding: '20px 0'
  },
  price: {
    whiteSpace: 'nowrap'
  }
});