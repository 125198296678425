import { createReducer } from 'redux-act';

import * as a from '../actions/navigation';

import { logout, receiveAuthData } from '../actions/auth';

const DEFAULT_STATE = {
  page: 'start',
  entityId: null,
  data: null
};

export default createReducer(
  {
    [a.to]: (state, { page }) => {
      return { ...state, page };
    },
    [receiveAuthData]: state => ({ ...state, page: 'start' }),
    [logout]: state => ({ ...state, page: 'login' })
  },
  DEFAULT_STATE
);
