export default (theme) => ({
  paper: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: '550px',
    minWidth: '270px',
    outline: 'none',
    borderTop: `7px ${theme.palette.primary.buttonColor} solid`,
    padding: '20px 30px',
  },
  topLine: {
    width: '100%',
    backgroundColor: theme.palette.primary.buttonColor,
    height: '7px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  header_content: {
    fontSize: '1.5em',
  },
  closeContainer: {
    cursor: 'pointer',
  },
});
