export default theme => ({
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  priceValue: {
    fontSize: '1.5em',
    marginLeft: '5px',
    width: '140px',
    color: 'black'
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right'
  },
  discountDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    color: theme.palette.primary.blue,
    marginTop: '10px'
  },
  taxText: {
    fontSize: '0.8em'
  },
  discount: {
    //minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    }
  },
  enterDiscountText: {
    marginBottom: '15px',
    color: theme.palette.primary.blue
  },
  discountBlock: {
    display: 'flex',
  },
  removeDiscount: {
    marginTop: '15px',
    cursor: 'pointer',
    color: theme.palette.primary.lightRed,
    textDecoration: 'underline',
  },
  button: {
    backgroundColor: theme.palette.primary.buttonColor,
    color: '#fff',
    marginBottom: '10px'
  },
  textInput: {
    marginBottom: '10px',
    marginRight: '10px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px'
    }
  },
  priceText: {
    color: 'black'
  },
  descriptionField: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center'
  }
});