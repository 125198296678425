export default theme => ({
  priceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0'
  },
  priceValue: {
    fontSize: '1.5em',
    marginLeft: '5px',
    width: '140px',
    color: '#000'
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right'
  },
  taxText: {
    fontSize: '0.8em'
  },
  priceText: {
    color: '#000'
  }
});
